import React, {useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import '../../components/Style List View/style.css';
import ElementList from "../../components/ElementList/ElementList";
import {useNavigate, useParams} from "react-router-dom";
import AddMemberModal from "../../components/Modals/ModalAddNewMember/AddNewMember";
import UserList from "../../components/ProjectEditComponents/UserList";

import {fetchProjectById, updateProject, updateProjectPermissions} from "../../Slice/updateProject/updateProjectSlice";
import {RootState} from "../../store";
import {setProjectTitle, updateProjectTitle} from "../../Slice/projectById/projectByIdSlice";
import ProjectDetailsHeader from "../../components/ProjectEditComponents/ProjectDetails";
import {Loader} from "../../components/Loader/Loader";
import {
    notifySuccessProject,

} from "../../components/Toast/AssisCreateToastNotification";
import MobileNavigation from "../../components/MobileNavigation/MobileNavigation";
import {IconUsersOnPage} from "../../IconComponents/IconComponents";

const ProjectEdit: React.FC<PropsFromRedux> = ({
                                             project,
                                             fetchProjectById,
                                             isFetching,
                                             error,
                                             setProjectTitle,
                                             updateProjectTitle,
                                             permissionsUpdated,
                                             isAuthenticated
                                         }) => {
    const {projectId} = useParams<{ projectId: string }>();
    const navigate = useNavigate()
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isEditingTitle, setIsEditingTitle] = useState<boolean>(false);
    const [isEditingDate, setIsEditingDate] = useState<boolean>(false);
    const [newTitle, setNewTitle] = useState<string>('');
    const [targetDate, setTargetDate] = useState<string>('');
    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerWidth <= 920);

    useEffect(() => {
        if (isAuthenticated) {
            if (projectId) {
                fetchProjectById(projectId);
            }
        } else {
            navigate('/home')
        }
    }, [projectId, isAuthenticated]);

    useEffect(() => {
        if (project) {
            setNewTitle(project.title);
            setTargetDate(project.target_date || '');
        }
    }, [project]);

    useEffect(() => {
        if (permissionsUpdated && projectId) {
            fetchProjectById(projectId);
        }
    }, [permissionsUpdated, projectId]);

    const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 920);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const handleEditTitleClick = () => {
        setIsEditingTitle(true);
        setIsEditingDate(false);
    };

    const handleEditDateClick = () => {
        setIsEditingDate(true);
        setIsEditingTitle(false);
    };

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewTitle(e.target.value);
    };

    const handleTargetDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTargetDate(e.target.value);
    };

    const handleBlur = () => {
        setIsEditingTitle(false);
        setIsEditingDate(false);
    };

    const handleSave = () => {
        const updateData: { title?: string; target_date?: string } = {};

        if (newTitle !== project?.title) {
            updateData.title = newTitle;
        }
        if (targetDate !== project?.target_date) {
            updateData.target_date = targetDate;
        }

        if (project) {
            const updatedData: { projectId: number; title?: string; target_date?: string } = {projectId: project.id};
            let toastMessage = '';
            if (updateData.title) {
                updatedData.title = updateData.title;
                setProjectTitle({projectId: project.id, title: updateData.title, target_date: project.target_date});
                toastMessage += `Project title successfully renamed to "${updateData.title}". `;
            }
            if (updateData.target_date) {
                updatedData.target_date = updateData.target_date;
                setProjectTitle({projectId: project.id, title: project.title, target_date: updateData.target_date});
                toastMessage += `Project completion date successfully changed to ${updateData.target_date}.`;
            }
            if (Object.keys(updateData).length > 0) {
                updateProjectTitle(updatedData);
                if (toastMessage) {
                    notifySuccessProject(toastMessage.trim());
                }
            }
        }
    };


    if (isFetching) return <Loader/>
    if (error) return <p>Error: {error}</p>;
    const columnsWidthClasses = ['width-user-username', 'width-user-email',
        "width-user-address", "width-user-project-group", "width-user-permission", "width-user-action-btn"];

    const role = localStorage.getItem('role')
    return (
        <>
            {isSmallScreen && (
                <MobileNavigation
                    links={[
                        {to: '/', label: 'Cashbuild'},
                        {to: '', label: '>'},
                        {to: '/', label: 'Home'},
                        {to: '', label: '>'},
                        {to: '/assistance', label: 'Project assistance'},
                        {to: '', label: '>'},
                        {to: '/team', label: 'Project edit'}
                    ]}
                >
                </MobileNavigation>
            )}
            <div>
                <div className='mh'>
                    {project && (
                        <ProjectDetailsHeader
                            key={project.id}
                            project={project}
                            isEditingTitle={isEditingTitle}
                            isEditingDate={isEditingDate}
                            newTitle={newTitle}
                            targetDate={targetDate}
                            handleEditTitleClick={handleEditTitleClick}
                            handleEditDateClick={handleEditDateClick}
                            handleTitleChange={handleTitleChange}
                            handleTargetDateChange={handleTargetDateChange}
                            handleBlur={handleBlur}
                            isSmallScreen={isSmallScreen}
                        />
                    )}
                </div>
                <div className='name-content-users'>
                    <div className='name-content-users-block'>
                        {isSmallScreen ? (
                            <div className='name-content-users-mobile'>
                                <p>Users</p>
                                <IconUsersOnPage/>
                            </div>
                        ) : (
                            <><p>Users</p><IconUsersOnPage/></>
                        )}
                    </div>
                    {role !== 'Customer' && (
                        <button onClick={() => setIsModalOpen(true)} className='mh-btn-member-users'>+ Add new member
                    </button>
                    )}
                    <AddMemberModal
                        pageTeamManagement={false}
                        isOpen={isModalOpen}
                        onRequestClose={() => setIsModalOpen(false)}
                        projectId={projectId}
                        onPermissionsUpdated={() => fetchProjectById(String(project?.id))}
                        showProjects={false}
                    />
                </div>
                {!isSmallScreen && (
                    <ElementList columnsWidthClasses={columnsWidthClasses}
                                 columns={['User', 'E-mail', 'Address', 'Project group', 'Access rights', 'Action']}
                                 isShow={false}/>
                )}
                {project && project.permissions && (
                    <UserList isSmallScreen={isSmallScreen}/>
                )}
                <div className='btn-save'>
                    <button onClick={handleSave} className='btn-save-edits'>Save edits</button>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    project: state.updateProject.currentProject,
    isFetching: state.updateProject.isFetching,
    error: state.updateProject.error,
    permissionsUpdated: state.updateProject.permissionsUpdated,
    isAuthenticated: state.auth.isAuthenticated
});

const mapDispatchToProps = {
    fetchProjectById,
    updateProject,
    setProjectTitle,
    updateProjectTitle,
    updateProjectPermissions,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ProjectEdit);
