import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './AuthStyle.css';
import { PuffLoader } from "react-spinners";
import { post } from "../../api/APIWraper";
import {IconHidePassword, IconShowPassword} from "../../IconComponents/IconComponents";

interface AuthFields {
    email: string;
    password: string;
    confirmPassword: string;
    username: string;
}

const Registration: React.FC = () => {
    const navigate = useNavigate();

    const [authFields, setAuthFields] = useState<AuthFields>({
        email: "",
        password: "",
        confirmPassword: "",
        username: ""
    });

    const [isBadEmail, setIsBadEmail] = useState(false);
    const [isBadConfirmPassword, setIsBadConfirmPassword] = useState(false);
    const [isBadUsername, setIsBadUsername] = useState(false);
    const [isWaitingForSignIn, setIsWaitingForSignIn] = useState(false);

    const validEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const validPasswordRegex = /^(?!\s*$).+/;

    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);


    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const togglePasswordConfirmVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const email = e.target.value;
        setAuthFields({ ...authFields, email });
        setIsBadEmail(!validEmailRegex.test(email));
    };

    const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const username = e.target.value;
        setAuthFields({ ...authFields, username });
        setIsBadUsername(username.length < 6);
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAuthFields({
            ...authFields,
            password: e.target.value
        });
    };

    const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAuthFields({
            ...authFields,
            confirmPassword: e.target.value
        });
    };

    const handleConfirmPasswordBlur = () => {
        if (authFields.password !== authFields.confirmPassword) {
            setIsBadConfirmPassword(true);
        } else {
            setIsBadConfirmPassword(false);
        }
    };

    const validateForm = () => {
        const { email, password, confirmPassword, username } = authFields;

        if (!validEmailRegex.test(email)) {
            toast.error("Invalid email format");
            return false;
        }
        if (username.length < 6) {
            toast.error("Username must be at least 6 characters long");
            return false;
        }
        if (!validPasswordRegex.test(password)) {
            toast.error("Password must not be empty");
            return false;
        }
        if (password !== confirmPassword) {
            toast.error("Passwords do not match");
            return false;
        }

        return true;
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!validateForm()) return;

        setIsWaitingForSignIn(true);
        try {
            const { email, password, username } = authFields;
            const response = await post(`${process.env.REACT_APP_BASE_URL_CUSTOMER}/register`, { email, password, username });

            if (response.status === 400) {
                toast.error("Email is already registered");
            } else if (response.status === 201) {
                toast.success("Registration successful");
                navigate("/auth/login");
            } else {
                toast.error("Registration failed");
            }
        } catch (error) {
            toast.error("An error occurred during registration");
        } finally {
            setIsWaitingForSignIn(false);
        }
    };

    const handleForgotPassword = () => {
        navigate("/auth/forgot-password");
    };

    const handleLogin = () => {
        navigate("/auth/login");
    };

    return (
        <div className="layout-login">
            <div className="register-card">
                <div className="login-card-top">
                    <h3>Create An Account</h3>
                </div>

                <form className="register-card-form" onSubmit={handleSubmit}>
                    <div>
                        <div className={`login-card-form-input-container ${isBadEmail ? 'red-font-error' : ''}`}>
                            <label className={`login-card-form-label ${isBadEmail ? 'red-font-error' : ''}`}>Email
                                <span style={{color: 'green', marginLeft: '5px'}}>*</span></label>
                            <input
                                className={`login-card-form-input 
                                ${isBadEmail ? 'red-font-error ' : ''}
                                `}
                                type="email"
                                value={authFields.email}
                                onChange={handleEmailChange}
                                placeholder="Email *"
                            />
                        </div>
                    </div>

                    <div>
                        <div className={`login-card-form-input-container ${isBadUsername ? 'red-font-error' : ''}`}>
                            <label className={`login-card-form-label ${isBadEmail ? 'red-font-error' : ''}`}>Username
                                <span style={{color: 'green', marginLeft: '5px'}}>*</span></label>
                            <input
                                className={`login-card-form-input`}
                                type="text"
                                value={authFields.username}
                                onChange={handleUsernameChange}
                                placeholder="Username *"
                            />
                        </div>
                        {isBadUsername &&
                            <p className="login-card-form-input-message">Username must be at least 6 characters</p>}
                    </div>

                    <div>
                        <div className="login-card-form-input-container">
                            <label className={`login-card-form-label ${isBadEmail ? 'red-font-error' : ''}`}>Password
                                <span style={{color: 'green', marginLeft: '5px'}}>*</span></label>
                            <input
                                className="login-card-form-input"
                                type={showPassword ? "text" : "password"}
                                value={authFields.password}
                                onChange={handlePasswordChange}
                                placeholder="Password *"
                            />
                            <span
                                onClick={togglePasswordVisibility}
                                className='login-card-show-password'
                            >
                                {showPassword ? <IconShowPassword/> : <IconHidePassword/>}</span>
                        </div>
                    </div>

                    <div>
                        <div className="login-card-form-input-container">
                            <label className={`login-card-form-label ${isBadEmail ? 'red-font-error' : ''}`}>Confirm
                                password
                                <span style={{color: 'green', marginLeft: '5px'}}>*</span></label>
                            <input
                                className={`login-card-form-input ${isBadConfirmPassword ? 'red-font-error' : ''}`}
                                type={showConfirmPassword ? "text" : "password"}
                                value={authFields.confirmPassword}
                                onChange={handleConfirmPasswordChange}
                                onBlur={handleConfirmPasswordBlur}
                                placeholder="Confirm Password *"
                            />
                            <span
                                onClick={togglePasswordConfirmVisibility}
                                className='login-card-show-password'
                            >
                                {showConfirmPassword ? <IconShowPassword/> : <IconHidePassword/>}</span>
                        </div>
                        {isBadConfirmPassword && (
                            <p className="login-card-form-input-message">Passwords do not match</p>
                        )}
                    </div>

                    <div className='login-card-optionals-btn'>

                        <div className='login-card-remember-me'>
                            <label title='' className='login-card-remember-me-label'>
                                <input
                                    type="checkbox"
                                    className='login-card-checkbox-remember'
                                />
                                I agree with Terms and Conditions
                            </label>
                        </div>

                        <p
                            className= "login-card-form-button-forgot"
                        >
                            Click here to view
                        </p>
                    </div>

                    <button
                        className={`login-card-form-button ${isWaitingForSignIn ? 'gray-background' : ''}`}
                        type="submit"
                    >
                        {!isWaitingForSignIn && "Create an Account"}
                        {isWaitingForSignIn &&
                            <div className="login-loading-spin">
                                <PuffLoader size={25}/>
                            </div>
                        }
                    </button>
                    <div className='register-bottom-password-block'>
                        <p className='register-bottom-question'>Already have an account?</p>
                        <p><span onClick={handleLogin} className='register-bottom-btn'>Log In</span> or <span
                            onClick={handleForgotPassword} className='register-bottom-btn'>Reset password</span></p>
                    </div>
                </form>
            </div>

            <ToastContainer
                position="bottom-center"
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                toastStyle={{
                    backgroundColor: "#333",
                    color: "#fff",
                    fontSize: "16px",
                    borderRadius: "8px",
                    padding: "16px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                }}
            />
        </div>
    );
};

export default Registration;
