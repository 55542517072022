import React, {  useState } from 'react';
import { useNavigate } from "react-router-dom";
import './style.css';
import { IconArrowAssistance, IconCreateProject, IconReplaceImage } from "../../IconComponents/IconComponents";
import { PostFormData } from "../../api/ApiWrapperImage";
import { toast } from "react-toastify";
import { BOMProject } from "../../types/ProjectBOMInterface";


const BASE_URL_CONTRACTOR = process.env.REACT_APP_BASE_URL_CONTRACTOR;

interface AllProjectsOwnProps {
    projectsBom: BOMProject[]
}

const BOMAllProjects: React.FC<AllProjectsOwnProps> = ({
                                                           projectsBom
                                                       }) => {
    const navigate = useNavigate();
    const [localProjectImages, setLocalProjectImages] = useState<{ [key: number]: string }>({});

    const uploadProjectImage = async (projectId: number, imageFile: File) => {
        const formData = new FormData();
        formData.append("file", imageFile);
        formData.append("projectId", projectId.toString());

        try {
            const response = await PostFormData(
                BASE_URL_CONTRACTOR || "",
                `/bom_projects/${projectId}/media`,
                formData
            );

            if (response.status !== 200 && response.status !== 201) {
                throw new Error("Failed to upload image");
            }
            setTimeout(() => {
                toast.success('You have successfully updated the image');
            }, 500);
            return response.data;
        } catch (error) {
            console.error('Error uploading image:', error);
            throw error;
        }
    };

    const handleImageChange = async (projectId: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const localUrl = URL.createObjectURL(file);
            setLocalProjectImages(prevState => ({
                ...prevState,
                [projectId]: localUrl,
            }));
            try {
                await uploadProjectImage(projectId, file);
            } catch (error) {
                console.error('Error uploading image:', error);
            }
        }
    };

    const handleNavigate = () => {
        navigate('/assistance/step1Bom');
    };


    return (
        <>
            <div>
                <div className='assistance-name-content'>
                    <p>Custom BOM's</p>
                    <div className='assistance-name-content-block' onClick={() => navigate(`/assistance/projectsBom`)}>
                        <span>/</span>
                        <p>Current all BOM's</p>
                        <IconArrowAssistance />
                    </div>
                </div>
                <div className='assistance-main'>
                    <div className='assistance-main-container'>
                        <div onClick={handleNavigate} className='assistance-main-new'>
                            <section className='assistance-main-new-create-project-container'>
                                <IconCreateProject />
                                <p className='assistance-main-new-create-project-title'>Create new BOM</p>
                            </section>
                        </div>
                        {projectsBom.slice(-5).map((project) => {
                            const projectImage = localProjectImages[project.id] ||
                                (project.galleries && project.galleries.length > 0 && project.galleries[0].medias.length > 0
                                    ? project.galleries[0].medias[project.galleries[0].medias.length - 1].url
                                    : '/img_projects/projectdefault.png');

                            return (
                                <div className='assistance-project-list' key={project.id}>
                                    <div className='assistance-project-get'>
                                        <img
                                            className='assistance-project-get-img'
                                            src={projectImage}
                                            alt='project'
                                            onClick={() => navigate(`step1Bom`, { state: { id: project.id } })}
                                        />
                                        <div
                                            className='assistance-project-get-icon'
                                            title='Replace image'
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                document.getElementById(`imageUpload-${project.id}`)?.click();
                                            }}
                                        >
                                            <IconReplaceImage />
                                        </div>
                                        <input
                                            type="file"
                                            id={`imageUpload-${project.id}`}
                                            accept="image/*"
                                            style={{ display: 'none' }}
                                            onChange={(e) => handleImageChange(project.id, e)}
                                        />
                                    </div>

                                    <div className='assistance-project-title'>
                                        <p className='assistance-project-name' data-title={project.title}>{project.title}</p>
                                        <p className='assistance-project-data'>{new Date(project.created_at).toLocaleDateString()}</p>
                                    </div>
                                </div>
                            );
                        })}

                    </div>
                </div>
            </div>
        </>
    );
};

export default BOMAllProjects;
