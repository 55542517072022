// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item-progress-bar{
    display: flex;
    align-items: center;
    gap: 8px;
}

.item-progress-bar-scale-part{
    width: 300px;
    height: 15px;
    background: var(--background-header-btn-color);
    border-radius: 10px;
    overflow: hidden;
}

.item-progress-bar-scale-fill{
    background-color: var(--basic-btn-color);
    border-radius: 10px;
    height: 100%;
}
.item-progress-bar-scale-text{
    font-size: 14px;
    font-family: var(--font-roboto),sans-serif;
    font-weight: 500;
    color: var(--basic-text-description-color);
}`, "",{"version":3,"sources":["webpack://./src/Page/Step2/ItemProgressBar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,QAAQ;AACZ;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,8CAA8C;IAC9C,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,wCAAwC;IACxC,mBAAmB;IACnB,YAAY;AAChB;AACA;IACI,eAAe;IACf,0CAA0C;IAC1C,gBAAgB;IAChB,0CAA0C;AAC9C","sourcesContent":[".item-progress-bar{\n    display: flex;\n    align-items: center;\n    gap: 8px;\n}\n\n.item-progress-bar-scale-part{\n    width: 300px;\n    height: 15px;\n    background: var(--background-header-btn-color);\n    border-radius: 10px;\n    overflow: hidden;\n}\n\n.item-progress-bar-scale-fill{\n    background-color: var(--basic-btn-color);\n    border-radius: 10px;\n    height: 100%;\n}\n.item-progress-bar-scale-text{\n    font-size: 14px;\n    font-family: var(--font-roboto),sans-serif;\n    font-weight: 500;\n    color: var(--basic-text-description-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
