import React, {useEffect, useState} from 'react';
import {useNavigate, useLocation, useParams} from "react-router-dom";
import {fetchAllTemplates} from "../../Slice/getAllTemplates/GetAllTemplatesSlice";
import {RootState, useAppDispatch} from "../../store";
import {connect, ConnectedProps, useSelector} from "react-redux";
import PaginationAssistance from "../../components/PaginationAssistance/pagination";
import './style.css';
import AssisTemplaeModal from "../../components/Modals/Assis Template Modal/AssisTemplaeModal";
import 'react-toastify/dist/ReactToastify.css';
import {
    notifyInfo,
    notifySuccess,
    notifyWarningOrderTemplate,
} from "../../components/Toast/AssisCreateToastNotification";
import {Template} from "../../types/TemplateType";
import {
    addTemplate,
    clearTemplates,
    removeTemplate,
    removeCalculatedTemplate
} from "../../Slice/LocalTemplate/LocalTemplateSlice";
import {transformTemplate} from "../../utils/TransformTemplate";
import DeleteModal from "../../components/Modals/ModalDelete/ModalDelete";
import {clearLocalStorageExceptToken as clearLocalStorageFunction} from "../../utils/ClearLocalStorage";
import {fetchProjectById, updatedProject, clearProject} from "../../Slice/projectById/projectByIdSlice";
import {Loader} from "../../components/Loader/Loader";
import MobileNavigation from "../../components/MobileNavigation/MobileNavigation";
import {getAllTemplatesByIds} from "../../api/APIWraper";
import TemplateGroupSelect from "../../components/Modals/TemplateGroupSelect/TemplateGroupSelect";
import {IconBuyTemplate} from "../../IconComponents/IconComponents";
import PaidTemplate from "../../components/Modals/PaidTemplate/PaidTemplate";
import {selectUser} from "../../Slice/tokenSlice/userSlice";
import {setTemplateMessage} from "../../Slice/TemplateSlice/TemplateSlice";
import ModalDelete from "../../components/Modals/ModalDelete/ModalDelete";

interface TemplateWithCalculated extends Template {
    isCalculated?: boolean;
}

const AssisCreate: React.FC<PropsFromRedux> = ({
                                                   error,
                                                   isFetching,
                                                   categories,
                                                   totalTemplate,
                                                   fetchAllTemplates,
                                                   fetchProjectById,
                                                   updatedProject,
                                                   addTemplate,
                                                   clearTemplates,
                                                   removeTemplate,
                                                   removeCalculatedTemplate,
                                                   clearProject,
                                                   isAuthenticated,
                                                   project
                                               }) => {

    const navigate = useNavigate();
    const location = useLocation();
    const selectedTemplates = useSelector((state: RootState) => state.localTemplate.templates.map(template => ({
        ...template,
        isCalculated: JSON.parse(localStorage.getItem(`selectedTemplate-${template.id}`) || 'false')
    })));
    const [currentTemplatePage, setCurrentTemplatePage] = useState(1);
    const templatesPerPage = 4;
    const totalPages = Math.ceil(totalTemplate / templatesPerPage);

    const [isSelectedTemplatesModalOpen, setIsSelectedTemplatesModalOpen] = useState<boolean>(false);
    const [templateToDelete, setTemplateToDelete] = useState<TemplateWithCalculated | null>(null);
    const [isOpenTemplateGroupModal, setIsOpenTemplateGroupModal] = useState<boolean>(false);
    const [templateGroupId, setTemplateGroupId] = useState<string | null>(null);
    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerWidth <= 920);
    const [isSmallScreenButton, setIsSmallScreenButton] = useState<boolean>(window.innerWidth <= 875);

    const {openModal} = location.state || {};
    const {projectId, from} = useParams<{ projectId: string; from: string }>();
    const projectIdNumber = projectId ? parseInt(projectId, 10) : NaN;

    const [templateId,setTemplateId] = useState<number>(0);

    const [isOpenPaidTemplate, setIsOpenPaidTemplate] = useState(false);
    const user = useSelector(selectUser);
    const checkRole = ["Contractor", "SAU"].includes(user?.role.role ?? "");
    const templateMessage = useSelector((state: RootState) => state.templateSocket.templateMessage);

    const [removeSelectPaidTemplate,setRemoveSelectPaidTemplate] = useState(false);
    const [reAddableTemplates, setReAddableTemplates] = useState<number[]>([]);

    const dispatch = useAppDispatch();


    const handeTemplateGroupModalClose = () => {
        setIsOpenTemplateGroupModal(false)
    }

    const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 920);
        setIsSmallScreenButton(window.innerWidth <= 875);
    };

    useEffect(() => {
        if (project?.data?.templates?.length === 0) {
            Object.keys(localStorage).forEach((key) => {
                if (key.startsWith("selectedTemplate-")) {
                    localStorage.removeItem(key);
                }
            });

            clearTemplates();
        }
    }, [project]);


    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (openModal) {
            setIsSelectedTemplatesModalOpen(true);
        }
    }, [openModal]);


    const handleOpenModal = async () => {
        if (selectedTemplates.length >= 1) {
            const response = await fetchSelectedTemplates(selectedTemplates);
            clearTemplates()
            response.forEach(template => {
                addTemplate(template);
            });
        }
        setIsSelectedTemplatesModalOpen(true);
    };


    const clearLocalStorageExceptTokenHandler = () => {
        navigate('/assistance');
        clearLocalStorageFunction();
        clearTemplates();
        clearProject();
    };

    useEffect(() => {
        return () => {
            clearTemplates();
            clearProject();
        };
    }, []);

    useEffect(() => {
        if (isSelectedTemplatesModalOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [isSelectedTemplatesModalOpen]);

    useEffect(() => {
        if (isAuthenticated) {
            fetchAllTemplates({ page: currentTemplatePage, perPage: templatesPerPage });
        } else {
            navigate('/home');
        }
    }, [currentTemplatePage, templatesPerPage, fetchAllTemplates, isAuthenticated]);

    useEffect(() => {
        if (templateMessage && templateMessage.trim() !== "") {
            fetchAllTemplates({ page: currentTemplatePage, perPage: templatesPerPage });
        }
    }, [templateMessage, currentTemplatePage, templatesPerPage, fetchAllTemplates]);

    useEffect(() => {
        if (project && project.data && project.data.templates && project.data.templates.length > 0) {
            clearTemplates();
            project?.data?.templates.forEach(template => {
                addTemplate(template);
            });
        }
    }, [project]);

    useEffect(() => {
        if (isAuthenticated) {
            if (from === 'projectCosts') {
                fetchProjectById({ projectId: String(projectId) });
            } else {
                clearTemplates();
                clearProject();
            }
            return () => {
                clearTemplates();
                clearProject();
            };
        } else {
            navigate('/auth/login');
        }
    }, [from, projectId, isAuthenticated]);

    const paginateTemplate = (pageNumber: number) => {
        if (pageNumber <= totalPages) {
            setCurrentTemplatePage(pageNumber);
        }
    };

    const handleTemplateClick = (template: Template) => {
        if (!checkRole && template.paid && !template.bought) {
            if (selectedTemplates.some(t => t.id === template.id)) {
                setTemplateId(template.id);
                setRemoveSelectPaidTemplate(true);
            } else {
                if (reAddableTemplates.includes(template.id)) {
                    addTemplate(template);
                    notifySuccess(`Template "${template.title}" has been re-added to your group`);
                    setReAddableTemplates(prev => prev.filter(id => id !== template.id));
                } else {
                    setIsOpenPaidTemplate(true);
                    setTemplateId(template.id);
                }
            }
        } else if (selectedTemplates.some(t => t.id === template.id)) {
            removeTemplate(template.id);
            localStorage.removeItem(`selectedTemplate-${template.id}`);
            notifyWarningOrderTemplate(`Template "${template.title}" has been removed from your project group`);
        } else {
            addTemplate(template);
            notifySuccess(`Template "${template.title}" has been added to your group`);
        }
    };




    const handleSaveSelectedTemplates = () => {
        setIsSelectedTemplatesModalOpen(false);
    };

    const handleRemoveTemplate = (templateId: number) => {
        removeTemplate(templateId);
        removeCalculatedTemplate(templateId);
        localStorage.removeItem(`selectedTemplate-${templateId}`);
        notifyWarningOrderTemplate(`Template removed from selection`);
        setTemplateToDelete(null);
    };

    function getTemplateIdsQueryParams(templates: Template[]): string {
        return templates.map(template => `templateIds[]=${template.id}`).join('&');
    }

    async function fetchSelectedTemplates(selectedTemplates: Template[]): Promise<Template[]> {
        const queryParams = getTemplateIdsQueryParams(selectedTemplates);
        const {templates} = await getAllTemplatesByIds(queryParams);
        return templates;
    }


    const handleNextStepClick = async () => {
        if (selectedTemplates.length === 0) {
            notifyInfo("Please select at least one template to proceed.");
            return;
        }

        const updatedSelectedTemplates = await fetchSelectedTemplates(selectedTemplates);

        const transformedTemplates = updatedSelectedTemplates.map(template => transformTemplate(template));

        const templatesWithoutDescription = transformedTemplates.map(template => {
            const {description, ...rest} = template;
            return rest;
        });

        const updatedProjectData = {
            data: {
                templates: templatesWithoutDescription,
            },
        };
        dispatch(setTemplateMessage('ok'));
        updatedProject({projectId: projectIdNumber, projectData: updatedProjectData})
            .then(() => {
                if (selectedTemplates.length === 1) {
                    const {description, ...templateWithoutDescription} = selectedTemplates[0];
                    localStorage.setItem(
                        `selectedTemplate-${selectedTemplates[0].id}`,
                        JSON.stringify(templateWithoutDescription)
                    );
                    navigate(`/assistance/step1/template/${selectedTemplates[0].id}/0/project/${projectId}`);
                } else {
                    clearLocalStorageFunction();
                    clearTemplates();
                    clearProject();
                    navigate(`/costs/${projectId}`);
                }
            })
            .catch((err) => {
                console.error("Failed to update template:", err);
                notifyInfo("Failed to update template. Please try again.");
            });
    };


    const cancelResetTemplates = () => {
        setTemplateToDelete(null);
    };

    const isTemplateSelected = (template: Template) => {
        return selectedTemplates.some(st => st.id === template.id);
    };

    const handleClickGroup = (templateGroupId: string) => {
        setTemplateGroupId(templateGroupId);
        setIsOpenTemplateGroupModal(true)
    }


    if (isFetching) return <Loader/>;
    if (error) return <p>Error: {error}</p>;
    return (
        <>
            <div className='template-name-content'>
                {isSmallScreen ? (
                    <MobileNavigation
                        links={[
                            {to: '/', label: 'Cashbuild'},
                            {to: '', label: '>'},
                            {to: '/', label: 'Home'},
                            {to: '', label: '>'},
                            {to: '/assistance', label: 'Project assistance'},
                            {to: '', label: '>'},
                            {to: '/assistance/create', label: 'Create new project space'}
                        ]}
                    />
                ) : (
                    <div className='template-blog-placeholder'>
                        <p>Home/</p>
                        <p onClick={clearLocalStorageExceptTokenHandler} className='template-link-assistance'>Project
                            group assistance</p>
                        <p className='name-link'>/Create new project space</p>
                    </div>
                )}
            </div>
            <div className='step-info'>
                <div className='step-info-block'>
                    <p>Step 1</p>
                    <span>/</span>
                    <p className='step-info-block-name'>Choose templates</p>
                </div>
                <div className='step-info-btn-mobile'>
                    <button className='step-info-btn' onClick={() => handleOpenModal()}>
                        View Selected template

                    </button>

                    {isSmallScreenButton && (
                        <button
                            className={`assis-bottom-btn ${selectedTemplates.length === 0 ? 'assis-btn-block' : ''}`}
                            onClick={handleNextStepClick}
                            disabled={selectedTemplates.length === 0}
                        >
                            Next step
                        </button>
                    )}
                </div>
            </div>
            <div className='template-main'>
                {categories.map(category => (
                    <div key={category.id}>
                        <h2>{(category.templates.data.length > 0 || category.templateGroups.data.length > 0) && category.category}</h2>

                        <div className='template-main-container'>
                            {category.templates.data.slice(0, Math.min(category.templates.pagination.total, templatesPerPage)).map((template) => {
                                const templateImage = template.galleries && template.galleries.length > 0 && template.galleries[0].medias.length > 0
                                    ? template.galleries[0].medias[template.galleries[0].medias.length - 1].url
                                    : '/img/house.jpg';

                                return (
                                    <div
                                        className={`template-project-list ${isTemplateSelected(template) ? 'selected' : ''}`}
                                        key={template.id}
                                        onClick={() => handleTemplateClick(template)}
                                    >
                                        <div className='template-project-get'>
                                            <div className='template-img-shadow' style={{
                                                backgroundImage: `url(${templateImage})`
                                            }}>
                                                {isTemplateSelected(template) &&
                                                    <div className="checkmark">&#10003;</div>}
                                            </div>
                                            {template.paid && !template.bought && (
                                                <div className='paid-icon'>
                                                    <IconBuyTemplate/>
                                                </div>
                                            )}

                                            <div className='template-title-block'>
                                                <p className='template-title'>{template.title}</p>
                                            </div>
                                            <div className='view-details'><span className='view-details-plus'></span>Add
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}

                            {category.templateGroups.data.slice(0, Math.min(category.templateGroups.pagination.total, templatesPerPage)).map((templateGroup) => {
                                const templateGroupImage = templateGroup.galleries && templateGroup.galleries.length > 0 && templateGroup.galleries[0].medias.length > 0
                                    ? templateGroup.galleries[0].medias[templateGroup.galleries[0].medias.length - 1].url
                                    : '/img/house.jpg';

                                return (
                                    <div
                                        className={`template-project-list`}
                                        key={templateGroup.id}
                                        onClick={() => handleClickGroup(String(templateGroup.id))}
                                    >
                                        <div className='template-project-get'>
                                            <div className='template-img-shadow' style={{
                                                backgroundImage: `url(${templateGroupImage})`
                                            }}>
                                            </div>
                                            <div className='template-title-block'>
                                                <p className='template-title'>{templateGroup.title}</p>
                                            </div>
                                            <div className='view-details'><span className='view-details-plus'></span>Add</div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                ))}


                {(!isSmallScreenButton || totalTemplate <= templatesPerPage) && (

                    <button
                        className="assis-bottom-btn"
                        onClick={handleNextStepClick}
                        disabled={selectedTemplates.length === 0}
                    >
                        <span>Next step</span>

                    </button>
                )}
            </div>
            <TemplateGroupSelect
                isOpen={isOpenTemplateGroupModal}
                onRequestClose={handeTemplateGroupModalClose}
                templateGroupId={templateGroupId ? templateGroupId : ''}
                createProjectPage={true}
            />
            {totalTemplate > templatesPerPage && (
                <PaginationAssistance
                    projectsPerPage={templatesPerPage}
                    totalProjects={totalTemplate}
                    paginate={paginateTemplate}
                    currentPage={currentTemplatePage}
                />
            )}
            {isSelectedTemplatesModalOpen && (
                <AssisTemplaeModal
                    selectedTemplates={selectedTemplates}
                    projectId={projectIdNumber}
                    isOpen={isSelectedTemplatesModalOpen}
                    onClose={() => setIsSelectedTemplatesModalOpen(false)}
                    templates={selectedTemplates}
                    onRemoveTemplate={handleRemoveTemplate}
                    onSave={handleSaveSelectedTemplates}
                />
            )}
            {templateToDelete && (
                <DeleteModal
                    isOpen={true}
                    onRequestClose={() => setTemplateToDelete(null)}
                    onConfirm={() => handleRemoveTemplate(templateToDelete.id)}
                    onCancel={cancelResetTemplates}
                    message={`Are you sure you want to remove the template "${templateToDelete.title}"?`}
                    confirmText="Yes"
                    cancelText="No"
                />
            )}
            <PaidTemplate
                templateId={templateId}
                isOpen={isOpenPaidTemplate}
                onRequestClose={() => setIsOpenPaidTemplate(false)}
                onCancel={() => setIsOpenPaidTemplate(false)}
            />
            <ModalDelete
                isOpen={removeSelectPaidTemplate}
                onRequestClose={() => setRemoveSelectPaidTemplate(false)}
                onCancel={() => setRemoveSelectPaidTemplate(false)}
                onConfirm={() => {
                    removeTemplate(templateId);
                    localStorage.removeItem(`selectedTemplate-${templateId}`);
                    notifyWarningOrderTemplate(`Template has been removed from your project group`);
                    setReAddableTemplates(prev => [...prev, templateId]);
                    setRemoveSelectPaidTemplate(false);
                }}
                message={`Are you sure you want to remove the template? If you want to add it in the future, you have to pay again for it for 10$`}
                cancelText={'Cancel'}
                confirmText={'Remove Template'}
            />

        </>
    )
};

const mapStateToProps = (state: RootState) => ({
    project: state.projectById.project,
    categories: state.allTemplates.categories,
    totalTemplate: state.allTemplates.totalTemplate,
    isFetching: state.allTemplates.isFetching,
    error: state.allTemplates.error,
    isAuthenticated: state.auth.isAuthenticated
});

const mapDispatchToProps = {
    fetchAllTemplates,
    fetchProjectById,
    updatedProject,
    clearTemplates,
    addTemplate,
    removeTemplate,
    removeCalculatedTemplate,
    clearProject,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AssisCreate);
