import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {buildStyles, CircularProgressbar} from "react-circular-progressbar";
import {connect, ConnectedProps, useDispatch, useSelector} from 'react-redux';
import "../Step2/Step2Styles/step2.css";
import MaterialName from "../../components/Assis Step2 Component/MaterialName";
import {fetchProjectById} from '../../Slice/projectById/projectByIdSlice';
import {ItemType, Template, TItem} from "../../types/TemplateType";
import './Step3.css';
import {clearSelectedItems} from "../../Slice/SelectedItems/SelectedItemsSlice";
import {RootState} from "../../store";
import {Loader} from "../../components/Loader/Loader";
import {patch, post} from "../../api/APIWraper";
import {clearLocalStorageExceptToken} from "../../utils/ClearLocalStorage";
import {clearTemplates} from "../../Slice/LocalTemplate/LocalTemplateSlice";
import SwitcherStep2 from "../../components/Switcher/SwitcherStep2";
import {
    toastItemError,
    toastItemTrue
} from "../../components/Toast/AssisCreateToastNotification";
import {IconArrowLightStpe2, IconEyesStep3} from "../../IconComponents/IconComponents";
import MobileNavigation from "../../components/MobileNavigation/MobileNavigation";
import {toast} from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL_CUSTOMER


interface IPayloadData {
    input_qty: number;
    item_type_id: number;
    item_id: number;
    template_index: number;
    fragment_index: number;
    item_type_index: number;
    template_id: number;
    fragment_id: number;
    ordered_qty: number;
}

interface ErrorMessages {
    [key: string]: string;
}

const Step3: React.FC<PropsFromRedux> = ({
                                             fetchProjectById,
                                             clearSelectedItems,
                                             project,
                                             isFetching,
                                             isLaborVisible,
                                             isToolVisible,
                                             isAuthenticated
                                         }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [currentTemplate, setCurrentTemplate] = useState<Template | null>(null);
    const [currentTemplateIndex, setCurrentTemplateIndex] = useState<number>(-1);
    const [isSetTemplateIndex, setIsSetTemplateIndex] = useState<boolean>(false);
    const [percentage, setPercentage] = useState(0);
    const [inputQuantities, setInputQuantities] = useState<{ [key: string]: number }>({});
    const [items, setItems] = useState<{ [key: string]: ItemType[] }>({});
    const [laborItems, setLaborItems] = useState<any[]>([]);
    const [toolsItems, setToolsItems] = useState<any[]>([]);
    const [fragments, setFragments] = useState<{ [key: string]: any }>({});
    const [totalMaterial, setTotalMaterial] = useState<{ [key: string]: number }>({});
    const [totalLabor, setTotalLabor] = useState(0);
    const [totalTools, setTotalTools] = useState(0);
    const [viewMode, setViewMode] = useState<'Sections' | 'Product type'>('Product type');

    const isProceedDisabled = !Object.values(inputQuantities).some(qty => qty > 0);


    const [visitedItems, setVisitedItems] = useState<string[]>([]);
    const [coordinates, setCoordinates] = useState<{ longitude: string; latitude: string } | null>(null);

    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerWidth <= 920);
    const [visibilityMap, setVisibilityMap] = useState<{ [key: string]: boolean }>({});


    const [errorMessages, setErrorMessages] = useState<ErrorMessages>({});

    const {projectId, indexTemplate} = useParams<{ projectId: string; indexTemplate: string }>();

    const templateIndexNumber = indexTemplate ? parseInt(indexTemplate, 10) : NaN;

    const [templateIndex,] = useState<number>(templateIndexNumber);

    const generateItemKeyVisible = (item: ItemType, idx: number) => {
        return `${item.id}-${item.clone_id}-${item.payload?.fragment_index}-${idx}`;
    };
    const toggleVisibility = (itemTypeKey: string) => {
        setVisibilityMap((prevMap) => ({
            ...prevMap,
            [itemTypeKey]: !prevMap[itemTypeKey],
        }));
    };

    const isVisibleItem = (itemTypeKey: string) => {
        return visibilityMap[itemTypeKey] || false;
    };

    const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 920);
    };


    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const visibleFragments = useSelector((state: RootState) => state.fragmentVisible.visibleFragments);


    const handleSwitchChange = (selectedOption: 'Sections' | 'Product type') => {
        setViewMode(selectedOption);
    };

    const addCloneId = (data: any) => {
        const updatedData = {...data};

        for (const fragmentKey in updatedData) {
            if (updatedData.hasOwnProperty(fragmentKey)) {
                const fragment = updatedData[fragmentKey];

                for (const itemKey in fragment.items) {
                    if (fragment.items.hasOwnProperty(itemKey)) {
                        const item = fragment.items[itemKey];
                        item.clone_id = `${item.id}-${item.payload.item_type_index}-0`;
                        item.items = item.items.map((subItem: any, idx: number) => ({
                            ...subItem,
                            clone_id: `${subItem.id}-${item.payload.item_type_index}-${idx}`
                        }));
                    }
                }

                for (const laborKey in fragment.LaborItemTypes) {
                    if (fragment.LaborItemTypes.hasOwnProperty(laborKey)) {
                        const laborItem = fragment.LaborItemTypes[laborKey];
                        laborItem.clone_id = `${laborItem.id}-${laborItem.payload.item_type_index}-0`;
                        laborItem.items = laborItem.items.map((subItem: any, idx: number) => ({
                            ...subItem,
                            fragmentKey: fragmentKey,
                            clone_id: `${subItem.id}-${laborItem.payload.item_type_index}-${idx}`
                        }));
                    }
                }

                for (const toolKey in fragment.ToolsItemTypes) {
                    if (fragment.ToolsItemTypes.hasOwnProperty(toolKey)) {
                        const toolItem = fragment.ToolsItemTypes[toolKey];
                        toolItem.clone_id = `${toolItem.id}-${toolItem.payload.item_type_index}-0`;
                        toolItem.items = toolItem.items.map((subItem: any, idx: number) => ({
                            ...subItem,
                            fragmentKey: fragmentKey,
                            clone_id: `${subItem.id}-${toolItem.payload.item_type_index}-${idx}`
                        }));
                    }
                }
            }
        }

        return updatedData;
    };

    useEffect(() => {
        const data = localStorage.getItem('selectedFragments');
        const visitedData = localStorage.getItem('visitedItems');
        const storedCoordinates = localStorage.getItem('coordinates');
        if (storedCoordinates) {
            setCoordinates(JSON.parse(storedCoordinates));
        }
        if (visitedData) {
            setVisitedItems(JSON.parse(visitedData));
        }

        if (data) {
            const parsedData = JSON.parse(data);
            const updatedData = addCloneId(parsedData);
            const itemsMap: { [key: string]: any[] } = {};
            const laborArray: any[] = [];
            const toolsArray: any[] = [];

            for (const fragmentKey in updatedData) {
                if (updatedData.hasOwnProperty(fragmentKey)) {
                    const fragment = updatedData[fragmentKey];
                    itemsMap[fragmentKey] = Object.values(fragment.items).flatMap((item: any) => item.items);

                    for (const laborKey in fragment.LaborItemTypes) {
                        if (fragment.LaborItemTypes.hasOwnProperty(laborKey)) {
                            fragment.LaborItemTypes[laborKey].items.forEach((item: any) => {
                                laborArray.push({
                                    ...item,
                                    fragmentKey: fragmentKey,
                                    template_index: fragment.LaborItemTypes[laborKey].payload.template_index,
                                    fragment_index: fragment.LaborItemTypes[laborKey].payload.fragment_index,
                                    item_type_index: fragment.LaborItemTypes[laborKey].payload.item_type_index,
                                    template_id: fragment.LaborItemTypes[laborKey].payload.template_id,
                                    item_type_id: fragment.LaborItemTypes[laborKey].payload.item_type_id,
                                });
                            });
                        }
                    }
                    for (const toolKey in fragment.ToolsItemTypes) {
                        if (fragment.ToolsItemTypes.hasOwnProperty(toolKey)) {
                            fragment.ToolsItemTypes[toolKey].items.forEach((item: any) => {
                                toolsArray.push({
                                    ...item,
                                    fragmentKey: fragmentKey,
                                    template_index: fragment.ToolsItemTypes[toolKey].payload.template_index,
                                    fragment_index: fragment.ToolsItemTypes[toolKey].payload.fragment_index,
                                    item_type_index: fragment.ToolsItemTypes[toolKey].payload.item_type_index,
                                    template_id: fragment.ToolsItemTypes[toolKey].payload.template_id,
                                    item_type_id: fragment.ToolsItemTypes[toolKey].payload.item_type_id,
                                });
                            });
                        }
                    }
                }
            }
            setItems(itemsMap);
            setLaborItems(laborArray);
            setToolsItems(toolsArray);
            setFragments(updatedData);
        } else {
            toast.warning('You must select the fragments in Step2')
            setTimeout(() => {
                navigate(`/assistance/step2/template/${templateIndex}/project/${projectId}`)
            }, 2000)
        }
    }, []);

    const handleLogoClick = (link: string, type: string, fragmentKey: string, itemId: number, cloneId?: number) => {
        const id = generateKey(type, fragmentKey, itemId, cloneId);
        const storedVisitedItems = JSON.parse(localStorage.getItem('visitedItems') || '[]');
        if (!storedVisitedItems.includes(id)) {
            const updatedVisited = [...storedVisitedItems, id];
            setVisitedItems(updatedVisited);
            localStorage.setItem('visitedItems', JSON.stringify(updatedVisited));
        }
        if (link) {
            window.open(link, '_blank', 'noopener');
        }
    };


    useEffect(() => {
        if (templateIndex >= 0) {
            setCurrentTemplateIndex(templateIndex);
            localStorage.setItem('templateIndex', templateIndex.toString());
        }
        if (project) {
            if (project.data.templates && project.data.templates.length > 0) {
                const firstTemplate = project.data.templates[currentTemplateIndex];
                setCurrentTemplate(firstTemplate);
                setIsSetTemplateIndex(true);
            }
            setPercentage(project.progress);
        }
    }, [project, templateIndex, currentTemplateIndex]);

    useEffect(() => {
        if (isAuthenticated) {

            if (projectId) {
                fetchProjectById({projectId: projectId});
            }
        } else {
            navigate('/home')
        }
    }, [projectId, fetchProjectById, isAuthenticated]);

    useEffect(() => {
        calculateTotals(inputQuantities);
    }, [inputQuantities, viewMode]);

    const generateKey = (type: string, fragmentKey: string, itemId: number, cloneId: number | undefined) => {
        return `${type}-${fragmentKey}-${itemId}-${cloneId}`;
    };

    const handleQtyChange = (key: string, value: string, item: ItemType) => {
        const parsedValue = parseInt(value, 10) || 0;

        const newQuantities = {
            ...inputQuantities,
            [key]: parsedValue > item.qty_from_store ? item.qty_from_store : parsedValue,
        };
        setInputQuantities(newQuantities);
        calculateTotals(newQuantities);

        const newErrorMessages = {...errorMessages};

        if (parsedValue > item.qty_from_store) {
            newErrorMessages[key] = item.qty_from_store !== 0 ? `You can buy a maximum of ${item.qty_from_store} pieces` : 'Out of stock';
            setErrorMessages(newErrorMessages);

            setTimeout(() => {
                const updatedErrorMessages = {...newErrorMessages};
                delete updatedErrorMessages[key];
                setErrorMessages(updatedErrorMessages);
            }, 5000);
        } else {
            delete newErrorMessages[key];
            setErrorMessages(newErrorMessages);
        }
    };

    const calculateTotals = (quantities: { [key: string]: number }) => {
        const materialTotals: { [key: string]: number } = {};
        let laborTotal = 0;
        let toolsTotal = 0;
        for (const key in items) {
            materialTotals[key] = 0;
            items[key].forEach((item) => {
                const price = item.price ? parseFloat(item.price) : 0;
                const qtyKey = generateKey('items', key, item.id, item.clone_id);
                const qty = quantities[qtyKey] || 0;
                materialTotals[key] += price * qty;
            });
        }

        laborItems.forEach((item) => {
            const price = item.price ? parseFloat(item.price) : 0;
            const qtyKey = generateKey('labors', item.fragmentKey, item.id, item.clone_id);
            const qty = quantities[qtyKey] || 0;
            laborTotal += price * qty;
        });

        toolsItems.forEach((item) => {
            const price = item.price ? parseFloat(item.price) : 0;
            const qtyKey = generateKey('tools', item.fragmentKey, item.id, item.clone_id);
            const qty = quantities[qtyKey] || 0;
            toolsTotal += price * qty;
        });

        setTotalMaterial(materialTotals);
        setTotalLabor(laborTotal);
        setTotalTools(toolsTotal);
    };


    const getPayloadData = (
        fragments: { [key: string]: any },
        inputQuantities: { [key: string]: number }
    ): IPayloadData[] => {
        const payloadData: IPayloadData[] = [];

        const extractPayloadData = (subItem: any, itemType: any, key: string) => {
            const templateIndex = itemType.payload?.template_index !== undefined ? itemType.payload.template_index : itemType.template_index;
            const fragmentIndex = itemType.payload?.fragment_index !== undefined ? itemType.payload.fragment_index : itemType.fragment_index;
            const itemTypeIndex = itemType.payload?.item_type_index !== undefined ? itemType.payload.item_type_index : itemType.item_type_index;
            const templateId = itemType.payload?.template_id !== undefined ? itemType.payload.template_id : itemType.template_id;
            const fragmentId = itemType.payload?.fragment_id !== undefined ? itemType.payload.fragment_id : itemType.fragment_id;
            const itemTypeId = itemType.payload?.item_type_id !== undefined ? itemType.payload.item_type_id : itemType.item_type_id;
            const itemId = subItem.id;

            const currentQty = subItem.ordered_qty || 0;
            const inputQty = inputQuantities[key] || 0;

            if (inputQty > 0) {
                return {
                    item_type_id: itemTypeId,
                    item_id: itemId,
                    template_index: templateIndex,
                    fragment_index: fragmentIndex,
                    item_type_index: itemTypeIndex,
                    template_id: templateId,
                    fragment_id: fragmentId,
                    ordered_qty: currentQty + inputQty,
                    input_qty: inputQty
                };
            }

            return null;
        };

        for (const fragmentKey in fragments) {
            const fragment = fragments[fragmentKey];

            Object.values(fragment.items).forEach((item: any) => {
                item.items.forEach((subItem: TItem) => {
                    const key = generateKey('items', fragmentKey, subItem.id, subItem.clone_id);
                    const payloadItem = extractPayloadData(subItem, item, key);
                    if (payloadItem) {
                        payloadData.push(payloadItem);
                    }
                });
            });

            Object.values(fragment.LaborItemTypes || {}).forEach((laborItem: any) => {
                laborItem.items.forEach((subItem: TItem) => {
                    const key = generateKey('labors', fragmentKey, subItem.id, subItem.clone_id);
                    const payloadItem = extractPayloadData(subItem, laborItem, key);
                    if (payloadItem) {
                        payloadData.push(payloadItem);
                    }
                });
            });

            Object.values(fragment.ToolsItemTypes || {}).forEach((toolItem: any) => {
                toolItem.items.forEach((subItem: TItem) => {
                    const key = generateKey('tools', fragmentKey, subItem.id, subItem.clone_id);
                    const payloadItem = extractPayloadData(subItem, toolItem, key);
                    if (payloadItem) {
                        payloadData.push(payloadItem);
                    }
                });
            });
        }

        return payloadData;
    };

    const handleSave = async () => {
        const payloadData = getPayloadData(fragments, inputQuantities);
        if (payloadData.length === 0) {
            console.error("No payload data to save.");
            return;
        }

        const itemsForProject = payloadData.map(item => ({
            item_type_id: item.item_type_id,
            item_id: item.item_id,
            template_index: item.template_index,
            fragment_index: item.fragment_index,
            item_type_index: item.item_type_index,
            template_id: item.template_id,
            fragment_id: item.fragment_id,
            ordered_qty: item.ordered_qty,
        }));

        const itemsForCart = payloadData.map(item => ({
            id: item.item_id,
            qty: item.input_qty
        }));

        const bodyCart = {
            coordinates: {
                longitude: coordinates?.longitude,
                latitude: coordinates?.latitude
            },
            items: itemsForCart
        };
        try {
            const response = await patch(
                `${process.env.REACT_APP_BASE_URL_CUSTOMER}/projects/${project?.id}/item_ordered_qty`,
                {data: itemsForProject}
            );

            if (response.status === 200) {
                toastItemTrue('Success!');
            }
            const responseCoordinates = await post(`${BASE_URL}/cart`, bodyCart);

            if (responseCoordinates.status === 200) {
                const cartData = responseCoordinates.data;
                if (cartData.cashBuildCart?.url) {
                    const url = cartData.cashBuildCart.url;
                    window.open(url, '_blank');
                } else {
                    toastItemError(`Error: URL not found in the response.`);
                }
            } else {
                toastItemError(`Error with cart submission.`);
            }
        } catch (error) {
            console.error("Failed to save selections:", error);
            toastItemError(`Error: Failed to save selections.`);
        }
    };


    const buyAndNavigateToStep2 = async () => {
        await handleSave();
        if (isProceedDisabled) {
            toast.error('You must enter in the input field')
        } else {
            localStorage.removeItem('selectedFragments');
            localStorage.removeItem('ToolsItemTypesInitialized');
            localStorage.removeItem('coordinates');
            clearSelectedItems();
            setTimeout(() => {
                navigate(`/costs/${projectId}`);
            }, 1000);
        }
    };

    const navigateToAssistance = () => {
        clearLocalStorageExceptToken();
        dispatch(clearTemplates());
        navigate('/assistance');
    };

    if (isFetching) {
        return <Loader/>;
    }
    if (!project) {
        return null;
    }

    const renderVendorBlock = (item: ItemType, visited: boolean, handleLogoClick: any, type: string, fragmentKey: string) => {
        if (!item.vendor) {
            return <p className='step3-bom-item-no-vendor'>No Vendor</p>;
        }
        if (!visited) {
            return (
                <div
                    onClick={() => handleLogoClick(item.merchant_info?.link, type, fragmentKey, item.id, item.clone_id)}
                    className='step3-bom-items-container-vendor'>
                    <p className='step3-bom-items-container-vendor-purchase'>
                        <span className='step3-vendor-eyes'>
                            <IconEyesStep3/>
                        </span>
                        <span className='step3-vendor-p'>
                            Purchase from
                        </span>
                    </p>
                    <p className='step3-vendor-img'>
                        {item.vendor}
                    </p>
                </div>
            );
        } else {
            return (
                <div
                    onClick={() => handleLogoClick(item.merchant_info?.link, type, fragmentKey, item.id, item.clone_id)}
                    className='step3-bom-items-container-vendor-visited'>
                    <p
                        className='step3-vendor-img'
                    >
                        {item.vendor}
                    </p>
                    <span
                        className='step3-vendor-eyes'
                    >
                        <IconEyesStep3/>
                    </span>
                    <span className='step3-vendor-p-visited'>Visited</span>
                </div>
            );
        }
    };


    const descriptionText = currentTemplate ? currentTemplate.short_description || 'No description' : '';

    const renderItems = (items: { [key: string]: ItemType[] }) => {
        return Object.keys(items).map((fragmentKey) => {
            const [fragmentId, fragmentIndex, templateIndex] = fragmentKey.split('-').map(Number);
            const template = project?.data.templates?.[templateIndex];
            const fragment = template?.data.fragments?.[fragmentIndex];
            const fragmentItems = items[fragmentKey];
            const fragmentLaborItems = laborItems.filter(item => item.fragmentKey === fragmentKey);
            const fragmentToolsItems = toolsItems.filter(item => item.fragmentKey === fragmentKey);

            if (fragmentItems.length === 0 && fragmentLaborItems.length === 0 && fragmentToolsItems.length === 0) {
                return null;
            }
            let fragmentTotalLabor = 0;
            let fragmentTotalTools = 0;
            let fragmentTotalMaterial = 0;

            const renderItemsWithTitle = (items: ItemType[], type: "items" | "labors" | "tools") => {
                let lastItemTypeTitle = '';

                return items.map((item, index) => {
                    const key = generateKey(type, fragmentKey, item.id, item.clone_id);
                    const newOrderedQty = inputQuantities[key] || '';
                    const price = parseFloat(String(item.price)) || 0;
                    const newPrice = price * Number(newOrderedQty);
                    if (type === 'items') {
                        fragmentTotalMaterial += newPrice;
                    } else if (type === 'labors') {
                        fragmentTotalLabor += newPrice;
                    } else if (type === 'tools') {
                        fragmentTotalTools += newPrice;
                    }
                    const showTitle = lastItemTypeTitle !== item.item_type_title;
                    lastItemTypeTitle = item.item_type_title;
                    const visited = visitedItems.includes(key);
                    const itemTypeKey = generateItemKeyVisible(item, index);

                    return (
                        <div key={key}>
                            {!isSmallScreen && (
                                <>
                                    {showTitle && <h4 className='view-fragments-h3'>{item.item_type_title}</h4>}
                                </>
                            )}
                            {!isSmallScreen ? (
                                <div className='step3-item-type-list'>
                                    <div className='step3-container-p'>
                                        <p className='step3-bom-item-title step3-other-bold'>{item.title}</p>
                                        <div className="step3-bom-item-input">
                                            <input
                                                type="number"
                                                className="step2-other-width-input"
                                                value={newOrderedQty}
                                                onChange={(e) => handleQtyChange(key, e.target.value, item)}
                                                min={0}
                                                max={item.qty_from_store}
                                            />
                                            {errorMessages[key] &&
                                                <div className="error-message">{errorMessages[key]}</div>}
                                        </div>
                                        <p className="step3-bom-qty-ordered">{item.ordered_qty || 0}/{item.qty} ({item.unit})</p>
                                        <p className="step3-bom-price">R {price.toFixed(2)}</p>
                                        <p className="step3-bom-total">R {(item.qty * price).toFixed(2)}</p>
                                        <p className="step3-bom-total-ordered">R {isNaN(newPrice) ? 0 : newPrice.toFixed(2)}</p>
                                    </div>
                                    {renderVendorBlock(item, visited, handleLogoClick, type, fragmentKey)}
                                </div>

                            ) : (

                                <div className='step3-item-type-list'>
                                    <div className='step3-container-p'>
                                        <div className='step3-item-checkbox'>
                                            <div className='step3-material-width step3-other-bold'>
                                                <label>{item.title}</label>
                                                <div onClick={() => toggleVisibility(itemTypeKey)}
                                                     className={isVisibleItem(itemTypeKey) ? 'icon-arrow-light-step2-up' : 'icon-arrow-light-step2-down'}>
                                                    <IconArrowLightStpe2/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='step3-container-p'>
                                            <div
                                                className={`step2-content-list ${isVisibleItem(itemTypeKey) ? 'visible' : 'hidden'}`}>
                                                <div className="step3-tooltip-container">
                                                    <span>QTY to order:</span><span className="dashed-line"></span>
                                                    <input
                                                        type="number"
                                                        className="step2-other-width-input"
                                                        value={newOrderedQty}
                                                        onChange={(e) => handleQtyChange(key, e.target.value, item)}
                                                        min={0}
                                                        max={item.qty_from_store}
                                                    />
                                                    {errorMessages[key] &&
                                                        <div className="error-message">{errorMessages[key]}</div>}

                                                </div>
                                                <p className="step3-other-width-list">
                                                    <span>QTY ordered:</span><span
                                                    className="dashed-line"></span>{item.ordered_qty || 0}/{item.qty} ({item.unit})
                                                </p>
                                                <p className="step3-other-width-list">
                                                    <span>Unit price:</span><span
                                                    className="dashed-line"></span>R {price.toFixed(2)}
                                                </p>
                                                <p className="step3-other-width-list">
                                                    <span>Total value:</span><span
                                                    className="dashed-line"></span>R {(item.qty * price).toFixed(2)}
                                                </p>
                                                <p className="step3-other-width-list-total">
                                                    <span>Current order:</span><span
                                                    className="dashed-line"></span>R {isNaN(newPrice) ? 0 : newPrice.toFixed(2)}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    {isVisibleItem(itemTypeKey) && renderVendorBlock(item, visited, handleLogoClick, type, fragmentKey)}
                                </div>
                            )}
                        </div>
                    );
                });
            };
            const key = `${fragment.id}-${fragmentIndex}`;
            const isVisible = visibleFragments[key];

            return (
                <div className='step2-fragment-block' key={`fragment-${fragmentId}-${fragmentIndex}`}>
                    <div className='step2-fragment'>
                        <MaterialName key={`material-${fragmentId}`}
                                      fragment={fragment}
                                      fragmentIndex={fragmentIndex}

                                      project={project}
                                      showSettingsButton={false} visibleStep3={true}

                        />
                        <div
                            className={`step2-fragment-content ${isSmallScreen ? (isVisible ? 'visible' : 'hidden') : 'visible'}`}>
                            {fragmentItems.length > 0 && (
                                <div>
                                    <h3 className='step3-item-name-block'>Product name</h3>
                                    {renderItemsWithTitle(fragmentItems, 'items')}
                                </div>
                            )}

                            {viewMode === 'Sections' && (
                                <>
                                    {fragmentLaborItems.length > 0 && (
                                        <div>
                                            <h3 className='step3-item-name-block'>Labor Items</h3>
                                            {renderItemsWithTitle(fragmentLaborItems, 'labors')}
                                        </div>
                                    )}
                                    {fragmentToolsItems.length > 0 && (
                                        <div>
                                            <h3 className='step3-item-name-block'>Tools Items</h3>
                                            {renderItemsWithTitle(fragmentToolsItems, 'tools')}
                                        </div>
                                    )}
                                    <div className='step3-bottom-block'>
                                        <div className='step3-bottom-block-total'>
                                            <p>Total product:</p>
                                            {isSmallScreen && (<span
                                                className="dashed-line"></span>)}
                                            <span>R {isNaN(fragmentTotalMaterial) ? '0.00' : fragmentTotalMaterial.toFixed(2)}</span>
                                        </div>
                                        {fragmentLaborItems.length > 0 && (
                                            <div className='step3-bottom-block-total'>
                                                <p>Total labor:</p>
                                                {isSmallScreen && (<span
                                                    className="dashed-line"></span>)}
                                                <span>R {isNaN(fragmentTotalLabor) ? '0.00' : fragmentTotalLabor.toFixed(2)}</span>
                                            </div>
                                        )}
                                        {fragmentToolsItems.length > 0 && (
                                            <div className='step3-bottom-block-total'>
                                                <p>Total labor:</p>
                                                {isSmallScreen && (<span
                                                    className="dashed-line"></span>)}
                                                <span>R {isNaN(fragmentTotalTools) ? '0.00' : fragmentTotalTools.toFixed(2)}</span>
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}
                            {viewMode === 'Product type' && (
                                <div className='step3-bottom-block-total labor-tool-total'>
                                    <p>Total product:</p>
                                    {isSmallScreen && (<span
                                        className="dashed-line"></span>)}
                                    <span>R {isNaN(fragmentTotalMaterial) ? '0.00' : fragmentTotalMaterial.toFixed(2)}</span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            );
        });
    };
    const renderLaborAndTools = () => {
        const renderItems = (items: any[], type: 'labors' | 'tools') => {
            return items.map((item, index) => {
                const key = generateKey(type, item.fragmentKey, item.id, item.clone_id);
                const newOrderedQty = inputQuantities[key] || '';
                const price = item.price ? parseFloat(String(item.price)) : 0;
                const newPrice = price * parseFloat(String(newOrderedQty));
                const visited = visitedItems.includes(key);
                const fragmentKey = item.fragmentKey
                const itemTypeKey = generateItemKeyVisible(item, index);

                return (
                    !isSmallScreen ? (
                        <div key={key} className='step3-item-type-list'>
                            <div className='step3-container-p'>
                                <p className='step3-bom-item-title step3-other-bold'>{item.title}</p>
                                <div className="step3-bom-item-input">
                                    <input
                                        type="number"
                                        className="step2-other-width-input"
                                        value={newOrderedQty}
                                        onChange={(e) => handleQtyChange(key, e.target.value, item)}
                                        min={0}
                                        max={item.qty_from_store}
                                    />
                                    {errorMessages[key] && <div className="error-message">{errorMessages[key]}</div>}
                                </div>
                                <p className="step3-bom-qty-ordered">{item.ordered_qty || 0}/{item.qty} ({item.unit})</p>
                                <p className="step3-bom-price">R {price.toFixed(2)}</p>
                                <p className="step3-bom-total">R {(item.qty * price).toFixed(2)}</p>
                                <p className="step3-bom-total-ordered">R {isNaN(newPrice) ? 0 : newPrice.toFixed(2)}</p>
                            </div>
                            {renderVendorBlock(item, visited, handleLogoClick, type, fragmentKey)}
                        </div>
                    ) : (
                        <div className='step3-item-type-list'>
                            <div className='step3-container-p'>
                                <div className='step3-item-checkbox'>
                                    <div className='step3-material-width step3-other-bold'>
                                        <label>{item.title}</label>
                                        <div onClick={() => toggleVisibility(itemTypeKey)}
                                             className={isVisibleItem(itemTypeKey) ? 'icon-arrow-light-step2-up' : 'icon-arrow-light-step2-down'}>
                                            <IconArrowLightStpe2/>
                                        </div>
                                    </div>
                                </div>
                                <div className='step3-container-p'>
                                    <div
                                        className={`step2-content-list ${isVisibleItem(itemTypeKey) ? 'visible' : 'hidden'}`}>
                                        <div className="step3-tooltip-container">
                                            <span>QTY to order:</span><span
                                            className="dashed-line"></span>
                                            <input
                                                type="number"
                                                className="step2-other-width-input"
                                                value={newOrderedQty}
                                                onChange={(e) => handleQtyChange(key, e.target.value, item)}
                                                min={0}
                                                max={item.qty_from_store}
                                            />
                                            {errorMessages[key] &&
                                                <div className="error-message">{errorMessages[key]}</div>}
                                        </div>
                                        <p className="step3-other-width-list">
                                            <span>QTY ordered:</span><span
                                            className="dashed-line"></span>{item.ordered_qty || 0}/{item.qty} ({item.unit})
                                        </p>
                                        <p className="step3-other-width-list">
                                            <span>Unit price:</span><span
                                            className="dashed-line"></span>R {price.toFixed(2)}</p>
                                        <p className="step3-other-width-list">
                                            <span>Total value:</span><span
                                            className="dashed-line"></span>R {(item.qty * price).toFixed(2)}</p>
                                        <p className="step3-other-width-list-total">
                                            <span>Current order:</span><span
                                            className="dashed-line"></span>R {isNaN(newPrice) ? 0 : newPrice.toFixed(2)}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {isVisibleItem(itemTypeKey) && renderVendorBlock(item, visited, handleLogoClick, type, fragmentKey)}
                        </div>
                    )
                );
            });
        };

        return (
            <>
                {laborItems.length > 0 && (
                    <div className='step2-fragment-block'>
                        <MaterialName key={`labor-combined`}
                                      project={project}
                                      showSettingsButton={false}
                                      isLabor={true}/>
                        <div
                            className={`step2-fragment-content ${isSmallScreen ? (isLaborVisible ? 'visible' : 'hidden') : 'visible'}`}>
                            {renderItems(laborItems, 'labors')}
                            <div className='step3-bottom-block-total labor-tool-total'>
                                <p>Total labor:</p>
                                {isSmallScreen && (<span
                                    className="dashed-line"></span>)}
                                <span>R {totalLabor.toFixed(2)}</span>
                            </div>
                        </div>
                    </div>
                )}
                {toolsItems.length > 0 && (
                    <div className='step2-fragment-block'>
                        <MaterialName key={`tools-combined`}
                                      project={project}
                                      showSettingsButton={false}
                                      isTool={true}
                        />
                        <div
                            className={`step2-fragment-content ${isSmallScreen ? (isToolVisible ? 'visible' : 'hidden') : 'visible'}`}>
                            {renderItems(toolsItems, 'tools')}
                            <div className='step3-bottom-block-total labor-tool-total'>
                                <p>Total tools:</p>
                                {isSmallScreen && (<span
                                    className="dashed-line"></span>)}
                                <span>R {totalTools.toFixed(2)}</span>
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    };
    const hasItems = Object.values(items).some(array => array.length > 0);

    return (
        <div className='step2'>
            {isSetTemplateIndex && (
                <div>
                    {isSmallScreen ? (
                        <MobileNavigation
                            links={[
                                {to: '/', label: 'Cashbuild'},
                                {to: '', label: '>'},
                                {to: '/', label: 'Home'},
                                {to: '', label: '>'},
                                {to: '/assistance', label: 'Project assistance'},
                                {to: '', label: '>'},
                                {to: '', label: 'Create new project space'}
                            ]}
                        />
                    ) : (
                        <div className='template-name-content'>
                            <div className='template-blog-placeholder'>
                                <p>Home/</p>
                                <p onClick={navigateToAssistance} className='template-link-assistance'>Project group
                                    assistance</p>
                                <p className='name-link'>/Create new project space</p>
                            </div>
                        </div>
                    )}
                    <div className='step-info'>
                        <div className='step-info-block'>
                            <h3>Step 3</h3>
                            <span>/</span>
                            <p className='step-info-block-name'>Specify parameters of building</p>
                        </div>
                    </div>
                    <div className='step-template-container'>
                        <div className='step-template-block left'>
                            <h2>Type:</h2>
                            <p className='step-template-block-title'>{currentTemplate?.title || 'template'}</p>
                        </div>
                        <div className='step-template-block right'>
                            <h2>Description:</h2>
                            <p className='step-template-block-desc'>{descriptionText}</p>
                        </div>
                        {!isSmallScreen && (
                            <div className='step-template-block-percent'>
                                <div className='progress-bar-wrapper'>
                                    <CircularProgressbar
                                        value={percentage}
                                        styles={buildStyles({
                                            pathColor: '#E30613',
                                            textColor: '#000000',
                                            trailColor: '#d6d6d6',
                                            textSize: '24px',
                                            pathTransitionDuration: 0.5,
                                        })}
                                    />
                                    <div className='progress-bar-text'>
                                        {percentage}%
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='step-calc'>
                        <h2>Costs Summary</h2>
                        <SwitcherStep2
                            options={['Sections', 'Product type']}
                            onChange={handleSwitchChange}
                        />
                        <div className='step2-container'>
                            {viewMode === 'Product type' ? (
                                <>
                                    {hasItems && renderItems(items)}
                                    {renderLaborAndTools()}
                                </>
                            ) : (
                                <>
                                    {renderItems(items)}
                                </>
                            )}
                        </div>
                        <div className='step2-page-bottom-total step3-bottom-block-total-mobile'>
                            <p>Total:</p>
                            <div className='step2-page-bottom-total-container'>
                                <div className='step2-page-bottom-total-price'>
                                    <div className='step2-page-bottom-total-price-block'>
                                        <p>Total labour:</p>
                                        {isSmallScreen && <span className='dashed-line'></span>}
                                        <span
                                            className='step2-page-bottom-price'>R {totalLabor.toFixed(2)}</span>
                                    </div>
                                    <div className='step2-page-bottom-total-price-block'>
                                        <p>Total tools:</p>
                                        {isSmallScreen && <span className='dashed-line'></span>}
                                        <span
                                            className='step2-page-bottom-price'>R {totalTools.toFixed(2)}</span>
                                    </div>
                                    <div className='step2-page-bottom-total-price-block'>
                                        <p>Total material:</p>
                                        {isSmallScreen && <span className='dashed-line'></span>}
                                        <span
                                            className='step2-page-bottom-price'>R {Object.values(totalMaterial).reduce((acc, val) => acc + val, 0).toFixed(2)}</span>
                                    </div>
                                </div>
                                <div className='step2-page-bottom-total-price-block'>
                                    <p>Total payable:</p>
                                    {isSmallScreen && <span className='dashed-line'></span>}
                                    <span
                                        className='step2-page-bottom-price-total'>R {(Object.values(totalMaterial).reduce((acc, val) => acc + val, 0) + totalLabor + totalTools).toFixed(2)}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='step2-page-bottom-btn'>
                        <div className='step2-page-bottom-btn-block'>
                            <div className='step2-page-btn'>
                                <button className='step2-page-bottom-block-btn' title={'in development'}>Save & Request
                                    finance
                                </button>
                            </div>
                            <div className='step2-page-btn'>
                                <button
                                    onClick={buyAndNavigateToStep2}
                                    className='step2-page-bottom-block-btn'
                                    style={{
                                        backgroundColor: isProceedDisabled ? '#cccccc' : '',
                                        cursor: isProceedDisabled ? 'not-allowed' : 'pointer'
                                    }}>Proceed to Checkout
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    project: state.projectById.project,
    isFetching: state.projectById.isFetching,
    isVisible: state.fragmentVisible,
    isLaborVisible: state.fragmentVisible.isLaborVisible,
    isToolVisible: state.fragmentVisible.isToolVisible,
    isAuthenticated: state.auth.isAuthenticated

});

const mapDispatchToProps = {
    fetchProjectById,
    clearSelectedItems,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Step3);
