import React from 'react';
import { useNavigate } from 'react-router-dom';

import './GuideStyles/GuideBom.css'
import {
    GuideNextArrow,
} from "../../IconComponents/IconGuide";
const Step2Guide: React.FC = () => {
    const navigate = useNavigate();


    return (
        <>
            <div className='new-user-info-block'>
                <p className='new-user-info-block-number'>2</p>
                <h3 className='new-user-info-block-text'>Adding dimensions and selecting products</h3>
            </div>

            <div className='new-user-info-content'>
                <div className='new-user-info-content-img'>
                    <img src='/img_guide/guide4.png'/>
                    <div><GuideNextArrow/></div>
                    <img src='/img_guide/guide5.png'/>
                    <div><GuideNextArrow/></div>
                    <img src='/img_guide/guide6.png'/>
                </div>
                <div className='new-user-info-content-text-block'>
                    <p className='new-user-info-content-text-4 new-user-info-content-text'>Add measurements and proceed
                        to product selection</p>
                    <p className='new-user-info-content-text-5 new-user-info-content-text'>Check and select all the
                        products you need and choose the right vendors for you</p>
                    <p className='new-user-info-content-text-6 new-user-info-content-text'>Complete your project and
                        proceed to checkout</p>
                </div>
                <div>
                </div>
            </div>
        </>
    );
};

export default Step2Guide;
