import React from 'react';
import Modal from 'react-modal';
import './PaidTemplate.css';
import {buyTemplate} from "../../../api/APIWraper";

interface ModalDeleteProps {
    isOpen: boolean;
    onRequestClose: () => void;
    onCancel: () => void;
    templateId: number;
}

const PaidTemplate: React.FC<ModalDeleteProps> = ({isOpen, onRequestClose, onCancel, templateId}) => {

    const handleBuyTemplate = async () => {
        try {
            const response = await buyTemplate(templateId);
            if (response.status === 200 && response.data) {
                const approveLink = response.data.links.find((link: { rel: string; }) => link.rel === "approve");

                if (approveLink) {
                    window.location.href = approveLink.href;
                } else {
                    console.error("Approve link not found in response:", response);
                }
            } else {
                console.error("Unexpected response status:", response);
            }
        } catch (error) {
            console.error("Error while buying template:", error);
        }
    };


    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className="warning-modal"
            overlayClassName="overlay-delete"
        >
            <div className="warning">
                <button className="close-button" onClick={onCancel}>&times;</button>
            </div>
            <div className="paid-template-block">
                <p>You want to add a template that contains paid measurement elements.
                    If you want to continue working with it, you will need to pay <span className='paid-template-price'>$10</span> after adding measurements.</p>
                <div className="confirmation-buttons">
                    <button className="cancel-button" onClick={onCancel}>Cancel</button>
                    <button
                        onClick={() => handleBuyTemplate()}
                        className="confirm-button">Add template
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default PaidTemplate;
