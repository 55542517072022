import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {TItem} from "../../types/TemplateType";


const SOCKET_STORAGE_KEY = 'SocketItemModal';

export const loadMessages = (): WebSocketMessage[] => {
    try {
        const serializedMessages = localStorage.getItem(SOCKET_STORAGE_KEY);
        if (serializedMessages === null) {
            return [];
        }
        return JSON.parse(serializedMessages);
    } catch (err) {
        console.error("Failed to load messages from localStorage:", err);
        return [];
    }
};

export const saveMessages = (messages: WebSocketMessage[]) => {
    try {
        const serializedMessages = JSON.stringify(messages);
        localStorage.setItem(SOCKET_STORAGE_KEY, serializedMessages);
    } catch (err) {
        console.error("Failed to save messages to localStorage:", err);
    }
};



interface WebSocketMessage {
    data: {
        item: TItem;
        message: string;
    }[];
}

interface WebSocketState {
    isConnected: boolean;
    message: WebSocketMessage[];
    error: string | null;
}

const initialState: WebSocketState = {
    isConnected: false,
    message: loadMessages(),
    error: null,
};



const websocketSlice = createSlice({
    name: 'websocket',
    initialState,
    reducers: {
        connect(state) {
            state.isConnected = true;
            state.error = null;
        },
        disconnect(state) {
            state.isConnected = false;
        },
        messageReceived(state, action: PayloadAction<WebSocketMessage>) {
            state.message.push(action.payload);
            saveMessages(state.message);
        },
        connectionError(state, action: PayloadAction<string>) {
            state.error = action.payload;
        },
        clearMessages(state) {
            state.message = [];
            localStorage.removeItem(SOCKET_STORAGE_KEY);
            localStorage.removeItem('ItemQuantity') ;
            localStorage.removeItem('Url');
            localStorage.removeItem('cashBuildLink');
            localStorage.removeItem('visitedVendors')
            localStorage.removeItem('ItemVendors')
            Object.keys(localStorage).forEach(key => {
                if (key.startsWith('visited_')) {
                    localStorage.removeItem(key);
                }
            });
        },
    },
});


export const {
    connect: websocketConnect,
    disconnect: websocketDisconnect,
    messageReceived,
    connectionError,
    clearMessages,
} = websocketSlice.actions;

 export default websocketSlice.reducer;
