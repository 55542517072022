// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* check-role-page.css */

.check-role-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    margin: 0 -180px;
    background: linear-gradient(135deg, #ece9e6, #ffffff);
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
}

.check-role-page p {
    font-size: 1.5rem;
    color: #555;
    margin-top: 20px;
    opacity: 0;
    animation: fadeIn 2s ease-in-out forwards, blink 1s step-end infinite;
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}

@keyframes blink {
    50% {
        opacity: 0.5;
    }
}
`, "",{"version":3,"sources":["webpack://./src/Page/RoleCheckPage/RoleCheckPage.css"],"names":[],"mappings":"AAAA,wBAAwB;;AAExB;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,gBAAgB;IAChB,qDAAqD;IACrD,kBAAkB;IAClB,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;IACjB,WAAW;IACX,gBAAgB;IAChB,UAAU;IACV,qEAAqE;AACzE;;AAEA;IACI;QACI,UAAU;IACd;AACJ;;AAEA;IACI;QACI,YAAY;IAChB;AACJ","sourcesContent":["/* check-role-page.css */\n\n.check-role-page {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 100vh;\n    margin: 0 -180px;\n    background: linear-gradient(135deg, #ece9e6, #ffffff);\n    text-align: center;\n    padding: 20px;\n    box-sizing: border-box;\n}\n\n.check-role-page p {\n    font-size: 1.5rem;\n    color: #555;\n    margin-top: 20px;\n    opacity: 0;\n    animation: fadeIn 2s ease-in-out forwards, blink 1s step-end infinite;\n}\n\n@keyframes fadeIn {\n    to {\n        opacity: 1;\n    }\n}\n\n@keyframes blink {\n    50% {\n        opacity: 0.5;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
