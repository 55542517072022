import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import GuideHeader from "./GuideHeader";
import GuideFooter from "./GuideFooter";
import './GuideStyles/GuideBom.css';

const GuideWrapper: React.FC = () => {
    const location = useLocation();

    useEffect(() => {
        document.body.style.paddingTop = '0px';

        return () => {
            document.body.style.paddingTop = '';
        };
    }, [location]);
    useEffect(() => {
        const currentRole = localStorage.getItem('role');
        if (currentRole === 'Contractor') {
            localStorage.setItem('hasSeenContractorGuide', 'true');
        }
    }, []);

    return (
        <div className="guide-wrapper">
            <GuideHeader />
            <div className="guide-content">
                <Outlet />
            </div>
            <GuideFooter />
        </div>
    );
};

export default GuideWrapper;
