import React, {useEffect, useState} from "react";
import "./Sidebar.css";
import {IconLogOutRevers} from "../../../IconComponents/IconComponents";
import {
    Home,
    HomeActive,
    IconPricingBomCostsLanding,
    OrderHistory,
    OrderHistoryActive,
    ProjectList,
    ProjectListActive,
    Settings,
    SettingsActive,
    SidebarNOTUpgradeCollapsed,
    SidebarPlus,
    SidebarToggle,
    SidebarUpgradeCollapsed,
    Supports,
    SupportsActive,
    TeamManagement,
    TeamManagementActive
} from "./IconsSidebar";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../../store";
import CreateProjectModal from "../../Modals/ModalCreateProject/ModalCreateProject";
import UpgradeRole from "../../Modals/UpgradeRole/UpgradeRole";
import Purchase3dProject from "../../Modals/Purchase3DProject/Purchase3dProject";
import {selectUser, setUser} from "../../../Slice/tokenSlice/userSlice";

const Sidebar: React.FC = () => {
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [currentPath, setCurrentPath] = useState(window.location.pathname);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const dispatch = useAppDispatch();
    const user = useSelector(selectUser);
    const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);

    const [checkRole, setCheckRole] = useState(false);
    const [roleContractorSau, setRoleContractorSau] = useState(false)
    const navigate = useNavigate();
    const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);

    const [purchaseModalOpen, setPurchaseModalOpen] = useState(false);

    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    const toggleSidebar = () => {
        setIsExpanded((prev) => !prev);
    };

    useEffect(() => {
        setCurrentPath(window.location.pathname);
    }, [window.location.pathname]);

    useEffect(() => {
        if (user?.role?.role) {
            setCheckRole(user.role.role === "Customer");
            setRoleContractorSau(user.role.role === "Contractor" || user.role.role === "SAU");
        }
    }, [user]);

    if (!user) return null;


    const handleOpenModal3dProject = () => {
            setPurchaseModalOpen(true);
    };

    const handleLogoClock = () => {
        if (isAuthenticated) {
            navigate("/assistance");
        } else {
            navigate("/auth/login");
        }
    };

    const handleLogOut = () => {
        navigate("/home");
        setTimeout(() => {
            sessionStorage.removeItem("Bearer");
            localStorage.removeItem("Bearer");
            localStorage.removeItem("oldRole");
            localStorage.removeItem("userId");
            localStorage.removeItem("Iam");
            dispatch(setUser(null));
            toast.success("You have successfully logged out!");
        }, 100);
    };

    const isActive = {
        "/assistance": currentPath === "/assistance",
        "/projects": currentPath === "/projects",
        "/team": currentPath === "/team" && !checkRole,
        "/order_history": currentPath === "/order_history",
        "/preferences": currentPath === "/preferences",
        "/support": currentPath === "/support"
    };

    return (
        <div className={`sidebar ${isExpanded ? "expanded" : "collapsed"}`}>
            <div
                title="Navigate to Assistance page"
                onClick={handleLogoClock}
                className={`sidebar-logo ${isExpanded ? "expanded" : "collapsed"}`}
            >
                <img
                    src="/img_projects/gobuild.svg"
                    alt="Logo"
                    className="sidebar-logo-img"
                />
            </div>
            <div onClick={toggleSidebar} className="sidebar-toggle">
                <SidebarToggle/>
            </div>
            <nav className={`sidebar-menu ${isExpanded ? "expanded" : "collapsed"}`}>
                <section>
                    <div
                        title="Navigate to Assistance page"
                        className={`${isActive["/assistance"] ? `icon-link-block ${isExpanded ? "expanded" : "collapsed"}` : ""}`}>
                        <div
                            onClick={() => navigate("/assistance")}
                            className={`menu-item-sidebar ${isExpanded ? "expanded" : "collapsed"}`}
                        >
                            {isActive["/assistance"] ? <HomeActive/> : <Home/>}
                            <i/>
                            {isExpanded && (
                                <span
                                    className={`text-path-sidebar ${isActive["/assistance"] ? "active-path" : ""}`}>Home</span>
                            )}
                        </div>
                    </div>
                    <div
                        title="Navigate to Project list page"
                        className={`${isActive["/projects"] ? `icon-link-block ${isExpanded ? "expanded" : "collapsed"}` : ""}`}>
                        <div
                            onClick={() => navigate("/projects")}
                            className={`menu-item-sidebar ${isExpanded ? "expanded" : "collapsed"}`}
                        >
                            {isActive["/projects"] ? <ProjectListActive/> : <ProjectList/>}
                            <i/>
                            {isExpanded && (
                                <span className={`text-path-sidebar ${isActive["/projects"] ? "active-path" : ""}`}>Project list</span>
                            )}
                        </div>
                    </div>
                    {!checkRole && (
                        <div
                            title="Navigate to Team management page"
                            className={`${isActive["/team"] ? `icon-link-block ${isExpanded ? "expanded" : "collapsed"}` : ""}`}>
                            <div
                                onClick={() => navigate("/team")}
                                className={`menu-item-sidebar ${isExpanded ? "expanded" : "collapsed"}`}
                            >
                                {isActive["/team"] ? <TeamManagementActive/> : <TeamManagement/>}
                                <i/>
                                {isExpanded && (
                                    <span className={`text-path-sidebar ${isActive["/team"] ? "active-path" : ""}`}>Team management</span>
                                )}
                            </div>
                        </div>
                    )}
                    <div
                        title="Navigate to Order history page"
                        className={`${
                            isActive["/order_history"]
                                ? `icon-link-block ${isExpanded ? "expanded" : "collapsed"}`
                                : ""
                        }`}
                    >
                        <div
                            onClick={() => navigate("/order_history")}
                            className={`menu-item-sidebar ${isExpanded ? "expanded" : "collapsed"}`}
                        >
                            {isActive["/order_history"] ? <OrderHistoryActive/> : <OrderHistory/>}
                            <i/>
                            {isExpanded && (<span
                                    className={`text-path-sidebar ${isActive["/order_history"] ? "active-path" : ""}`}>Order history</span>
                            )}
                        </div>
                    </div>
                    <div
                        title="Navigate to My account"
                        className={`${isActive["/preferences"] ? `icon-link-block ${isExpanded ? "expanded" : "collapsed"}` : ""}`}>
                        <div
                            onClick={() => navigate("/preferences")}
                            className={`menu-item-sidebar ${isExpanded ? "expanded" : "collapsed"}`}
                        >
                            {isActive["/preferences"] ? <SettingsActive/> : <Settings/>}
                            <i/>
                            {isExpanded && (<span
                                    className={`text-path-sidebar ${isActive["/preferences"] ? "active-path" : ""}`}>Settings</span>
                            )}
                        </div>
                    </div>
                    <div
                        title="Navigate to Support page"
                        className={`${isActive["/support"] ? `icon-link-block ${isExpanded ? "expanded" : "collapsed"}` : ""}`}>
                        <div
                            onClick={() => navigate("/support")}
                            className={`menu-item-sidebar ${isExpanded ? "expanded" : "collapsed"}`}
                        >
                            {isActive["/support"] ? <SupportsActive/> : <Supports/>}
                            <i/>
                            {isExpanded && (<span
                                    className={`text-path-sidebar ${isActive["/support"] ? "active-path" : ""}`}>Support</span>
                            )}
                        </div>
                    </div>
                    {roleContractorSau ? (
                        <div
                            title="You already have the role of Contractor"
                            className={`sidebar-upgrade ${isExpanded ? "expanded" : "collapsed"}`}
                        >
                            <div className={`sidebar-upgrade-block role-sau ${isExpanded ? "expanded" : "collapsed"}`}>
                                <div>
                                    <SidebarNOTUpgradeCollapsed/>
                                </div>
                                {isExpanded && <span className="sidebar-upgrade-text">Upgrade now</span>}
                            </div>
                        </div>
                    ) : (
                        <div
                            title="Enhance the plan"
                            onClick={() => setIsUpgradeModalOpen(true)}
                            className={`sidebar-upgrade ${isExpanded ? "expanded" : "collapsed"}`}
                        >
                            <div className={`sidebar-upgrade-block ${isExpanded ? "expanded" : "collapsed"}`}>
                                <div>
                                    <SidebarUpgradeCollapsed/>
                                </div>
                                {isExpanded && <span className="sidebar-upgrade-text">Upgrade now</span>}
                            </div>
                        </div>
                    )}

                    <div
                        title="Create new project"
                        onClick={handleOpenModal}
                        className={`sidebar-create-project ${isExpanded ? "expanded" : "collapsed"}`}
                    >
                        <div>
                            <SidebarPlus/>
                        </div>
                        {isExpanded && <span className="sidebar-create-project-text">Create new project</span>}
                    </div>

                    {/*<div*/}
                    {/*    title="Payment of tokens for downloading a 3D file"*/}
                    {/*    className={`${isActive["/preferences"] ? `icon-link-block ${isExpanded ? "expanded" : "collapsed"}` : ""}`}>*/}
                    {/*    <div*/}
                    {/*        onClick={handleOpenModal3dProject}*/}
                    {/*        className={`menu-item-sidebar ${isExpanded ? "expanded" : "collapsed"} project-purchase`}*/}
                    {/*    >*/}
                    {/*        {isActive["/preferences"] ? <IconPricingBomCostsLanding/> : <IconPricingBomCostsLanding/>}*/}
                    {/*        <i/>*/}
                    {/*        {isExpanded && (*/}
                    {/*            <span className={`text-path-sidebar ${isActive["/preferences"] ? "active-path" : ""}`}>Proceed to payment</span>)}*/}
                    {/*        <p className={`count-buy-project ${isExpanded ? "count-expanded" : ""}`}>{user.tokens}</p>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </section>
            </nav>

            <CreateProjectModal isOpen={isModalOpen} onRequestClose={handleCloseModal}/>
            <UpgradeRole isOpen={isUpgradeModalOpen} onCancel={() => setIsUpgradeModalOpen(false)}/>
            {/*<Purchase3dProject onCancel={() => setPurchaseModalOpen(false)} isOpen={purchaseModalOpen}/>*/}

            <div onClick={handleLogOut} title="Log out" className="logout-button">
                <div className="logout-after-auth">
                    <IconLogOutRevers/>
                </div>
                {isExpanded && <span>Log Out</span>}
            </div>
        </div>
    );
};

export default Sidebar;
