import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './AuthStyle.css';
import { PuffLoader } from "react-spinners";
import { post } from "../../api/APIWraper";

interface AuthFields {
    email: string;
}

const ForgotPassword: React.FC = () => {
    const navigate = useNavigate();
    const [authFields, setAuthFields] = useState<AuthFields>({ email: "" });
    const [isBadEmail, setIsBadEmail] = useState(false);
    const [isWaitingForSubmit, setIsWaitingForSubmit] = useState(false);
    const [responseStatus,setIsResponseStatus] = useState(false);

    const validEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const email = e.target.value;
        setAuthFields({ ...authFields, email });
        setIsBadEmail(!validEmailRegex.test(email));
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!validEmailRegex.test(authFields.email)) {
            setIsBadEmail(true);
            toast.error("Invalid email format");
            return;
        }

        setIsWaitingForSubmit(true);

        try {
            const response = await post(`${process.env.REACT_APP_BASE_URL_CUSTOMER}/forgot_password`, { email: authFields.email });

            if (response.status === 404) {
                toast.error("Email not found");
            } else if (response.status === 200) {
                setIsResponseStatus(true)
                toast.success("An email has been sent with the password reset instructions");
            } else {
                toast.error("This email is not registered");
            }
        } catch (error) {
            toast.error("An error occurred. Please try again.");
        } finally {
            setIsWaitingForSubmit(false);
        }
    };

    const handleSignIn = () => {
        navigate("/auth/login");
    };

    const handleRegistration = () => {
        navigate("/auth/register");
    };

    return (
        <div className="layout-login">
            <div className="forgot-card">
                <div className="login-card-top">
                    <h3>Forgot Your Password</h3>
                    <p className='forgot-password-text'>Enter the email address you used to register. You will receive a temporary link to reset your password.</p>
                </div>

                <form className="forgot-card-form" onSubmit={handleSubmit}>
                    {responseStatus ? (
                        <div>
                            <p className="login-card-form-email-reset-message">A password recovery link has been sent to your email "<span className='login-card-form-email-email'>{authFields.email}</span>"</p>
                        </div>
                    ) : (
                        <div>
                            <div className={`login-card-form-input-container ${isBadEmail ? 'red-font-error' : ''}`}>
                                <label className={`login-card-form-label ${isBadEmail ? 'red-font-error' : ''}`}>Email
                                    <span style={{color: 'green', marginLeft: '5px'}}>*</span></label>
                                <input
                                    className={`login-card-form-input ${isBadEmail ? 'red-font-error' : ''}`}
                                    type="email"
                                    value={authFields.email}
                                    onChange={handleEmailChange}
                                    placeholder='samsmith@gmail.com'
                                />

                            </div>
                            {isBadEmail && (
                                <p className="login-card-form-input-message">Invalid email format</p>
                            )}
                        </div>
                    )}
                    {responseStatus ? ('') : (
                        <button
                            className={`login-card-form-button ${isWaitingForSubmit ? 'gray-background' : ''}`}
                            type="submit"
                        >
                            {!isWaitingForSubmit && "Send reset link"}
                            {isWaitingForSubmit && (
                                <div className="login-loading-spin">
                                    <PuffLoader size={25}/>
                                </div>
                            )}
                        </button>
                    )}

                    <div className="forgot-block-button">
                        <button
                            className="login-card-form-button-registration"
                            type="button"
                            onClick={handleSignIn}
                        >
                            &larr; Back to Log In
                        </button>


                        <button
                            className="login-card-form-button-forgot"
                            type="button"
                            onClick={handleRegistration}
                        >
                            Create an Account
                        </button>

                    </div>
                </form>
            </div>

            <ToastContainer
                position="bottom-center"
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                toastStyle={{
                    backgroundColor: "#333",
                    color: "#fff",
                    fontSize: "16px",
                    borderRadius: "8px",
                    padding: "16px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                }}
            />
        </div>
    );
};

export default ForgotPassword;
