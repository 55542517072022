import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {getAllCategoriesTemplates} from '../../api/APIWraper';
import {CategoryTemplates, Template} from '../../types/TemplateType';

interface AllTemplatesState {
    categories: CategoryTemplates[];
    totalTemplate: number;
    isFetching: boolean;
    error: string | null;
}

const initialState: AllTemplatesState = {
    categories: [],
    totalTemplate: 0,
    isFetching: false,
    error: null,
};

export const fetchAllTemplates = createAsyncThunk(
    'templates/fetchAllTemplates',
    async ({ page, perPage }: { page: number; perPage: number; }) => {
        const response = await getAllCategoriesTemplates(page, perPage);
        const filteredCategories = response.categories.map((category: CategoryTemplates) => {
            return {
                ...category,
                templates: {
                    ...category.templates,
                    data: category.templates.data
                }
            };
        });
        const maxTemplateCount = Math.max(...response.categories.map((category: CategoryTemplates) => category.templates.pagination.total));
        return {
            categories: filteredCategories,
            totalTemplate: maxTemplateCount,
        };
    }
);

const allTemplatesSlice = createSlice({
    name: 'templates',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllTemplates.pending, (state) => {
                state.isFetching = true;
            })
            .addCase(fetchAllTemplates.fulfilled, (state, action: PayloadAction<{ categories: CategoryTemplates[]; totalTemplate: number }>) => {
                state.isFetching = false;
                state.categories = action.payload.categories;
                state.totalTemplate = action.payload.totalTemplate;
            })
            .addCase(fetchAllTemplates.rejected, (state, action) => {
                state.isFetching = false;
                state.error = action.error.message ?? null;
            });
    },
});

export default allTemplatesSlice.reducer;