// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.guide-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 0 75px;
}

.guide-content {
    flex: 1 1;
    padding: 20px;
}

`, "",{"version":3,"sources":["webpack://./src/Page/BOMGuide/GuideStyles/GuideBom.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,sBAAsB;IACtB,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,SAAO;IACP,aAAa;AACjB","sourcesContent":["\n.guide-wrapper {\n    display: flex;\n    flex-direction: column;\n    min-height: 100vh;\n    padding: 0 75px;\n}\n\n.guide-content {\n    flex: 1;\n    padding: 20px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
