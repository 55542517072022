import React, { useState } from 'react';
import './DataProtection.css';

const DataProtection = () => {

    return (
        <div>DataProtection</div>
    );
}

export default DataProtection;