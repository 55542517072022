import React, {useState} from "react";
import {IconLogOutRevers} from "../../IconComponents/IconComponents";
import "./DropDownUserList.css";
import {User} from "../../types/ProjectTypes";
import {
    IconPricingBomCostsLanding,
    SideBarNotification,
    SideBarPlan,
    SideBarPrivacy,
    SideBarSettings
} from "./Sidebar/IconsSidebar";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import Purchase3dProject from "../Modals/Purchase3DProject/Purchase3dProject";
import {clearLogOutStorage} from "../../utils/ClearLogOutStorage";
import {setUser} from "../../Slice/tokenSlice/userSlice";
import {useDispatch} from "react-redux";

interface DropDownUserListProps {
    userPhoto: string;
    user: User;
    isOpen: boolean;
    setUserListOpen: (open: boolean) => void;
}

const DropDownUserList: React.FC<DropDownUserListProps> = ({userPhoto, user, isOpen, setUserListOpen}) => {
    const navigate = useNavigate();
    const [purchaseModalOpen, setPurchaseModalOpen] = useState(false);
    const dispatch = useDispatch();

    const handleLogOut = () => {
        toast.info("Wait, we're coming out...");
        setTimeout(() => {
            clearLogOutStorage()
            navigate("/home");
            dispatch(setUser(null))

            toast.success("You have successfully logged out!");
        }, 1000);
    };

    const handleNavigateToPreferencePage = (scrollTo: string) => {
        setUserListOpen(false);
        navigate('/preferences', {state: {scrollTo}});
    };

    const handleOpenModal3dProject = () => {
        setPurchaseModalOpen(true);
    };

    return (
        <section
            className={`drop-down-user-list-container ${isOpen ? 'open' : 'close'}`}>
            <div className="user-dropdown-user-name">
                <div>
                    <img src={userPhoto} alt={user.username} className="user-dropdown-avatar"/>
                </div>
                <p className="user-block-username">{user.username}</p>
                <p className="user-block-email">{user.email}</p>
            </div>
            <main className="drop-down-user-list-main">
                <div className="drop-down-user-list-block">
                    <SideBarSettings/>
                    <p
                        onClick={() => handleNavigateToPreferencePage('account-details')}
                        className="drop-down-user-list-block-text">Account Settings</p>
                </div>
                <div className="drop-down-user-list-block">
                    <SideBarNotification/>
                    <p
                        onClick={()=>handleNavigateToPreferencePage('notification-settings')}
                        className="drop-down-user-list-block-text">Notification Preferences</p>
                </div>
                <div className="drop-down-user-list-block">
                    <SideBarPrivacy/>
                    <p
                        onClick={()=>handleNavigateToPreferencePage('privacy-settings')}
                        className="drop-down-user-list-block-text">Privacy Preferences</p>
                </div>
                <div className="drop-down-user-list-block">
                    <SideBarPlan/>
                    <p
                        onClick={() => handleNavigateToPreferencePage('subscription-plan')}
                        className="drop-down-user-list-block-text">Subscription plan</p>
                </div>
                {/*<div*/}
                {/*    onClick={() => handleOpenModal3dProject()}*/}
                {/*    title="Payment of tokens for downloading a 3D file"*/}
                {/*    className="drop-down-user-list-block">*/}
                {/*    <IconPricingBomCostsLanding/>*/}
                {/*    <p className='drop-down-user-list-block-text'>Tokens purchasing</p>*/}
                {/*    <p className="drop-down-user-list-block-tokens">{user.tokens}</p>*/}
                {/*</div>*/}
            </main>
            <div className="user-dropdown-btn-block">
                <button onClick={handleLogOut} className="user-dropdown-btn-block-button">
                    <IconLogOutRevers/>
                    <p className="user-dropdown-btn-block-button-text">Log Out</p>
                </button>
            </div>
            <Purchase3dProject onCancel={() => setPurchaseModalOpen(false)} isOpen={purchaseModalOpen}/>
        </section>
    );
};

export default DropDownUserList;
