// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.guide-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-top: 1px solid #e0e0e0;
    background-color: #fff;
    width: 100%;
    box-sizing: border-box;
}

.progress-container-guide {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: start;
    font-family: var(--font-roboto), sans-serif;
}

.progress-bar-background-guide {
    width: 185px;
    height: 10px;
    background-color: #e0e0e0;
    border-radius: 4px;
    position: relative;
}

.progress-bar-guide {
    height: 100%;
    background-color: var(--basic-color);
    border-radius: 4px;
    transition: width 0.3s ease-in-out;
}

.guide-footer-btn-next {
    background-color: var(--basic-btn-dark-color);
    border: 1px solid var(--basic-btn-dark-color);
    color: white;
    font-family: var(--font-roboto), sans-serif;
    font-weight: bold;
    width: 215px;
    height: 50px;
    border-radius: 10px;
    cursor: pointer;
    margin-left: 10px;
    transition: 0.3s;
}

.guide-footer-btn-next:hover {
    background-color: white;
    color: var(--basic-btn-dark-color);
    transition: 0.3s;
}
`, "",{"version":3,"sources":["webpack://./src/Page/BOMGuide/GuideStyles/GuideFooter.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,aAAa;IACb,6BAA6B;IAC7B,sBAAsB;IACtB,WAAW;IACX,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,kBAAkB;IAClB,2CAA2C;AAC/C;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,oCAAoC;IACpC,kBAAkB;IAClB,kCAAkC;AACtC;;AAEA;IACI,6CAA6C;IAC7C,6CAA6C;IAC7C,YAAY;IACZ,2CAA2C;IAC3C,iBAAiB;IACjB,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,eAAe;IACf,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,uBAAuB;IACvB,kCAAkC;IAClC,gBAAgB;AACpB","sourcesContent":[".guide-footer {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 1rem;\n    border-top: 1px solid #e0e0e0;\n    background-color: #fff;\n    width: 100%;\n    box-sizing: border-box;\n}\n\n.progress-container-guide {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    align-items: start;\n    font-family: var(--font-roboto), sans-serif;\n}\n\n.progress-bar-background-guide {\n    width: 185px;\n    height: 10px;\n    background-color: #e0e0e0;\n    border-radius: 4px;\n    position: relative;\n}\n\n.progress-bar-guide {\n    height: 100%;\n    background-color: var(--basic-color);\n    border-radius: 4px;\n    transition: width 0.3s ease-in-out;\n}\n\n.guide-footer-btn-next {\n    background-color: var(--basic-btn-dark-color);\n    border: 1px solid var(--basic-btn-dark-color);\n    color: white;\n    font-family: var(--font-roboto), sans-serif;\n    font-weight: bold;\n    width: 215px;\n    height: 50px;\n    border-radius: 10px;\n    cursor: pointer;\n    margin-left: 10px;\n    transition: 0.3s;\n}\n\n.guide-footer-btn-next:hover {\n    background-color: white;\n    color: var(--basic-btn-dark-color);\n    transition: 0.3s;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
