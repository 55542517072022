import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import './CheckUserPermission.css';
import {Permission, Project} from "../../../types/ProjectTypes";
import {RootState} from "../../../store";
import {connect, ConnectedProps, useSelector} from "react-redux";
import {Loader} from "../../Loader/Loader";
import {IconInfoIcon} from "../../../IconComponents/IconComponents";
import {selectUser} from "../../../Slice/tokenSlice/userSlice";

interface ModalDeleteProps {
    isOpen: boolean;
    onRequestClose: () => void;
    project: Project | null;
}

const CheckUserPermission: React.FC<ModalDeleteProps & PropsFromRedux> = ({
                                                                              isOpen,
                                                                              onRequestClose,
                                                                              project,
                                                                              isFetching
                                                                          }) => {
    const [userObject, setUserObject] = useState<Permission | null>(null);

    const [isEditSection, setIsEditSection] = useState(true);
    const [isMeasurement, setIsMeasurement] = useState(true);
    const [isPurchasePermissions, setIsPurchasePermissions] = useState(true);
    const [isChangeAddress, setIsChangeAddress] = useState(true);
    const [isMakePayments, setIsMakePayments] = useState(true);
    const userGlobal = useSelector(selectUser);

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
        return () => {
            document.body.style.overflow = '';
        };
    }, [isOpen]);

    useEffect(() => {
        if (project && userGlobal) {
            const userId = userGlobal.id;
            if (userId) {
                const user = project.permissions.find(
                    (permission: Permission) => permission.user_id === userId
                );
                if (user) {
                    setUserObject(user);
                }
            }
        }
    }, [project]);

    useEffect(() => {
        const randomizeState = () => {
            setIsEditSection(Math.random() >= 0.5);
            setIsMeasurement(Math.random() >= 0.5);
            setIsPurchasePermissions(Math.random() >= 0.5);
            setIsChangeAddress(Math.random() >= 0.5);
            setIsMakePayments(Math.random() >= 0.5);
        };
        randomizeState();
    }, []);

    if (isFetching) {
        return <Loader/>;
    }

    const userGalleyCheck = userObject?.user.galleries && userObject.user.galleries.length > 0 && userObject.user.galleries[0].medias[userObject.user.galleries[0].medias.length - 1].url;
    const user = userObject?.user;
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className="check-permission-modal-permission"
            overlayClassName="overlay-check-permission-permissions"
        >
            <button className="close-button" onClick={onRequestClose}>&times;</button>
            <div className="check-permission-permissions">
                <div className='check-permission-user-photo-block'>
                    <img className='check-permission-user-photo' src={userGalleyCheck || '/img/profile-default.png'}/>
                </div>
                <div className='check-permission-modal-name-block'>
                    <p>{user?.first_name ? user.first_name : 'Test name'} {user?.last_name}</p>
                    <p>{user?.email}</p>
                    <p className='check-permission-modal-project-title'>{project?.title}</p>
                </div>
                <div>
                    <p>Permissions</p>
                    <div className='check-permission-modal-project-permission'>
                        <p>Editor</p>
                    </div>
                </div>
                <div className='check-permission-modal-toggle-block'>
                    <div className='check-user-permissions-content-block'>
                        <p>Edit Item Section</p>
                        <div
                            className={`check-user-permissions-switcher-toggle ${isEditSection ? '' : 'active'}`}
                            style={{ pointerEvents: 'none' }}
                        >
                            <div
                                className={`check-user-permissions-switcher-toggle-knob ${isEditSection ? '' : 'active'}`}>
                            </div>
                        </div>
                    </div>

                    <div className='check-user-permissions-content-block'>
                        <p>Measurement Adjustments</p>
                        <div
                            className={`check-user-permissions-switcher-toggle ${isMeasurement ? '' : 'active'}`}
                            style={{ pointerEvents: 'none' }}
                        >
                            <div
                                className={`check-user-permissions-switcher-toggle-knob ${isMeasurement ? '' : 'active'}`}>
                            </div>
                        </div>
                    </div>

                    <div className='check-user-permissions-content-block'>
                        <p>Purchase Permissions</p>
                        <div
                            className={`check-user-permissions-switcher-toggle ${isPurchasePermissions ? '' : 'active'}`}
                            style={{ pointerEvents: 'none' }}
                        >
                            <div
                                className={`check-user-permissions-switcher-toggle-knob ${isPurchasePermissions ? '' : 'active'}`}>
                            </div>
                        </div>
                    </div>

                    <div className='check-user-permissions-content-block-info'>
                        <div className='check-user-permissions-content-block-info-content'>
                            <p>Add or Change Delivery Address</p>
                            <div className="user-permissions-tooltip-container">
                                <IconInfoIcon/>
                                <span className="user-permissions-tooltip-text">Gives this user access to make payments on this project.</span>
                            </div>
                        </div>
                        <div
                            className={`check-user-permissions-switcher-toggle ${isChangeAddress ? '' : 'active'}`}
                            style={{ pointerEvents: 'none' }}
                        >
                            <div
                                className={`check-user-permissions-switcher-toggle-knob ${isChangeAddress ? '' : 'active'}`}>
                            </div>
                        </div>
                    </div>

                    <div className='check-user-permissions-content-block-info'>
                        <div className='check-user-permissions-content-block-info-content'>
                            <p>Make Payments</p>
                            <div className="user-permissions-tooltip-container">
                                <IconInfoIcon/>
                                <span className="user-permissions-tooltip-text">Gives this user access to make payments on this project.</span>
                            </div>
                        </div>
                        <div
                            className={`check-user-permissions-switcher-toggle ${isMakePayments ? '' : 'active'}`}
                            style={{ pointerEvents: 'none' }}
                        >
                            <div
                                className={`check-user-permissions-switcher-toggle-knob ${isMakePayments ? '' : 'active'}`}>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='check-permission-modal-btn-block'>
                    <button className="check-confirm-button-warning-permission" onClick={onRequestClose}>OK</button>
                </div>
            </div>
        </Modal>
    );
};

const mapStateToProps = (state: RootState) => ({
    project: state.projectById.project,
    isFetching: state.projectById.isFetching,
    isAuthenticated: state.auth.isAuthenticated
});

const connector = connect(mapStateToProps, null);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CheckUserPermission);
