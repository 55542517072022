import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { connect, ConnectedProps } from "react-redux";
import './style.css';
import { updateProject } from "../../Slice/updateProject/updateProjectSlice";
import { transformTemplate } from "../../utils/TransformTemplate";
import { Template } from "../../types/TemplateType";
import { clearTemplates, updateLocalTemplate } from "../../Slice/LocalTemplate/LocalTemplateSlice";
import { RootState } from "../../store";
import parseTemplateToRequestObject from "../../Parsers/TemplateParse/templateToTemplateRequestParser";
import WarningPermissions from "../Modals/WarningPermissionSteps/WarningPermissions";
import {Permission} from "../../types/UserTypes";
import GetPermission from "../RoleComponent/Role";

interface WallCalculatorModalProps {
    templateIndex: number;
    isOpen: boolean;
    onSave: () => void;
    onClose: () => void;
    onTemplateUpdate: (updatedTemplates: Template[]) => void;
}

const WallCalculatorModal: React.FC<WallCalculatorModalProps & PropsFromRedux> = ({
                                                                                      project,
                                                                                      templateIndex,
                                                                                      isOpen,
                                                                                      onSave,
                                                                                      onClose,
                                                                                      updateProject,
                                                                                      updateLocalTemplate,
                                                                                      localTemplates,
                                                                                      onTemplateUpdate,
                                                                                      clearTemplates
                                                                                  }) => {
    const [selectedAnswers, setSelectedAnswers] = useState<Record<string, string>>({});
    const template = localTemplates[templateIndex] || project?.data?.templates?.[templateIndex] || null;
    const [expandedFragments, setExpandedFragments] = useState<Set<number>>(new Set());
    const [isTemplateExpanded, setIsTemplateExpanded] = useState<boolean>(true);
    const [checkRole, setIsCheckRole] = useState<boolean>(false);
    const [isOpenWarningPermissionModal, setIsOpenWarningPermissionModal] = useState<boolean>(false);


    const handeWarningPermissionModalClose =()=>{
        setIsOpenWarningPermissionModal(false)
    }

    useEffect(() => {
        if (project) {
            const userId = localStorage.getItem('userId');
            if (userId) {
                const userPermission = project.permissions.find(
                    (permission: Permission) => permission.user_id === parseInt(userId)
                );
                if (userPermission) {
                    const role = GetPermission(userPermission.level);
                    setIsCheckRole(role === 'Admin')
                } else {
                    console.error('User not found in project permissions.');
                }
            }
        }
    }, [project]);

    useEffect(() => {
        if (template) {
            const initialSelectedAnswers: Record<string, string> = {};

            Object.entries(template.data || {}).forEach(([key, item]: [string, any]) => {
                if (!isNaN(parseInt(key)) && item?.value) {
                    initialSelectedAnswers[`${template.id}-calc-${key}`] = item.value;
                } else if (item?.defaultValue) {
                    initialSelectedAnswers[`${template.id}-calc-${key}`] = item.defaultValue;
                }
            });
            template.data?.fragments?.forEach((fragment: any, fragmentIndex: number) => {
                Object.entries(fragment.data || {}).forEach(([key, item]: [string, any]) => {
                    const uniqueKey = `fragment-${fragmentIndex}-${key}`;
                    if (item?.value) {
                        initialSelectedAnswers[uniqueKey] = item.value;
                    } else if (item?.defaultValue) {
                        initialSelectedAnswers[uniqueKey] = item.defaultValue;
                    }
                });
            });

            setSelectedAnswers(initialSelectedAnswers);
        }
    }, [template]);

    const handleAnswerChange = (key: string, value: string) => {
        setSelectedAnswers((prevState) => ({
            ...prevState,
            [key]: value
        }));
    };

    const handleInputChange = (key: string, value: string) => {
        if (/^\d*\.?\d*$/.test(value)) {
            setSelectedAnswers((prevState) => ({
                ...prevState,
                [key]: value
            }));
        }
    };

    const handleSubmit = () => {
        if (!checkRole) {
            setIsOpenWarningPermissionModal(true);
            return;
        }
        if (template && project && project.data && project.data.templates) {
            const updatedTemplate = {
                ...template,
                isCalculated: true,
                data: {
                    ...template.data,
                    ...Object.entries(template.data || {})
                        .filter(([key, item]: [string, any]) => !isNaN(parseInt(key)) && item?.question && item?.select)
                        .reduce((acc: Record<string, any>, [key, item]: [string, any]) => {
                            acc[key] = {
                                ...item,
                                value: selectedAnswers[`${template.id}-calc-${key}`] || item?.value,
                            };
                            return acc;
                        }, {}),
                    fragments: template.data?.fragments?.map((fragment: any, fragmentIndex: number) => ({
                        ...fragment,
                        data: Object.entries(fragment.data || {}).reduce((acc: Record<string, any>, [key, item]: [string, any]) => {
                            const uniqueKey = `fragment-${fragmentIndex}-${key}`;
                            acc[key] = {
                                ...item,
                                value: selectedAnswers[uniqueKey] || item?.value,
                                answers: item?.answers ? item.answers.map((answer: any) => ({
                                    ...answer,
                                    value: item.select === 'input' ? selectedAnswers[uniqueKey] : answer?.value,
                                    selected: item.select === 'input' ? true : selectedAnswers[uniqueKey] === answer?.value?.toString()
                                })) : item?.answers
                            };
                            return acc;
                        }, {})
                    }))
                }
            };

            updateLocalTemplate(updatedTemplate);

            const updatedTemplates = project.data.templates.map((tmpl, index) => index === templateIndex ? { ...updatedTemplate, counted: true } : tmpl);
            const transformedTemplates = updatedTemplates.map(transformTemplate);

            const updatedProjectData = {
                data: {
                    templates: transformedTemplates
                }
            };

            updateProject({ projectId: project.id, projectData: updatedProjectData });
            onTemplateUpdate(updatedTemplates);
            onSave();
            clearTemplates();
            onClose();
        } else {
            console.error("Project data or templates are undefined.");
        }
    };

    const parserTemplate = parseTemplateToRequestObject(template!);

    const toggleTemplateVisibility = () => {
        setIsTemplateExpanded(prevState => !prevState);
    };

    const toggleFragment = (index: number) => {
        setExpandedFragments(prevState => {
            const newSet = new Set(prevState);
            if (newSet.has(index)) {
                newSet.delete(index);
            } else {
                newSet.add(index);
            }
            return newSet;
        });
    };

    const filterVarMappingQuestions = (fragment: any) => {
        const hiddenKeys = new Set<string>();
        if (typeof fragment?.var_mapping === 'string') {
            const mappings = fragment.var_mapping.split('&&').map((map: string) => map.trim());
            mappings.forEach((mapping: string) => {
                const [left, right] = mapping.split('=').map((s: string) => s.trim());
                hiddenKeys.add(right);
            });
        }
        return hiddenKeys;
    };

    return (
        <Modal
            className="modal-overlay"
            isOpen={isOpen}
            onRequestClose={onClose}
            overlayClassName="modal-content-more-list"
            ariaHideApp={false}
        >
            <div className='modal-content-more-list'>
                <div className='modal-content-show-step-btn'>
                    <div>
                        <p className='modal-content-required'>Required information</p>
                        <p className='modal-content-text-questions'>Mandatory questions:</p>
                    </div>
                    <button className="modal-close" onClick={onClose}>
                        &times;
                    </button>
                </div>
                <div className={`step-calc-fragment ${isTemplateExpanded ? 'expanded' : 'collapsed'}`} key={template?.id}>
                    <h3 className="step-calc-fragment-title" onClick={toggleTemplateVisibility}>
                        {template?.title}
                        <button className={`toggle-button ${isTemplateExpanded ? 'expanded' : 'collapsed'}`}>
                            {isTemplateExpanded ? '▲' : '▼'}
                        </button>
                    </h3>
                    <div className="step-calc-fragment-content">
                        {Object.entries(template?.data || {})
                            .filter(([key, item]: [string, any]) => !isNaN(parseInt(key)) && item?.question && item?.select)
                            .map(([key, templateQuestion]) => (
                                <div className="step-answer-block" key={`${template?.id}-calc-${key}`}>
                                    <div className="step-question">{templateQuestion?.question || 'Default Question'}</div>
                                    <div className='step-calc-field-fragment'>
                                        {templateQuestion.select === 'select' && templateQuestion.answers ? (
                                            templateQuestion.answers.map((answer: any, idx: number) => (
                                                <label key={idx} className="step-answer">
                                                    <input
                                                        type="radio"
                                                        name={`${template?.id}-calc-${key}`}
                                                        value={answer.value}
                                                        checked={selectedAnswers[`${template?.id}-calc-${key}`] === answer.value}
                                                        onChange={() => handleAnswerChange(`${template?.id}-calc-${key}`, answer.value)}
                                                    />
                                                    <span className="radio-custom"></span>
                                                    {answer.title}
                                                </label>
                                            ))
                                        ) : (
                                            <input
                                                className={`step-calc-field-fragment ${selectedAnswers[`${template?.id}-calc-${key}`] ? 'filled' : ''}`}
                                                type="text"
                                                value={selectedAnswers[`${template?.id}-calc-${key}`] || templateQuestion.defaultValue || ''}
                                                onChange={(e) => handleInputChange(`${template?.id}-calc-${key}`, e.target.value)}
                                                onBlur={(e) => handleAnswerChange(`${template?.id}-calc-${key}`, e.target.value)}
                                            />
                                        )}
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>

                <div className='step-calc-fragment-block'>
                    {parserTemplate?.data?.fragments?.map((fragment: any, fragmentIndex: number) => {
                        const hiddenKeys = filterVarMappingQuestions(fragment.data);

                        const hasQuestions = Object.entries(fragment.data || {}).some(([key, fragmentData]: [string, any]) => {
                            return !hiddenKeys.has(fragmentData?.key) && fragmentData?.question && fragmentData?.select;
                        });

                        if (!hasQuestions) {
                            return null;
                        }

                        const isExpanded = expandedFragments.has(fragmentIndex);

                        return (
                            <div
                                key={`fragment-${fragmentIndex}`}
                                className={`step-calc-fragment fragment-${fragmentIndex} ${isExpanded ? 'expanded' : 'collapsed'}`}
                            >
                                <h3 className="step-calc-fragment-title" onClick={() => toggleFragment(fragmentIndex)}>
                                    {fragment.title || `Fragment ${fragmentIndex + 1}`}
                                    <button className={`toggle-button ${isExpanded ? '' : 'collapsed'}`}>
                                        {isExpanded ? '▲' : '▼'}
                                    </button>
                                </h3>
                                <div className={`step-calc-fragment-content ${isExpanded ? 'expanded' : ''}`}>
                                    {Object.entries(fragment.data || {}).map(([key, fragmentData]: [string, any], index: number) => {
                                        const uniqueKey = `fragment-${fragmentIndex}-${key}`;

                                        if (!hiddenKeys.has(fragmentData?.key) && fragmentData?.question && fragmentData?.select) {
                                            return (
                                                <div key={`${fragmentIndex}-${key}`} className="step-answer-block-modal">
                                                    <div className="step-question">{fragmentData.question}</div>
                                                    {fragmentData.select === 'select' ? (
                                                        fragmentData.answers ? (
                                                            fragmentData.answers.map((answer: any, idx: number) => (
                                                                <label key={idx} className="step-answer">
                                                                    <input
                                                                        type="radio"
                                                                        name={`fragment-${fragmentIndex}-${key}`}
                                                                        value={answer.value}
                                                                        checked={selectedAnswers[uniqueKey] === answer.value}
                                                                        onChange={() => handleAnswerChange(uniqueKey, answer.value)}
                                                                    />
                                                                    <span className="radio-custom"></span>
                                                                    {answer.title}
                                                                </label>
                                                            ))
                                                        ) : <p>No answers available</p>
                                                    ) : (
                                                        <div className='step-calc-field-fragment'>
                                                            <input
                                                                type="text"
                                                                value={selectedAnswers[uniqueKey] || fragmentData.defaultValue || ''}
                                                                onChange={(e) => handleInputChange(uniqueKey, e.target.value)}
                                                                onBlur={(e) => handleAnswerChange(uniqueKey, e.target.value)}
                                                                readOnly={false}
                                                                className={selectedAnswers[uniqueKey] ? 'filled' : ''}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            );
                                        }
                                        return null;
                                    })}
                                </div>
                            </div>
                        );
                    })}
                </div>
                <WarningPermissions isOpen={isOpenWarningPermissionModal} onRequestClose={handeWarningPermissionModalClose}/>

                <div className='calc-btn-block'>
                    <button className="calc-btn" type="button" onClick={handleSubmit}>Submit</button>
                </div>
            </div>
        </Modal>
    );
};

const mapStateToProps = (state: RootState) => ({
    localTemplates: state.localTemplate.templates,
    project: state.projectById.project
});

const mapDispatchToProps = {
    updateProject,
    updateLocalTemplate,
    clearTemplates
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(WallCalculatorModal);
