import React, {useEffect, useRef} from 'react';
import '../Pricing/Pricing.css'
import {
    IconAboutUsCheckLanding,
    IconAboutUsUnCheckLanding, IconPricing3DLanding,
    IconPricingAddLanding,
    IconPricingBomCostsLanding,
    IconPricingBoMLanding, IconPricingContractorsLanding,
    IconPricingMeasurementLanding,
    IconPricingPDFLanding,
    IconPricingProductLanding,
    IconPricingProjectLanding,
    IconPricingShareLanding,
    IconPricingTemplateLanding,
} from "../../../IconComponents/IconComponents";
import {get, post} from "../../../api/APIWraper";
import {toast} from "react-toastify";
import {notifySuccessOrderTemplate} from "../../../components/Toast/AssisCreateToastNotification";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectUser} from "../../../Slice/tokenSlice/userSlice";


const BASE_CUSTOMER_URL = process.env.REACT_APP_BASE_URL_CUSTOMER

function Pricing() {
    const roleCheckIntervalRef = useRef<NodeJS.Timeout | null>(null);
    const navigate = useNavigate()
    const user = useSelector(selectUser);
    const userRole = user?.role?.role

    const handleVisibilityChange = () => {
        if (document.visibilityState === 'visible') {
            const redirectOnReturn = localStorage.getItem('redirectOnReturn');
            if (redirectOnReturn === 'true') {
                localStorage.removeItem('redirectOnReturn');
                startRoleCheckInterval();
            }
        }
    };

    useEffect(() => {
        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            if (roleCheckIntervalRef.current) {
                clearInterval(roleCheckIntervalRef.current);
                roleCheckIntervalRef.current = null;
            }
        };
    }, []);


    const handeBuy = async () => {
        const checkToken = localStorage.getItem('Bearer') || sessionStorage.getItem('Bearer');
        const scrollPosition = window.scrollY;
        const currentPath = window.location.pathname;

        if (!checkToken) {
            toast.error('You must authorization');

            navigate('/auth/login', {state: {from: currentPath, scrollPosition}});
            return;
        }
        const response = await post(`${BASE_CUSTOMER_URL}/subscription/contractor/monthly`);
        if (response.status === 200) {
            const sessionToken = sessionStorage.getItem('Bearer');
            if (sessionToken) {
                sessionStorage.setItem('Bearer', sessionToken);
            }
            window.open(response.data.links[0].href);
            localStorage.setItem('redirectOnReturn', 'true');
        } else {
            toast.error('You must authorization');
        }
    };

    const startRoleCheckInterval = () => {
        if (roleCheckIntervalRef.current) {
            clearInterval(roleCheckIntervalRef.current);
        }

        roleCheckIntervalRef.current = setInterval(async () => {
            try {
                const responseMe = await get(`${process.env.REACT_APP_BASE_URL_CUSTOMER}/my_profile_info`);
                const serverRole = responseMe.data.role.role;

                if (userRole !== serverRole) {
                    localStorage.setItem('role', serverRole);

                    clearInterval(roleCheckIntervalRef.current as NodeJS.Timeout);
                    roleCheckIntervalRef.current = null;

                    notifySuccessOrderTemplate(`You have successfully purchased a "${serverRole}" plan`);
                    setTimeout(() => {
                        window.location.href = '/assistance';
                    }, 2500);
                }
            } catch (error) {
                console.error("Error fetching user role:", error);
            }
        }, 5000);
    };


    return (
        <main className='landing-page'>
            <div className='landing-middle-info-pricing'>
                <p className='landing-middle-info-pricing-start'>Let’s get started</p>
                <p className='landing-middle-info-pricing-text'>Choose the best plan that fit for you. </p>
            </div>

            <section className='section-with-card'>
                <div className="card-container-pricing">
                    <div className="card-pricing card-green hover-effect">
                        <div className="card-title">Foundation</div>
                        <div className='card-title-desc'>Access to basic DIY templates, change your measurements and get
                            a BoM
                        </div>
                        <p className='card-title-info-free'>Pay $10 per measurement on more advanced templates</p>
                        <div className="card-price">Free</div>
                        <div className="card-description">Manual Measurement Insertion</div>
                        <button className="card-button card-btn-default">Get started</button>
                    </div>

                    <div className="card-pricing card-green card-recommended-pricing">
                        <div className="card-recommended-label">Recommended</div>
                        <div className="card-title">Structural</div>
                        <div className='card-title-desc'>
                            Manual Measurement Insertion
                            Users can select projects, input measurements of required building project, customize
                            materials, and receive a comprehensive cost breakdown.
                        </div>
                        <div className="card-price">$12.50<span className='card-price-time'>/month</span></div>
                        <div className="card-description">Unlimited Measuring</div>
                        <button
                            onClick={handeBuy}
                            className="card-button ">Get started
                        </button>
                    </div>

                    <div className="card-pricing card-orange hover-effect">
                        <div className="card-title">Architect</div>
                        <div className='card-title-desc'>3D digital Revit architectural plans Integration:
                            Architects can upload 3D digital Revit architectural plans and instantly receive detailed
                            cost estimates.
                        </div>
                        <div className="card-description">Unlimited Measuring</div>
                        {/*<button*/}
                        {/*    className="card-button card-btn-default">Get started*/}
                        {/*</button>*/}
                        <div className="pricing-architect-card">Coming soon...</div>
                    </div>
                </div>
            </section>

            <section>
                <div className='pricing-info-tablet'>
                    <p className='pricing-tablet-features'>Features</p>
                    <p className='pricing-tablet-foundation'>Foundation</p>
                    <p className='pricing-tablet-recommended'>Recommended</p>
                    <p className='pricing-tablet-architect'>Architect</p>
                </div>

                <h3 className='pricing-info-text'>Basic Features</h3>

                <div className='pricing-tablet-block'>
                    <div className='pricing-tablet-block-info'>
                        <div className='pricing-tablet-block-feature'>
                            <IconPricingProjectLanding/>
                            <p className='pricing-tablet-feature-text'>Project creation</p>
                        </div>
                        <IconAboutUsCheckLanding/>
                        <IconAboutUsCheckLanding/>
                        <IconAboutUsCheckLanding/>
                    </div>
                </div>

                <div className='pricing-tablet-block'>
                    <div className='pricing-tablet-block-info'>
                        <div className='pricing-tablet-block-feature'>
                            <IconPricingTemplateLanding/>
                            <p className='pricing-tablet-feature-text'>Template selection</p>
                        </div>
                        <div className='pricing-tablet-section-information'>
                            <div className='pricing-tablet-block-information-one'>
                                <IconAboutUsCheckLanding/>
                                <p className='pricing-tablet-block-information-text'>Limited or $10 per template</p>
                            </div>
                            <div className='pricing-tablet-block-information-two'>
                                <IconAboutUsCheckLanding/>
                                <p className='pricing-tablet-block-information-text'>All templates</p>
                            </div>
                            <div className='pricing-tablet-block-information-three'>
                                <IconAboutUsCheckLanding/>
                                <p className='pricing-tablet-block-information-text'>All templates</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='pricing-tablet-block'>
                    <div className='pricing-tablet-block-info'>
                        <div className='pricing-tablet-block-feature'>
                            <IconPricingMeasurementLanding/>
                            <p className='pricing-tablet-feature-text'>Measurement insertion</p>
                        </div>
                        <IconAboutUsCheckLanding/>
                        <IconAboutUsCheckLanding/>
                        <IconAboutUsCheckLanding/>
                    </div>
                </div>

                <div className='pricing-tablet-block'>
                    <div className='pricing-tablet-block-info'>
                        <div className='pricing-tablet-block-feature'>
                            <IconPricingBoMLanding/>
                            <p className='pricing-tablet-feature-text'>BoM creation</p>
                        </div>
                        <div className='pricing-tablet-section-information'>
                            <div className='pricing-tablet-block-information-one'>
                                <IconAboutUsCheckLanding/>
                                <p className='pricing-tablet-block-information-text'>Based on Gobuild templates</p>
                            </div>
                            <div className='pricing-tablet-block-information-two'>
                                <IconAboutUsCheckLanding/>
                                <p className='pricing-tablet-block-information-text'></p>
                            </div>
                            <div className='pricing-tablet-block-information-three'>
                                <IconAboutUsCheckLanding/>
                                <p className='pricing-tablet-block-information-text'></p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='pricing-tablet-block'>
                    <div className='pricing-tablet-block-info'>
                        <div className='pricing-tablet-block-feature'>
                            <IconPricingProductLanding/>
                            <p className='pricing-tablet-feature-text'>Product selection</p>
                        </div>
                        <div className='pricing-tablet-section-information'>
                            <div className='pricing-tablet-block-information-one'>
                                <IconAboutUsCheckLanding/>
                                <p className='pricing-tablet-block-information-text'>$10 per template</p>
                            </div>
                            <div className='pricing-tablet-block-information-two'>
                                <IconAboutUsCheckLanding/>
                                <p className='pricing-tablet-block-information-text'></p>
                            </div>
                            <div className='pricing-tablet-block-information-three'>
                                <IconAboutUsCheckLanding/>
                                <p className='pricing-tablet-block-information-text'></p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='pricing-tablet-block'>
                    <div className='pricing-tablet-block-info'>
                        <div className='pricing-tablet-block-feature'>
                            <IconPricingBomCostsLanding/>
                            <p className='pricing-tablet-feature-text'>BoM cost estimate</p>
                        </div>
                        <IconAboutUsCheckLanding/>
                        <IconAboutUsCheckLanding/>
                        <IconAboutUsCheckLanding/>
                    </div>
                </div>

                <div className='pricing-tablet-block'>
                    <div className='pricing-tablet-block-info'>
                        <div className='pricing-tablet-block-feature'>
                            <IconPricingBomCostsLanding/>
                            <p className='pricing-tablet-feature-text'>Checkout at builders merchant of choice</p>
                        </div>
                        <IconAboutUsCheckLanding/>
                        <IconAboutUsCheckLanding/>
                        <IconAboutUsCheckLanding/>
                    </div>
                </div>

                <h3 className='pricing-info-text'>Contractor Workspace</h3>


                <div className='pricing-tablet-block'>
                    <div className='pricing-tablet-block-info'>
                        <div className='pricing-tablet-block-feature'>
                            <IconPricingProjectLanding/>
                            <p className='pricing-tablet-feature-text'>Custom Bill of Materials Creation</p>
                        </div>
                        <IconAboutUsUnCheckLanding/>
                        <IconAboutUsCheckLanding/>
                        <IconAboutUsCheckLanding/>
                    </div>
                </div>

                <div className='pricing-tablet-block'>
                    <div className='pricing-tablet-block-info'>
                        <div className='pricing-tablet-block-feature'>
                            <IconPricingTemplateLanding/>
                            <p className='pricing-tablet-feature-text'>Add own list of products required</p>
                        </div>
                        <IconAboutUsUnCheckLanding/>
                        <IconAboutUsCheckLanding/>
                        <IconAboutUsCheckLanding/>
                    </div>
                </div>

                <div className='pricing-tablet-block'>
                    <div className='pricing-tablet-block-info'>
                        <div className='pricing-tablet-block-feature'>
                            <IconPricingAddLanding/>
                            <p className='pricing-tablet-feature-text'>Add Preliminary and Generals</p>
                        </div>
                        <IconAboutUsUnCheckLanding/>
                        <IconAboutUsCheckLanding/>
                        <IconAboutUsCheckLanding/>
                    </div>
                </div>

                <div className='pricing-tablet-block'>
                    <div className='pricing-tablet-block-info'>
                        <div className='pricing-tablet-block-feature'>
                            <IconPricingAddLanding/>
                            <p className='pricing-tablet-feature-text'>Add Profit and Attendance</p>
                        </div>
                        <IconAboutUsUnCheckLanding/>
                        <IconAboutUsCheckLanding/>
                        <IconAboutUsCheckLanding/>
                    </div>
                </div>

                <div className='pricing-tablet-block'>
                    <div className='pricing-tablet-block-info'>
                        <div className='pricing-tablet-block-feature'>
                            <IconPricingBomCostsLanding/>
                            <p className='pricing-tablet-feature-text'>All inclusive BoM Cost Estimate</p>
                        </div>
                        <IconAboutUsUnCheckLanding/>
                        <IconAboutUsCheckLanding/>
                        <IconAboutUsCheckLanding/>
                    </div>
                </div>

                <div className='pricing-tablet-block'>
                    <div className='pricing-tablet-block-info'>
                        <div className='pricing-tablet-block-feature'>
                            <IconPricingPDFLanding/>
                            <p className='pricing-tablet-feature-text'>Share BoM as PDF</p>
                        </div>
                        <IconAboutUsCheckLanding/>
                        <IconAboutUsCheckLanding/>
                        <IconAboutUsCheckLanding/>
                    </div>
                </div>

                <div className='pricing-tablet-block'>
                    <div className='pricing-tablet-block-info'>
                        <div className='pricing-tablet-block-feature'>
                            <IconPricingShareLanding/>
                            <p className='pricing-tablet-feature-text'>Share link for payment with Client</p>
                        </div>
                        <IconAboutUsUnCheckLanding/>
                        <IconAboutUsCheckLanding/>
                        <IconAboutUsCheckLanding/>
                    </div>
                </div>

                <div className='pricing-tablet-block'>
                    <div className='pricing-tablet-block-info'>
                        <div className='pricing-tablet-block-feature'>
                            <IconPricingBomCostsLanding/>
                            <p className='pricing-tablet-feature-text'>Cost to complete management</p>
                        </div>
                        <IconAboutUsCheckLanding/>
                        <IconAboutUsCheckLanding/>
                        <IconAboutUsCheckLanding/>
                    </div>
                </div>


                <h3 className='pricing-info-text'>3D Digital Plans Integration</h3>

                <div className='pricing-tablet-block'>

                </div>

                <div className='pricing-tablet-block'>
                    <div className='pricing-tablet-block-info'>
                        <div className='pricing-tablet-block-feature'>
                            <IconPricing3DLanding/>
                            <p className='pricing-tablet-feature-text'>Revit 3D Digital Plans Upload</p>
                        </div>
                        <IconAboutUsUnCheckLanding/>
                        <IconAboutUsUnCheckLanding/>
                        <IconAboutUsCheckLanding/>
                    </div>
                </div>

                <div className='pricing-tablet-block'>
                    <div className='pricing-tablet-block-info'>
                        <div className='pricing-tablet-block-feature'>
                            <IconPricingBomCostsLanding/>
                            <p className='pricing-tablet-feature-text'>Cost to complete management</p>
                        </div>
                        <IconAboutUsUnCheckLanding/>
                        <IconAboutUsUnCheckLanding/>
                        <IconAboutUsCheckLanding/>
                    </div>
                </div>

                <div className='pricing-tablet-block'>
                    <div className='pricing-tablet-block-info'>
                        <div className='pricing-tablet-block-feature'>
                            <IconPricing3DLanding/>
                            <p className='pricing-tablet-feature-text'>AutoCAD 3D Digital Plans Upload</p>
                        </div>
                        <IconAboutUsUnCheckLanding/>
                        <IconAboutUsUnCheckLanding/>
                        <IconAboutUsCheckLanding/>
                    </div>
                </div>

                <div className='pricing-tablet-block'>
                    <div className='pricing-tablet-block-info'>
                        <div className='pricing-tablet-block-feature'>
                            <IconPricingContractorsLanding/>
                            <p className='pricing-tablet-feature-text'>Extracting of all plan details</p>
                        </div>
                        <IconAboutUsUnCheckLanding/>
                        <IconAboutUsUnCheckLanding/>
                        <IconAboutUsCheckLanding/>
                    </div>
                </div>

                <div className='pricing-tablet-block'>
                    <div className='pricing-tablet-block-info'>
                        <div className='pricing-tablet-block-feature'>
                            <IconPricingContractorsLanding/>
                            <p className='pricing-tablet-feature-text'>Extracting of all measurements</p>
                        </div>
                        <IconAboutUsUnCheckLanding/>
                        <IconAboutUsUnCheckLanding/>
                        <IconAboutUsCheckLanding/>
                    </div>
                </div>

                <div className='pricing-tablet-block'>
                    <div className='pricing-tablet-block-info'>
                        <div className='pricing-tablet-block-feature'>
                            <IconPricingAddLanding/>
                            <p className='pricing-tablet-feature-text'>Creation of BoM from plans uploaded</p>
                        </div>
                        <IconAboutUsUnCheckLanding/>
                        <IconAboutUsUnCheckLanding/>
                        <IconAboutUsCheckLanding/>
                    </div>
                </div>


            </section>

        </main>
    );
}

export default Pricing;




