import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Home from "./Page/Home";
import ProjectAssistance from "./Page/Project Assistance/ProjectAssistance";
import AssisCreate from "./Page/Assistance Create Project/AssisCreate";
import Step1 from "./Page/Step1/Step1";
import Step2 from "./Page/Step2/Step2";
import Step3 from "./Page/Step3/Step3";
import ProjectPage from "./Page/Projects/ProjectPage";
import OrderHistory from "./Page/Order History/OrderHistory";
import Users from "./Page/Users/Users";
import TeamManagerPage from "./Page/TeamMeneger/TeamManagerPage";
import ProjectCosts from "./Page/ProjectCosts/ProjectCosts";
import UserRoleRouteMiddleware from "./Middlewares/UserRoleRouteMiddleware";
import SessionPolicy from "./Middlewares/SessionPolicy";
import { AuthPage } from "./Page/AuthPage/AuthPage";
import TemplagePage from "./Page/TemplateInfoPage/TemplagePage";
import ScrollToTop from "./utils/ScrolToTop";
import PageHome from "./Page/LandingPageHome/LandingHome/PageHome";
import AboutUs from "./Page/LandingPageHome/AboutUs/AboutUs";
import Functionality from "./Page/LandingPageHome/Functionality/Functionality";
import Support from "./Page/LandingPageHome/Support/Support";
import TopHeader from "./components/TopHeader/TopHeader";
import Step1BOMCreate from "./Page/Step1BOMCreate/Step1BOMCreate";
import { UserRoleEnum } from "./Enums/UserRoleEnum";
import Step2Bom from "./Page/Step2Bom/Step2Bom";
import Step3Bom from "./Page/Step3Bom/Step3Bom";
import Pricing from "./Page/LandingPageHome/Pricing/Pricing";
import SeoWrapper from "./utils/SeoWrapper";
import RoleCheckPage from "./Page/RoleCheckPage/RoleCheckPage";
import Footer from "./components/ProjectAssistanceFooter/Footer";
import ProjectsAllBom from "./Page/ProjectsAllBOM/ProjectsAllBom";
import TermsAndConditions from "./Page/TermsAndConditions/TermsAndConditons";
import DataProtection from "./Page/DataProtection/DataProtection";

const AVAILABLE_EVERYONE_ROLE = [UserRoleEnum.SAU, UserRoleEnum.MAU, UserRoleEnum.Contractor, UserRoleEnum.Customer];
const AVAILABLE_BOM_ROLE = [UserRoleEnum.Contractor, UserRoleEnum.SAU];

const protectedRoutes = [
    { path: "assistance", element: <ProjectAssistance />, roles: AVAILABLE_EVERYONE_ROLE },
    { path: "assistance/create/:projectId/from/:from", element: <AssisCreate />, roles: AVAILABLE_EVERYONE_ROLE },
    { path: "assistance/step1/template/:templateId/:indexTemplate/project/:projectId", element: <Step1 />, roles: AVAILABLE_EVERYONE_ROLE },
    { path: "assistance/step2/template/:indexTemplate/project/:projectId", element: <Step2 />, roles: AVAILABLE_EVERYONE_ROLE },
    { path: "assistance/step3/template/:indexTemplate/project/:projectId", element: <Step3 />, roles: AVAILABLE_EVERYONE_ROLE },
    { path: "projects", element: <ProjectPage />, roles: AVAILABLE_EVERYONE_ROLE },
    { path: "order_history", element: <OrderHistory />, roles: AVAILABLE_EVERYONE_ROLE },
    { path: "users/:projectId", element: <Users />, roles: AVAILABLE_EVERYONE_ROLE },
    { path: "team", element: <TeamManagerPage />, roles: AVAILABLE_EVERYONE_ROLE },
    { path: "costs/:projectId", element: <ProjectCosts />, roles: AVAILABLE_EVERYONE_ROLE },
    { path: "template/:tempId", element: <TemplagePage />, roles: AVAILABLE_EVERYONE_ROLE },
    { path: "checkRole", element: <RoleCheckPage />, roles: AVAILABLE_EVERYONE_ROLE },
    { path: "assistance/step1Bom", element: <Step1BOMCreate />, roles: AVAILABLE_BOM_ROLE },
    { path: "assistance/projectsBom", element: <ProjectsAllBom />, roles: AVAILABLE_BOM_ROLE },
];

function AppRoutes() {
    const location = useLocation();
    const isRoleCheckPage = location.pathname === "/checkRole";
    const token = localStorage.getItem('Bearer') || sessionStorage.getItem('Bearer')
    const role = localStorage.getItem('role')

    return (
        <>
            {!isRoleCheckPage && <TopHeader />}
            <Routes>
                <Route path="/" element={<SessionPolicy><Home /></SessionPolicy>}>
                    <Route index element={<Navigate to="/home" replace />} />
                    <Route path="home" element={<SeoWrapper><PageHome /></SeoWrapper>} />
                    <Route path="aboutUs" element={<SeoWrapper><AboutUs /></SeoWrapper>} />
                    <Route path="termsAndConditions" element={<SeoWrapper><TermsAndConditions/></SeoWrapper>} />
                    <Route path="dataProtection" element={<SeoWrapper><DataProtection /></SeoWrapper>} />

                    <Route path="functionality" element={<SeoWrapper><Functionality /></SeoWrapper>} />
                    <Route path="pricing" element={<SeoWrapper><Pricing /></SeoWrapper>} />
                    <Route path="support" element={<SeoWrapper><Support /></SeoWrapper>} />
                    <Route path="create/step2Bom/:link" element={<SeoWrapper><Step2Bom /></SeoWrapper>} />
                    <Route path="create/step3Bom" element={<SeoWrapper><Step3Bom /></SeoWrapper>} />

                    {protectedRoutes.map(({ path, element, roles }) => (
                        <Route
                            key={path}
                            path={path}
                            element={
                                <UserRoleRouteMiddleware redirectPath="/home" allowedRoles={roles}>
                                    {element}
                                </UserRoleRouteMiddleware>
                            }
                        />
                    ))}
                </Route>
                <Route path="/auth/*" element={!token || !role ? <AuthPage /> : <Navigate to="/" />} />
                <Route path="*" element={<Navigate to="/home" replace />} />
            </Routes>
            {!isRoleCheckPage && <Footer />}
        </>
    );
}

function App() {
    return (
        <Router>
            <ScrollToTop />
            <AppRoutes />
        </Router>
    );
}

export default App;
