import React, {useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {buildStyles, CircularProgressbar} from "react-circular-progressbar";
import {connect, ConnectedProps, useDispatch, useSelector} from 'react-redux';
import './Step2Styles/Step2Page.css'
import store, {RootState} from '../../store';
import {fetchProjectById} from '../../Slice/projectById/projectByIdSlice';
import {clearTemplates, updateLocalTemplate} from "../../Slice/LocalTemplate/LocalTemplateSlice";
import {clearLocalStorageExceptToken} from "../../utils/ClearLocalStorage";
import {Project} from "../../types/ProjectTypes";
import {toast} from 'react-toastify';
import ILaborItemType from "../../Interfaces/ProjectInterfaces/IlaborItemType";
import {get, minimumPriceProject, patch, post} from "../../api/APIWraper";
import ItemTypeListCalculation from "./ItemTypesLists/ItemTypeListCalculation";
import ItemTypeList from "./ItemTypesLists/ItemTypeList";
import {
    Fragment,
    FragmentData,
    ItemType,
    Template, TMerchanInfo,
} from "../../types/TemplateType";
import LaborItemTypeList from "./LaborItemLists/LaborItemTypeList";
import {clearSelectedItems, setSelectedItems, setSelectedTools} from "../../Slice/SelectedItems/SelectedItemsSlice";
import MaterialName from "../../components/Assis Step2 Component/MaterialName";
import LaborItemTypeListCalculation from "./LaborItemLists/LaborItemTypeListCalculation";

import 'react-toastify/dist/ReactToastify.css';
import {Loader} from "../../components/Loader/Loader";
import IToolsItemType from "../../Interfaces/ProjectInterfaces/IToolsItemType";
import ToolsItemTypeListCalculation from "./ToolsItemLists/ToolsItemTypeListCalculation";
import ToolsItemTypeList from "./ToolsItemLists/ToolsItemTypeList";
import SwitcherStep2 from "../../components/Switcher/SwitcherStep2";
import ShowMoreList from "../../components/ShowMoreListStep2/ShowMoreListStep2";
import {
    notifyInfoOrderTemplate
} from "../../components/Toast/AssisCreateToastNotification";
import {filterLaborAndToolsItemTypes} from "../../utils/FilterAndSetLaborToolItemTypes";
import MobileNavigation from "../../components/MobileNavigation/MobileNavigation";
import {IconEyesStpe2, IconPen, IconPlusStpe2} from "../../IconComponents/IconComponents";
import ModalShowMoreListStep2 from "../../components/ShowMoreListStep2/ModalShowMoreListStep2";
import StepTotalsBlock from "../../components/StepTotalsBlock";
import GetPermission from "../../components/RoleComponent/Role";
import {Permission} from "../../types/UserTypes";
import WarningPermissions from "../../components/Modals/WarningPermissionSteps/WarningPermissions";

interface FragmentWithItemTypes extends Fragment {
    data: {
        item_types: ItemType[];
        [key: string]: any;
    };
}

interface RequestStep2 {
    item_type_id: number;
    item_ids: { id: number; qty: number; }[];
    template_index: number;
    fragment_index: any;
    item_type_index: number;
    template_id: number;
    fragment_id: any;
}

const appUrl = process.env.REACT_APP_BASE_URL_CUSTOMER;

const Step2: React.FC<PropsFromRedux> = ({
                                             project,
                                             fetchProjectById,
                                             updateLocalTemplate,
                                             selectedItem,
                                             selectedLabor,
                                             selectedTools,
                                             selectedItemTotal,
                                             selectedLaborTotal,
                                             selectedToolsTotal,
                                             setSelectedTools,
                                             isLaborVisible,
                                             isToolVisible,
                                             isAuthenticated
                                         }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [currentTemplate, setCurrentTemplate] = useState<Template | null>(null);
    const [templates,setTemplates] = useState<Template[]>([])
    const [currentTemplateIndex, setCurrentTemplateIndex] = useState<number>(0);
    const [isSetTemplateIndex, setIsSetTemplateIndex] = useState<boolean>(false);
    const [activeFragments, setActiveFragments] = useState<number[]>([]);
    const [toggleActive, setToggleActive] = useState<boolean>(false);
    const [percentage, setPercentage] = useState(0);
    const [updatedProject, setUpdatedProject] = useState<Project | null>(project);
    const [showTemplateDropdown, setShowTemplateDropdown] = useState<boolean>(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    // const [checkRole, setIsCheckRole] = useState<boolean>(false);
    // const [isOpenWarningPermissionModal, setIsOpenWarningPermissionModal] = useState<boolean>(false);

    const [isLaborActive, setIsLaborActive] = useState<boolean>(false);
    const [isToolsActive, setIsToolsActive] = useState<boolean>(false);

    const [relatedProjectItemTypes, setRelatedProjectItemTypes] = useState<ItemType[]>([]);
    const [laborItemTypes, setLaborItemTypes] = useState<ILaborItemType[]>([]);
    const [toolsItemTypes, setToolsItemTypes] = useState<IToolsItemType[]>([]);

    const [, setHasFetchedItems] = useState<boolean>(false);

    const [totalLabor, setTotalLabor] = useState<number>(0);
    const [totalTools, setTotalTools] = useState<number>(0);
    const [totalMaterialPriceItem, setTotalMaterialPriceItem] = useState<number>(0);

    const [viewMode, setViewMode] = useState<'Work Stage' | 'Materials|Labor|Tools'>('Materials|Labor|Tools');
    const [viewModeTools, setViewModeTools] = useState<'Recommended' | 'Minimum'>('Recommended');

    const [showAll,] = useState(false);
    const [isButtonDisabledOrderTemplate, setIsButtonDisabledOrderTemplate] = useState(false);
    const [isButtonDisabledOrderSelected, setIsButtonDisabledOrderSelected] = useState(false);

    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerWidth <= 920);
    const [isModalOpenShowMore, setIsModalOpenShowMore] = useState(false);
    const closeModalShowMore = () => setIsModalOpenShowMore(false);

    const visibleFragments = useSelector((state: RootState) => state.fragmentVisible.visibleFragments);
    const {projectId, indexTemplate} = useParams<{ projectId: string; indexTemplate: string }>();

    const templateIndexNumber = indexTemplate ? parseInt(indexTemplate, 10) : NaN;

    const [templateIndex, setTemplateIndex] = useState<number>(
        location.state?.templateIndex ?? templateIndexNumber
    );

    const fromPage = location.state?.from;
    const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 920);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (isAuthenticated) {
            const projectIdExtension = localStorage.getItem('selectedProjectFromExtensionId');
            if (projectIdExtension) {
                fetchProjectById({projectId: projectIdExtension});
            } else if (projectId) {
                fetchProjectById({projectId});
            }
        } else {
            navigate('/home')
        }
    }, [isAuthenticated, projectId]);

    // useEffect(() => {
    //     if (project) {
    //         const userId = localStorage.getItem('userId');
    //         if (userId) {
    //             const userPermission = project.permissions.find(
    //                 (permission: Permission) => permission.user_id === parseInt(userId)
    //             );
    //             if (userPermission) {
    //                 const role = GetPermission(userPermission.level);
    //                 setIsCheckRole(role === 'Admin')
    //             } else {
    //                 console.error('User not found in project permissions.');
    //             }
    //         }
    //     }
    // }, [project]);


    useEffect(() => {
        if (project) {
            setUpdatedProject(project);
        }
    }, [project]);


    useEffect(() => {
        if (currentTemplate) {
            const itemTypeIds = currentTemplate.data.fragments
                .flatMap((fragment: Fragment) => fragment.data.item_types)
                .map((itemType: ItemType) => itemType.id);

            const itemTypeItems = currentTemplate.data.fragments
                .flatMap((fragment: Fragment) => fragment.data?.item_types || [])
                .filter((itemType: ItemType) => itemType.items && itemType.items.length > 0)
                .map((itemType: ItemType) => itemType.items);

            if (itemTypeItems.length === 0) {
                post(`${appUrl}/item_types_all?storeIdFromProjects=${project?.store_id ? project?.store_id : 0}`, {item_type_ids: itemTypeIds})
                    .then((response) => {
                        setRelatedProjectItemTypes(response.data.data);
                    })
                    .catch(() => {
                        toast.error("Failed to fetch item types.");
                    });
            }
        }
    }, [currentTemplate]);



    const hasFetchedPrice = useRef(false);
    useEffect(() => {
        if (fromPage && fromPage.includes('/step1') && project && currentTemplate && !hasFetchedPrice.current) {
            const addMinimumPrice = async () => {
                try {
                    const response = await minimumPriceProject(Number(projectId));
                    setUpdatedProject(response.data);
                    hasFetchedPrice.current = true;
                } catch (error) {
                    console.error('Error when getting minimum price:', error);
                }
            };
            addMinimumPrice();
        }
    }, [fromPage, project]);

    useEffect(() => {
        if (currentTemplate) {
            const {
                laborItemTypes,
                toolsItemTypes
            } = filterLaborAndToolsItemTypes(currentTemplate, currentTemplateIndex);
            setLaborItemTypes(laborItemTypes || []);
            setToolsItemTypes(toolsItemTypes || []);
        }
    }, [currentTemplate, updatedProject]);
    useEffect(() => {
        const checkIfButtonShouldBeDisabled = () => {
            const storedSelectedFragments = JSON.parse(localStorage.getItem('selectedFragments') || '{}');
            const hasSelections = Object.keys(storedSelectedFragments).some(fragmentKey => {
                const fragment = storedSelectedFragments[fragmentKey];
                return (
                    (fragment.items && Object.keys(fragment.items).length > 0) ||
                    (fragment.LaborItemTypes && Object.keys(fragment.LaborItemTypes).length > 0) ||
                    (fragment.ToolsItemTypes && Object.keys(fragment.ToolsItemTypes).length > 0)
                );
            });
            setIsButtonDisabledOrderSelected(!hasSelections);
        };

        checkIfButtonShouldBeDisabled();
        const unsubscribeFromSelectedItem = store.subscribe(() => {
            checkIfButtonShouldBeDisabled();
        });
        return () => {
            unsubscribeFromSelectedItem();
        };
    }, [selectedItem, selectedLabor, selectedTools, selectedItemTotal, selectedLaborTotal, selectedToolsTotal]);

    useEffect(() => {
        const checkIfButtonShouldBeDisabled = () => {
            const hasItemsInFragments = currentTemplate?.data.fragments.some((fragment: {
                    data: { item_types: any[]; };
                }) =>
                    fragment.data.item_types.some((itemType) => itemType.items && itemType.items.length > 0)
            );

            const hasLaborItems = laborItemTypes.some((labor) =>
                labor.item_type.items && labor.item_type.items.length > 0
            );

            const hasToolItems = toolsItemTypes.some((tool) =>
                tool.item_type.items && tool.item_type.items.length > 0
            );

            setIsButtonDisabledOrderTemplate(!(hasItemsInFragments || hasLaborItems || hasToolItems));
        };

        checkIfButtonShouldBeDisabled();
    }, [currentTemplate, laborItemTypes, toolsItemTypes]);

    useEffect(() => {
        if (currentTemplate) {
            const hasItem = currentTemplate.data.fragments.some((fragment: { data: { item_types: ItemType[]; }; }) =>
                fragment.data.item_types.some(itemType => itemType.item)
            );
            setIsButtonDisabledOrderTemplate(!hasItem);
        }
    }, [currentTemplate]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setShowTemplateDropdown(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    useEffect(() => {
        const index = localStorage.getItem('templateIndex');
        if (index !== null) {
            setTemplateIndex(Number(index));
        }
    }, []);

    useEffect(() => {
        if (updatedProject?.data?.templates) {
            const selectedTemplate = updatedProject?.data?.templates[templateIndex] || updatedProject.data.templates[0];
            setCurrentTemplate(selectedTemplate);
            setTemplates(updatedProject?.data?.templates)
            setCurrentTemplateIndex(templateIndex);
            localStorage.setItem('templateIndex', String(templateIndex));
            setIsSetTemplateIndex(true);
            setPercentage(updatedProject.progress);
            if (updatedProject.address && updatedProject.address.longitude && updatedProject.address.latitude) {
                const coordinates = {
                    longitude: updatedProject.address.longitude,
                    latitude: updatedProject.address.latitude,
                };
                localStorage.setItem('coordinates', JSON.stringify(coordinates));
            }
        }
    }, [updatedProject]);

    useEffect(() => {
        localStorage.removeItem('updatedProject');
    }, []);



    useEffect(() => {
        const toolsInitialized = localStorage.getItem('ToolsItemTypesInitialized');
        if (!toolsInitialized) {
            const storedSelectedFragments = JSON.parse(localStorage.getItem('selectedFragments') || '{}');
            if (toolsItemTypes.length > 0) {
                toolsItemTypes.forEach(item => {
                    if (item.item_type.items && item.item_type.items.length > 0) {
                        const fragmentKey = `${item.fragment_id}-${item.fragment_index}-${item.template_index}`;
                        if (!storedSelectedFragments[fragmentKey]) {
                            storedSelectedFragments[fragmentKey] = {items: {}, LaborItemTypes: {}, ToolsItemTypes: {}};
                        }

                        const toolKey = `${item.item_type_id}-${item.clone_id || item.item_type_index}-${item.fragment_index}`;

                        storedSelectedFragments[fragmentKey].ToolsItemTypes[toolKey] = {
                            id: item.item_type.id,
                            qty: item.qty,
                            tool: true,
                            items: item.item_type.items.map(i => ({
                                id: i.id,
                                qty: i.qty,
                                unit: i.unit,
                                price: i.price,
                                title: i.title,
                                qty_from_store: i.qty_from_store ? i.qty_from_store : 0,
                                ordered_qty: i.ordered_qty,
                                payload: {
                                    template_index: item.template_index,
                                    fragment_index: item.fragment_index,
                                    item_type_index: item.item_type_index,
                                    template_id: item.template_id,
                                    fragment_id: item.fragment_id,
                                    item_type_id: item.item_type.id,
                                    item_ids: item.item_type.items.map(i => i.id) || [],
                                }
                            })),
                            labor: false,
                            title: item.item_type.title,
                            percentage: item.item_type.percentage,
                            ordered_qty: item.item_type.items.reduce((sum, toolItem) => sum + (toolItem.ordered_qty || 0), 0) || 0,
                            originalIndex: item.item_type_index,
                            clone_id: item.clone_id || item.item_type_index,
                            payload: {
                                template_index: item.template_index,
                                fragment_index: item.fragment_index,
                                item_type_index: item.item_type_index,
                                template_id: item.template_id,
                                fragment_id: item.fragment_id,
                                item_type_id: item.item_type.id,
                                item_ids: item.item_type.items.map(i => i.id) || [],
                            }
                        };
                    }
                });

                localStorage.setItem('selectedFragments', JSON.stringify(storedSelectedFragments));
                localStorage.setItem('ToolsItemTypesInitialized', 'true');
                setSelectedTools(Date.now());
                calculateTotalTools(storedSelectedFragments);
            }
        }
    }, [toolsItemTypes]);

    useEffect(() => {
        let itemsExist = false;
        if (currentTemplate) {
            currentTemplate.data.fragments.forEach((fragment: { data: { item_types: any[]; }; }) => {
                fragment.data.item_types.forEach((itemType) => {
                    if (itemType.items && itemType.items.length > 0) {
                        itemsExist = true;
                    }
                });
            });
        }
        setIsButtonDisabledOrderTemplate(!itemsExist);
    }, [currentTemplate, currentTemplateIndex, fetchProjectById, updatedProject]);


    const calculateTotalLabor = (selectedFragments: { [key: string]: any }) => {
        let total = 0;
        Object.keys(selectedFragments).forEach(fragmentKey => {
            const laborItems = selectedFragments[fragmentKey].LaborItemTypes || {};

            Object.keys(laborItems).forEach(key => {
                const laborItem = laborItems[key];

                if (laborItem && laborItem.items) {
                    laborItem.items.forEach((item: { price: any; qty: any; ordered_qty: any }) => {
                        const price = parseFloat(String(item.price));
                        const quantity = item.qty;
                        const orderedQty = item.ordered_qty || 0;
                        const totalLabor = (quantity - orderedQty) * price;
                        total += totalLabor;
                    });
                }
            });
        });

        setTotalLabor(Math.max(0, total));
    };


    const calculateTotalTools = (selectedFragments: { [key: string]: any }) => {
        let total = 0;

        Object.keys(selectedFragments).forEach(fragmentKey => {
            const toolsItems = selectedFragments[fragmentKey].ToolsItemTypes || {};

            Object.keys(toolsItems).forEach(key => {
                const toolsItem = toolsItems[key];

                if (toolsItem && toolsItem.items) {
                    toolsItem.items.forEach((item: { price: any; qty: any; ordered_qty: any }) => {
                        const price = parseFloat(String(item.price));
                        const quantity = item.qty;
                        const orderedQty = item.ordered_qty || 0;
                        const totalTools = (quantity - orderedQty) * price;
                        total += totalTools;
                    });
                }
            });
        });

        setTotalTools(Math.max(0, total));
    };

    const calculateTotalMaterial = (selectedFragments: { [key: string]: any }) => {
        let total = 0;

        Object.keys(selectedFragments).forEach(fragmentKey => {
            const items = selectedFragments[fragmentKey].items || {};

            Object.keys(items).forEach(key => {
                const item = items[key];

                if (item && item.items) {
                    item.items.forEach((subItem: { price: any; qty: any; ordered_qty: any }) => {
                        const price = parseFloat(String(subItem.price));
                        const quantity = subItem.qty;
                        const orderedQty = subItem.ordered_qty || 0;
                        const totalItem = (quantity - orderedQty) * price;
                        total += totalItem;
                    });
                }
            });
        });

        setTotalMaterialPriceItem(Math.max(0, total));
    };


    useEffect(() => {
        const storedSelectedFragments = JSON.parse(localStorage.getItem('selectedFragments') || '{}');
        calculateTotalMaterial(storedSelectedFragments);
        calculateTotalLabor(storedSelectedFragments);
        calculateTotalTools(storedSelectedFragments);
    }, [selectedItem, selectedLabor, selectedTools, selectedItemTotal, selectedLaborTotal, selectedToolsTotal]);

    const navigateToStep1Pen = () => {
        if (currentTemplate) {
            updateLocalTemplate(currentTemplate);

            const {description, ...templateWithoutDescription} = currentTemplate;

            localStorage.setItem(`selectedTemplate-${currentTemplate.id}`, JSON.stringify(templateWithoutDescription));
        }

        setTimeout(() => {
            navigate(`/assistance/step1/template/${currentTemplate?.id}/${templateIndex}/project/${projectId}`);
        }, 1000);
    };


    const handleProjectUpdate = (newProject: Project) => {
        setUpdatedProject(newProject);
    };

    const handleToggle = (fragmentIndex: number) => {
        // if(checkRole) {
        setToggleActive((prev) => !prev)
        setActiveFragments(prev => {
            if (prev.includes(fragmentIndex)) {
                return prev.filter(index => index !== fragmentIndex);
            } else {
                return [...prev, fragmentIndex];
            }
        });
        // } else{
        //     setIsOpenWarningPermissionModal(true)
        // }
    };
    // const handeWarningPermissionModalClose =()=>{
    //     setIsOpenWarningPermissionModal(false)
    // }

    const handleToggleLabor = () => {
        setIsLaborActive((prev) => !prev);
        setToggleActive((prev) => !prev)
    };

    const handleToggleTools = () => {
        setIsToolsActive((prev) => !prev);
        setToggleActive((prev) => !prev)
    };
    const handleSwitchChange = (selectedOption: 'Work Stage' | 'Materials|Labor|Tools') => {
        setViewMode(selectedOption);
    };
    const handleSwitchChangeTools = (selectedOption: 'Recommended' | 'Minimum') => {
        setViewModeTools(selectedOption);
    };

    const handleTemplateSelect = (selectedTemplateIndex: number) => {
        const selectedTemplate = project?.data.templates?.[selectedTemplateIndex] || null;
        setCurrentTemplate(selectedTemplate);
        setTemplateIndex(selectedTemplateIndex);
        setCurrentTemplateIndex(selectedTemplateIndex);
        localStorage.setItem('templateIndex', selectedTemplateIndex.toString());
        setShowTemplateDropdown(false);
        setHasFetchedItems(false);
    };

    const renderTemplateOptions = () => {
        const templateTitles = project?.data.templates?.map(template => template.title) || [];
        const templateTitleCounts = templateTitles.reduce((acc, title) => {
            if (!acc[title]) {
                acc[title] = 0;
            }
            acc[title] += 1;
            return acc;
        }, {} as { [key: string]: number });

        const templateTitleIndices = {} as { [key: string]: number };

        return project?.data.templates?.map((template, index) => {
            const title = template.title;
            if (!templateTitleIndices[title]) {
                templateTitleIndices[title] = 1;
            } else {
                templateTitleIndices[title] += 1;
            }
            const displayTitle = templateTitleCounts[title] > 1 ? `${title} (${templateTitleIndices[title]})` : title;

            return (
                <div
                    key={template.id}
                    onClick={() => handleTemplateSelect(index)}
                    className='step-template-container-option'
                >
                    {displayTitle}
                </div>
            );
        });
    };

    if (!updatedProject) {
        return <Loader/>;
    }

    if (!project) {
        return <Loader/>
    }

    if (!currentTemplate) {
        return <Loader/>;
    }
    const descriptionText = currentTemplate.short_description || 'No description';

    const handleOrderSelected = () => {
        const storedSelectedFragments = JSON.parse(localStorage.getItem('selectedFragments') || '{}');
        const hasSelections = Object.keys(storedSelectedFragments).some(fragmentKey => {
            const fragment = storedSelectedFragments[fragmentKey];
            return (
                (fragment.items && Object.keys(fragment.items).length > 0) ||
                (fragment.LaborItemTypes && Object.keys(fragment.LaborItemTypes).length > 0) ||
                (fragment.ToolsItemTypes && Object.keys(fragment.ToolsItemTypes).length > 0)
            );
        });

        if (!hasSelections) {
            notifyInfoOrderTemplate('You must add at least one product, tool, or labor.');
            return;
        }
        navigate(`/assistance/step3/template/${templateIndex}/project/${projectId}`);
    };


    const handleOrderTemplate = () => {
        if (isButtonDisabledOrderTemplate) {
            notifyInfoOrderTemplate('You must choose a product, tool, or labor');
            return;
        }
        const storedSelectedFragments = JSON.parse(localStorage.getItem('selectedFragments') || '{}');
        if (!currentTemplate) {
            return;
        }

        currentTemplate.data.fragments.forEach((fragment: {
            id: any;
            data: { item_types: any[] };
        }, fragmentIndex: any) => {
            const fragmentKey = `${fragment.id}-${fragmentIndex}-${currentTemplateIndex}`;
            if (!storedSelectedFragments[fragmentKey]) {
                storedSelectedFragments[fragmentKey] = { items: {}, LaborItemTypes: {}, ToolsItemTypes: {} };
            }

            const addItemType = (itemType: {
                id: any;
                qty: any;
                title: any;
                items: any[];
            }, itemTypeIndex: number, category: string) => {
                const itemKey = `${itemType.id}-${itemTypeIndex}-${fragmentIndex}`;

                if (!storedSelectedFragments[fragmentKey][category]) {
                    storedSelectedFragments[fragmentKey][category] = {};
                }

                const filteredItems = itemType.items?.filter(i => i.qty !== 0);

                storedSelectedFragments[fragmentKey][category][itemKey] = {
                    id: itemType.id,
                    qty: itemType.qty,
                    tool: category === 'ToolsItemTypes',
                    labor: category === 'LaborItemTypes',
                    item_type_title: itemType.title,
                    items: filteredItems
                        ? filteredItems.map(i => ({
                            id: i.id,
                            qty: i.qty,
                            unit: i.unit,
                            price: i.price,
                            title: i.title,
                            ordered_qty: i.ordered_qty,
                            qty_from_store: i.qty_from_store ? i.qty_from_store : 0,
                            item_type_title: itemType.title,
                            vendor: i.vendor,
                            merchant_info: i.merchant_info as TMerchanInfo,
                            payload: {
                                template_index: currentTemplateIndex,
                                fragment_index: fragmentIndex,
                                item_type_index: itemTypeIndex,
                                template_id: currentTemplate.id,
                                fragment_id: fragment.id,
                                item_type_id: itemType.id,
                                item_ids: i.id ? [i.id] : [],
                            }
                        }))
                        : [],
                    title: itemType.title,
                    ordered_qty: filteredItems
                        ? filteredItems.reduce((sum, item) => sum + (item.ordered_qty || 0), 0)
                        : 0,
                    originalIndex: itemTypeIndex,
                    clone_id: itemTypeIndex,
                    payload: {
                        template_index: currentTemplateIndex,
                        fragment_index: fragmentIndex,
                        item_type_index: itemTypeIndex,
                        template_id: currentTemplate.id,
                        fragment_id: fragment.id,
                        item_type_id: itemType.id,
                        item_ids: filteredItems ? filteredItems.map(i => i.id) : [],
                    }
                };
            };

            fragment.data.item_types.forEach((itemType, itemTypeIndex) => {
                if (itemType.items && itemType.items.length > 0) {
                    if (!itemType.tool && !itemType.labor) {
                        addItemType(itemType, itemTypeIndex, 'items');
                    } else if (itemType.labor) {
                        addItemType(itemType, itemTypeIndex, 'LaborItemTypes');
                    } else if (itemType.tool) {
                        addItemType(itemType, itemTypeIndex, 'ToolsItemTypes');
                    }
                }
            });
        });
        localStorage.setItem('selectedFragments', JSON.stringify(storedSelectedFragments));
        navigate(`/assistance/step3/template/${templateIndex}/project/${projectId}`);
    };


    const renderQuestionAnswers = (data: { [key: string]: FragmentData }, hiddenKeys: Set<string>) => {
        return Object.entries(data)
            .filter(([key, _]) => !isNaN(parseInt(key)))
            .filter(([_, dataItem]) => dataItem.question && !hiddenKeys.has(dataItem.key))
            .map(([key, dataItem]) => (
                <div className="step-group-calc" key={`dataItem-${currentTemplate.id}-${key}`}>
                    <div className='step2-calc-field-container'>
                        <label className="step-group-calc-label">{dataItem.question}</label>
                        <div className='step2-calc-field'>
                            <p>{dataItem.value}</p>
                        </div>
                    </div>
                </div>
            ));
    };

    const renderFragments = (fragments: Fragment[]) => {
        return fragments.map((fragment, fragmentIndex) => {
            const hiddenKeys = new Set<string>();
            const varMapping = fragment.data?.var_mapping as unknown as string | undefined;
            if (typeof varMapping === 'string') {
                const mappings = varMapping.split('&&').map((map: string) => map.trim());
                mappings.forEach((mapping: string) => {
                    const [left, right] = mapping.split('=').map((s: string) => s.trim());
                    hiddenKeys.add(right);
                });
            }
            const questionAnswers = renderQuestionAnswers(fragment.data, hiddenKeys);
            return (
                <div className='step2-fragment' key={`fragment-${fragmentIndex}`}>
                    {questionAnswers.length > 0 && <h3>{fragment.title}</h3>}
                    {questionAnswers}
                </div>
            );
        });
    };

    const questionAnswers = currentTemplate?.data ? renderQuestionAnswers(currentTemplate.data, new Set<string>()) : [];
    const fragments = currentTemplate?.data.fragments ? renderFragments(currentTemplate.data.fragments) : [];


    const handleCalculateClick = () => {
        if (currentTemplate) {
            navigate(`/assistance/create/${projectId}/from/projectCosts`, {state: {from: 'projectCosts'}});
        }
    };

    const navigateTo = (path:string) =>{
        switch (path) {
            case 'home':{
                clearLocalStorageExceptToken();
                dispatch(clearTemplates());
                navigate('/assistance');
                break
            }
            case 'createProject':{
                handleCalculateClick()
                break
            }
            case 'step1':{
                navigateToStep1Pen()
                break
            }
        }
    }

    return (
        <div className='step2'>
            {isSetTemplateIndex && (
                <div>
                    {isSmallScreen ? (
                        <MobileNavigation
                            links={[
                                {to: '/', label: 'Cashbuild'},
                                {to: '', label: '>'},
                                {to: '/', label: 'Home'},
                                {to: '', label: '>'},
                                {to: '/assistance', label: 'Project assistance'},
                                {to: '', label: '>'},
                                {to: '', label: 'Create new project space'}
                            ]}
                        />
                    ) : (
                        <div className='template-name-content'>
                            <div className='template-blog-placeholder'>
                                <p onClick={() => navigateTo('home')} className='template-link-assistance'>Home/</p>
                                <p onClick={() => navigateTo('createProject')}
                                   className='template-link-assistance'>Create new project/</p>
                                <p onClick={() => navigateTo('step1')} className='template-link-assistance'>Step1/</p>
                                <p className='name-link'>/Step2</p>
                            </div>
                        </div>
                    )}
                    <div className='step-info'>
                        <div className='step-info-block'>
                            <h3>Step 2</h3>
                            <span>/</span>
                            <p className='step-info-block-name'>Select Your Products</p>
                        </div>
                    </div>
                    <div className='step-template-container'>
                        <div className='step-template-block left'>
                            <h2>Type:</h2>
                            <div className='step-template-container'>
                                <div className='step-template-dropdown-container'>
                                    {project?.data?.templates && project.data.templates.length > 1 && (
                                        <>
                                            <button
                                                className={`step-template-block-title-dropdown ${showTemplateDropdown ? 'active' : ''}`}
                                                onClick={() => setShowTemplateDropdown(!showTemplateDropdown)}
                                            >
                                                {currentTemplate.title || 'Select template'}
                                            </button>
                                            {showTemplateDropdown && (
                                                <section className='step-template-dropdown'>
                                                    {renderTemplateOptions()}
                                                </section>
                                            )}
                                        </>
                                    )}
                                    {project?.data?.templates && project.data.templates.length === 1 && (
                                        <div className='step-template-block-title'>
                                            {currentTemplate.title || 'Select template'}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className='step-template-block right'>
                            <h2>Description:</h2>
                            <p className='step-template-block-desc'>{descriptionText}</p>
                        </div>
                        {!isSmallScreen && (
                            <div className='step-template-block-percent'>
                                <div className='progress-bar-wrapper'>

                                    <CircularProgressbar
                                        value={percentage}
                                        styles={buildStyles({
                                            pathColor: 'var(--basic-btn-dark-color)',
                                            textColor: '#000000',
                                            trailColor: '#d6d6d6',
                                            textSize: '24px',
                                            pathTransitionDuration: 0.5,
                                        })}
                                    />

                                    <div className='progress-bar-text'>
                                        {percentage}%
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {isSmallScreen ? (
                        <div className="step-calc-step2-more-information">
                            <h2>Summary of Required Information:</h2>
                            <div onClick={navigateToStep1Pen} className='step2-pen'>
                                <IconPen/>
                            </div>
                            <div className='step2-form-calc-block' key={`template-${currentTemplate.id}`}>
                                <div className='step2-question-answer'>
                                    <div className='step2-form-calc-block' key={`template-${currentTemplate.id}`}>
                                        <ShowMoreList items={[...questionAnswers, ...fragments]} initialVisibleCount={2}
                                                      showAll={showAll}/>
                                        {isModalOpenShowMore && (
                                            <ModalShowMoreListStep2
                                                closeModalShowMore={closeModalShowMore}
                                                isModalOpenShowMore={isModalOpenShowMore}
                                                template={currentTemplate}
                                            />
                                        )}
                                        <button className='show-more-list-button'
                                                onClick={() => setIsModalOpenShowMore(true)}>
                                            View all
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="step-calc-step2-more-information">
                            <div className='step2-required-information'>
                                <h2>Summary of Required Information:</h2>
                                <div className='step2-pen'>
                                    <div className='step2-btn-pen' onClick={navigateToStep1Pen}>
                                        <div><IconPlusStpe2/></div>
                                        <p>Update Dimensions</p>
                                    </div>
                                    <div className='step2-btn-eyes' onClick={() => setIsModalOpenShowMore(true)}>
                                        <IconEyesStpe2/>
                                        <p>Full Summary</p>
                                    </div>
                                </div>
                            </div>
                            <div className='step2-form-calc-block' key={`template-${currentTemplate.id}`}>
                                <div className='step2-question-answer'>
                                    <h3>{currentTemplate.title}</h3>
                                    <div className='step2-form-calc-block' key={`template-${currentTemplate.id}`}>
                                        <ShowMoreList items={[...questionAnswers, ...fragments]} initialVisibleCount={6}
                                                      showAll={showAll}/>
                                        {isModalOpenShowMore && (
                                            <ModalShowMoreListStep2
                                                closeModalShowMore={closeModalShowMore}
                                                isModalOpenShowMore={isModalOpenShowMore}
                                                template={currentTemplate}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className='step-calc-step2'>
                        <div className='step-calc-step2-costs-summary'>
                            <h2>Costs Summary</h2>
                            <SwitcherStep2
                                options={['Work Stage', 'Materials|Labor|Tools']}
                                onChange={handleSwitchChange}
                                onUpdateProject={handleProjectUpdate}
                                templates={templates}
                                projectId={projectId}
                                step3={false}
                                toggleActive={toggleActive}
                            />
                        </div>

                        <div className='step2-container'>
                            {currentTemplate.data.fragments.map((fragment: FragmentWithItemTypes, fragmentIndex: number) => {
                                const key = `${fragment.id}-${fragmentIndex}`;
                                const isVisible = visibleFragments[key];

                                let shouldDisplayFragment = true;

                                if (viewMode === "Materials|Labor|Tools") {
                                    shouldDisplayFragment = fragment.data.item_types.some((itemType) => {
                                        return itemType.labor === false && itemType.tool === false;
                                    });
                                }
                                if (!shouldDisplayFragment) {
                                    return null;
                                }

                                return (
                                    <div key={`fragment-${fragment.id}-${fragmentIndex}`} className='step2-fragment'>
                                        <div className='step2-fragment-block'>
                                            <MaterialName
                                                fragment={fragment}
                                                fragmentIndex={fragmentIndex}
                                                project={updatedProject}
                                                onToggle={() => handleToggle(fragmentIndex)}
                                                showSettingsButton={true}
                                            />
                                            <div
                                                className={`step2-fragment-content ${isSmallScreen ? (isVisible ? 'visible' : 'hidden') : 'visible'}`}>
                                                {activeFragments.includes(fragmentIndex) ? (
                                                    <ItemTypeListCalculation
                                                        fragment={fragment}
                                                        fragmentIndex={fragmentIndex}
                                                        templateIndex={currentTemplateIndex}
                                                        project={updatedProject}
                                                        templateId={currentTemplate.id}
                                                        onUpdateProject={handleProjectUpdate}
                                                        onToggle={() => handleToggle(fragmentIndex)}
                                                        viewMode={viewMode}
                                                    />
                                                ) : (
                                                    <ItemTypeList
                                                        templateId={currentTemplate.id}
                                                        fragment={fragment}
                                                        fragmentIndex={fragmentIndex}
                                                        templateIndex={currentTemplateIndex}
                                                        viewMode={viewMode}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>


                    </div>
                    {viewMode === 'Materials|Labor|Tools' && (
                        <div className='step-calc-step2'>
                            <div className='step2-container'>
                                {laborItemTypes && laborItemTypes.length > 0 && (
                                    <div key={`fragment-labor-`} className='step2-fragment'>
                                        <div className='step2-fragment-block'>
                                            <MaterialName
                                                key={`material-labor`}
                                                project={updatedProject}
                                                onToggle={() => handleToggleLabor()}
                                                showSettingsButton={true}
                                                isLabor={true}
                                            />
                                            <div
                                                className={`step2-fragment-content ${isSmallScreen ? (isLaborVisible ? 'visible' : 'hidden') : 'visible'}`}>
                                                {isLaborActive ? (
                                                    <LaborItemTypeListCalculation
                                                        laborItemTypes={laborItemTypes || []}
                                                        project={updatedProject}
                                                        templateId={currentTemplate.id}
                                                        templateIndex={currentTemplateIndex}
                                                        onUpdateProject={handleProjectUpdate}
                                                        relatedProjectItemTypes={relatedProjectItemTypes}
                                                        onToggle={() => handleToggleLabor()}
                                                    />
                                                ) : (
                                                    <LaborItemTypeList laborItemTypes={laborItemTypes || []}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {toolsItemTypes && toolsItemTypes.length > 0 && (
                                    <div key={`fragment-labor-`} className='step2-fragment'>
                                        <div className='step2-fragment-block'>
                                            <MaterialName
                                                key={`material-labor`}
                                                project={updatedProject}
                                                onToggle={() => handleToggleTools()}
                                                showSettingsButton={true}
                                                isToolStep2={true}
                                                handleSwitchChangeTools={handleSwitchChangeTools}
                                            />
                                            <div
                                                className={`step2-fragment-content ${isSmallScreen ? (isToolVisible ? 'visible' : 'hidden') : 'visible'}`}>
                                                {isToolsActive ? (
                                                    <ToolsItemTypeListCalculation
                                                        toolsItemTypes={toolsItemTypes || []}
                                                        project={updatedProject}
                                                        templateId={currentTemplate.id}
                                                        templateIndex={currentTemplateIndex}
                                                        onUpdateProject={handleProjectUpdate}
                                                        relatedProjectItemTypes={relatedProjectItemTypes}
                                                        onToggle={() => handleToggleTools()}
                                                        viewModeTools={viewModeTools}/>
                                                ) : (
                                                    <ToolsItemTypeList
                                                        toolsItemTypes={toolsItemTypes || []}
                                                        viewModeTools={viewModeTools}/>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    <div className='step3-page-bottom-block step3-bottom-block-total-mobile'>

                        <StepTotalsBlock project={project} indexTemplate={indexTemplate}
                                         isSmallScreen={isSmallScreen}/>

                        <div className='step3-page-bottom-total-container'>
                            <div className='step3-page-bottom-block-price'>
                                <h3 className='step3-page-bottom-block-total'>Total selected for purchase:</h3>
                                <div className='step3-page-bottom-total-price-block'>
                                    <p>Labours:</p>
                                    {isSmallScreen && <span className='dashed-line'></span>}
                                    <span
                                        className='step2-page-bottom-price'>R {Math.max(0, totalLabor).toFixed(2)}</span>
                                </div>
                                <div className='step3-page-bottom-total-price-block'>
                                    <p>Tools:</p>
                                    {isSmallScreen && <span className='dashed-line'></span>}
                                    <span
                                        className='step2-page-bottom-price'>R {Math.max(0, totalTools).toFixed(2)}</span>
                                </div>

                                <div className='step3-page-bottom-total-price-block'>
                                    <p>Materials:</p>
                                    {isSmallScreen && <span className='dashed-line'></span>}
                                    <span
                                        className='step2-page-bottom-price'>R {Math.max(0, totalMaterialPriceItem).toFixed(2)}</span>
                                </div>

                            </div>
                            <div className='step3-page-bottom-total-price-block-total'>
                                <p>Grand total:</p>
                                {isSmallScreen && <span className='dashed-line'></span>}
                                <span
                                    className='step2-page-bottom-price-total'>R {(totalLabor + totalMaterialPriceItem + totalTools).toFixed(2)}</span>
                            </div>
                        </div>
                    </div>


                    <div className='step2-page-bottom-btn'>
                        <div className='step2-page-bottom-btn-block'>
                            <div className='step2-page-btn'>
                                <button
                                    className='step2-page-bottom-block-btn'
                                    onClick={handleOrderSelected}
                                    style={{
                                        backgroundColor: isButtonDisabledOrderSelected ? '#cccccc' : '',
                                        cursor: isButtonDisabledOrderSelected ? 'not-allowed' : 'pointer'
                                    }}
                                >
                                    Order selected
                                </button>
                            </div>
                            <div className='step2-page-btn'>
                                <button
                                    className='step2-page-bottom-block-btn'
                                    onClick={handleOrderTemplate}
                                    style={{
                                        backgroundColor: isButtonDisabledOrderTemplate ? '#cccccc' : '',
                                        cursor: isButtonDisabledOrderTemplate ? 'not-allowed' : 'pointer'
                                    }}
                                >
                                    Order Project
                                </button>

                            </div>
                            <div className='step2-page-btn'>
                                <button className='step2-page-bottom-block-btn'>Save & Request finance</button>
                            </div>
                        </div>
                    </div>
                    {/*<WarningPermissions isOpen={isOpenWarningPermissionModal} onRequestClose={handeWarningPermissionModalClose}/>*/}

                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    project: state.projectById.project,
    isFetching: state.projectById.isFetching,
    selectedItem: state.selectedItems.selectedFragments,
    selectedLabor: state.selectedItems.selectedLabor,
    selectedTools: state.selectedItems.selectedTools,
    selectedItemTotal: state.selectedItems.selectedFragmentsTotal,
    selectedLaborTotal: state.selectedItems.selectedLaborTotal,
    selectedToolsTotal: state.selectedItems.selectedToolsTotal,
    isVisible: state.fragmentVisible,
    isLaborVisible: state.fragmentVisible.isLaborVisible,
    isToolVisible: state.fragmentVisible.isToolVisible,
    isAuthenticated: state.auth.isAuthenticated
});

const mapDispatchToProps = {
    fetchProjectById,
    updateLocalTemplate,
    clearSelectedItems,
    setSelectedItems,
    setSelectedTools,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Step2);