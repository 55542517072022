import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import './style.css';
import { fetchLastProjects } from "../../Slice/getProjectAssistance/ProjectAssistanceSlice";
import { RootState } from "../../store";
import {connect, ConnectedProps, useSelector} from "react-redux";
import CreateProjectModal from "../Modals/ModalCreateProject/ModalCreateProject";
import {IconArrowAssistance, IconCreateProject, IconReplaceImage} from "../../IconComponents/IconComponents";
import { PostFormData } from "../../api/ApiWrapperImage";
import {toast} from "react-toastify";

const BASE_URL_CUSTOMER = process.env.REACT_APP_BASE_URL_CUSTOMER


interface AllProjectsOwnProps {
    setIsLoading: (loading: boolean) => void;
    isLoading: boolean;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type AllProjectsPropsCombined = PropsFromRedux & AllProjectsOwnProps;

const AllProjects: React.FC<AllProjectsPropsCombined> = ({
                                                             projects,
                                                             isFetching,
                                                             error,
                                                             fetchLastProjects,
                                                             setIsLoading,
                                                             isLoading,
                                                             isAuthenticated
                                                         }) => {
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isPageChanging, setIsPageChanging] = useState(false);
    const [localProjectImages, setLocalProjectImages] = useState<{ [key: number]: string }>({});

    useEffect(() => {
        setIsLoading(isFetching);
    }, [isFetching, setIsLoading]);

    useEffect(() => {
        if(isAuthenticated) {
            setIsPageChanging(true);
            fetchLastProjects().finally(() => {
                setIsPageChanging(false);
            });
        } else{
            navigate('/home')
        }

    }, [fetchLastProjects,isAuthenticated]);



    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    const uploadProjectImage = async (projectId: number, imageFile: File) => {
        const formData = new FormData();
        formData.append("file", imageFile);
        formData.append("projectId", projectId.toString());

        try {
            const response = await PostFormData(
                BASE_URL_CUSTOMER || "",
                `/projects/${projectId}/media`,
                formData
            );

            if (response.status !== 201) {
                throw new Error("Failed to upload image");
            }
            setTimeout(()=>{
                toast.success('You have successfully updated the image')
            },500)
            return response.data;
        } catch (error) {
            console.error('Error uploading image:', error);
            throw error;
        }
    };

    const handleImageChange = async (projectId: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const localUrl = URL.createObjectURL(file);
            setLocalProjectImages(prevState => ({
                ...prevState,
                [projectId]: localUrl,
            }));
            try {
                await uploadProjectImage(projectId, file);
            } catch (error) {
                console.error('Error uploading image:', error);
            }
        }
    };

    if (error) {
        return <p>Error: {error}</p>;
    }

    return (
        <>
            {!isLoading && (
                <div>
                    <div className='assistance-name-content'>
                        <p>Recent projects</p>
                        <div className='assistance-name-content-block' onClick={() => navigate(`/projects`)}>
                            <span>/</span>
                            <p>View all projects</p>
                            <IconArrowAssistance/>
                        </div>
                    </div>
                    {isPageChanging || (isFetching && projects.length === 0) ? (
                        <div className="loader-overlay">
                            <div className="loader"></div>
                        </div>
                    ) : (
                        <div className='assistance-main'>
                            <div className='assistance-main-container'>
                                <div onClick={handleOpenModal} className='assistance-main-new'>
                                    <section className='assistance-main-new-create-project-container'>
                                        <IconCreateProject />
                                        <p className='assistance-main-new-create-project-title'>Create new project space</p>
                                    </section>
                                </div>
                                {projects.slice(-5).map((project) => {
                                    const projectImage = localProjectImages[project.id] ||
                                        (project.galleries && project.galleries.length > 0 && project.galleries[0].medias.length > 0
                                            ? project.galleries[0].medias[project.galleries[0].medias.length - 1].url
                                            : '/img_projects/projectdefault.png');

                                    return (
                                        <div className='assistance-project-list' key={project.id}>
                                            <div className='assistance-project-get'>
                                                <img
                                                    className='assistance-project-get-img'
                                                    src={projectImage}
                                                    alt='project'
                                                    onClick={() => navigate(`/costs/${project.id}`)}
                                                />
                                                <div
                                                    className='assistance-project-get-icon'
                                                    title='Replace image'
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        document.getElementById(`imageUpload-${project.id}`)?.click();
                                                    }}
                                                >
                                                    <IconReplaceImage />
                                                </div>
                                                <input
                                                    type="file"
                                                    id={`imageUpload-${project.id}`}
                                                    accept="image/*"
                                                    style={{display: 'none'}}
                                                    onChange={(e) => handleImageChange(project.id, e)}
                                                />
                                            </div>

                                            <div className='assistance-project-title'>
                                                <p className='assistance-project-name' data-title={project.title}>{project.title}</p>
                                                <p className='assistance-project-data'>{new Date(project.created_at).toLocaleDateString()}</p>
                                            </div>
                                        </div>
                                    );
                                })}

                            </div>
                        </div>
                    )}
                </div>
            )}
            <CreateProjectModal
                isOpen={isModalOpen}
                onRequestClose={handleCloseModal}
            />
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    projects: state.projectAssistance.projects,
    isFetching: state.projectAssistance.isFetching,
    error: state.projectAssistance.error,
    currentPage: state.projectAssistance.currentPage,
    isAuthenticated: state.auth.isAuthenticated
});

const mapDispatchToProps = {
    fetchLastProjects,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(AllProjects);
