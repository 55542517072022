import React, {useState, useEffect} from 'react';
import './SwitcherStep2.css';
import {toast} from "react-toastify";
import {IconStep2Flush} from "../../IconComponents/IconComponents";
import {Project} from "../../types/ProjectTypes";
import {Template} from "../../types/TemplateType";
import {flushProject, minimumPriceProject} from "../../api/APIWraper";

interface SwitcherProps {
    options: Array<'Work Stage' | 'Materials|Labor|Tools'>;
    onChange: (selectedOption: 'Work Stage' | 'Materials|Labor|Tools') => void;
    onUpdateProject?: (newProject: Project) => void;
    templates?:Template[];
    projectId?:string
    step3?:boolean
    toggleActive?:boolean
}

const SwitcherStep2: React.FC<SwitcherProps> = ({options,step3, onChange,onUpdateProject,templates,projectId,toggleActive}) => {
    const [selectedOption, setSelectedOption] = useState<'Work Stage' | 'Materials|Labor|Tools'>(options[0]);
    const [isLoadingFlush, setIsLoadingFlush] = useState(false);
    const [isLoadingPrice, setIsLoadingPrice] = useState(false);
    const [checkOrderdQty, setCheckOrderedQty] = useState(false);
    useEffect(() => {
        onChange(selectedOption);
    }, [selectedOption, onChange]);

    const handleClick = (option: 'Work Stage' | 'Materials|Labor|Tools') => {
        setSelectedOption(option);
        toast.success(option === 'Work Stage' ? 'Everything in one block' : 'Sorting by type. Labors and tools separately')
    };

    const checkOrderedQty = (templates: Template[]): boolean => {
        for (const template of templates) {
            for (const fragment of template.data.fragments) {
                for (const itemType of fragment.data.item_types) {
                    if (itemType.ordered_qty && itemType.ordered_qty > 0) {
                        return true;
                    }
                }
            }
        }
        return false;
    };
    useEffect(() => {
        if(templates) {
            setCheckOrderedQty(checkOrderedQty(templates));
        }
    }, [templates]);

    const handleFlushProject = async () => {
        setIsLoadingFlush(true);
        if (projectId) {
            const response = await flushProject(projectId)
            if(response.status === 200) {
                toast.success('You have successfully reset the project!')
                setIsLoadingFlush(false);
                if (onUpdateProject) {
                    onUpdateProject(response.data)
                }
            }
        }
    }

    const handleMinimumPriceProject = async () => {
        setIsLoadingPrice(true);
            const response = await minimumPriceProject(Number(projectId))
            if(response.status === 200) {
                toast.success('You have successfully added products with minimum price to the project')
                setIsLoadingPrice(false);
                if (onUpdateProject) {
                    onUpdateProject(response.data)
                }
            }
    }


    return (
        <div className='switcher-container'>
            <div className={`switcher switcher-${options.length}`}>
                {options.map((option, index) => (
                    <div
                        title={option === 'Work Stage' ? 'Everything in one block' : 'Sorting by type. Labors and tools separately'}
                        key={index}
                        className={`switcher-option ${selectedOption === option ? 'active-switcher' : ''}`}
                        onClick={() => handleClick(option)}
                    >
                        {option}
                    </div>
                ))}
            </div>
            {!step3 && !checkOrderdQty && (
                toggleActive ? (
                    <div
                        onClick={() => handleMinimumPriceProject()}
                        className={`minimum-price-project ${isLoadingPrice ? 'is-loading' : ''}`}>
                        <div><IconStep2Flush/></div>
                        <p>Minimum price</p>
                    </div>
                    ) : (
                    <div
                        onClick={() => handleFlushProject()}
                        className={`flush-project ${isLoadingFlush ? 'is-loading' : ''}`}>
                        <div><IconStep2Flush/></div>
                        <p>Flush</p>
                    </div>
                )
            )}
        </div>
    );
};

export default SwitcherStep2;
