import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Fragment, FragmentData } from "../../../types/TemplateType";
import '../../../components/AssisStepCalc/StepCalc.css'

interface ModalCalculateFragmentProps {
    isOpen: boolean;
    onClose: (updatedFragment: Fragment | null) => void;
    fragment: Fragment;
}

const ModalCalculateFragment: React.FC<ModalCalculateFragmentProps> = ({
                                                                           isOpen,
                                                                           onClose,
                                                                           fragment
                                                                       }) => {
    const [selectedAnswers, setSelectedAnswers] = useState<Record<string, string>>({});
    const [isExpanded, setIsExpanded] = useState<boolean>(true);

    useEffect(() => {
        if (fragment?.data) {
            const initialSelectedAnswers: Record<string, string> = {};
            Object.entries(fragment.data).forEach(([key, item]: [string, any]) => {
                if (item?.value) {
                    initialSelectedAnswers[key] = item.value;
                } else if (item?.defaultValue) {
                    initialSelectedAnswers[key] = item.defaultValue;
                }
            });
            setSelectedAnswers(initialSelectedAnswers);
        }
    }, [fragment]);

    const handleAnswerChange = (key: string, value: string) => {
        setSelectedAnswers((prevState) => ({
            ...prevState,
            [key]: value
        }));
    };

    const handleInputChange = (key: string, value: string) => {
        if (/^\d*\.?\d*$/.test(value)) {
            setSelectedAnswers((prevState) => ({
                ...prevState,
                [key]: value
            }));
        }
    };

    const toggleFragmentVisibility = () => {
        setIsExpanded(prevState => !prevState);
    };

    const handleConfirm = () => {
        const updatedData: Record<string, FragmentData> = {};

        Object.entries(selectedAnswers).forEach(([key, value], index) => {
            const actualKey = fragment.data?.[key]?.key || key;

            updatedData[index] = {
                key: actualKey,
                value,
                question: fragment.data?.[key]?.question || "",
                select: fragment.data?.[key]?.select || "",
                answers: fragment.data?.[key]?.answers || [],
                item_types: fragment.data?.[key]?.item_types || [],
            };
        });


        const updatedFragment: Fragment = {
            ...fragment,
            data: {
                ...updatedData,
                key: "",
                question: "",
                value: "",
                answers: [],
                item_types: fragment.data?.item_types || [],
            } as FragmentData,
        };
        onClose(updatedFragment);
    };



    const handleCancel = () => {
        onClose(null);
    };

    return (
        <Modal
            className="modal-overlay"
            isOpen={isOpen}
            onRequestClose={handleCancel}
            overlayClassName="modal-content-more-list"
            ariaHideApp={false}
        >
            <div className='modal-content-more-list'>
                <div className='modal-content-show-step-btn'>
                    <div>
                        <p className='modal-content-required'>Required information</p>
                    </div>
                    <button className="modal-close" onClick={handleCancel}>
                        &times;
                    </button>
                </div>

                <div className={`step-calc-fragment ${isExpanded ? 'expanded' : 'collapsed'}`}>
                    <h3 className="step-calc-fragment-title" onClick={toggleFragmentVisibility}>
                        {fragment.title || "Fragment"}
                        <button className={`toggle-button ${isExpanded ? 'expanded' : 'collapsed'}`}>
                            {isExpanded ? '▲' : '▼'}
                        </button>
                    </h3>

                    {isExpanded && (
                        <div className="step-calc-fragment-content">
                            {Object.entries(fragment.data || {}).map(([key, fragmentData]: [string, any]) => (
                                fragmentData.question ? (
                                    <div key={key} className="step-answer-block">
                                        <div className="step-question">{fragmentData.question}</div>
                                            {fragmentData.select === 'select' && fragmentData.answers ? (
                                                fragmentData.answers.map((answer: any, idx: number) => (
                                                    <label key={idx} className="step-answer">
                                                        <input
                                                            type="radio"
                                                            name={key}
                                                            value={answer.value}
                                                            checked={selectedAnswers[key] === answer.value}
                                                            onChange={() => handleAnswerChange(key, answer.value)}
                                                        />
                                                        <span className="radio-custom"></span>
                                                        {answer.title}
                                                    </label>
                                                ))
                                            ) : (
                                                <div className="step-calc-field-fragment">
                                                <input
                                                    type="text"
                                                    value={selectedAnswers[key] || fragmentData.defaultValue || ''}
                                                    onChange={(e) => handleInputChange(key, e.target.value)}
                                                    onBlur={(e) => handleAnswerChange(key, e.target.value)}
                                                    className={selectedAnswers[key] ? 'filled' : ''}
                                                />
                                                </div>
                                            )}
                                    </div>
                                ) : null
                            ))}
                        </div>
                    )}
                </div>

                <div className='calc-btn-block'>
                    <button className="calc-btn" type="button" onClick={handleConfirm}>
                        Confirm
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default ModalCalculateFragment;
