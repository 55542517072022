import React from 'react';
import './styles/header.css';

interface PreferencesHeaderProps {
    activeSection: string;
    scrollToSection: (sectionId: string) => void;
}

const sections = [
    'account-details',
    'subscription-plan',
    'notification-settings',
    'change-password',
    'privacy-settings',
];

const PreferencesHeader: React.FC<PreferencesHeaderProps> = ({ activeSection, scrollToSection }) => {
    return (
        <div className='preferences-header'>
            {sections.map((section) => (
                <p
                    key={section}
                    className={`preferences-header-list ${activeSection === section ? 'active' : ''}`}
                    onClick={() => scrollToSection(section)}
                >
                    {section.replace(/-/g, ' ').replace(/\b\w/g, c => c.toUpperCase())}
                </p>
            ))}
        </div>
    );
};

export default PreferencesHeader;
