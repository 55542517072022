import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import GuideHeader from "./GuideHeader";
import GuideFooter from "./GuideFooter";
import './GuideStyles/GuideBom.css';
import {useSelector} from "react-redux";
import {selectUser} from "../../Slice/tokenSlice/userSlice";

const GuideWrapper: React.FC = () => {
    const location = useLocation();
    const user = useSelector(selectUser);

    useEffect(() => {
        document.body.style.paddingTop = '0px';

        return () => {
            document.body.style.paddingTop = '';
        };
    }, [location]);
    useEffect(() => {
        const currentRole = user?.role.role;
        if (currentRole === 'Contractor') {
            localStorage.setItem('hasSeenContractorGuide', 'true');
        }
    }, []);

    return (
        <div className="guide-wrapper">
            <GuideHeader />
            <div className="guide-content">
                <Outlet />
            </div>
            <GuideFooter />
        </div>
    );
};

export default GuideWrapper;
