import store from "../store";
import {selectUser} from "../Slice/tokenSlice/userSlice";

export enum Vendor {
    Cashbuild = 'cashbuild',
    Afrisam = 'afrisam',
    LeroyMerlin = 'leroymerlincoza',
    Builders = 'builderscoza',
    HomeDepotCA = 'homedepotca',
    HomeDepotUSA = 'homedepotusa',
    HouzzUSA = 'houzzusa',
    LowesUSA = 'lowesusa'
}


const cashBuildLink = localStorage.getItem('cashBuildLink');
const tmR = localStorage.getItem('Bearer') || sessionStorage.getItem('Bearer');
const state = store.getState();
const user = selectUser(state);
const role = user?.role?.role;

const displayedTMR = () =>{
    if(tmR && role){
        return '®'
    } else
        return '™'
}

export const vendorData: Record<Vendor, { displayName: string; url: string }> = {
    [Vendor.Cashbuild]: { displayName: `Cashbuild${displayedTMR()}`, url: cashBuildLink ? cashBuildLink : '' },
    [Vendor.Builders]: { displayName: `Builders${displayedTMR()}`, url: "https://www.builders.co.za/" },
    [Vendor.LeroyMerlin]: { displayName: `Leroy Merlin${displayedTMR()}`, url: "https://leroymerlin.co.za/" },
    [Vendor.HomeDepotCA]: { displayName: `Home Depot CA${displayedTMR()}`, url: "https://www.homedepot.ca" },
    [Vendor.HomeDepotUSA]: { displayName: `Home Depot USA${displayedTMR()}`, url: "https://www.homedepot.com" },
    [Vendor.HouzzUSA]: { displayName: `Houzz USA${displayedTMR()}`, url: "https://www.houzz.com" },
    [Vendor.LowesUSA]: { displayName: `Lowes USA${displayedTMR()}`, url: "https://www.lowes.com/" },
    [Vendor.Afrisam]: { displayName: `Afrisam${displayedTMR()}`, url: "https://www.afrisam.com/" }
};
