// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.paid-template-block{
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
}
.paid-template-block>p{
    color: black;
    font-size: 24px;
    text-align: center;
    margin: 0;
}

.paid-template-price{
    color: var(--basic-btn-dark-color);
}`, "",{"version":3,"sources":["webpack://./src/components/Modals/PaidTemplate/PaidTemplate.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;AACnB;AACA;IACI,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,SAAS;AACb;;AAEA;IACI,kCAAkC;AACtC","sourcesContent":[".paid-template-block{\n    height: 250px;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    padding: 20px 0;\n}\n.paid-template-block>p{\n    color: black;\n    font-size: 24px;\n    text-align: center;\n    margin: 0;\n}\n\n.paid-template-price{\n    color: var(--basic-btn-dark-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
