import React, {useState} from 'react';
import '../AboutUs/AboutUs.css';
import CountInfoSection from "../../../components/LandingPageComponent/CountInfoSection";
import InfoBlockBlog from "../../../components/LandingPageComponent/InfoBlockBlog";
import ReviewSlider from "../../../components/LandingPageComponent/ReviewsBlock";

function AboutUs() {
    const [isHovered, setIsHovered] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isImageVisible, setIsImageVisible] = useState(true);

    const handleImageClick = () => {
        setIsImageVisible(false);
    };

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        if (!isPlaying) {
            setIsHovered(false);
        }
    };

    const handleClick = () => {
        setIsHovered(true);
        setIsPlaying(true);
    };


    return (
        <main className="landing-page">
            <div className="landing-middle-info-nav">
                <p>About Us</p>
            </div>

            <section className="landing-video-section-about">
                <div className="upper-block-video-about"
                     onClick={handleClick}

                >
                    <iframe
                        width="1560"
                        height="500"
                        src="https://drive.google.com/file/d/1uIKGRljIErLZCn_iYAWV-Y9EMB7IPSmO/preview"
                        title="Google Drive video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    ></iframe>
                    {isImageVisible && (
                        <div
                            className="video-overlay"
                            onClick={handleImageClick}
                        >
                            <img title='Play video' src="/img_landing/functionaluty.png" alt="Play Video"
                                 className="overlay-image"/>
                        </div>
                    )}
                </div>
            </section>

            <div className={`review-slider-container ${isHovered || isPlaying ? 'shifted' : ''}`}>
                <ReviewSlider variant="compact"/>
            </div>

            <CountInfoSection iconVisible={false}/>

            <div className="landing-middle-info-nav">
                <p>Our Advantages</p>
            </div>
            <InfoBlockBlog/>

            <section className="about-us-section-partners">
                <div className="about-us-partners-block">
                    <div className="about-us-info-partners-text">
                        <p className="about-us-info-block-text-bold">Our Partners</p>
                        <p className="about-us-info-block-text-partners">
                            To bring our vision to life, we partnered with Autodesk, the creators of leading
                            architectural software programs like Revit and AutoCAD, to develop a solution that automates
                            quantity take-offs and simplifies construction planning for everyone.


                        </p>
                        <p><img src="/img_landing/autodesk.png" alt="autodesk"/></p>
                    </div>

                    <div className="about-us-info-block-second"></div>
                </div>
                <img className="about-us-image" src="/img_landing/partners.png" alt="partners"/>
            </section>
        </main>
    );
}

export default AboutUs;
