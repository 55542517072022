import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "../../api/APIWraper";
import { RootState } from "../../store";
import {User} from "../../types/ProjectTypes";

export const fetchUser = createAsyncThunk("user/fetchUser", async () => {
    const response = await get(`${process.env.REACT_APP_BASE_URL_CUSTOMER}/my_profile_info`);
    return response.data;
});

interface UserState {
    user: User | null;
}

const initialState: UserState = {
    user: null,
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<any>) => {
            state.user = action.payload;
        },
        updateUserFromWS: (state, action: PayloadAction<any>) => {
            state.user = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUser.fulfilled, (state, action) => {
            state.user = action.payload;
        });
    },
});

export const { setUser, updateUserFromWS } = userSlice.actions;
export const selectUser = (state: RootState) => state.user.user;
export default userSlice.reducer;
