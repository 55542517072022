// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project-bom-create{
    margin-bottom: 65px;
}
.create-project-bom-section{
    display: flex;
    justify-content: space-between;
    width: 100%
}
.map-bom{
    width: 675px;
    height: 320px;
    display: block;
}
.project-bom-fields{
    display: flex;
    flex-direction: column;
    width: 375px;
}
.create-bom-region{
    width: 375px;
    position: relative;
}
.create-bom-block-region{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/Page/Step1BOMCreate/CreateProjectBom/CreateProjectBom.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,8BAA8B;IAC9B;AACJ;AACA;IACI,YAAY;IACZ,aAAa;IACb,cAAc;AAClB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;AAChB;AACA;IACI,YAAY;IACZ,kBAAkB;AACtB;AACA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,8BAA8B;AAClC","sourcesContent":[".project-bom-create{\n    margin-bottom: 65px;\n}\n.create-project-bom-section{\n    display: flex;\n    justify-content: space-between;\n    width: 100%\n}\n.map-bom{\n    width: 675px;\n    height: 320px;\n    display: block;\n}\n.project-bom-fields{\n    display: flex;\n    flex-direction: column;\n    width: 375px;\n}\n.create-bom-region{\n    width: 375px;\n    position: relative;\n}\n.create-bom-block-region{\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
