import React, {useState} from 'react';
import '../Functionality/Functionality.css'

import FAQSection from "../../../components/LandingPageComponent/FAQSection";
import {
    IconAboutUsCheckLanding, IconAccuracyAboutUsLanding, IconBoardLanding,
    IconCalendarFunctionality, IconPenWriteLanding, IconPriceLanding, IconSupportFunctionality,
    IconUserFunctionality,
    IconWatchFunctionality
} from "../../../IconComponents/IconComponents";


function Functionality() {
    const [isImageVisible, setIsImageVisible] = useState(true);

    const handleImageClick = () => {
        setIsImageVisible(false);
    };
    return (
        <main className='landing-page'>
            <div className='landing-middle-info-nav'>
                <p className='landing-middle-info-nav-func-info'>Functionality</p>
            </div>

            <div className='landing-middle-info-nav-func-text'>
                <p>GoBuild BoM (Bill of Materials) provides the answer</p>
            </div>

            <section className='func-section'>
                <div className='func-block'>
                    <IconUserFunctionality/>
                    <p className='func-block-name'>Efficiency & Accuracy</p>
                    <p>Reduces manual errors while enhancing precision. </p>
                </div>

                <div className='func-block'>
                    <IconCalendarFunctionality/>
                    <p className='func-block-name'>Customization</p>
                    <p>Enables tailored project planning to suit specific needs. </p>
                </div>


                <div className='func-block'>
                    <IconWatchFunctionality/>
                    <p className='func-block-name'>Seamless Integration</p>
                    <p>Works effortlessly with 3D Revit architectural software for a streamlined workflow.</p>
                </div>


                <div className='func-block'>
                    <IconSupportFunctionality/>
                    <p className='func-block-name'>User Empowerment</p>
                    <p>Supports both professionals and DIY in cost estimation. </p>
                </div>


            </section>

            <div className='landing-middle-info-nav-func'>
                <p>You can see an example of our product in action in the video below.</p>
            </div>
            <section className='landing-video-section func-video'>
                <div className='upper-block-video-about'>
                    <iframe
                        width="1560"
                        height="580"
                        src="https://drive.google.com/file/d/1uIKGRljIErLZCn_iYAWV-Y9EMB7IPSmO/preview"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                    {isImageVisible && (
                        <div
                            className="video-overlay"
                            onClick={handleImageClick}
                        >
                            <img title='Play video' src="/img_landing/functionality2.png" alt="Play Video" className="func-image" />
                        </div>
                    )}
                </div>
            </section>

            <section className='about-us-manual'>
                <p className='about-us-manual-info-text'>No more manual measuring of digital or PDF building plans!</p>
                <div className='about-us-manual-block'>
                    <div className='about-us-block-info'>
                        <div className='about-us-icon'><IconPriceLanding/></div>
                        <p>Architects can provide clients with accurate costs in minutes, even with product changes.</p>
                    </div>
                    <div className='about-us-block-info'>
                        <div className='about-us-icon'><IconAccuracyAboutUsLanding/></div>
                        <p>Builders can instantly generate bills of materials and guided labor costs.</p>
                    </div>
                    <div className='about-us-block-info'>
                        <div className='about-us-icon'><IconBoardLanding/></div>
                        <p>Quantity Surveyors get precise quantities without spending hours measuring.</p>
                    </div>
                    <div className='about-us-block-info'>
                        <div className='about-us-icon'><IconPenWriteLanding/></div>
                        <p>DIY enthusiasts can enter measurements and instantly see costs. GoBuild BoM makes it fast and
                            easy for everyone involved in construction.</p>
                    </div>
                </div>
            </section>


            <section className='about-us-section'>
                <p className='about-us-section-title'>3D Digital Revit Architectural Plans</p>
                <p className='about-us-section-title-info'>Simply upload your 3D Digital Revit plans and we will
                    calculate your BoM, nothing else needed.</p>
                <div className='about-us-section-block'>
                    <div className='about-us-block-image-text'>
                        <img className='about-us-image' src='/img_landing/aboutU.png' alt='About Us'/>
                        <div>
                            <div>
                                <p className='about-us-section-diy'>DIY</p>
                                <div className='about-us-block-info-text'><IconAboutUsCheckLanding/><p>110mm brick
                                    wal</p>
                                </div>
                                <div className='about-us-block-info-text'><IconAboutUsCheckLanding/><p>220mm brick
                                    wall</p>
                                </div>
                                <div className='about-us-block-info-text'><IconAboutUsCheckLanding/><p>Plastering a
                                    wall</p>
                                </div>
                                <div className='about-us-block-info-text'><IconAboutUsCheckLanding/><p>Painting an
                                    interior
                                    wall</p></div>
                                <div className='about-us-block-info-text'><IconAboutUsCheckLanding/><p>Painting an
                                    exterior
                                    wall</p></div>
                                <div className='about-us-block-info-text'><IconAboutUsCheckLanding/><p>Tiling a
                                    floor</p>
                                </div>
                                <div className='about-us-block-info-text'><IconAboutUsCheckLanding/><p>Tiling a wall</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='about-us-block-image-text about-us-odd'>
                        <img className='about-us-image' src='/img_landing/buildingStructure.png' alt='About Us'/>
                        <div>
                            <div>
                                <p className='about-us-section-diy'>Building structures</p>
                                <div className='about-us-block-info-text about-us-odd'><IconAboutUsCheckLanding/>
                                    <p>Garage</p>
                                </div>
                                <div className='about-us-block-info-text about-us-odd'><IconAboutUsCheckLanding/>
                                    <p>Single story house</p>
                                </div>
                                <div className='about-us-block-info-text about-us-odd'><IconAboutUsCheckLanding/>
                                    <p>Deck</p>
                                </div>
                                <div className='about-us-block-info-text about-us-odd'><IconAboutUsCheckLanding/>
                                    <p>Outdoor entertainment area</p></div>
                            </div>
                        </div>
                    </div>


                    <div className='about-us-block-image-text'>
                        <img className='about-us-image' src='/img_landing/renovation.png' alt='About Us'/>
                        <div>
                            <div>
                                <p className='about-us-section-diy'>Renovation</p>
                                <div className='about-us-block-info-text'><IconAboutUsCheckLanding/><p>Bedroom</p>
                                </div>
                                <div className='about-us-block-info-text'><IconAboutUsCheckLanding/><p>Bathroom</p>
                                </div>
                                <div className='about-us-block-info-text'><IconAboutUsCheckLanding/><p>Living room</p>
                                </div>
                                <div className='about-us-block-info-text'><IconAboutUsCheckLanding/><p>Kitchen</p></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <FAQSection/>
        </main>
    );
}

export default Functionality;
