import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import './Step3SocketModal.css';
import { TItem } from "../../../types/TemplateType";
import { connect, ConnectedProps } from "react-redux";
import {
    clearMessages,
    connectionError,
    messageReceived,
    websocketConnect,
    websocketDisconnect
} from "../../../Slice/WebSocket/WebSocketSlice";
import { RootState } from "../../../store";
import { socket } from "../../../App";
import {Vendor, vendorData} from "../../../Enums/VendorEnum";

Modal.setAppElement('#root');

interface SelectItemModalProps {
    isOpen: boolean;
    onRequestClose: () => void;
}

const Step3SocketModal: React.FC<SelectItemModalProps & PropsFromRedux> = ({
                                                                               isOpen,
                                                                               onRequestClose,
                                                                               websocketConnect,
                                                                               messageReceived,
                                                                               connectionError,
                                                                               websocketMessages,
                                                                               clearMessages,
                                                                           }) => {
    const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState<boolean>(false);
    const [selectedVendor, setSelectedVendor] = useState<Vendor | null>(null);
    const [visitedVendors, setVisitedVendors] = useState<Set<Vendor>>(new Set());
    const userId = localStorage.getItem('userId');

    useEffect(() => {
        const visited = JSON.parse(localStorage.getItem('visitedVendors') || '[]') as Vendor[];
        setVisitedVendors(new Set(visited));
    }, []);

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
            document.body.classList.add('modal-open');
            websocketConnect();
        } else {
            document.body.style.overflow = '';
            document.body.classList.remove('modal-open');
        }

        return () => {
            document.body.style.overflow = '';
            document.body.classList.remove('modal-open');
        };
    }, [isOpen,websocketConnect]);



    useEffect(() => {
        const vendorKeys: Vendor[] = Object.values(Vendor);

        vendorKeys.forEach((vendor) => {
            socket.on(`${vendor} ${userId}`, (data: any) => {
                messageReceived(data);
                socket.off(`${vendor} ${userId}`);
            });
        });

        socket.on('connect_error', (err: any) => {
            console.error('error', err);
            connectionError(err.message);
        });

        return () => {
            vendorKeys.forEach((vendor) => {
                socket.off(`${vendor} ${userId}`);
            });
        };
    }, [userId, messageReceived, connectionError]);

    const uniqueItems = websocketMessages.flatMap(msg => msg.data)
        .reduce((acc, entry) => {
            const { item, message }: any = entry;
            if (!acc[item.id]) {
                acc[item.id] = { ...item, isAvailable: !!message, hasMessage: !!message };
            } else {
                acc[item.id].isAvailable = acc[item.id].isAvailable || !!message;
                acc[item.id].hasMessage = acc[item.id].hasMessage || !!message;
            }
            return acc;
        }, {} as Record<number, TItem & { isAvailable: boolean; hasMessage: boolean }>);

    const itemsArray = Object.values(uniqueItems);
    const vendors = Array.from(new Set(itemsArray.map(item => item.vendor))) as Vendor[];

    const [selectedVendors, setSelectedVendors] = useState<Vendor[]>(vendors.length > 0 ? [vendors[0]] : []);


    useEffect(() => {
        if (vendors.length > 0 && selectedVendors.length === 0) {
            setSelectedVendors([vendors[0]]);
        }
    }, [vendors, selectedVendors]);

    useEffect(() => {
        const QUANTITY_STORAGE_KEY = 'ItemQuantity';

        const updateVisitedVendors = () => {
            const itemQuantityStr = localStorage.getItem(QUANTITY_STORAGE_KEY);
            if (!itemQuantityStr) return;

            const itemQuantity = parseInt(itemQuantityStr, 10);

            if (visitedVendors.size >= itemQuantity) {
                clearMessages();
                onRequestClose();
            }
        };

        updateVisitedVendors();

        const handleStorageChange = (event: StorageEvent) => {
            if (event.key === QUANTITY_STORAGE_KEY) {
                updateVisitedVendors();
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [visitedVendors, onRequestClose, clearMessages]);


    const itemStatusMap = websocketMessages.flatMap(msg =>
        msg.data.map(({ item, message }) => ({
            id: item.id,
            vendor: item.vendor,
            isAvailable: !!message,
            qty: item.qty
        }))
    );

    const handleVendorToggle = (vendor: Vendor) => {
        setSelectedVendors((prev) =>
            prev.includes(vendor) ? prev.filter((v) => v !== vendor) : [...prev, vendor]
        );
    };

    const handleGoToStoreClick = (vendor: Vendor) => {
        setSelectedVendor(vendor);
        setConfirmationModalIsOpen(true);
    };

    const handleConfirmGoToStore = () => {
        if (selectedVendor) {
            let url = vendorData[selectedVendor].url;

            if (selectedVendor === Vendor.Cashbuild) {
                const cashBuildLink = localStorage.getItem("cashBuildLink");
                if (cashBuildLink) {
                    url = cashBuildLink;
                } else {
                    console.error("Cashbuild link not found in localStorage.");
                    return;
                }
            }

            window.open(url, "_blank");

            setVisitedVendors((prev) => {
                const updatedVendors = new Set(prev);
                updatedVendors.add(selectedVendor);
                localStorage.setItem("visitedVendors", JSON.stringify(Array.from(updatedVendors)));
                return updatedVendors;
            });

            setConfirmationModalIsOpen(false);

            const allVendorsVisited = vendors.every((vendor) => visitedVendors.has(vendor));
            if (allVendorsVisited) {
                clearMessages();
                onRequestClose();
            }
        }
    };


    return (
        <>
            <Modal
                isOpen={isOpen}
                onRequestClose={onRequestClose}
                contentLabel="Select Allowed Item"
                className="step3-socket-modal"
                overlayClassName="step3-socket-modal-overlay"
            >
                <div className="step3-socket-modal-header">
                    <h2>Please, check your products</h2>
                    <button onClick={onRequestClose} className="step3-socket-modal-close-button">X</button>
                </div>

                <div className='step3-socket-modal-header-content-text'>
                    <div className='step3-socket-modal-vendor-buttons'>
                        {vendors.map((vendor) => (
                            <button
                                key={vendor}
                                title={`Sorted by ${vendorData[vendor].displayName}`}
                                className={`step3-socket-modal-vendor-button ${selectedVendors.includes(vendor) ? 'selected' : ''}`}
                                onClick={() => handleVendorToggle(vendor)}
                            >
                                {vendorData[vendor].displayName}
                            </button>
                        ))}
                    </div>
                </div>

                <div className="step3-socket-modal-content">
                    {vendors
                        .filter(vendor => selectedVendors.includes(vendor))
                        .map((vendor) => (
                            <div key={vendor}>
                                <div className='step3-socket-modal-store-header'>
                                    <h3 className='step3-socket-modal-vendor'>{vendorData[vendor].displayName}</h3>
                                    {!visitedVendors.has(vendor) && (
                                        <button onClick={() => handleGoToStoreClick(vendor)}
                                                className='step3-socket-modal-store-btn'>Go to store
                                        </button>
                                    )}
                                </div>
                                {itemsArray
                                    .filter(item => item.vendor === vendor)
                                    .map((item) => {
                                        const itemStatus = itemStatusMap.find(status => status.id === item.id);
                                        const isAvailable = itemStatus ? itemStatus.isAvailable : false;
                                        const quantity = itemStatus ? itemStatus.qty : 0;

                                        return (
                                            <div
                                                key={item.id}
                                                className='step3-socket-modal-item'
                                            >
                                                <div className='step3-socket-modal-item-block'>
                                                    <img
                                                        className="step3-socket-modal-item-img"
                                                        src={
                                                            item.vendor === 'cashbuild'
                                                                ? item.merchant_info?.image ||
                                                                (item.galleries && item.galleries.length > 0 && item.galleries[0].medias.length > 0 && item.galleries[0].medias[item.galleries[0].medias.length - 1]?.url) ||
                                                                '/img_vendor/item.png'
                                                                : (item.galleries && item.galleries.length > 0 && item.galleries[0].medias.length > 0 && item.galleries[0].medias[item.galleries[0].medias.length - 1]?.url) ||
                                                                '/img_vendor/item.png'
                                                        }
                                                        alt="Item"
                                                    />
                                                    <p className='step3-socket-modal-item-title'>{item.title}</p>
                                                </div>
                                                <p className='step3-socket-modal-item-price'>R {Number(item.price).toFixed(2)}</p>
                                                <div className='step3-socket-modal-qty-block'>
                                                    {isAvailable ? (
                                                        <p className='step3-socket-modal-item-qty'>{quantity}</p>
                                                    ) : (
                                                        <p className='step3-socket-modal-item-unavailable'>Out of
                                                            Stock</p>
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        ))}
                </div>
            </Modal>

            <Modal
                isOpen={confirmationModalIsOpen}
                onRequestClose={() => setConfirmationModalIsOpen(false)}
                contentLabel="Confirmation"
                className="step3-confirmation-modal"
                overlayClassName="step3-confirmation-modal-overlay"
            >
                <div className="step3-confirmation-modal-content">
                    <p>
                        Are you sure you want to go to the{" "}
                        <span
                            className="vendor-name">{selectedVendor && vendorData[selectedVendor].displayName}</span>{" "}
                        store now? Your cart will be formed only once, if you go there now you will not be able to
                        return there again later.
                    </p>
                    <div className="step3-confirmation-modal-buttons">
                        <button onClick={() => setConfirmationModalIsOpen(false)} className="cancel-button">
                            Cancel
                        </button>
                        <button onClick={handleConfirmGoToStore} className="continue-button">
                            Continue
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
}

const mapStateToProps = (state: RootState) => ({
    isConnected: state.websocket.isConnected,
    websocketMessages: state.websocket.message,
    websocketError: state.websocket.error,
});

const mapDispatchToProps = {
    websocketConnect,
    websocketDisconnect,
    messageReceived,
    connectionError,
    clearMessages
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Step3SocketModal);
