import React, { useState } from 'react';
import './TermsAndConditions.css';

const TermsAndConditions = () => {

    return (
        <div>TermsAndConditions</div>
    );
}

export default TermsAndConditions;