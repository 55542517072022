import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ItemType, TItem, TMerchanInfo } from '../../../types/TemplateType';
import { Project } from '../../../types/ProjectTypes';
import { get, patch } from '../../../api/APIWraper';
import { updateLocalTemplate } from '../../../Slice/LocalTemplate/LocalTemplateSlice';
import ItemSelectionModal from '../ItemSelectionModal';
import ILaborItemType from '../../../Interfaces/ProjectInterfaces/IlaborItemType';
import { Vendor, vendorData } from "../../../Enums/VendorEnum";
import { IconArrowLightStpe2 } from "../../../IconComponents/IconComponents";
import {SelectedItem} from "../../../Interfaces/ProjectInterfaces/SelectedItems";

interface ItemTypeListCalculationProps {
    laborItemTypes: ILaborItemType[],
    templateIndex: number,
    templateId: number,
    project: Project,
    onUpdateProject: (newProject: Project) => void,
    onToggle: () => void,
    relatedProjectItemTypes?: ItemType[]
}

interface SelectedItemInfo {
    packaging: any;
    vendor: Vendor | null;
    merchant_info: TMerchanInfo;
    user_item_qty?: number;
    item_packaging_qty?: number;
    item_packaging_value?: string;
    title: string;
    id: number;
    qty: number;
    ordered_qty: number;
    unit: string;
    price: number;
}


interface GroupedItem {
    item_type_id: number;
    item_ids: { id: number, qty: number }[];
    template_index: number;
    item_type_index: number;
    template_id: number;
    fragment_id: number;
    fragment_index: number;
}

interface ItemTypeWithIndex extends ItemType {
    originalIndex: number;
}

const LaborItemTypeListCalculation: React.FC<ItemTypeListCalculationProps & PropsFromRedux> = ({
                                                                                                   laborItemTypes,
                                                                                                   project,
                                                                                                   onUpdateProject,
                                                                                                   onToggle,
                                                                                                   templateId,
                                                                                               }) => {
    const [mergedItemTypes, setMergedItemTypes] = useState<(ILaborItemType & { originalIndex: number })[]>([]);
    const [selectedItemTypeIndex, setSelectedItemTypeIndex] = useState<number | null>(null);
    const [items, setItems] = useState<TItem[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [selectedItemInfos, setSelectedItemInfos] = useState<{ [id: string]: SelectedItemInfo }>({});
    const [selectedItems, setSelectedItems] = useState<SelectedItem[]>([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [debouncedOrderedQty, setDebouncedOrderedQty] = useState<{ [id: string]: string }>({});
    const [parsedItemTypes, setParsedItemTypes] = useState<{ [index: number]: any }>({});
    const [itemTypeUnit, setItemTypeUnit] = useState<string>('');

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5);
    const [totalItems, setTotalItems] = useState(0);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerWidth <= 920);
    const [visibilityMap, setVisibilityMap] = useState<{ [key: string]: boolean }>({});

    const generateItemTypeKey = (itemType: ItemTypeWithIndex, idx: number) => {
        return `${itemType.id}-${idx}-${itemType.title}`;
    };

    const toggleVisibility = (itemTypeKey: string) => {
        setVisibilityMap((prevMap) => ({
            ...prevMap,
            [itemTypeKey]: !prevMap[itemTypeKey],
        }));
    };

    const isVisible = (itemTypeKey: string) => {
        return visibilityMap[itemTypeKey] || false;
    };

    const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 920);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const mergeLaborItemTypes = (): (ILaborItemType & { originalIndex: number })[] => {
        const mergedItemsMap: { [key: number]: ILaborItemType & { originalIndex: number } } = {};

        laborItemTypes.forEach((item, index) => {
            const itemTypeId = item.item_type.id;
            if (mergedItemsMap[itemTypeId]) {
                const existingItemType = mergedItemsMap[itemTypeId];
                item.item_type.items?.forEach((laborItem) => {
                    const existingLaborItem = existingItemType.item_type.items?.find(li => li.id === laborItem.id);
                    if (existingLaborItem) {
                        existingLaborItem.qty += laborItem.qty;
                        const existingOrd = Number(existingLaborItem.ordered_qty);
                        const newOrd = Number(laborItem.ordered_qty);
                        if (existingOrd > 0 && newOrd > 0) {
                            existingLaborItem.ordered_qty = existingOrd + newOrd;
                        } else {
                            existingLaborItem.ordered_qty = Number(item.item_type.ordered_qty) || existingOrd;
                        }
                    } else {
                        const newLaborItem = { ...laborItem };
                        const newOrd = Number(newLaborItem.ordered_qty);
                        if (!(newOrd > 0)) {
                            newLaborItem.ordered_qty = Number(item.item_type.ordered_qty) || 0;
                        }
                        existingItemType.item_type.items?.push(newLaborItem);
                    }
                });
            } else {
                const cloned = JSON.parse(JSON.stringify(item));
                if (cloned.item_type.items) {
                    cloned.item_type.items = cloned.item_type.items.map((laborItem: any) => {
                        const ord = Number(laborItem.ordered_qty);
                        if (!(ord > 0)) {
                            laborItem.ordered_qty = Number(item.item_type.ordered_qty) || 0;
                        }
                        return laborItem;
                    });
                }
                mergedItemsMap[itemTypeId] = { ...cloned, originalIndex: index };
            }
        });

        return Object.values(mergedItemsMap);
    };

    useEffect(() => {
        const merged = mergeLaborItemTypes().filter(item => item.qty > 0);
        merged.sort((a, b) => a.originalIndex - b.originalIndex);
        setMergedItemTypes(merged);
    }, [laborItemTypes]);

    useEffect(() => {
        if (mergedItemTypes.length === 0) return;

        const initialSelectedItems = mergedItemTypes.flatMap((laborItem, mergedIdx) =>
            (laborItem.item_type.items || []).map((item, itemIdx) => {
                const packagingKeys = item.packaging ? Object.keys(item.packaging) : [];
                return {
                    itemTypeId: laborItem.item_type.id,
                    itemId: item.id,
                    originalIndex: laborItem.originalIndex,
                    index: mergedIdx,
                    user_item_qty: item.qty,
                    item_packaging_qty: packagingKeys.length > 0 ? item.packaging[packagingKeys[0]] : 1,
                    item_packaging_value: packagingKeys.length > 0 ? packagingKeys[0] : 'each',
                    clone_id: `${item.id}-${mergedIdx}-${itemIdx}`,
                    packaging: item.packaging
                };
            })
        );
        setSelectedItems(initialSelectedItems);

        const initialSelectedItemInfos = initialSelectedItems.reduce((acc, selectedItem) => {
            const mergedItem = mergedItemTypes.find(mi => mi.item_type.id === selectedItem.itemTypeId);
            const item = mergedItem?.item_type.items?.find((it: any) => it.id === selectedItem.itemId);

            if (mergedItem && item) {
                const packagingKeys = item.packaging ? Object.keys(item.packaging) : [];
                acc[selectedItem.clone_id] = {
                    merchant_info: {} as TMerchanInfo,
                    vendor: item.vendor,
                    user_item_qty: selectedItem.user_item_qty,
                    item_packaging_qty: packagingKeys.length > 0 ? item.packaging[packagingKeys[0]] : 1,
                    item_packaging_value: packagingKeys.length > 0 ? packagingKeys[0] : 'each',
                    title: item.title,
                    id: item.id,
                    qty: item.qty || 0,
                    ordered_qty: item.ordered_qty || 0,
                    unit: item.unit,
                    price: item.price || 0,
                    packaging: item.packaging
                };
            }
            return acc;
        }, {} as { [id: string]: SelectedItemInfo });

        setSelectedItemInfos(initialSelectedItemInfos);

        const parsedItemTypesObj = mergedItemTypes.reduce((acc, laborItem, mergedIdx) => {
            if (!laborItem.qty || laborItem.qty === 0) return acc;
            const clone_id = `${laborItem.item_type.id}-${mergedIdx}-${mergedIdx}`;
            const itemInfo = initialSelectedItemInfos[clone_id];
            const title = laborItem.item_type.title;
            let qty = itemInfo ? itemInfo.qty : laborItem.qty;
            if (!qty) qty = 0;
            let orderedQty = itemInfo ? itemInfo.ordered_qty : laborItem.item_type.ordered_qty;
            if (!orderedQty) orderedQty = 0;
            const price = (laborItem.item_type.items && laborItem.item_type.items.length > 0)
                ? laborItem.item_type.items[0].price
                : 0;
            const priceTotalQty = itemInfo ? itemInfo.price * qty : 0;
            const isItemSelected = laborItem.item_type.items && laborItem.item_type.items.length > 0;
            const unit = (laborItem.item_type.items && laborItem.item_type.items.length > 0)
                ? laborItem.item_type.items[0].unit
                : '';
            const unitItemType = laborItem.item_type.unit ? laborItem.item_type.unit : 'each';
            const itemId = laborItem.item_type.id;

            acc[mergedIdx] = {
                index: mergedIdx,
                originalIndex: laborItem.originalIndex,
                title,
                qty,
                orderedQty,
                price,
                priceTotalQty,
                isItemSelected,
                unit,
                unitItemType,
                itemId
            };
            return acc;
        }, {} as { [index: number]: any });
        setParsedItemTypes(parsedItemTypesObj);

        calculateTotalPrice();
    }, [mergedItemTypes]);

    useEffect(() => {
        calculateTotalPrice();
    }, [selectedItemInfos]);

    const calculateTotalPrice = () => {
        let total = 0;
        selectedItems.forEach((selectedItem) => {
            const item = selectedItemInfos[selectedItem.clone_id];
            if (item) {
                const generalPrice = item.price * (item.user_item_qty || item.qty);
                const difTotalPrice = Math.max(0, item.price * Math.min(item.ordered_qty, item.user_item_qty || item.qty));
                total += generalPrice - difTotalPrice;
            }
        });
        setTotalPrice(total);
    };

    const storeId = project?.store_id ? project.store_id : 0;

    const fetchItems = async (mergedIndex: number, page: number = 1, searchTerm: string = '') => {
        setSelectedItemTypeIndex(mergedIndex);
        setLoading(true);
        try {
            if (!mergedItemTypes[mergedIndex]) return;
            const itemTypeId = mergedItemTypes[mergedIndex].item_type.id;
            const itemTypeUnit = mergedItemTypes[mergedIndex].unit;

            const shouldIncludeUnit = itemTypeUnit && itemTypeUnit !== "each" && itemTypeUnit !== "psc";

            let url = `${process.env.REACT_APP_BASE_URL_CUSTOMER}/item_types/${itemTypeId}?page=${page}&perPage=${itemsPerPage}&storeIdFromProjects=${storeId}`;

            if (searchTerm) {
                url += `&title=${encodeURIComponent(searchTerm)}`;
            }
            if (shouldIncludeUnit) {
                url += `&packaging=${encodeURIComponent(itemTypeUnit)}`;
            }

            const response = await get(url);
            setTotalItems(response.data.items.pagination.lastPage);

            const itemsWithQty = (Array.isArray(response.data.items.data)
                    ? response.data.items.data
                    : [response.data.items.data]
            ).map((item: TItem) => {
                const qty_item_type = laborItemTypes
                    .filter(labor => labor.item_type.id === itemTypeId)
                    .reduce((total, current) => total + (current.qty || 0), 0);
                return { ...item, qty_item_type };
            });

            setItems(itemsWithQty);
        } catch (error) {
            console.error("Failed to get elements:", error);
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
        if (selectedItemTypeIndex !== null) {
            fetchItems(selectedItemTypeIndex, pageNumber, searchTerm);
        }
    };

    const handleButtonClick = (mergedIndex: number, itemTypeUnit: string) => {
        setIsModalOpen(true);
        setItemTypeUnit(itemTypeUnit);
        fetchItems(mergedIndex, currentPage);
    };

    const handleReload = () => {
        if (selectedItemTypeIndex !== null) {
            fetchItems(selectedItemTypeIndex, currentPage);
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setItemTypeUnit('');
        setCurrentPage(1);
        setSearchTerm('');
    };

    const handleItemClick = (modalItems: TItem[], mergedIndex: number | null) => {
        if (mergedIndex === null) return;
        const currentOriginalIndex = mergedItemTypes[mergedIndex].originalIndex;
        const updatedSelectedItems = [
            ...selectedItems.filter(item => item.originalIndex !== currentOriginalIndex),
            ...modalItems.map((item, idx) => ({
                itemTypeId: mergedItemTypes[mergedIndex].item_type.id,
                originalIndex: currentOriginalIndex,
                index: mergedIndex,
                itemId: item.id,
                item_packaging_qty: item.packaging[Object.keys(item.packaging)[0]] || 1,
                item_packaging_value: Object.keys(item.packaging)[0] || 'each',
                user_item_qty: item.user_item_qty,
                clone_id: `${item.id}-${mergedIndex}-${idx}`,
                packaging: item.packaging
            }))
        ];

        const updatedItemInfos = modalItems.reduce((acc, item, idx) => {
            const clone_id = `${item.id}-${mergedIndex}-${idx}`;
            acc[clone_id] = {
                merchant_info: {} as TMerchanInfo,
                vendor: item.vendor,
                title: item.title,
                id: item.id,
                qty: item.qty,
                ordered_qty: item.ordered_qty || 0,
                user_item_qty: item.user_item_qty || 0,
                item_packaging_qty: item.packaging[Object.keys(item.packaging)[0]] || 1,
                item_packaging_value: Object.keys(item.packaging)[0] || 'each',
                unit: item.unit,
                price: item.price || 0,
                packaging: item.packaging
            };
            return acc;
        }, { ...selectedItemInfos });
        setSelectedItemInfos(updatedItemInfos);
        setSelectedItems(updatedSelectedItems);
        calculateTotalPrice();
        setIsModalOpen(false);
    };

    const handleQtyChange = (clone_id: string, newQty: string) => {
        const parsedQty = parseInt(newQty);
        setDebouncedOrderedQty((prev) => ({
            ...prev,
            [clone_id]: !isNaN(parsedQty) ? newQty : '',
        }));
        setSelectedItemInfos((prev) => ({
            ...prev,
            [clone_id]: {
                ...prev[clone_id],
                ordered_qty: !isNaN(parsedQty) ? parsedQty : 0,
            },
        }));
        calculateTotalPrice();
    };

    const handleSave = async () => {
        function isMergedItemType(itemTypeId: number): boolean {
            return laborItemTypes.filter(item => item.item_type.id === itemTypeId).length > 1;
        }

        function generateIndexes(itemTypeId: number) {
            return laborItemTypes
                .filter(item => item.item_type.id === itemTypeId)
                .map(item => ({
                    fragment_id: item.fragment_id,
                    fragment_index: item.fragment_index,
                    item_type_index: item.item_type_index,
                    qty: item.item_type.qty,
                }));
        }

        async function handleItemOrderedQty(isCascade: boolean): Promise<Project | undefined> {
            const payload = Object.entries(debouncedOrderedQty)
                .map(([clone_id, qtyStr]) => {
                    const selectedItem = selectedItems.find(item => item.clone_id === clone_id);
                    if (!selectedItem) return null;

                    if (isMergedItemType(selectedItem.itemTypeId) !== isCascade) return null;
                    const userOrderedQty = parseInt(qtyStr, 10);
                    return {
                        ordered_qty: userOrderedQty,
                        template_index: laborItemTypes[selectedItem.originalIndex]?.template_index || 0,
                        item_type_index: laborItemTypes[selectedItem.originalIndex]?.item_type_index || 0,
                        template_id: templateId,
                        item_type_id: selectedItem.itemTypeId,
                        item_id: selectedItem.itemId,
                        fragment_id: laborItemTypes[selectedItem.originalIndex]?.fragment_id || 0,
                        fragment_index: laborItemTypes[selectedItem.originalIndex]?.fragment_index || 0,
                        ...(isCascade && { indexes: generateIndexes(selectedItem.itemTypeId) }),
                    };
                })
                .filter(item => item !== null);

            if (payload.length > 0) {
                const url = isCascade
                    ? `${process.env.REACT_APP_BASE_URL_CUSTOMER}/projects/${project.id}/item_ordered_qty_cascade`
                    : `${process.env.REACT_APP_BASE_URL_CUSTOMER}/projects/${project.id}/item_ordered_qty`;
                try {
                    const response = await patch(url, { data: payload });
                    return response.data;
                } catch (error) {
                    console.error(`Failed to update ordered_qty ${isCascade ? 'cascade' : ''}:`, error);
                }
            }
            return undefined;
        }

        const groupedItems = selectedItems.reduce((acc: { [key: string]: GroupedItem & { originalQty: number } }, selection) => {
            const { itemTypeId, originalIndex, index } = selection;
            const key = `${itemTypeId}-${index}`;
            const info = selectedItemInfos[selection.clone_id];
            const laborItem = laborItemTypes[originalIndex];
            const originalQtyFromLabor = (laborItem?.item_type?.items && laborItem.item_type.items.length > 0)
                ? laborItem.qty
                : 0;
            const originalQty = (info && typeof info.qty !== "undefined") ? info.qty : originalQtyFromLabor;

            if (!acc[key]) {
                acc[key] = {
                    item_type_id: itemTypeId,
                    item_ids: [],
                    template_index: laborItem?.template_index || 0,
                    item_type_index: laborItem?.item_type_index || 0,
                    template_id: templateId,
                    fragment_id: laborItem?.fragment_id || 0,
                    fragment_index: laborItem?.fragment_index || 0,
                    originalQty,
                };
            }
            if (selection.itemId) {
                const qty = (selection.user_item_qty !== undefined ? selection.user_item_qty : 1) * (selection.item_packaging_qty || 1);
                acc[key].item_ids.push({ id: selection.itemId, qty });
            }
            return acc;
        }, {} as { [key: string]: GroupedItem & { originalQty: number } });

        const ordinaryItems = Object.values(groupedItems).filter(group => !isMergedItemType(group.item_type_id));
        const mergedItems = Object.values(groupedItems).filter(group => isMergedItemType(group.item_type_id));

        const ordinaryChangedItems = ordinaryItems.filter(group => {
            const newQty = group.item_ids.reduce((sum, item) => sum + item.qty, 0);
            return newQty !== group.originalQty;
        });
        const mergedChangedItems = mergedItems.filter(group => {
            const newQty = group.item_ids.reduce((sum, item) => sum + item.qty, 0);
            return newQty !== group.originalQty;
        });

        const deletedItems = laborItemTypes
            .filter(item => item.item_type.items && item.item_type.items.length > 0)
            .map(item => ({
                item_type_id: item.item_type.id,
                item_ids: [],
                template_index: item.template_index,
                item_type_index: item.item_type_index,
                template_id: templateId,
                fragment_id: item.fragment_id,
                fragment_index: item.fragment_index,
            }))
            .filter(item => !selectedItems.some(selection => selection.itemTypeId === item.item_type_id));

        let updatedProject: Project | undefined;

        try {
            const ordinaryQtyPayloadExists = Object.entries(debouncedOrderedQty)
                .some(([clone_id]) => {
                    const sel = selectedItems.find(item => item.clone_id === clone_id);
                    return sel && !isMergedItemType(sel.itemTypeId);
                });
            if (ordinaryChangedItems.length > 0 || deletedItems.length > 0 || ordinaryQtyPayloadExists) {
                const payload = [...ordinaryChangedItems, ...deletedItems];
                const responseOrd = payload.length > 0
                    ? await patch(`${process.env.REACT_APP_BASE_URL_CUSTOMER}/projects/${project.id}/add_item`, { data: payload })
                    : null;
                let projectAfterOrdQty = await handleItemOrderedQty(false);
                if (!projectAfterOrdQty && responseOrd) {
                    projectAfterOrdQty = responseOrd.data;
                }
                if (projectAfterOrdQty) {
                    updatedProject = projectAfterOrdQty;
                }
            }

            const mergedQtyPayloadExists = Object.entries(debouncedOrderedQty)
                .some(([clone_id]) => {
                    const sel = selectedItems.find(item => item.clone_id === clone_id);
                    return sel && isMergedItemType(sel.itemTypeId);
                });
            if (mergedChangedItems.length > 0 || mergedQtyPayloadExists) {
                const mergedPayload = mergedChangedItems.map(item => ({
                    ...item,
                    indexes: generateIndexes(item.item_type_id),
                }));
                const responseCascade = mergedPayload.length > 0
                    ? await patch(`${process.env.REACT_APP_BASE_URL_CUSTOMER}/projects/${project.id}/add_item_cascade`, { data: mergedPayload })
                    : null;
                let projectAfterCascadeQty = await handleItemOrderedQty(true);
                if (!projectAfterCascadeQty && responseCascade) {
                    projectAfterCascadeQty = responseCascade.data;
                }
                if (projectAfterCascadeQty) {
                    updatedProject = projectAfterCascadeQty;
                }
            }
        } catch (error) {
            console.error("Failed to save selections:", error);
        }

        handleCloseModal();
        if (updatedProject) {
            onUpdateProject(updatedProject);
            onToggle();
        }
    };

    if (!laborItemTypes || !Array.isArray(laborItemTypes)) return null;

    const handleSearch = (query: string) => {
        setSearchTerm(query);
        if (selectedItemTypeIndex !== null) fetchItems(selectedItemTypeIndex, 1, query);
    };

    const calculateTotalQty = (id: number) =>
        mergedItemTypes
            .filter(item => item.item_type.id === id)
            .reduce((total, current) => total + (current.qty || 0), 0);

    return (
        <div>
            {mergedItemTypes.length > 0 && (
                <>
                    {Object.values(parsedItemTypes)
                        .filter((parsedItem: any) => parsedItem && parsedItem.qty > 0)
                        .map((parsedItem: any, idx: number) => {
                            const { title, qty, price, unit, itemId, unitItemType } = parsedItem;
                            const totalQty = calculateTotalQty(itemId);
                            const selectedItemsForType = selectedItems.filter(item => item.itemTypeId === itemId);
                            const itemTypeKey = generateItemTypeKey({
                                id: itemId,
                                originalIndex: parsedItem.originalIndex,
                                title
                            } as ItemTypeWithIndex, idx);
                            return (
                                <div key={`${itemId}-${idx}`}>
                                    <label
                                        className='static-item-type-info'
                                        htmlFor={`button-${itemId}-${idx}`}
                                    >
                                        <p className='static-item-type-info-title'>{title}</p>
                                        <p className='static-item-type-info-qty'>{qty} ({unitItemType})</p>
                                    </label>
                                    <div>
                                        {selectedItemsForType.length === 0 ? (
                                            !isSmallScreen ? (
                                                <div className="step2-item-type-list">
                                                    <button
                                                        className="step2-item-button-list"
                                                        id={`button-${itemId}-${idx}`}
                                                        onClick={() => handleButtonClick(parsedItem.index, unitItemType)}
                                                        disabled={loading}
                                                    >
                                                        {loading && selectedItemTypeIndex === parsedItem.index ? "Loading..." : "Select Labors"}
                                                        <svg width="26" height="26" viewBox="0 0 26 26" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M7 13L18 13M18 13L13.875 17M18 13L13.875 9"
                                                                  stroke="#0053A0" strokeWidth="1.5"
                                                                  strokeLinecap="round" strokeLinejoin="round"/>
                                                            <circle cx="13" cy="13" r="12" stroke="#0053A0"
                                                                    strokeWidth="2"/>
                                                        </svg>
                                                    </button>
                                                    <p className="step2-other-width-list"></p>
                                                    <div className="step2-tooltip-container">
                                                        <input type="number" className="step2-other-width-input" min="0"
                                                               disabled={!loading}/>
                                                    </div>
                                                    <p className="step2-other-width-list">R {parseInt(price).toFixed(2)}</p>
                                                    <p className="step2-other-width-list">R {parseInt((qty * price).toFixed(2))}</p>
                                                    <p className="step2-other-width-list-total">{Math.max(0, qty * price).toFixed(2)}</p>
                                                    <div className="step2-other-width-vendor-list"></div>
                                                </div>
                                            ) : (
                                                <div className="step2-item-type-list">
                                                    <div className="step2-item-type-list-block-arrow">
                                                        <button
                                                            className="step2-item-button-list"
                                                            id={`button-${itemId}-${idx}`}
                                                            onClick={() => handleButtonClick(parsedItem.index, unitItemType)}
                                                            disabled={loading}
                                                        >
                                                            {loading && selectedItemTypeIndex === parsedItem.index ? "Loading..." : "Select Labors"}
                                                            <svg width="26" height="26" viewBox="0 0 26 26" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M13 7L13 18M13 18L9 14M13 18L17 14"
                                                                      stroke="#0053A0" strokeWidth="1.5"
                                                                      strokeLinecap="round" strokeLinejoin="round"/>
                                                                <circle cx="13" cy="13" r="12" stroke="#0053A0"
                                                                        strokeWidth="2"/>
                                                            </svg>
                                                        </button>
                                                        <div onClick={() => toggleVisibility(itemTypeKey)}
                                                             className={isVisible(itemTypeKey) ? 'icon-arrow-light-step2-up-calc' : 'icon-arrow-light-step2-down-calc'}>
                                                            <IconArrowLightStpe2/>
                                                        </div>
                                                    </div>
                                                    <div className={`step2-content-list ${isVisible(itemTypeKey) ? 'visible' : 'hidden'}`}>
                                                        <div className="step2-tooltip-container">
                                                            <span>QTY to order:</span>
                                                            <span className="dashed-line"></span>
                                                            <input type="number" className="step2-other-width-input" min="0"
                                                                   disabled={!loading}/>
                                                        </div>
                                                        <p className="step2-other-width-list">
                                                            <span>QTY ordered:</span>
                                                            <span className="dashed-line"></span>
                                                            {totalQty}
                                                            <span>({unit ? unit : 'psc'})</span>
                                                        </p>
                                                        <p className="step2-other-width-list">
                                                            <span>Unit price:</span>
                                                            <span className="dashed-line"></span>
                                                            R {parseInt(price).toFixed(2)}
                                                        </p>
                                                        <p className="step2-other-width-list">
                                                            <span>Total:</span>
                                                            <span className="dashed-line"></span>
                                                            R {parseInt((qty * price).toFixed(2))}
                                                        </p>
                                                        <p className="step2-other-width-list-total total-ordred-bold">
                                                            <span>Total ordered:</span>
                                                            <span className="dashed-line"></span>
                                                            R {Math.max(0, qty * price).toFixed(2)}
                                                        </p>
                                                    </div>
                                                </div>
                                            )
                                        ) : (
                                            selectedItemsForType.map((selectedItem, i) => {
                                                const item = selectedItemInfos[selectedItem.clone_id];
                                                if (!item) return null;
                                                const totalItemPrice = item.price * (item.user_item_qty || item.qty);
                                                return !isSmallScreen ? (
                                                    <div key={`${selectedItem.clone_id}`} className="step2-item-type-list">
                                                        <button
                                                            className="step2-item-button-list"
                                                            id={`button-${itemId}-${idx}-${i}`}
                                                            onClick={() => handleButtonClick(parsedItem.index, unit)}
                                                            disabled={loading}
                                                        >
                                                            {loading && selectedItemTypeIndex === parsedItem.index ? "Loading..." : item.title}
                                                            <svg width="26" height="26" viewBox="0 0 26 26" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M7 13L18 13M18 13L13.875 17M18 13L13.875 9"
                                                                      stroke="#0053A0" strokeWidth="1.5"
                                                                      strokeLinecap="round" strokeLinejoin="round"/>
                                                                <circle cx="13" cy="13" r="12" stroke="#0053A0"
                                                                        strokeWidth="2"/>
                                                            </svg>
                                                        </button>
                                                        <p className="step2-other-width-list">{item.user_item_qty || item.qty} (psc)</p>
                                                        <div className="step2-tooltip-container">
                                                            <input
                                                                type="number"
                                                                className="step2-other-width-input"
                                                                value={debouncedOrderedQty[selectedItem.clone_id] ?? item.ordered_qty}
                                                                onChange={(e) => handleQtyChange(selectedItem.clone_id, e.target.value)}
                                                                min="0"
                                                            />
                                                            <span className="packing-value-steps">psc</span>
                                                        </div>
                                                        <p className="step2-other-width-list">R {parseInt(String(item.price)).toFixed(2)}</p>
                                                        <p className="step2-other-width-list">R {totalItemPrice.toFixed(2)}</p>
                                                        <p className="step2-other-width-list-total">R {Math.max(0, totalItemPrice).toFixed(2)}</p>
                                                        <div className="step2-other-width-vendor-list">
                                                            {item.vendor !== null && vendorData[item.vendor as Vendor] ? (
                                                                <p className="item-selection-modal-vendor">{vendorData[item.vendor as Vendor].displayName}</p>
                                                            ) : (
                                                                <p className="item-selection-modal-no-vendor">No Vendor</p>
                                                            )}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div key={`${selectedItem.clone_id}`} className="step2-item-type-list">
                                                        <div className="step2-item-type-list-block-arrow">
                                                            <button
                                                                className="step2-item-button-list"
                                                                id={`button-${itemId}-${idx}-${i}`}
                                                                onClick={() => handleButtonClick(parsedItem.index, unit)}
                                                                disabled={loading}
                                                            >
                                                                {loading && selectedItemTypeIndex === parsedItem.index ? "Loading..." : item.title}
                                                                <svg width="26" height="26" viewBox="0 0 26 26" fill="none"
                                                                     xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M13 7L13 18M13 18L9 14M13 18L17 14"
                                                                          stroke="#0053A0" strokeWidth="1.5"
                                                                          strokeLinecap="round" strokeLinejoin="round"/>
                                                                    <circle cx="13" cy="13" r="12" stroke="#0053A0"
                                                                            strokeWidth="2"/>
                                                                </svg>
                                                            </button>
                                                            <div onClick={() => toggleVisibility(itemTypeKey)}
                                                                 className={isVisible(itemTypeKey) ? 'icon-arrow-light-step2-up-calc' : 'icon-arrow-light-step2-down-calc'}>
                                                                <IconArrowLightStpe2/>
                                                            </div>
                                                        </div>
                                                        <div className={`step2-content-list ${isVisible(itemTypeKey) ? 'visible' : 'hidden'}`}>
                                                            <div className="step2-tooltip-container">
                                                                <span>QTY to order:</span>
                                                                <span className="dashed-line"></span>
                                                                <input
                                                                    type="number"
                                                                    className="step2-other-width-input"
                                                                    value={debouncedOrderedQty[selectedItem.clone_id] ?? item.ordered_qty}
                                                                    onChange={(e) => handleQtyChange(selectedItem.clone_id, e.target.value)}
                                                                    min="0"
                                                                />
                                                            </div>
                                                            <p className="step2-other-width-list">
                                                                <span>QTY ordered:</span>
                                                                <span className="dashed-line"></span>
                                                                {item.user_item_qty || item.qty} (psc)
                                                            </p>
                                                            <p className="step2-other-width-list">
                                                                <span>Unit price:</span>
                                                                <span className="dashed-line"></span>
                                                                R {parseInt(String(item.price)).toFixed(2)}
                                                            </p>
                                                            <p className="step2-other-width-list">
                                                                <span>Total:</span>
                                                                <span className="dashed-line"></span>
                                                                R {totalItemPrice.toFixed(2)}
                                                            </p>
                                                            <p className="step2-other-width-list-total total-ordred-bold">
                                                                <span>Total ordered:</span>
                                                                <span className="dashed-line"></span>
                                                                R {Math.max(0, totalItemPrice).toFixed(2)}
                                                            </p>
                                                            <div className="step2-other-width-vendor-list">
                                                                {item.vendor !== null && vendorData[item.vendor as Vendor] ? (
                                                                    <p className="item-selection-modal-vendor">{vendorData[item.vendor as Vendor].displayName}</p>
                                                                ) : (
                                                                    <p className="item-selection-modal-no-vendor">No Vendor</p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                </>
            )}
            <ItemSelectionModal
                itemTypeUnit={itemTypeUnit ? itemTypeUnit : ''}
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                items={items}
                loading={loading}
                handleItemClick={(items) => handleItemClick(items, selectedItemTypeIndex)}
                selectedItems={selectedItems.filter(item => item.index === selectedItemTypeIndex)}
                setSelectedItems={setSelectedItems}
                onReload={handleReload}
                currentPage={currentPage}
                totalItems={totalItems}
                itemsPerPage={itemsPerPage}
                onPageChange={handlePageChange}
                onSearch={handleSearch}
                totalSearchResults={totalItems}
                storeId={storeId}
            />

            <div className="step2-bottom-block">
                {!isSmallScreen ? (
                    <>
                        <p>Total:</p>
                        {totalPrice ? <span> R {totalPrice.toFixed(2)}</span> : <span> R 0</span>}
                        <button className="step2-bottom-block-btn-cart-list" onClick={handleSave}>Save</button>
                    </>
                ) : (
                    <>
                        <div className="step2-bottom-block-mobile">
                            <p>Total: </p>
                            <span className="dashed-line"></span>
                            {totalPrice ? <span className="mobile-total-price-red"> R {totalPrice.toFixed(2)}</span> :
                                <span className="mobile-total-price-red"> R 0</span>}
                        </div>
                        <button className="step2-bottom-block-btn-cart-list" onClick={handleSave}>Save</button>
                    </>
                )}
            </div>
        </div>
    );
};

const mapDispatchToProps = { updateLocalTemplate };
const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(LaborItemTypeListCalculation);
