import React, { useState } from 'react';
import './Accordion.css';
import {IconArrowDownLanding, IconArrowUpLanding} from "../../IconComponents/IconComponents";

interface FAQSectionProps {
    question: string;
    answer: string;
}

const AccordionItem: React.FC<FAQSectionProps> = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={`accordion-item ${isOpen ? 'open' : ''}`}>
            <div className="accordion-header" onClick={toggleAccordion}>
                <p>{question}</p>
                <button className="accordion-toggle">
                    {isOpen ? <IconArrowUpLanding/> : <IconArrowDownLanding/>}
                </button>
            </div>
            {isOpen && <div className="accordion-body"><p>{answer}</p></div>}
        </div>
    );
};

interface FAQSectionPropsForm {
    formRef?: React.RefObject<HTMLDivElement>;
}

const FAQSection: React.FC<FAQSectionPropsForm> = ({ formRef }) => {
    const questionsAnswers = [
        { question: 'Lorem ipsum sit dalor amet', answer: 'Lorem ipsum sit dalor amet lorem ipsum sit dalor amet lorem ipsum sit dalor amet.' },
        { question: 'Lorem ipsum sit dalor amet', answer: 'Lorem ipsum sit dalor amet lorem ipsum sit dalor amet lorem ipsum sit dalor amet.' },
        { question: 'Lorem ipsum sit dalor amet', answer: 'Lorem ipsum sit dalor amet lorem ipsum sit dalor amet lorem ipsum sit dalor amet.' },
        { question: 'Lorem ipsum sit dalor amet', answer: 'Lorem ipsum sit dalor amet lorem ipsum sit dalor amet lorem ipsum sit dalor amet.' },
    ];
    const scrollToForm = () => {
        formRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };
    return (
        <section className='landing-section-question'>
            <div className='landing-block-question'>
                <p className='upper-text-bold'>Do You Have Any Questions?</p>
                <p className='landing-video-section-text-info'>
                    Lorem ipsum sit dalor amet lorem ipsum sit dalor amet lorem ipsum sit dalor amet lorem ipsum sit dalor amet.
                    Lorem ipsum sit dalor amet. Lorem ipsum sit dalor amet lorem ipsum sit dalor amet lorem ipsum sit dalor amet
                    lorem ipsum sit dalor amet. Lorem ipsum sit dalor amet.
                </p>
                <button
                    onClick={scrollToForm}
                    className='upper-get-started'>Contact Us</button>
            </div>
            <div className='accordion-container'>
                {questionsAnswers.map((item, index) => (
                    <AccordionItem key={index} question={item.question} answer={item.answer} />
                ))}
            </div>
        </section>
    );
};

export default FAQSection;
