// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.guide-header{
    padding-top: 100px;
    position: relative;
}

.guide-header-scip {
    width: 120px;
    height: 40px;
    border-radius: 40px;
    border: 2px solid var(--basic-color);
    background-color: var(--basic-color);
    color: white;
    font-weight: 600;
    font-size: 16px;
    transition: 0.3s;
    cursor: pointer;
    position: absolute;
    top: 50px;
    right:0;
}

.guide-header-scip:hover {
    background-color: white;
    color: var(--basic-color);
    border: 2px solid var(--basic-color);
}

.guide-header-block{
    display: flex;
    gap: 45px;
    justify-content: center;
}
.guide-header-icon{
    display: flex;
    gap: 10px;
}
.guide-header-text{
    font-size: 20px;
    font-family: var(--font-roboto),sans-serif;
    color: var(--basic-text-description-color);
}


.guide-icon{
    cursor: pointer;
    position: relative;
}
.guide-header-line {
    position: absolute;
    top: 50%;
    left: 75px;
    width: 35px;
    height: 2px;
    background-color: #ADADAD;
    transition: background-color 0.3s;
}

.guide-header-line.completed {
    background-color: var(--basic-color);
}`, "",{"version":3,"sources":["webpack://./src/Page/BOMGuide/GuideStyles/GuideHeader.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,oCAAoC;IACpC,oCAAoC;IACpC,YAAY;IACZ,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,eAAe;IACf,kBAAkB;IAClB,SAAS;IACT,OAAO;AACX;;AAEA;IACI,uBAAuB;IACvB,yBAAyB;IACzB,oCAAoC;AACxC;;AAEA;IACI,aAAa;IACb,SAAS;IACT,uBAAuB;AAC3B;AACA;IACI,aAAa;IACb,SAAS;AACb;AACA;IACI,eAAe;IACf,0CAA0C;IAC1C,0CAA0C;AAC9C;;;AAGA;IACI,eAAe;IACf,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,QAAQ;IACR,UAAU;IACV,WAAW;IACX,WAAW;IACX,yBAAyB;IACzB,iCAAiC;AACrC;;AAEA;IACI,oCAAoC;AACxC","sourcesContent":[".guide-header{\n    padding-top: 100px;\n    position: relative;\n}\n\n.guide-header-scip {\n    width: 120px;\n    height: 40px;\n    border-radius: 40px;\n    border: 2px solid var(--basic-color);\n    background-color: var(--basic-color);\n    color: white;\n    font-weight: 600;\n    font-size: 16px;\n    transition: 0.3s;\n    cursor: pointer;\n    position: absolute;\n    top: 50px;\n    right:0;\n}\n\n.guide-header-scip:hover {\n    background-color: white;\n    color: var(--basic-color);\n    border: 2px solid var(--basic-color);\n}\n\n.guide-header-block{\n    display: flex;\n    gap: 45px;\n    justify-content: center;\n}\n.guide-header-icon{\n    display: flex;\n    gap: 10px;\n}\n.guide-header-text{\n    font-size: 20px;\n    font-family: var(--font-roboto),sans-serif;\n    color: var(--basic-text-description-color);\n}\n\n\n.guide-icon{\n    cursor: pointer;\n    position: relative;\n}\n.guide-header-line {\n    position: absolute;\n    top: 50%;\n    left: 75px;\n    width: 35px;\n    height: 2px;\n    background-color: #ADADAD;\n    transition: background-color 0.3s;\n}\n\n.guide-header-line.completed {\n    background-color: var(--basic-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
