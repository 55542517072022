// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accordion-item {
    background-color: var(--basic-btn-dark-color);
    margin: 10px 0;
    width: 745px;
    border-radius: 5px;
    overflow: hidden;
    transition: background-color 0.3s ease;
}

.accordion-item.open {
    background-color: var(--basic-btn-dark-color);

}

.accordion-header {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    cursor: pointer;
    font-weight: bold;
    align-items: center;
    font-size: 20px;
}

.accordion-toggle {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.accordion-body {
    padding: 15px;
    background-color: #fff;
    border-top: 1px solid #d3d3d3;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    font-size: 20px;
    color: #666;
    margin-top: -5px;
}
.landing-section-question {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    width: 100%;
    gap: 20px;
    margin-top: 20px;
}

.landing-block-question {
    width: 715px;
}

.accordion-container {
    width: 715px;
}

`, "",{"version":3,"sources":["webpack://./src/components/LandingPageComponent/Accordion.css"],"names":[],"mappings":"AAAA;IACI,6CAA6C;IAC7C,cAAc;IACd,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;IAChB,sCAAsC;AAC1C;;AAEA;IACI,6CAA6C;;AAEjD;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,aAAa;IACb,eAAe;IACf,iBAAiB;IACjB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,eAAe;IACf,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,6BAA6B;IAC7B,8BAA8B;IAC9B,+BAA+B;IAC/B,eAAe;IACf,WAAW;IACX,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,8BAA8B;IAC9B,eAAe;IACf,WAAW;IACX,SAAS;IACT,gBAAgB;AACpB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".accordion-item {\n    background-color: var(--basic-btn-dark-color);\n    margin: 10px 0;\n    width: 745px;\n    border-radius: 5px;\n    overflow: hidden;\n    transition: background-color 0.3s ease;\n}\n\n.accordion-item.open {\n    background-color: var(--basic-btn-dark-color);\n\n}\n\n.accordion-header {\n    display: flex;\n    justify-content: space-between;\n    padding: 15px;\n    cursor: pointer;\n    font-weight: bold;\n    align-items: center;\n    font-size: 20px;\n}\n\n.accordion-toggle {\n    background: none;\n    border: none;\n    font-size: 20px;\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.accordion-body {\n    padding: 15px;\n    background-color: #fff;\n    border-top: 1px solid #d3d3d3;\n    border-bottom-left-radius: 5px;\n    border-bottom-right-radius: 5px;\n    font-size: 20px;\n    color: #666;\n    margin-top: -5px;\n}\n.landing-section-question {\n    display: flex;\n    justify-content: space-between;\n    padding: 20px 0;\n    width: 100%;\n    gap: 20px;\n    margin-top: 20px;\n}\n\n.landing-block-question {\n    width: 715px;\n}\n\n.accordion-container {\n    width: 715px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
