import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import './WarningPermissions.css';
import {IconWarning} from "../../../IconComponents/IconComponents";
import CheckUserPermission from "../CheckUserPermission/CheckUserPermission";

interface ModalDeleteProps {
    isOpen: boolean;
    onRequestClose: () => void;
}

const WarningPermissions: React.FC<ModalDeleteProps> = ({ isOpen, onRequestClose }) => {
    const [isCheckRoleModalOpen, setIsCheckRoleModalOpen] = useState(false);
    const handleCheckPermissionModalClose = () =>{
        setIsCheckRoleModalOpen(false);
    }
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
        return () => {
            document.body.style.overflow = '';
        };
    }, [isOpen]);
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className="warning-modal-permission"
            overlayClassName="overlay-warning-permissions"
        >
                <button className="close-button" onClick={onRequestClose}>&times;</button>
            <div className="warning-permissions">
                <div>
                    <IconWarning/>
                </div>
                <h3 className='warning-permissions-h3'>You can't change items in this project because you don't have
                    permission to do so!</h3>
                <p>You can contact the project owner to give you permission through the project settings.</p>
                <div className="confirmation-buttons-warning-permission">
                    <button className="cancel-button-warning-permission" onClick={()=>setIsCheckRoleModalOpen(true)}>Check my permissions</button>
                    <button className="confirm-button-warning-permission" onClick={onRequestClose}>OK</button>
                </div>
            </div>
            <CheckUserPermission isOpen={isCheckRoleModalOpen} onRequestClose={handleCheckPermissionModalClose} />
        </Modal>
    );
};

export default WarningPermissions;
