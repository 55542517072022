import React, {useEffect, useRef, useState} from 'react';
import './PageHome.css'
import {clearLocalStorageExceptToken} from "../../../utils/ClearLocalStorage";
import {clearTemplates} from "../../../Slice/LocalTemplate/LocalTemplateSlice";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

import CountInfoSection from "../../../components/LandingPageComponent/CountInfoSection";
import InfoBlockBlog from "../../../components/LandingPageComponent/InfoBlockBlog";
import {IconCheckboxLanding} from "../../../IconComponents/IconComponents";
import ReviewSlider from "../../../components/LandingPageComponent/ReviewsBlock";
import FAQSection from "../../../components/LandingPageComponent/FAQSection";
import SupportForm from "../../../components/LandingPageComponent/SupportForm";
import ScrollToTopButton from "../../../components/ScrollArrowUp/ScrollToTopButton";
import {get, post} from "../../../api/APIWraper";
import {toast} from "react-toastify";
import {
    notifySuccessOrderTemplate
} from "../../../components/Toast/AssisCreateToastNotification";

const BASE_CUSTOMER_URL = process.env.REACT_APP_BASE_URL_CUSTOMER


function PageHome() {
    const roleCheckIntervalRef = useRef<NodeJS.Timeout | null>(null);
    const formRef = useRef<HTMLDivElement | null>(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isMonthly, setIsMonthly] = useState(true);

    const handleToggle = () => {
        setIsMonthly(!isMonthly);
    };

    const navigateToAssistance = () => {
        clearLocalStorageExceptToken();
        dispatch(clearTemplates());

        const token = localStorage.getItem('Bearer');
        if (!token) {
            navigate('/auth/login', {state: {redirectTo: '/assistance'}});
        } else {
            navigate('/assistance');
        }
    };

    const handeBuy = async () => {
        const actualRole = localStorage.getItem('role')
        if (actualRole === ('Contractor' || 'SAU' || 'MAU')) {
            toast.warning('Your "contractor" plan has already been paid for')
            return
        }
        const checkToken = localStorage.getItem('Bearer') || sessionStorage.getItem('Bearer');
        const scrollPosition = window.scrollY;
        const currentPath = window.location.pathname;

        if (!checkToken) {
            toast.error('You must authorization');

            navigate('/auth/login', {state: {from: currentPath, scrollPosition}});
            return;
        }
        const response = await post(`${BASE_CUSTOMER_URL}/subscription/contractor/monthly`);
        if (response.status === 200) {
            const sessionToken = sessionStorage.getItem('Bearer');
            if (sessionToken) {
                sessionStorage.setItem('Bearer', sessionToken);
            }
            window.open(response.data.links[0].href, "_self");
            localStorage.setItem('redirectOnReturn', 'true');
        } else {
            toast.error('You must authorization');
        }
    };

    const startRoleCheckInterval = () => {
        if (roleCheckIntervalRef.current) {
            clearInterval(roleCheckIntervalRef.current);
        }

        roleCheckIntervalRef.current = setInterval(async () => {
            try {
                const responseMe = await get(`${process.env.REACT_APP_BASE_URL_CUSTOMER}/my_profile_info`);
                const serverRole = responseMe.data.role.role;
                const localRole = localStorage.getItem('role');

                if (localRole !== serverRole) {
                    localStorage.setItem('role', serverRole);

                    clearInterval(roleCheckIntervalRef.current as NodeJS.Timeout);
                    roleCheckIntervalRef.current = null;

                    notifySuccessOrderTemplate(`You have successfully purchased a "${serverRole}" plan`);
                    setTimeout(() => {
                        window.location.href = '/assistance';
                    }, 2500);
                }
            } catch (error) {
                console.error("Error fetching user role:", error);
            }
        }, 5000);
    };

    const handleVisibilityChange = () => {
        if (document.visibilityState === 'visible') {
            const redirectOnReturn = localStorage.getItem('redirectOnReturn');
            if (redirectOnReturn === 'true') {
                localStorage.removeItem('redirectOnReturn');
                startRoleCheckInterval();
            }
        }
    };

    useEffect(() => {
        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            if (roleCheckIntervalRef.current) {
                clearInterval(roleCheckIntervalRef.current);
                roleCheckIntervalRef.current = null;
            }
        };
    }, []);

    const structuralCard = () => {
        return (
            <>
                <div className="card-title">Structural</div>
                <div className='card-title-desc'>
                    Manual Measurement Insertion
                    Users can select projects, input measurements of required building project, customize
                    materials, and receive a comprehensive cost breakdown.
                </div>
                <div className="card-price">$12.50<span className='card-price-time'>/month</span></div>
                <div className="card-description">Unlimited Measuring</div>
                <ul className='card-list'>
                    <li><span><IconCheckboxLanding/></span> Project creation</li>
                    <li><span><IconCheckboxLanding/></span> Template selection</li>
                    <li><span><IconCheckboxLanding/></span> Measurement insertion</li>
                    <li><span><IconCheckboxLanding/></span> BoM creation</li>
                    <li><span><IconCheckboxLanding/></span> Product selection from builders’ merchants</li>
                    <li><span><IconCheckboxLanding/></span> Contractors’ workspace</li>
                </ul>

            </>
        )
    }

    const arhitectCard = () => {
        return (
            <>
                <div className="card-title">Architect</div>
                <div className="card-title-desc">
                    3D digital Revit architectural plans Integration: Architects can upload 3D digital Revit
                    architectural plans and instantly receive detailed cost estimates.
                </div>
                <div className="card-price">$1000<span className="card-price-time">/year</span></div>
                <div className="card-description">Unlimited Measuring</div>
                <ul className="card-list">
                    <li><span><IconCheckboxLanding/></span> Project creation</li>
                    <li><span><IconCheckboxLanding/></span> Template selection</li>
                    <li><span><IconCheckboxLanding/></span> BoM creation</li>
                    <li><span><IconCheckboxLanding/></span> Product selection from builders’ merchants</li>
                    <li><span><IconCheckboxLanding/></span> Contractors’ workspace</li>
                </ul>

            </>
        )
    }

    return (
        <main className='landing-page'>
            <section className='landing-upper-section'>
                <div className='upper-block-text'>
                    <p className='upper-text-bold'>
                        What materials do I need, and how much will it cost?
                    </p>
                    <p className='upper-text-info'>
                        Whether you’re building, renovating, or tackling a small project like a wall or outdoor space,
                        everyone asks these questions.
                    </p>
                    <div className='upper-block-btn'>
                        <button onClick={navigateToAssistance} className='upper-get-started'>Get Started</button>
                        <button className='upper-see-works'> See how it works <span
                            className="arrow-header">-&gt;</span></button>
                    </div>
                </div>
                <div className='upper-block-image'>
                    <img className='upper-block-image-screen0' src='/img_landing/tablet.png' alt='teablet'/>
                    <img className='upper-block-image-screen1' src='/img_landing/screenBig.png' alt='pop up member'/>
                    <img className='upper-block-image-screen2' src='/img_landing/Addtemplate2.png'
                         alt='project add info'/>
                    <img className='upper-block-image-screen3' src='/img_landing/Addtemplate1.png'
                         alt='project add to project grout'/>
                </div>
            </section>
            <div className='landing-middle-info-nav'>
                <p>About Us</p>
            </div>
            <CountInfoSection iconVisible={false}/>
            <InfoBlockBlog/>

            <section className='about-us-section-partners'>
                <div className='about-us-partners-block'>
                    <div className='about-us-info-partners-text'>
                        <p className='about-us-info-block-text-bold'>Our Partners</p>
                        <p className='about-us-info-block-text-partners'>Lorem ipsum sit dalor amet lorem ipsum sit
                            dalor amet lorem ipsum sit dalor amet lorem ipsum sit dalor amet. Lorem ipsum sit dalor
                            amet. </p>
                        <p><img src='/img_landing/autodesk.png' alt='autodesc'/></p>
                    </div>

                    <div className='about-us-info-block-second'></div>
                </div>
                <img className='about-us-image' src='/img_landing/partners.png' alt='partners'/>
            </section>

            <div className='landing-middle-info-nav'>
                <p>Functionality</p>
            </div>

            <section className='landing-video-section'>
                <div className='upper-block-video'>
                    <iframe
                        width="770"
                        height="430"
                        src="https://www.youtube.com/embed/tO01J-M3g0U"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>

                </div>
                <div className='upper-block-text'>
                    <p className='upper-text-bold'>
                        GoBuild BoM (Bill of Materials) provides the answer
                    </p>
                    <p className='landing-video-section-text-info'>
                        Simply upload your 3D Revit plans or enter your project measurements, and GoBuild BoM generates
                        a detailed list of materials, labor estimates, and costs—making construction planning easier and
                        more efficient.
                    </p>
                    <div className='upper-block-btn'>
                        <button className='upper-get-started'>Read More</button>
                        <button className='upper-see-works'> Watch video <span
                            className="arrow-header">-&gt;</span></button>
                    </div>
                </div>
            </section>

            <div className='landing-middle-info-nav'>
                <p className='landing-middle-info-nav-get-start'>Let's get started</p>
                <p className='landing-middle-info-nav-info'>Choose the best plan that fit for you.</p>
            </div>

            <div className="landing-middle-info-switcher">
                <span className={`landing-middle-info-switcher-option ${isMonthly ? 'active' : ''}`}>
                    Monthly
                </span>
                <div className="landing-middle-info-switcher-toggle" onClick={handleToggle}>
                    <div className={`landing-middle-info-switcher-knob ${isMonthly ? '' : 'active'}`}></div>
                </div>
                <span className={`landing-middle-info-switcher-option ${!isMonthly ? 'active' : ''}`}>
                    Yearly
                </span>
            </div>

            <section className='section-with-card'>
                <div className="card-container">
                    <div
                        className="card card-green hover-effect"
                        style={!isMonthly ? {filter: 'blur(8px)'} : undefined}

                    >
                        <div className="card-title">Foundation</div>
                        <div className='card-title-desc'>Access to basic DIY templates, change your measurements and get
                            a BoM
                        </div>
                        <p className='card-title-info-free'>Pay $10 per measurement on more advanced templates</p>
                        <div className="card-price">Free</div>
                        <div className="card-description">Manual Measurement Insertion</div>
                        <ul className='card-list'>
                            <li><span><IconCheckboxLanding/></span> Project creation</li>
                            <li><span><IconCheckboxLanding/></span> Template selection</li>
                            <li><span><IconCheckboxLanding/></span> Measurement insertion</li>
                            <li><span><IconCheckboxLanding/></span> BoM creation</li>
                            <li><span><IconCheckboxLanding/></span> Product selection from builders’ merchants</li>

                        </ul>
                        <button className="card-button card-btn-default">Get started</button>
                    </div>

                    <div className="card card-green card-recommended">
                        <div className="card-recommended-label">Recommended</div>
                        {isMonthly ? structuralCard() : arhitectCard()}
                        <button
                            onClick={handeBuy}
                            className="card-button ">Get started
                        </button>
                    </div>


                    <div
                        className={`card card-orange ${!isMonthly ? 'hover-effect' : ''}`}
                        style={{filter: 'blur(8px)'}}
                    >
                        {!isMonthly ? structuralCard() : arhitectCard()}
                        <button
                            className="card-button card-btn-default"
                            style={{pointerEvents: 'none', opacity: 0.5}}
                        >
                            Get started
                        </button>
                    </div>

                </div>
            </section>

            <ReviewSlider variant={'detailed'}/>

            <div className='landing-middle-info-support'>
                <p>Support</p>
                <p>Do you have any problem? </p>
                <p>Please submit a support request by filling out the form below.</p>
            </div>
            <SupportForm ref={formRef}/>

            <FAQSection formRef={formRef}/>


            <ScrollToTopButton/>
        </main>
    );
}

export default PageHome;
