import React, { forwardRef } from 'react';
import './SupportForm.css';

interface SupportFormProps {}

const SupportForm = forwardRef<HTMLDivElement, SupportFormProps>((props, ref) => {
    return (
        <section ref={ref} className='support-form-section'>
            <div className='support-form-blocks'>
                <p className='support-form-text'>Contact name</p>
                <input
                    type='text'
                    className='support-form-input'
                />
            </div>
            <div className='support-form-blocks'>
                <p className='support-form-text'>E-mail</p>
                <input
                    type='email'
                    className='support-form-input'
                />
            </div>
            <div className='support-form-blocks'>
                <p className='support-form-text'>Phone</p>
                <input
                    type='number'
                    className='support-form-input'
                />
            </div>
            <div className='support-form-blocks'>
                <p className='support-form-text'>Description</p>
                <textarea
                    className='support-form-textarea-description'
                ></textarea>
            </div>
            <div className='support-form-blocks-btn'>
                <button className='support-form-blocks-get-help'>Get help</button>
            </div>
        </section>
    );
});

export default SupportForm;
