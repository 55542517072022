import {BrowserRouter as Router, Route, Routes, Navigate, useLocation, matchPath} from 'react-router-dom';
import Home from "./Page/Home";
import ProjectAssistance from "./Page/Project Assistance/ProjectAssistance";
import AssisCreate from "./Page/Assistance Create Project/AssisCreate";
import Step1 from "./Page/Step1/Step1";
import Step2 from "./Page/Step2/Step2";
import Step3 from "./Page/Step3/Step3";
import ProjectPage from "./Page/Projects/ProjectPage";
import OrderHistory from "./Page/Order History/OrderHistory";
import Users from "./Page/ProjectEdit/ProjectEdit";
import TeamManagerPage from "./Page/TeamMeneger/TeamManagerPage";
import ProjectCosts from "./Page/ProjectCosts/ProjectCosts";
import UserRoleRouteMiddleware from "./Middlewares/UserRoleRouteMiddleware";
import SessionPolicy from "./Middlewares/SessionPolicy";
import { AuthPage } from "./Page/AuthPage/AuthPage";
import TemplagePage from "./Page/TemplateInfoPage/TemplagePage";
import ScrollToTop from "./utils/ScrolToTop";
import PageHome from "./Page/LandingPageHome/LandingHome/PageHome";
import AboutUs from "./Page/LandingPageHome/AboutUs/AboutUs";
import Functionality from "./Page/LandingPageHome/Functionality/Functionality";
import Support from "./Page/LandingPageHome/Support/Support";
import TopHeader from "./components/TopHeader/TopHeader";
import Step1BOMCreate from "./Page/Step1BOMCreate/Step1BOMCreate";
import { UserRoleEnum } from "./Enums/UserRoleEnum";
import Step2Bom from "./Page/Step2Bom/Step2Bom";
import Step3Bom from "./Page/Step3Bom/Step3Bom";
import Pricing from "./Page/LandingPageHome/Pricing/Pricing";
import SeoWrapper from "./utils/SeoWrapper";
import Footer from "./components/ProjectAssistanceFooter/Footer";
import ProjectsAllBom from "./Page/ProjectsAllBOM/ProjectsAllBom";
import LiveHelpNowChat from "./utils/LiveSupportUser";
import {io, Socket} from "socket.io-client";
import Step1Guide from "./Page/BOMGuide/Step1Guide";
import NewUserGuide from "./Page/NewUserGuide/NewUserGuide";
import Step2Guide from "./Page/BOMGuide/Step2Guide";
import GuideWrapper from "./Page/BOMGuide/GuideWrapper";
import BOMProjectEdit from "./Page/BOMProjectEdit/BOMProjectEdit";
import TemplateGroupPage from "./Page/TemplateGroupInfoPage/TemplateGroupPage";
import TopHeaderAfterAuth from "./components/TopHeaderAfterAuth/TopHeaderAfterAuth";
import Sidebar from "./components/TopHeaderAfterAuth/Sidebar/Sidebar";
import React from "react";
import ArhitectPage from "./Page/ArchitectPage/ArсhitectPage";
import ProjectsListArchitect from "./Page/ProjectsAllArchitect/ProjectsListArchitect";
import ArchitectProjectEdit from "./Page/ArchitectProjectEdit/ArchitectProjectEdit";
import Step2Architect from "./Page/Step2Architect/Step2Architect";
import Step3Architect from "./Page/Step3Architect/Step3Architect";
import PreferencesPage from "./Page/PreferencesPage/PreferencesPage";
import {useSelector} from "react-redux";
import {RootState} from "./store";


const SOCKET_SERVER_URL = process.env.REACT_APP_ADAPTER_SOCKET_LINK;
export const socket_items: Socket = io(SOCKET_SERVER_URL, {
    transports: ['websocket', 'polling'],
    withCredentials: false,
});

const AVAILABLE_EVERYONE_ROLE = [UserRoleEnum.SAU, UserRoleEnum.MAU, UserRoleEnum.Contractor, UserRoleEnum.Customer, UserRoleEnum.Architect];
const AVAILABLE_BOM_ROLE = [UserRoleEnum.Contractor, UserRoleEnum.SAU, UserRoleEnum.Architect];
const AVAILABLE_NEW_USER_ROLE = [UserRoleEnum.Customer];
const AVAILABLE_ARHITECT_ROLE = [UserRoleEnum.SAU, UserRoleEnum.Architect];

const protectedRoutes = [
    { path: "assistance", element: <ProjectAssistance />, roles: AVAILABLE_EVERYONE_ROLE },
    { path: "assistance/create/:projectId/from/:from", element: <AssisCreate />, roles: AVAILABLE_EVERYONE_ROLE },
    { path: "assistance/step1/template/:templateId/:indexTemplate/project/:projectId", element: <Step1 />, roles: AVAILABLE_EVERYONE_ROLE },
    { path: "assistance/step2/template/:indexTemplate/project/:projectId", element: <Step2 />, roles: AVAILABLE_EVERYONE_ROLE },
    { path: "assistance/step3/template/:indexTemplate/project/:projectId", element: <Step3 />, roles: AVAILABLE_EVERYONE_ROLE },
    { path: "projects", element: <ProjectPage />, roles: AVAILABLE_EVERYONE_ROLE },
    { path: "order_history", element: <OrderHistory />, roles: AVAILABLE_EVERYONE_ROLE },
    { path: "users/:projectId", element: <Users />, roles: AVAILABLE_EVERYONE_ROLE },
    { path: "team", element: <TeamManagerPage />, roles: AVAILABLE_EVERYONE_ROLE },
    { path: "costs/:projectId", element: <ProjectCosts />, roles: AVAILABLE_EVERYONE_ROLE },
    { path: "template/:tempId", element: <TemplagePage />, roles: AVAILABLE_EVERYONE_ROLE },
    { path: "template-group/:templateGroupId", element: <TemplateGroupPage />, roles: AVAILABLE_EVERYONE_ROLE },

    { path: "preferences", element: <PreferencesPage />, roles: AVAILABLE_EVERYONE_ROLE },


    { path: "bom-users/:projectId", element: <BOMProjectEdit />, roles: AVAILABLE_BOM_ROLE },
    { path: "assistance/step1Bom", element: <Step1BOMCreate />, roles: AVAILABLE_BOM_ROLE },
    { path: "assistance/projectsBom", element: <ProjectsAllBom />, roles: AVAILABLE_BOM_ROLE },

    // { path: "architect-page/:fileType/:urn", element: <ArhitectPage />, roles: AVAILABLE_EVERYONE_ROLE },
    // { path: "architect-page/step2/:urn", element: <Step2Architect />, roles: AVAILABLE_EVERYONE_ROLE },
    // { path: "architect-page/step3/:urn", element: <Step3Architect />, roles: AVAILABLE_EVERYONE_ROLE },
    // { path: "assistance/projects-architect", element: <ProjectsListArchitect />, roles: AVAILABLE_EVERYONE_ROLE },
    // { path: "architect-users/:projectId", element: <ArchitectProjectEdit />, roles: AVAILABLE_EVERYONE_ROLE },


    { path: "guide-new-user", element: <NewUserGuide />, roles: AVAILABLE_NEW_USER_ROLE },
];


function AppRoutes() {
    const location = useLocation();
    const landingPages = ["/home", "/aboutUs", "/functionality", "/pricing", "/support"];
    const isAuthPage = matchPath("/auth/*", location.pathname);
    const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
    const isLandingPage = landingPages.includes(location.pathname) || isAuthPage;

    const guideNewUser = location.pathname === "/guide-new-user";
    const guidePathMatch = location.pathname.startsWith("/guide");

    return (
        <>
            {
                isLandingPage || !isAuthenticated ? (
                    <TopHeader />
                ) : (
                    <>
                        <TopHeaderAfterAuth />
                        <Sidebar />
                    </>
                )
            }



            <Routes>
                <Route path="/" element={<SessionPolicy><Home /></SessionPolicy>}>
                    <Route index element={<Navigate to="/home" replace />} />
                    <Route path="home" element={<SeoWrapper><PageHome /></SeoWrapper>} />
                    <Route path="aboutUs" element={<SeoWrapper><AboutUs /></SeoWrapper>} />
                    <Route path="functionality" element={<SeoWrapper><Functionality /></SeoWrapper>} />
                    <Route path="pricing" element={<SeoWrapper><Pricing /></SeoWrapper>} />
                    <Route path="support" element={<SeoWrapper><Support /></SeoWrapper>} />
                    <Route path="create/step2Bom/:link" element={<SeoWrapper><Step2Bom /></SeoWrapper>} />
                    <Route path="create/step3Bom/:link" element={<SeoWrapper><Step3Bom /></SeoWrapper>} />

                    {protectedRoutes.map(({ path, element, roles }) => (
                        <Route
                            key={path}
                            path={path}
                            element={
                                <UserRoleRouteMiddleware redirectPath="/auth/login" allowedRoles={roles}>
                                    {element}
                                </UserRoleRouteMiddleware>
                            }
                        />
                    ))}
                </Route>

                <Route
                    path="/guide/*"
                    element={
                        <UserRoleRouteMiddleware redirectPath="/home" allowedRoles={AVAILABLE_BOM_ROLE}>
                            <GuideWrapper />
                        </UserRoleRouteMiddleware>
                    }
                >
                    <Route path="step1" element={<Step1Guide />} />
                    <Route path="step2" element={<Step2Guide />} />
                    <Route path="new-user" element={<NewUserGuide />} />
                </Route>

                <Route path="/auth/*" element={<AuthPage />}/>
                <Route path="*" element={<Navigate to="/home" replace />} />
            </Routes>
            {!guidePathMatch && !guideNewUser &&  <Footer />}
        </>
    );
}

function App() {
    const displayedLiveChat = process.env.REACT_APP_BUTTON_CLEAR === 'true'
    return (
        <Router>
            {!displayedLiveChat && (
                <LiveHelpNowChat/>
            )}
            <ScrollToTop />
            <AppRoutes />
        </Router>
    );
}

export default App;
