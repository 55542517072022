// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.preferencesPage{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 35px;
}
.preferences-section{
    width: 1165px;
}`, "",{"version":3,"sources":["webpack://./src/Page/PreferencesPage/styles/preferences.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,SAAS;AACb;AACA;IACI,aAAa;AACjB","sourcesContent":[".preferencesPage{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 35px;\n}\n.preferences-section{\n    width: 1165px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
