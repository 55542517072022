import React, {useState} from 'react';
import Modal from 'react-modal';
import './SelectItemModal.css';
import {BOMItem, BOMItemType} from "../../types/ProjectBOMInterface";
import {Vendor, vendorData} from "../../Enums/VendorEnum";

Modal.setAppElement('#root');

interface SelectItemModalProps {
    isOpen: boolean;
    onRequestClose: () => void;
    items: any[];
    onSelect: (selectedItem: any) => void;
    selectedItem: any;
}

const SelectItemModal: React.FC<SelectItemModalProps> = ({isOpen, onRequestClose, items, onSelect,selectedItem}) => {
    const [searchTerm, ] = useState('');

    const highlightMatch = (text: string, match: string) => {
        if (!match) return text;
        const regex = new RegExp(`(${match})`, 'gi');
        return text.replace(regex, (matched) => `<span class="item-selection-modal-highlight">${matched}</span>`);
    };
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Select Allowed Item"
            className="step2-bom-modal"
            overlayClassName="step2-bom-modal-overlay"
        >
            <div className="step2-bom-modal-header">
                <h2>Select an Allowed Product</h2>
                <button onClick={onRequestClose} className="step2-bom-modal-close-button">X</button>
            </div>

            <div className="step2-bom-modal-content">
                {items.map((item: BOMItem) => {
                    const isSelected = selectedItem && selectedItem.id === item.id;
                    return (
                    <div
                        key={item.id}
                        className='step2-bom-modal-item'
                    >
                        <img
                            className="step2-bom-modal-item-img"
                            src={
                                item.vendor === 'cashbuild'
                                    ? item.merchant_info?.image ||
                                    (item.galleries && item.galleries.length > 0 && item.galleries[0].medias.length > 0 && item.galleries[0].medias[item.galleries[0].medias.length - 1]?.url) ||
                                    '/img_vendor/item.png'
                                    : (item.galleries && item.galleries.length > 0 && item.galleries[0].medias.length > 0 && item.galleries[0].medias[item.galleries[0].medias.length - 1]?.url) ||
                                    '/img_vendor/item.png'
                            }
                            alt="Item"
                        />
                        <section className="item-selection-modal-list-item-info">
                            <p
                                className="item-selection-modal-item-title"
                                dangerouslySetInnerHTML={{
                                    __html: highlightMatch(item.title, searchTerm),
                                }}
                            />
                            <p className="item-selection-modal-item-price">
                                R {item.price ? Number(item.price).toFixed(2) : '0.00'}
                            </p>
                        </section>

                        <div className='item-packaging'>
                            {item.packaging && Object.keys(item.packaging).length > 0 ? (
                                <>
                                                        <span
                                                            className='item-packaging-value'>{item.packaging[Object.keys(item.packaging)[0]]}</span>
                                    <p className='item-packaging-key'>{Object.keys(item.packaging)[0]}</p>
                                </>
                            ) : (
                                <p className='item-packaging-none'>{item.unit}</p>
                            )}
                        </div>

                        <div className='item-selection-modal-action-block-img'>
                            {item.vendor !== null && vendorData[item.vendor as Vendor] ? (
                                <p className='item-selection-modal-vendor'>{vendorData[item.vendor as Vendor].displayName}</p>
                            ) : (
                                <p className='item-selection-modal-no-vendor'>No vendor</p>
                            )}
                        </div>
                        <button
                            onClick={() => onSelect(item)}
                            className={`item-selection-modal-button ${isSelected ? 'remove-item' : 'add-item'}`}
                            disabled={isSelected}
                        >
                            {isSelected ? 'Added' : 'Select'}
                            </button>


                    </div>
                )})}
            </div>
        </Modal>
    );
}

export default SelectItemModal;
