import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import './AssisTemplaeModal.css';
import { Template } from "../../../types/TemplateType";
import { useNavigate } from 'react-router-dom';
import {connect, ConnectedProps} from 'react-redux';
import { RootState } from '../../../store';
import {
    updateLocalTemplate,
    removeTemplate,
    removeCalculatedTemplate,
    clearTemplates,
    addTemplate
} from '../../../Slice/LocalTemplate/LocalTemplateSlice';
import { transformTemplate } from '../../../utils/TransformTemplate';
import DeleteModal from "../ModalDelete/ModalDelete";
import {clearLocalStorageExceptToken} from "../../../utils/ClearLocalStorage";
import {IconCalculation,  IconRemoveTemplateModal} from "../../../IconComponents/IconComponents";
import {updatedProject} from "../../../Slice/projectById/projectByIdSlice";

interface AssisCreateModalProps {
    isOpen: boolean;
    onClose: () => void;
    templates: Template[];
    onSave: () => void;
    onRemoveTemplate: (templateId: number) => void;
    projectId: number;
    selectedTemplates:Template[];
}

interface TemplateWithCalculated extends Template {
    isCalculated: boolean;
}

const AssisTemplaeModal: React.FC<PropsFromRedux & AssisCreateModalProps> = ({
                                                                                 clearTemplates,
                                                                                 isOpen,
                                                                                 onClose,
                                                                                 templates,
                                                                                 onSave,
                                                                                 onRemoveTemplate,
                                                                                 projectId,
                                                                                 storedCalculatedTemplates,
                                                                                 updatedProject,
                                                                                 addTemplate,
                                                                                 selectedTemplates
                                                                             }) => {
    const [templatesWithCalculated, setTemplatesWithCalculated] = useState<TemplateWithCalculated[]>([]);
    const [templateToDelete, setTemplateToDelete] = useState<TemplateWithCalculated | null>(null);
    const [isSmallScreenButton, setIsSmallScreenButton] = useState<boolean>(window.innerWidth <= 600);
    const navigate = useNavigate();
    const handleResize = () => {
        setIsSmallScreenButton(window.innerWidth <= 600)
    };
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const updatedTemplates = templates.map(template => ({
            ...template,
            isCalculated: storedCalculatedTemplates.includes(template.id) || JSON.parse(localStorage.getItem(`selectedTemplate-${template.id}`) || 'false')
        }));
        setTemplatesWithCalculated(updatedTemplates);
    }, [templates, storedCalculatedTemplates]);

    useEffect(() => {
        localStorage.setItem('projectId', projectId.toString());
    }, [projectId]);

    const handleCalculateClick = (template: TemplateWithCalculated, index:number) => {
        localStorage.setItem(`selectedTemplate-${template.id}`, JSON.stringify(template));
        const transformedTemplates = templatesWithCalculated.map((template) => transformTemplate(template));
        const updatedProjectData = {
            data: {
                templates: transformedTemplates
            }
        };
        updatedProject({ projectId, projectData: updatedProjectData });
        clearTemplates()
        clearLocalStorageExceptToken()
        onClose();
        setTimeout(() => {
            navigate(`/assistance/step1/${template.id}`, {
                state: {
                    projectId,
                    template:template,
                    templIndex:index
                }
            });
        }, 1000);
    };

    const handleRemoveTemplate = (template: TemplateWithCalculated) => {
        localStorage.removeItem(`selectedTemplate-${template.id}`);
        removeTemplate(template.id);
        removeCalculatedTemplate(template.id);
        onRemoveTemplate(template.id);
        setTemplatesWithCalculated(prevTemplates => {
            const updatedTemplates = prevTemplates.filter(t => t.id !== template.id);
            if (updatedTemplates.length === 0) {
                onClose();
            }
            return updatedTemplates;
        });
        setTemplateToDelete(null);
    };

    const handleSave = () => {
        const transformedTemplates = templatesWithCalculated.map(template => transformTemplate(template));
        const updatedProjectData = {
            data: {
                templates: transformedTemplates
            }
        };
        updatedProject({ projectId, projectData: updatedProjectData });
        if (selectedTemplates.length === 1){
            navigate(`/assistance/step1/template/${selectedTemplates[0].id}/0/project/${projectId}`);
        } else {
            navigate(`/costs/${projectId}`);
        }
        clearTemplates();
        templatesWithCalculated.forEach(template => addTemplate(template));
        onSave();
        onClose();
    };
    const isSaveDisabled = templatesWithCalculated.length === 0;
    return (
        <>
            <Modal isOpen={isOpen} onRequestClose={onClose} className="selected-templates-modal" overlayClassName="overlay">
                <div className="modal-header">
                    <button className="close-button" onClick={onClose}>&times;</button>
                </div>
                <div className="modal-content">
                    <div className="templates-table">
                        <div className='assis-modal-info'>
                            <p>Your template</p>
                            <p>Category</p>
                            <p>Options</p>
                        </div>
                        <div>
                            {templatesWithCalculated.map((template,index,) => (
                                <div
                                    className={`assis-modal-content ${template.isCalculated ? 'template-calculated' : ''}`}
                                    key={template.id}>
                                    <p>{template.title}</p>
                                    <p>{template.categories && template.categories.length > 0 ? template.categories[0].category : 'Other'}</p>
                                    {!isSmallScreenButton ? (
                                        <>
                                            <button className="calculate-btn"
                                                    onClick={() => handleCalculateClick(template, index)}>
                                                Calculate
                                            </button>
                                            <div className='assis-action-block'>
                                                <button className="modal-template-btn"
                                                        onClick={() => setTemplateToDelete(template)}>
                                                    <div className='delete-modal-template '><IconRemoveTemplateModal/>
                                                    </div>
                                                </button>
                                            </div>
                                        </>
                                    ) : (
                                        <div className='action-block-modal-template-mobile'>
                                            <button className="calculate-btn"
                                                    onClick={() => handleCalculateClick(template, index)}>
                                                <IconCalculation/>
                                            </button>
                                            <button
                                                className='delete-modal-template '
                                                onClick={() => setTemplateToDelete(template)}
                                            ><IconRemoveTemplateModal/>
                                            </button>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='assis-save-button'>
                        <button className={`save-button-template ${isSaveDisabled ? 'disabled' : ''}`} onClick={handleSave} disabled={isSaveDisabled}>Save</button>
                    </div>
                </div>
            </Modal>

            {templateToDelete && (
                <DeleteModal
                    isOpen={true}
                    onRequestClose={() => setTemplateToDelete(null)}
                    onConfirm={() => handleRemoveTemplate(templateToDelete)}
                    onCancel={() => setTemplateToDelete(null)}
                    message={`Are you sure you want to delete the template "${templateToDelete.title}"?`}
                    confirmText="Yes"
                    cancelText="No"
                />
            )}
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    storedCalculatedTemplates: state.localTemplate.calculatedTemplates,
});

const mapDispatchToProps = {
    updatedProject,
    clearTemplates,
    updateLocalTemplate,
    removeTemplate,
    removeCalculatedTemplate,
    addTemplate,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AssisTemplaeModal);