import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from "../../store";
import { connect, ConnectedProps } from "react-redux";
import './Step1Bom.css';
import 'react-toastify/dist/ReactToastify.css';
import { updateLocalTemplate } from '../../Slice/LocalTemplate/LocalTemplateSlice';
import { clearLocalStorageExceptToken } from "../../utils/ClearLocalStorage";
import MobileNavigation from "../../components/MobileNavigation/MobileNavigation";
import {
    IconBomSettingItem,
    IconBomSettingItemType,
    IconPlusStpe2,
    IconRemoveItem,
    IconRemoveItemType
} from "../../IconComponents/IconComponents";
import ItemTypeBomModal from "./ModalsBom/ItemTypeBomModal";
import ItemsBomModal from "./ModalsBom/ItemsBomModal";
import { ItemType, TItem } from "../../types/TemplateType";
import { get, getProjectBomById, post, postCreateBom, patchCreateBom } from "../../api/APIWraper";
import CreateProjectBom, { AddressData, FileData } from "./CreateProjectBom/CreateProjectBom";
import { PostFormData } from "../../api/ApiWrapperImage";
import { toast } from "react-toastify";
import { BOMItem, BOMItemType, BOMProject } from "../../types/ProjectBOMInterface";
import {Vendor, vendorData} from "../../Enums/VendorEnum";

const BASE_URL_CONTRACTOR = process.env.REACT_APP_BASE_URL_CONTRACTOR;

interface ModalItemState<T> {
    isOpen: boolean;
    loading: boolean;
    items: T[];
    selectedItems: T[];
    currentPage: number;
    totalItems: number;
    searchTerm: string;
    sortDirection: 'asc' | 'desc';
}

interface ModalState {
    itemType: ModalItemState<ItemType>;
    itemBom: ModalItemState<TItem>;
}

interface SelectedItemsMap {
    [itemTypeIndex: number]: TItem[];
}

const Step1BOMCreate: React.FC<PropsFromRedux> = () => {
    const navigate = useNavigate();

    const [modalState, setModalState] = useState<ModalState>({
        itemType: {
            isOpen: false,
            loading: false,
            items: [],
            selectedItems: [],
            currentPage: 1,
            totalItems: 0,
            searchTerm: '',
            sortDirection: 'asc',
        },
        itemBom: {
            isOpen: false,
            loading: false,
            items: [],
            selectedItems: [],
            currentPage: 1,
            totalItems: 0,
            searchTerm: '',
            sortDirection: 'asc',
        }
    });

    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerWidth <= 920);
    const [itemTypeQty, setItemTypeQty] = useState<number>(0);
    const [selectedItemsMap, setSelectedItemsMap] = useState<SelectedItemsMap>({});
    const [currentItemTypeIndex, setCurrentItemTypeIndex] = useState<number | null>(null);

    const [getProject, setGetProject] = useState<BOMProject | null>(null);
    const [getProjectHas, setGetProjectHas] = useState<boolean>(false);

    const [isReadOnly, setIsReadOnly] = useState<boolean>(false);
    const [hasGotClientLink, setHasGotClientLink] = useState<boolean>(false);
    const [clientLink, setClientLink] = useState<string>('');

    const [, setRequiredQty] = useState<boolean>(false);

    const [projectData, setProjectData] = useState<AddressData>({
        address: {
            full_address: '',
            latitude: '',
            longitude: ''
        },
        target_date: '',
        title: ''
    });
    const [projectImg, setProjectImg] = useState<File[] | null>(null);
    const [projectId, setProjectId] = useState<number | null>(null);

    const [itemTypeCount, setItemTypeCount] = useState<{ [key: number]: number }>({});


    const location = useLocation();
    const { id } = location.state || {};


    useEffect(() => {
        const totalQty = Object.values(selectedItemsMap)
            .flat()
            .reduce((sum, item) => sum + item.qty, 0);

        setRequiredQty(totalQty > 0);
    }, [selectedItemsMap]);

    useEffect(() => {
        const newItemTypeCount: { [key: number]: number } = {};
        modalState.itemType.selectedItems.forEach(itemType => {
            newItemTypeCount[itemType.id] = (newItemTypeCount[itemType.id] || 0) + 1;
        });
        setItemTypeCount(newItemTypeCount);
    }, [modalState.itemType.selectedItems]);



    useEffect(() => {
        const storedProjectId = localStorage.getItem('projectId');

        if (storedProjectId) {
            const fetchProject = async () => {
                try {
                    const response = await getProjectBomById(storedProjectId);
                    setGetProject(response);
                    setGetProjectHas(!!response.link);
                    if (response.link) {
                        setClientLink(response.link);
                    }
                    setProjectId(Number(storedProjectId));

                    const storedHasGotClientLink = localStorage.getItem(`hasGotClientLink_${storedProjectId}`);
                    if (storedHasGotClientLink === 'true') {
                        setIsReadOnly(true);
                        setHasGotClientLink(true);
                        setClientLink(response.link);
                    } else {
                        setIsReadOnly(false);
                        setHasGotClientLink(false);
                    }

                    if (!response.link) {
                        const selectedItemTypes: ItemType[] = response.data.item_types;
                        setModalState(prev => ({
                            ...prev,
                            itemType: {
                                ...prev.itemType,
                                selectedItems: selectedItemTypes,
                            }
                        }));

                        const initialItemTypeCount: { [key: number]: number } = {};
                        selectedItemTypes.forEach(itemType => {
                            initialItemTypeCount[itemType.id] = (initialItemTypeCount[itemType.id] || 0) + 1;
                        });
                        setItemTypeCount(initialItemTypeCount);

                        const initialSelectedItemsMap: SelectedItemsMap = {};
                        selectedItemTypes.forEach((itemType, index) => {
                            initialSelectedItemsMap[index] = [];

                            if (itemType.item) {
                                initialSelectedItemsMap[index].push({
                                    ...itemType.item,
                                    source: 'add',
                                    qty: itemType.qty || 0,
                                });
                            }

                            if (itemType.allowed_items && itemType.allowed_items.length > 0) {
                                const checkboxItems = itemType.allowed_items.map((item: { qty: any; }) => ({
                                    ...item,
                                    source: 'checkbox',
                                    qty: item.qty || 0,
                                }));
                                initialSelectedItemsMap[index].push(...checkboxItems);
                            }
                        });
                        setSelectedItemsMap(initialSelectedItemsMap);
                    }
                } catch (error) {
                    console.error("Error fetching project BOM:", error);
                }
            };

            fetchProject();
        } else if (id) {
            const fetchProject = async () => {
                try {
                    const response = await getProjectBomById(id);
                    setGetProject(response);
                    setGetProjectHas(!!response.link);
                    if (response.link) {
                        setClientLink(response.link);
                    }
                    setProjectId(id);
                    localStorage.setItem('projectId', id.toString());

                    const storedHasGotClientLink = localStorage.getItem(`hasGotClientLink_${id}`);
                    if (storedHasGotClientLink === 'true') {
                        setIsReadOnly(true);
                        setHasGotClientLink(true);
                        setClientLink(response.link);
                    } else {
                        setIsReadOnly(false);
                        setHasGotClientLink(false);
                    }

                    if (!response.link) {
                        const selectedItemTypes: ItemType[] = response.data.item_types;
                        setModalState(prev => ({
                            ...prev,
                            itemType: {
                                ...prev.itemType,
                                selectedItems: selectedItemTypes,
                            }
                        }));

                        const initialItemTypeCount: { [key: number]: number } = {};
                        selectedItemTypes.forEach(itemType => {
                            initialItemTypeCount[itemType.id] = (initialItemTypeCount[itemType.id] || 0) + 1;
                        });
                        setItemTypeCount(initialItemTypeCount);

                        const initialSelectedItemsMap: SelectedItemsMap = {};
                        selectedItemTypes.forEach((itemType, index) => {
                            initialSelectedItemsMap[index] = [];

                            if (itemType.item) {
                                initialSelectedItemsMap[index].push({
                                    ...itemType.item,
                                    source: 'add',
                                    qty: itemType.qty || 0,
                                });
                            }

                            if (itemType.allowed_items && itemType.allowed_items.length > 0) {
                                const checkboxItems = itemType.allowed_items.map((item: { qty: any; }) => ({
                                    ...item,
                                    source: 'checkbox',
                                    qty: item.qty || 0,
                                }));
                                initialSelectedItemsMap[index].push(...checkboxItems);
                            }
                        });
                        setSelectedItemsMap(initialSelectedItemsMap);
                    }
                } catch (error) {
                    console.error("Error fetching project BOM:", error);
                }
            };

            fetchProject();
        }
    }, [id]);


    useEffect(() => {
        return () => {
            localStorage.removeItem('projectId');
        };
    }, [projectId]);

    const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 920);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleItemTypeCountChange = (itemId: number, count: number) => {
        setItemTypeCount(prev => ({
            ...prev,
            [itemId]: (prev[itemId] || 0) + count
        }));
    };


    const navigateToAssistance = () => {
        clearLocalStorageExceptToken();
        navigate('/assistance');
    };

    const handleCloseModal = (modal: keyof ModalState) => {
        setModalState(prev => ({
            ...prev,
            [modal]: { ...prev[modal], isOpen: false }
        }));
    };

    const handlePageChange = (modal: keyof ModalState, pageNumber: number) => {
        setModalState(prev => ({
            ...prev,
            [modal]: { ...prev[modal], currentPage: pageNumber }
        }));
        let itemTypeId: number | undefined;
        if (modal === 'itemBom' && currentItemTypeIndex !== null) {
            itemTypeId = modalState.itemType.selectedItems[currentItemTypeIndex].id;
        }
        fetchItems(modal, pageNumber, modalState[modal].searchTerm, modalState[modal].sortDirection, modal === 'itemBom' ? itemTypeId : undefined);
    };

    const handleReload = (modal: keyof ModalState) => {
        let itemTypeId: number | undefined;
        if (modal === 'itemBom' && currentItemTypeIndex !== null) {
            itemTypeId = modalState.itemType.selectedItems[currentItemTypeIndex].id;
        }
        fetchItems(modal, modalState[modal].currentPage, modalState[modal].searchTerm, modalState[modal].sortDirection, modal === 'itemBom' ? itemTypeId : undefined);
    };

    const handleSortChange = (modal: keyof ModalState, direction: 'asc' | 'desc') => {
        setModalState(prev => ({
            ...prev,
            [modal]: { ...prev[modal], sortDirection: direction }
        }));
        let itemTypeId: number | undefined;
        if (modal === 'itemBom' && currentItemTypeIndex !== null) {
            itemTypeId = modalState.itemType.selectedItems[currentItemTypeIndex].id;
        }
        fetchItems(modal, modalState[modal].currentPage, modalState[modal].searchTerm, direction, modal === 'itemBom' ? itemTypeId : undefined);
    };

    const fetchItems = useCallback(async (
        modal: keyof ModalState,
        page: number = 1,
        searchTerm: string = '',
        sortDir: "asc" | "desc" = 'asc',
        itemTypeId?: number | null | undefined
    ) => {
        setModalState(prev => ({
            ...prev,
            [modal]: { ...prev[modal], loading: true }
        }));

        try {
            let url = '';
            if (modal === 'itemType') {
                url = searchTerm
                    ? `${process.env.REACT_APP_BASE_URL_CUSTOMER}/item_types?page=${page}&perPage=5&title=${encodeURIComponent(searchTerm)}&sortOrder=${sortDir}`
                    : `${process.env.REACT_APP_BASE_URL_CUSTOMER}/item_types?page=${page}&perPage=5&sortOrder=${sortDir}`;
            } else if (modal === 'itemBom') {
                if (!itemTypeId) {
                    console.error("itemTypeId is required for fetching itemBom");
                    return;
                }
                url = searchTerm
                    ? `${process.env.REACT_APP_BASE_URL_CUSTOMER}/item_types/${itemTypeId}?page=${page}&perPage=5&title=${searchTerm}&sortOrder=${sortDir}`
                    : `${process.env.REACT_APP_BASE_URL_CUSTOMER}/item_types/${itemTypeId}?page=${page}&perPage=5`;
            }

            const response = await get(url);

            if (modal === 'itemType') {
                setModalState(prev => ({
                    ...prev,
                    itemType: {
                        ...prev.itemType,
                        items: response.data.data,
                        totalItems: response.data.pagination.lastPage,
                    }
                }));
            } else if (modal === 'itemBom') {
                setModalState(prev => ({
                    ...prev,
                    itemBom: {
                        ...prev.itemBom,
                        items: response.data.items.data,
                        totalItems: response.data.items.pagination.lastPage,
                    }
                }));
            }
        } catch (error) {
            console.error(`Failed to get elements for ${modal}:`, error);
        } finally {
            setModalState(prev => ({
                ...prev,
                [modal]: { ...prev[modal], loading: false }
            }));
        }
    }, [modalState, currentItemTypeIndex]);

    const handleSearch = (modal: keyof ModalState, query: string) => {
        setModalState(prev => ({
            ...prev,
            [modal]: { ...prev[modal], searchTerm: query, currentPage: 1 }
        }));
        let itemTypeId: number | undefined;
        if (modal === 'itemBom' && currentItemTypeIndex !== null) {
            itemTypeId = modalState.itemType.selectedItems[currentItemTypeIndex].id;
        }
        fetchItems(modal, 1, query, modalState[modal].sortDirection, modal === 'itemBom' ? itemTypeId : undefined);
    };

    const handleOpenModal = (modal: keyof ModalState, itemTypeIndex?: number, itemTypeQty?: number) => {
        if (modal === 'itemBom' && itemTypeIndex !== undefined) {
            setCurrentItemTypeIndex(itemTypeIndex);
        }
        setItemTypeQty(itemTypeQty ? itemTypeQty : 0);

        if (modal === 'itemType') {
            const selectedItems = modalState.itemType.selectedItems.reduce<ItemType[]>((acc, itemType) => {
                const count = itemTypeCount[itemType.id] || 0;
                for (let i = 0; i < count; i++) {
                    acc.push(itemType);
                }
                return acc;
            }, []);

            setModalState(prev => ({
                ...prev,
                itemType: { ...prev.itemType, isOpen: true, initialSelectedItems: selectedItems }
            }));
        } else {
            setModalState(prev => ({
                ...prev,
                [modal]: { ...prev[modal], isOpen: true }
            }));
        }

        const itemTypeId = itemTypeIndex !== undefined ? modalState.itemType.selectedItems[itemTypeIndex].id : undefined;
        fetchItems(modal, 1, modalState[modal].searchTerm, modalState[modal].sortDirection, modal === 'itemBom' ? itemTypeId : undefined);
    };


    const handleSelectItemsItemType = (selected: ItemType[]) => {
        setModalState(prev => ({
            ...prev,
            itemType: { ...prev.itemType, selectedItems: selected }
        }));

        const updatedSelectedItemsMap: SelectedItemsMap = {};
        selected.forEach((itemType, index) => {
            updatedSelectedItemsMap[index] = [];

            if (itemType.item) {
                updatedSelectedItemsMap[index].push({
                    ...itemType.item,
                    source: 'add',
                    qty: itemType.qty || 0,
                });
            }

            if (itemType.allowed_items && itemType.allowed_items.length > 0) {
                const checkboxItems = itemType.allowed_items.map((item: { qty: any; }) => ({
                    ...item,
                    source: 'checkbox',
                    qty: item.qty || 0,
                }));
                updatedSelectedItemsMap[index].push(...checkboxItems);
            }
        });
        setSelectedItemsMap(updatedSelectedItemsMap);
    };

    const handleSelectItemsItemBom = (selected: TItem[]) => {
        if (currentItemTypeIndex !== null) {
            setSelectedItemsMap(prev => ({
                ...prev,
                [currentItemTypeIndex]: selected
            }));
        }
    };

    const handleRemoveSelectedItem = (itemTypeIndex: number, itemIndex: number) => {
        setSelectedItemsMap(prev => {
            const updatedItems = prev[itemTypeIndex].filter((_, idx) => idx !== itemIndex);
            return {
                ...prev,
                [itemTypeIndex]: updatedItems,
            };
        });
    };

    const handleDataReady = (addressData: AddressData, fileData: FileData) => {
        setProjectData(addressData);
        setProjectImg(fileData.file);
    };

    const handleSaveProject = async () => {
        const itemTypesData = modalState.itemType.selectedItems.map((selectedItemType, index) => {
            const selectedItems = selectedItemsMap[index];

            if (selectedItems && selectedItems.length > 0) {
                const qtyItemType = selectedItems.find((item: TItem) => item.source === 'add')?.qty || 0;
                const itemDefaultId = selectedItems.find((item: TItem) => item.source === 'add')?.id;
                const allowedItemId = selectedItems.map((item: TItem) => item.id);
                return {
                    id: selectedItemType.id,
                    qty: qtyItemType,
                    item: itemDefaultId,
                    allowed_items: allowedItemId
                };
            } else {
                return {
                    id: selectedItemType.id,
                    allowed_items: []
                };
            }
        });
        const body = {
            address: projectData.address,
            title: projectData.title,
            target_date: projectData.target_date,
            data: {
                item_types: itemTypesData
            }
        };
        try {
            let response;
            if (projectId === null) {
                response = await postCreateBom(body);

                if(response.status === 400){
                    toast.error('You need to add the product for the product type and enter the qty',{autoClose:5000})
                }

                if (response.status === 201) {
                    setProjectId(response.data.id);
                    localStorage.setItem(`hasGotClientLink_${response.data.id}`, 'false');
                }

                if (response.status === 201 && projectImg) {
                    for (const imageFile of projectImg) {
                        await uploadProjectImage(response.data.id, imageFile);
                    }
                }
            } else {
                response = await patchCreateBom(body, projectId);
                if(response.status === 400){
                    toast.error('You need to add the product for the product type and enter the qty',{autoClose:5000})
                }

                if (response.status === 200) {
                    toast.success('Project updated successfully');
                }

                if (response.status === 200 && projectImg && projectId) {
                    for (const imageFile of projectImg) {
                        await uploadProjectImage(projectId, imageFile);
                    }
                }
            }

            return response.data;
        } catch (error) {
            if (projectId === null) {
                console.error("Error in handleCreateBomProject:", error);
                toast.error('Error creating project.');
            } else {
                console.error("Error in handleUpdateProject:", error);
                toast.error('Error updating project.');
            }
            throw error;
        }
    };

    const uploadProjectImage = async (projectId: number, imageFile: File) => {
        const formData = new FormData();
        formData.append("file", imageFile);
        formData.append("projectId", projectId.toString());

        try {
            const response = await PostFormData(
                BASE_URL_CONTRACTOR || "",
                `/bom_projects/${projectId}/media`,
                formData
            );

            if (response.status !== 200 && response.status !== 201) {
                throw new Error("Failed to upload image");
            }
            return response.data;
        } catch (error) {
            console.error('Error uploading image:', error);
            throw error;
        }
    };

    const handleGetLink = async (projectId: number) => {
        try {
            const response = await post(`${BASE_URL_CONTRACTOR}/bom_projects/${projectId}/link`);

            if (response.status === 200) {
                const link = response.data.link;
                const fullLink = `${window.location.origin}/create/step2Bom/${link}`;

                await navigator.clipboard.writeText(fullLink);

                toast.success('Link received. Enter it in the URL field');

                setIsReadOnly(true);
                setHasGotClientLink(true);
                setClientLink(link);
                localStorage.setItem(`hasGotClientLink_${projectId}`, 'true');
            }
        } catch (error) {
            toast.error('Couldn\'t get the link');
            throw error;
        }
    };

    const handleQtyChange = (itemTypeIndex: number, itemId: number, qty: number) => {
        setSelectedItemsMap(prev => ({
            ...prev,
            [itemTypeIndex]: prev[itemTypeIndex].map(item =>
                item.id === itemId ? { ...item, qty } : item
            )
        }));
    };

    const totalPayable = useMemo(() => {
        return Object.values(selectedItemsMap).flat().reduce((acc, item) => acc + (item.price * item.qty), 0);
    }, [selectedItemsMap]);

    const totalPayableGet = () => {
        if (getProject) {
            const total = getProject.data.item_types.reduce((total, itemType) => {
                const price = parseFloat(itemType.item.price).toFixed(2);
                const quantity = itemType.qty;
                return total + parseFloat(price) * quantity;
            }, 0);

            return total.toFixed(2);
        }
    };

    const handleRemoveItemType = (itemTypeIndex: number) => {
        const newSelectedItems = modalState.itemType.selectedItems.filter((_, idx) => idx !== itemTypeIndex);
        setModalState(prev => ({
            ...prev,
            itemType: {
                ...prev.itemType,
                selectedItems: newSelectedItems,
                totalItems: newSelectedItems.length,
            }
        }));

        setSelectedItemsMap(prev => {
            const updatedMap: SelectedItemsMap = {};
            Object.keys(prev).forEach(key => {
                const keyNum = parseInt(key, 10);
                if (keyNum === itemTypeIndex) {
                    return;
                } else if (keyNum > itemTypeIndex) {
                    updatedMap[keyNum - 1] = prev[keyNum];
                } else {
                    updatedMap[keyNum] = prev[keyNum];
                }
            });
            return updatedMap;
        });

        setItemTypeCount(prevCount => {
            const updatedCount: { [key: number]: number } = {};
            Object.keys(prevCount).forEach(key => {
                const keyNum = parseInt(key, 10);
                if (keyNum === itemTypeIndex) {
                    return;
                } else if (keyNum > itemTypeIndex) {
                    updatedCount[keyNum - 1] = prevCount[keyNum];
                } else {
                    updatedCount[keyNum] = prevCount[keyNum];
                }
            });
            return updatedCount;
        });
    };

    const handleCopyLink = (link: string) => {
        navigator.clipboard.writeText(link);
        toast.success('Link copied to clipboard!');
    };

    const renderSelectedItems = () => {
        if (modalState.itemType.selectedItems.length === 0) {
            return null;
        }

        return modalState.itemType.selectedItems.map((itemType: ItemType, index: number) => (
            <div key={index} className="selected-item-type-section">
                <div className="selected-item-type">
                    <div className="selected-item-type-info">
                        <img
                            src={
                                itemType.galleries &&
                                itemType.galleries.length > 0 &&
                                itemType.galleries[0].medias.length > 0
                                    ? itemType.galleries[0].medias[itemType.galleries[0].medias.length - 1].url
                                    : "/img_vendor/item.png"
                            }
                            alt={itemType.title}
                            className="selected-item-type-image"
                        />
                        <div>
                            <p className="selected-item-type-qty">Name</p>
                            <p className="selected-item-type-title">{itemType.title}</p>
                        </div>
                    </div>

                    <div className="selected-item-type-actions">
                        <div
                            className="btn-selected-item-button"
                            onClick={() => handleOpenModal('itemBom', index, itemType.qty)}
                        >
                            <IconBomSettingItemType />
                        </div>
                        <div
                            title='Remove product type'
                            onClick={() => handleRemoveItemType(index)}
                            className="btn-selected-item-button"
                        >
                            <IconRemoveItemType />
                        </div>
                    </div>
                </div>

                {renderSelectedItemsMap(index)}
            </div>
        ));
    };

    const renderSelectedItemsMap = (itemTypeIndex: number) => {
        const items = selectedItemsMap[itemTypeIndex];

        if (!items || items.length === 0) {
            return null;
        }

        return (
            <div>
                {items
                    .filter((item: TItem) => item.source === 'add')
                    .map((item: TItem, itemIndex: number) => {
                        return (
                            <div key={itemIndex} className={isReadOnly ? 'selected-get-bom-section' : 'selected-item-bom-section'}>
                                <div className="selected-item">
                                    <div className="selected-item-info">
                                        <img
                                            className="selected-item-image"
                                            src={
                                                item.vendor === 'cashbuild'
                                                    ? item.merchant_info?.image ||
                                                    (item.galleries && item.galleries.length > 0 && item.galleries[0].medias.length > 0 && item.galleries[0].medias[item.galleries[0].medias.length - 1]?.url) ||
                                                    '/img_vendor/item.png'
                                                    : (item.galleries && item.galleries.length > 0 && item.galleries[0].medias.length > 0 && item.galleries[0].medias[item.galleries[0].medias.length - 1]?.url) ||
                                                    '/img_vendor/item.png'
                                            }
                                            alt="Item"
                                        />

                                        <div className='selected-item-name'>
                                            <p className="selected-item-type-qty">Name</p>
                                            <p className="selected-item-type-title">{item.title}</p>
                                        </div>

                                        <div>
                                            <p className="selected-item-type-qty">Unit price</p>
                                            <p className="selected-item-type-title">R {Number(item.price).toFixed(2)}</p>
                                        </div>

                                        <div>
                                            <p className="selected-item-type-qty">Vendor</p>
                                            <p className="selected-item-type-title">
                                                {item.vendor ? (
                                                    <span className='item-selection-modal-vendor'>{vendorData[item.vendor as Vendor].displayName}</span>
                                                ) : (
                                                    <span className='item-selection-modal-no-vendor'>No Vendor</span>
                                                )}
                                            </p>
                                        </div>

                                        <div>
                                            <p className="selected-item-type-qty">QTY to order</p>
                                            <input
                                                className="input-bom-item-type"
                                                value={item.qty}
                                                onChange={(e) =>
                                                    handleQtyChange(
                                                        itemTypeIndex,
                                                        item.id,
                                                        parseInt(e.target.value, 10) || 0
                                                    )
                                                }
                                                disabled={isReadOnly}
                                            />
                                        </div>

                                        <div>
                                            <p className="selected-item-type-qty">Total</p>
                                            <p className="selected-item-type-total">R {(item.qty * item.price).toFixed(2)}</p>
                                        </div>
                                    </div>

                                    <div className='selected-item-actions-block'>
                                        {!isReadOnly && (
                                            <div className="selected-item-actions">
                                                <div className="btn-selected-item-button">
                                                    <IconBomSettingItem />
                                                </div>
                                                <div
                                                    title='Remove product'
                                                    className="btn-selected-item-button"
                                                    onClick={() => handleRemoveSelectedItem(itemTypeIndex, itemIndex)}
                                                >
                                                    <IconRemoveItem />
                                                </div>
                                            </div>
                                        )}

                                    </div>
                                </div>
                            </div>
                        );
                    })}
            </div>
        );
    };

    const renderGetProjectItemTypes = () => {
        return getProject?.data.item_types.map((itemType: BOMItemType, index: number) => (
            <div key={index} className="selected-item-type-section">
                <div className="selected-item-type">
                    <div className="selected-item-type-info">
                        <img
                            src={
                                "/img_vendor/item.png"
                            }
                            alt={itemType.title}
                            className="selected-item-type-image"
                        />
                        <div>
                            <p className="selected-item-type-qty">Name</p>
                            <p className="selected-item-type-title">{itemType.title}</p>
                        </div>
                    </div>
                </div>

                {renderGetProjectItem(itemType.item, itemType)}
            </div>
        ));
    };

    const renderGetProjectItem = (item: BOMItem, itemType: BOMItemType) => {
        return (
            <div key={item.id} className="selected-get-bom-section">
                <div className="selected-item">
                    <div className="selected-item-info">
                        <img
                            className="selected-item-image"
                            src={
                                item.vendor === 'cashbuild'
                                    ? item.merchant_info?.image ||
                                    (item.galleries && item.galleries.length > 0 && item.galleries[0].medias.length > 0 && item.galleries[0].medias[item.galleries[0].medias.length - 1]?.url) ||
                                    '/img_vendor/item.png'
                                    : (item.galleries && item.galleries.length > 0 && item.galleries[0].medias.length > 0 && item.galleries[0].medias[item.galleries[0].medias.length - 1]?.url) ||
                                    '/img_vendor/item.png'
                            }
                            alt="Item"
                        />
                        <div className='selected-item-name'>
                            <p className="selected-item-type-qty">Name</p>
                            <p className="selected-item-type-title">{item.title}</p>
                        </div>

                        <div>
                            <p className="selected-item-type-qty">Unit price</p>
                            <p className="selected-item-type-title">R {Number(item.price).toFixed(2)}</p>
                        </div>

                        <div>
                            <p className="selected-item-type-qty">Vendor</p>
                            <p className="selected-item-type-title">
                                {item.vendor ? (
                                    <span className='item-selection-modal-vendor'>{vendorData[item.vendor as Vendor].displayName}</span>
                                ) : (
                                    <span className='item-selection-modal-no-vendor'>No Vendor</span>
                                )}
                            </p>
                        </div>

                        <div>
                            <p className="selected-item-type-qty">QTY to order</p>
                            <input
                                className="input-bom-item-type"
                                value={itemType.qty}
                                disabled={true}
                            />
                        </div>

                        <div>
                            <p className="selected-item-type-qty">Total</p>
                            <p className="selected-item-type-total">
                                R {(itemType.qty * parseFloat(Number(item.price).toFixed(2))).toFixed(2)}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    return (
        <>
            <div className='template-name-content'>
                {isSmallScreen ? (
                    <MobileNavigation
                        links={[
                            { to: '/', label: 'Cashbuild' },
                            { to: '', label: '>' },
                            { to: '/', label: 'Home' },
                            { to: '', label: '>' },
                            { to: '/assistance', label: 'Project assistance' },
                            { to: '', label: '>' },
                            { to: '', label: 'Create new project space' }
                        ]}
                    />
                ) : (
                    <div className='template-blog-placeholder'>
                        <p>Home/</p>
                        <p onClick={() => navigateToAssistance()} className='template-link-assistance'>Project group assistance</p>
                        <p className='name-link'>/Create new project space</p>
                    </div>
                )}
            </div>
            <div className='step-info'>
                <div className='step-info-block'>
                    <h3>Step 1</h3>
                    <span>/</span>
                    <p className='step-info-block-name'>Specify parameters of building</p>
                </div>
            </div>
            <CreateProjectBom
                onDataReady={handleDataReady}
                getProject={getProject || undefined}
                getProjectHas={getProjectHas}
                isReadOnlyLink={isReadOnly}
            />

            {!getProjectHas && (
                <div
                    className="step1-bom-add-building"
                    onClick={() => handleOpenModal('itemType')}
                >
                    <div><IconPlusStpe2 /></div>
                    <p>Add Product Type</p>
                </div>
            )}

            <div>
                {(modalState.itemType.items.length > 0 || (getProject?.data?.item_types && getProject?.data?.item_types.length > 0)) && (
                    <p>Building units:</p>
                )}
                {!getProjectHas ? renderSelectedItems() : renderGetProjectItemTypes()}

                <div className='step1-bom-total-secrion'>
                    <div className='step1-bom-total-block'>
                        <p className='step1-bom-total-text'>Total payable:</p>
                        <p className='step1-bom-total-price'>R {getProjectHas ? totalPayableGet() : totalPayable.toFixed(2)}</p>
                    </div>
                </div>
                <section className='create-bom-section-btn'>
                    <div className='create-bom-section-btn-block'>
                        {projectData.title !== '' &&
                            Object.values(selectedItemsMap).some(itemsArray => itemsArray.length > 0) &&
                            !hasGotClientLink && (
                                <button
                                    className='create-bom-bottom-btn'
                                    onClick={handleSaveProject}
                                >
                                    Save
                                </button>
                            )}
                        {projectId !== null &&
                            (
                                clientLink ? (
                                    <div className='copy-link-container'>
                                        <p className='link-text'>
                                            {`${window.location.origin}/create/step2Bom/${clientLink}`}
                                        </p>
                                        <button
                                            className='copy-link-btn'
                                            onClick={() => handleCopyLink(`${window.location.origin}/create/step2Bom/${getProject?.link}`)}
                                        >
                                            Copy link
                                        </button>
                                    </div>
                                ) : (
                                    <button
                                        className='create-bom-bottom-btn'
                                        onClick={() => handleGetLink(projectId)}
                                    >
                                        Get client link
                                    </button>
                                )
                            )}
                    </div>
                </section>
            </div>

            <ItemTypeBomModal
                isOpen={modalState.itemType.isOpen}
                onClose={() => handleCloseModal('itemType')}
                itemTypes={modalState.itemType.items}
                loading={modalState.itemType.loading}
                onReload={() => handleReload('itemType')}
                currentPage={modalState.itemType.currentPage}
                totalItems={modalState.itemType.totalItems}
                itemsPerPage={5}
                onPageChange={(page) => handlePageChange('itemType', page)}
                onSearch={(query) => handleSearch('itemType', query)}
                onSortChange={(direction) => handleSortChange('itemType', direction)}
                sortDirection={modalState.itemType.sortDirection}
                totalSearchResults={modalState.itemType.totalItems}
                onSelectItems={handleSelectItemsItemType}
                initialSelectedItems={modalState.itemType.selectedItems}
                onItemTypeCountChange={handleItemTypeCountChange}
                itemTypeCount={itemTypeCount}
            />

            <ItemsBomModal
                isOpen={modalState.itemBom.isOpen}
                onClose={() => handleCloseModal('itemBom')}
                items={modalState.itemBom.items}
                loading={modalState.itemBom.loading}
                onReload={() => handleReload('itemBom')}
                currentPage={modalState.itemBom.currentPage}
                totalItems={modalState.itemBom.totalItems}
                itemsPerPage={5}
                onPageChange={(page) => handlePageChange('itemBom', page)}
                onSearch={(query) => handleSearch('itemBom', query)}
                onSortChange={(direction) => handleSortChange('itemBom', direction)}
                sortDirection={modalState.itemBom.sortDirection}
                totalSearchResults={modalState.itemBom.totalItems}
                onSelectItems={handleSelectItemsItemBom}
                initialSelectedItems={currentItemTypeIndex !== null ? selectedItemsMap[currentItemTypeIndex] : []}
                itemTypeQty={itemTypeQty}
            />
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    selectedTemplates: state.localTemplate.templates,
    project: state.projectById.project,
});

const mapDispatchToProps = {
    updateLocalTemplate,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Step1BOMCreate);
