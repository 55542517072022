import React, {useState, useEffect, useRef} from 'react';
import Modal from 'react-modal';
import { TItem} from "../../types/TemplateType";
import "./Step2Styles/itemModal.css";
import 'react-toastify/dist/ReactToastify.css';
import {
    toastItemCheckboxFalse,
    toastItemError,
    toastItemTrue
} from "../../components/Toast/AssisCreateToastNotification";
import {
    IconArrowBottom,
    IconArrowTop,
    IconCheckItemAvailable,
    IconResetInput,
    IconSearch
} from "../../IconComponents/IconComponents";
import {Loader} from "../../components/Loader/Loader";
import PaginationInModal from "../../components/PaginationAssistance/paginationInModal";
import {Vendor, vendorData} from "../../Enums/VendorEnum";
import ProgressBar from "./ItemProgressBar";
import {SelectedItem} from "../../Interfaces/ProjectInterfaces/SelectedItems";

interface ItemSelectionModalProps {
    isOpen: boolean,
    onClose: () => void,
    items: TItem[],
    loading: boolean,
    handleItemClick: (items: TItem[]) => void,
    selectedItems: { itemId: number; user_item_qty: number,item_packaging_qty: number,item_packaging_value:string }[],
    setSelectedItems: React.Dispatch<React.SetStateAction<SelectedItem[]>>,
    onReload?: () => void,
    currentPage: number,
    totalItems: number,
    itemsPerPage: number,
    onPageChange: (page: number) => void,
    onSearch?: (query: string, page: number) => void;
    totalSearchResults?: number,
    storeId: number
    itemTypeUnit?:string
}

const ItemSelectionModal: React.FC<ItemSelectionModalProps> = ({
                                                                   isOpen,
                                                                   onClose,
                                                                   items,
                                                                   loading,
                                                                   handleItemClick,
                                                                   selectedItems,
                                                                   onReload,
                                                                   currentPage,
                                                                   totalItems,
                                                                   itemsPerPage,
                                                                   onPageChange,
                                                                   onSearch,
                                                                   totalSearchResults,
                                                                   itemTypeUnit,
                                                                   setSelectedItems
                                                               }) => {
    const [userItemQuantities, setUserItemQuantities] = useState<{ [id: number]: number }>({});
    const [isError, setIsError] = useState(false);
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
    const [filteredItems, setFilteredItems] = useState<TItem[]>(items);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchTimer, setSearchTimer] = useState<NodeJS.Timeout | null>(null);
    const isUserAction = useRef(false);
    const [forceRemountKey, setForceRemountKey] = useState<number>(0);
    const [allItemsData, setAllItemsData] = useState<{ [id: number]: TItem }>({});
    const didInitialize = useRef(false);

    useEffect(() => {
        if (isOpen && !didInitialize.current) {
            setForceRemountKey(prev => prev + 1);

            const itemsMap: { [id: number]: TItem } = {};
            items.forEach(item => {
                itemsMap[item.id] = item;
            });
            selectedItems.forEach(sel => {
                const { itemId, item_packaging_qty, item_packaging_value } = sel;
                if (!itemsMap[itemId]) {
                    itemsMap[itemId] = {
                        id: itemId,
                        packaging: { [item_packaging_value]: item_packaging_qty },
                    } as unknown as TItem;
                } else {
                    itemsMap[itemId].packaging = { [item_packaging_value]: item_packaging_qty };
                }
            });
            setAllItemsData(itemsMap);

            const initialQuantities: { [id: number]: number } = {};
            selectedItems.forEach(sel => {
                initialQuantities[sel.itemId] = sel.user_item_qty;
            });
            items.forEach(item => {
                if (initialQuantities[item.id] === undefined) {
                    initialQuantities[item.id] = 0;
                }
            });
            setUserItemQuantities(initialQuantities);
            setFilteredItems(items);
            didInitialize.current = true;
        }
        if (!isOpen) {
            didInitialize.current = false;
            resetState();
        }
    }, [isOpen]);

    useEffect(() => {
        if (onSearch) {
            if (searchTimer) clearTimeout(searchTimer);
            if (searchTerm) {
                const timer = setTimeout(() => onSearch(searchTerm, currentPage), 500);
                setSearchTimer(timer);
            } else {
                onSearch('', currentPage);
            }
        }
    }, [searchTerm]);

    useEffect(() => {
        if (!isOpen) return;
        setAllItemsData(prev => {
            const newAllItemsData = { ...prev };
            items.forEach(item => {
                newAllItemsData[item.id] = item;
            });
            selectedItems.forEach(sel => {
                const { itemId, item_packaging_qty, item_packaging_value } = sel;
                if (!newAllItemsData[itemId]) {
                    newAllItemsData[itemId] = {
                        id: itemId,
                        packaging: { [item_packaging_value]: item_packaging_qty },
                    } as unknown as TItem;
                } else {
                    newAllItemsData[itemId].packaging = { [item_packaging_value]: item_packaging_qty };
                }
            });
            return newAllItemsData;
        });

        setUserItemQuantities(prev => {
            const newQuantities = { ...prev };
            items.forEach(item => {
                if (newQuantities[item.id] === undefined) {
                    newQuantities[item.id] = 0;
                }
            });
            return newQuantities;
        });

        setFilteredItems(items);
    }, [items, selectedItems, isOpen]);


    useEffect(() => {
        const totalQty = calculateTotalQty();
        const qty_item_type = items[0]?.qty_item_type || 0;
        if (totalQty === qty_item_type && isUserAction.current) {
            isUserAction.current = false;
        }
    }, [userItemQuantities, items]);

    const resetState = () => {
        setUserItemQuantities({});
        setIsError(false);
        setSearchTerm('');
        setFilteredItems([]);
        setSortDirection('asc');
        setAllItemsData({});
    };

    const handleQtyChange = (id: number, value: number) => {
        isUserAction.current = true;
        setUserItemQuantities(prev => ({ ...prev, [id]: value }));
    };

    const handleFillOut = (item: TItem) => {
        const qty_item_type = items[0]?.qty_item_type || 0;
        const totalEnteredQty = Object.entries(userItemQuantities).reduce((sum, [itemId, qty]) => {
            const currentItem = items.find(i => i.id === Number(itemId));
            if (!currentItem) return sum;
            let packagingValue = 1;
            if (currentItem.packaging && typeof currentItem.packaging === 'object') {
                const firstPackagingValue = Object.values(currentItem.packaging)[0];
                packagingValue = typeof firstPackagingValue === 'number' && !isNaN(firstPackagingValue) ? firstPackagingValue : 1;
            }
            return sum + Math.ceil(qty * packagingValue);
        }, 0);

        const remainingQty = Math.max(qty_item_type - totalEnteredQty, 0);
        const userInputValue = userItemQuantities[item.id] || 0;
        let packagingValue = 1;
        if (item.packaging && typeof item.packaging === 'object') {
            const firstPackagingValue = Object.values(item.packaging)[0];
            packagingValue = typeof firstPackagingValue === 'number' && !isNaN(firstPackagingValue) ? firstPackagingValue : 1;
        }
        if (userInputValue > 0) {
            setUserItemQuantities(prev => ({ ...prev, [item.id]: 0 }));
            toastItemCheckboxFalse(`Field "${item.title}" cleared`);
            return;
        }
        if (remainingQty > 0) {
            const adjustedQty = Math.ceil(remainingQty / packagingValue);
            setUserItemQuantities(prev => ({ ...prev, [item.id]: adjustedQty }));
            toastItemTrue(`Field "${item.title}" filled with ${adjustedQty} units (packaging: ${packagingValue}).`);
        } else {
            toastItemError(`No remaining quantity to distribute.`);
        }
    };

    const handleResetQuantities = () => {
        const resetQuantities = items.reduce<{ [id: number]: number }>((acc, item) => {
            acc[item.id] = 0;
            return acc;
        }, {});
        setUserItemQuantities(resetQuantities);
        setSelectedItems([]);
        toastItemTrue('All input fields have been reset.');
    };


    const calculateTotalQty = () => {
        return Object.values(userItemQuantities).reduce((total, qty) => total + qty, 0);
    };

    const handleSave = () => {
        const updatedItems = Object.entries(userItemQuantities)
            .filter(([id, qty]) => qty > 0)
            .map(([id, qty]) => {
                const item = allItemsData[Number(id)];
                return {
                    ...item,
                    user_item_qty: qty,
                };
            });
        handleItemClick(updatedItems);
        onClose();
    };

    const calculateTotalProgress = () => {
        const allSelectedQuantities = { ...userItemQuantities };
        selectedItems.forEach(({ itemId, user_item_qty }) => {
            if (allSelectedQuantities[itemId] === undefined) {
                allSelectedQuantities[itemId] = user_item_qty;
            }
        });
        return Object.entries(allSelectedQuantities).reduce((total, [id, qty]) => {
            const currentItem = allItemsData[Number(id)];
            let packagingValue = 1;
            if (currentItem && currentItem.packaging) {
                const firstPackagingValue = Object.values(currentItem.packaging)[0];
                if (typeof firstPackagingValue === 'number') {
                    packagingValue = firstPackagingValue;
                }
            }
            return total + Math.ceil(qty * packagingValue);
        }, 0);
    };

    const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    const highlightMatch = (text: string, match: string) => {
        if (!match) return text;
        const regex = new RegExp(`(${match})`, 'gi');
        return text.replace(regex, (matched) => `<span class="item-selection-modal-highlight">${matched}</span>`);
    };

    const sortedItems = [...filteredItems].sort((a, b) =>
        sortDirection === 'asc' ? a.price - b.price : b.price - a.price
    );
    const qty_item_type = items[0]?.qty_item_type || 0;

    const handleClose = () => {
        resetState();
        onClose();
    };

    useEffect(() => {
        calculateTotalProgress();
    }, [userItemQuantities]);

    return (
        <Modal
            isOpen={isOpen}
            key={forceRemountKey}
            onRequestClose={handleClose}
            className="item-selection-modal-content"
            overlayClassName="item-selection-modal-overlay"
            ariaHideApp={false}
        >
            <section className="item-selection-modal-section">
                <div className='item-selection-modal-header'>
                    <div className='item-selection-modal-header-block-text'>
                        <h2 className="item-selection-modal-title">Please, select your product</h2>
                    </div>
                    <button className="item-selection-modal-close-button" onClick={handleClose}>
                        &#x2715;
                    </button>
                    <ProgressBar unit={itemTypeUnit ? itemTypeUnit : 'each'} total={qty_item_type} current={calculateTotalProgress()} />
                </div>
                <div className='item-selection-modal-search'>
                    <div className='item-selection-modal-search-icon'>
                        <IconSearch/>
                    </div>
                    <input
                        className='item-selection-modal-search-input'
                        placeholder='Search'
                        type='search'
                        value={searchTerm}
                        onChange={handleSearchInput}
                    />
                </div>
                <div className='item-selection-modal-sort-icons'>
                    <div className='item-selection-modal-sort-buttons'>
                        {onReload && (
                            <div className='item-selection-modal-sort-button' onClick={handleResetQuantities}>
                                <IconResetInput/>
                            </div>
                        )}
                        <div className='item-selection-modal-sort-button' onClick={() => setSortDirection('desc')}>
                            <IconArrowTop/>
                        </div>
                        <div className='item-selection-modal-sort-button' onClick={() => setSortDirection('asc')}>
                            <IconArrowBottom/>
                        </div>
                    </div>
                </div>
                {loading ? (
                    <Loader/>
                ) : (
                    <ul className="item-selection-modal-list">
                        {sortedItems.map((item: TItem) => {
                            const isSelected = userItemQuantities[item.id] > 0;
                            const initialQty = userItemQuantities[item.id] || 0;
                            const quantity = item.qty_from_store;

                            return (
                                <li key={item.id} className="item-selection-modal-list-item">
                                    <section className="item-selection-modal-list-item-content">
                                        <div className="item-selection-modal-list-item-image">
                                            <img
                                                className="item-selection-modal-image"
                                                src={
                                                    item.vendor === 'cashbuild'
                                                        ? item.merchant_info?.image ||
                                                        (item.galleries && item.galleries.length > 0 && item.galleries[0].medias.length > 0 && item.galleries[0].medias[item.galleries[0].medias.length - 1]?.url) ||
                                                        '/img_vendor/item.png'
                                                        : (item.galleries && item.galleries.length > 0 && item.galleries[0].medias.length > 0 && item.galleries[0].medias[item.galleries[0].medias.length - 1]?.url) ||
                                                        '/img_vendor/item.png'
                                                }
                                                alt="Item"
                                            />

                                            <section className="item-selection-modal-list-item-info">
                                                <p
                                                    className="item-selection-modal-item-title"
                                                    dangerouslySetInnerHTML={{
                                                        __html: highlightMatch(item.title, searchTerm)
                                                    }}
                                                />
                                                <p className="item-selection-modal-item-description">
                                                    {item.merchant_info?.description_short ||
                                                        item.merchant_info?.description ||
                                                        item.merchant_info?.meta_description}
                                                </p>
                                                <p className="item-selection-modal-item-price">R {item.price ? Number(item.price).toFixed(2) : '0.00'}</p>
                                            </section>
                                        </div>

                                        <div className='item-selection-modal-action-block'>

                                            <div className='item-packaging'>
                                                {item.packaging && Object.keys(item.packaging).length > 0 ? (
                                                    <>
                                                        <span
                                                            className='item-packaging-value'>{item.packaging[Object.keys(item.packaging)[0]]}</span>
                                                        <p className='item-packaging-key'>{Object.keys(item.packaging)[0]}</p>
                                                    </>
                                                ) : (
                                                    <p className='item-packaging-none'>{item.unit}</p>
                                                )}
                                            </div>

                                            <div className='item-selection-modal-action-block-img'>
                                                {item.vendor !== null && vendorData[item.vendor as Vendor] ? (
                                                    <p className='item-selection-modal-vendor'>{vendorData[item.vendor as Vendor].displayName}</p>
                                                ) : (
                                                    <p className='item-selection-modal-no-vendor'>No vendor</p>
                                                )}
                                            </div>

                                            {quantity === 0 ? (
                                                <>
                                                    <div className='item-selection-modal-quantity-input'>
                                                        <button
                                                            disabled={!isSelected}
                                                            className="item-selection-modal-qty-button minus"
                                                            onClick={() => handleQtyChange(item.id, Math.max(0, initialQty - 1))}>-
                                                        </button>
                                                        <input
                                                            type="number"
                                                            value={initialQty > 0 ? initialQty : ''}
                                                            className="item-selection-modal-qty-input-field"
                                                            onChange={(e) => {
                                                                const newValue = e.target.value;
                                                                handleQtyChange(item.id, Math.max(0, Number(newValue)));
                                                            }}
                                                            min={0}
                                                            disabled={quantity === 0}
                                                        />
                                                        <button
                                                            disabled={!isSelected}
                                                            className="item-selection-modal-qty-button plus"
                                                            onClick={() => handleQtyChange(item.id, initialQty + 1)}>+
                                                        </button>
                                                    </div>
                                                    <button className="item-selection-modal-button out-of-stock">
                                                        Out of stock
                                                    </button>
                                                </>
                                            ) : (
                                                <div className='item-selection-modal-quantity-input'>
                                                    <button
                                                        className="item-selection-modal-qty-button minus"
                                                        onClick={() => handleQtyChange(item.id, Math.max(0, initialQty - 1))}>-
                                                    </button>
                                                    <input
                                                        type="number"
                                                        value={initialQty > 0 ? initialQty : ''}
                                                        onChange={(e) => {
                                                            const newValue = e.target.value;
                                                            handleQtyChange(item.id, Math.max(0, Number(newValue)));
                                                        }}
                                                        className="item-selection-modal-qty-input-field"
                                                        min={0}
                                                    />
                                                    <button
                                                        className="item-selection-modal-qty-button plus"
                                                        onClick={() => handleQtyChange(item.id, initialQty + 1)}>+
                                                    </button>
                                                </div>
                                            )}
                                            {quantity !== 0 && (
                                                <button
                                                    onClick={() => handleFillOut(item)}
                                                    disabled={userItemQuantities[item.id] === 0 && calculateTotalProgress() >= qty_item_type}
                                                    className={`item-selection-modal-button ${
                                                        userItemQuantities[item.id] > 0 ? 'add-item' : (calculateTotalProgress() >= qty_item_type ? 'disabled-item' : 'add-item')
                                                    }`}
                                                >
                                                    {userItemQuantities[item.id] > 0 ? 'Clear' : 'Fill'}
                                                </button>

                                            )}

                                            {typeof (item.qty_from_store) === 'string' ? (
                                                <button className="item-selection-modal-button out-of-stock">
                                                    No limit
                                                </button>
                                            ) : (
                                                <div className='item-available-block'>
                                                    <div><IconCheckItemAvailable/></div>
                                                    <p>{item.qty_from_store}</p>
                                                    <p>available</p>
                                                </div>
                                            )}
                                        </div>

                                    </section>
                                </li>
                            );
                        })}
                    </ul>
                )}
                <div className='item-selection-modal-save-button-block'>
                    <div>
                        <PaginationInModal
                            perPage={itemsPerPage}
                            total={searchTerm ? totalSearchResults : totalItems}
                            paginate={onPageChange}
                            currentPage={currentPage}
                        />
                    </div>
                    <button onClick={handleSave} className="item-selection-modal-save-button">Confirm selection</button>
                </div>
            </section>
        </Modal>
    );
};

export default ItemSelectionModal;
