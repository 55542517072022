// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.architect-coming-soon-block{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    background-color: var(--background-checkbox-color);
    border: 2px solid var(--basic-btn-color);
    border-radius: 15px;
}

.architect-coming-soon-text{
    font-family: var(--font-roboto),sans-serif;
    font-size: 36px;
    color: var(--basic-btn-dark-color);
}`, "",{"version":3,"sources":["webpack://./src/Page/Project Assistance/ProjectAssistance.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,kDAAkD;IAClD,wCAAwC;IACxC,mBAAmB;AACvB;;AAEA;IACI,0CAA0C;IAC1C,eAAe;IACf,kCAAkC;AACtC","sourcesContent":[".architect-coming-soon-block{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 200px;\n    background-color: var(--background-checkbox-color);\n    border: 2px solid var(--basic-btn-color);\n    border-radius: 15px;\n}\n\n.architect-coming-soon-text{\n    font-family: var(--font-roboto),sans-serif;\n    font-size: 36px;\n    color: var(--basic-btn-dark-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
