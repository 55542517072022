import React, {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import './NewUserGuide.css'
import {GuideMouse, GuideNextArrow} from "../../IconComponents/IconGuide";

const NewUserGuide: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const guideNewUser = location.pathname === "/guide-new-user";

    useEffect(() => {
        if (guideNewUser) {
            document.body.style.paddingTop = "20px";
        } else {
            document.body.style.paddingTop = "0px";
        }
        return () => {
            document.body.style.paddingTop = "0px";
        };
    }, [guideNewUser]);

    return (
        <div className='new-user'>
            <div className='new-user-header'>
                <h1 className='new-user-header-h'>Greensite help center</h1>
                <p className='new-user-header-text'>Learn how to use all the features and benefits</p>
                <button title='Get Started!' onClick={() => navigate('/assistance')}
                        className='guide-header-scip'>Skip
                </button>
            </div>

            <div className='new-user-info-block'>
                <p className='new-user-info-block-number'>1</p>
                <h3 className='new-user-info-block-text'>Creating a project</h3>
            </div>
            <div className='new-user-info-content'>
                <div className='new-user-info-content-img'>
                    <img src='/img_guide/guide1.png'/>
                    <div><GuideNextArrow/></div>
                    <img src='/img_guide/guide2.png'/>
                    <div><GuideNextArrow/></div>
                    <img src='/img_guide/guide3.png'/>
                    <div className='new-user-mouse'><GuideMouse/></div>
                </div>
                <div className='new-user-info-content-text-block'>
                    <p className='new-user-info-content-text-1 new-user-info-content-text'>Click to “Create new
                        project”</p>
                    <p className='new-user-info-content-text-2 new-user-info-content-text'>Choose template</p>
                    <p className='new-user-info-content-text-3 new-user-info-content-text'>Check your template and start
                        measuring”</p>
                </div>
                <div>
                </div>
            </div>

            <div className='new-user-info-block'>
                <p className='new-user-info-block-number'>2</p>
                <h3 className='new-user-info-block-text'>Adding dimensions and selecting products</h3>
            </div>

            <div className='new-user-info-content'>
                <div className='new-user-info-content-img'>
                    <img src='/img_guide/guide4.png'/>
                    <div><GuideNextArrow/></div>
                    <img src='/img_guide/guide5.png'/>
                    <div><GuideNextArrow/></div>
                    <img src='/img_guide/guide6.png'/>
                </div>
                <div className='new-user-info-content-text-block'>
                    <p className='new-user-info-content-text-4 new-user-info-content-text'>Add measurements and proceed
                        to product selection</p>
                    <p className='new-user-info-content-text-5 new-user-info-content-text'>Check and select all the
                        products you need and choose the right vendors for you</p>
                    <p className='new-user-info-content-text-6 new-user-info-content-text'>Complete your project and
                        proceed to checkout</p>
                </div>
                <div>
                </div>
            </div>
            <div className='new-user-info-block'>
                <p className='new-user-info-block-number'>3</p>
                <h3 className='new-user-info-block-text'>Using paid templates</h3>
            </div>
            <div className='new-user-info-block'>
                <p className='new-user-info-block-number'>4</p>
                <h3 className='new-user-info-block-text'>Creationg a BoM project</h3>
            </div>

        </div>
    );
};

export default NewUserGuide;
