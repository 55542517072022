import './style.css';
import { useCallback, useState, useEffect } from 'react';
import { clearLocalStorageExceptToken } from "../../utils/ClearLocalStorage";
import { clearTemplates } from "../../Slice/LocalTemplate/LocalTemplateSlice";
import {useDispatch, useSelector} from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { IconLogOut } from "../../IconComponents/IconComponents";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {RootState} from "../../store";

function TopHeader() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [isSpinning, setIsSpinning] = useState(false);
    const [activeButton, setActiveButton] = useState('');
    const [isHovered, setIsHovered] = useState('');
    const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);

    const isOnAuthPage = location.pathname.startsWith('/auth');

    const buttons = ['Home', 'About Us', 'Functionality', 'Pricing', 'Support'];
    const buttonPaths: Record<string, string> = {
        'Home': '/home',
        'About Us': '/aboutUs',
        'Functionality': '/functionality',
        'Pricing': '/pricing',
        'Support': '/support'
    };

    useEffect(() => {
        const currentPath = location.pathname;
        const activeBtn = Object.keys(buttonPaths).find(key => buttonPaths[key] === currentPath);
        if (activeBtn) {
            setActiveButton(activeBtn);
        } else {
            setActiveButton('');
        }
    }, [location.pathname, buttonPaths]);

    const navigateToPage = useCallback((path: string) => {
        clearLocalStorageExceptToken();
        dispatch(clearTemplates());
        navigate(path);
    }, [dispatch, navigate]);


    const handleButtonClick = useCallback((button: string) => {
        setActiveButton(button);
        const path = buttonPaths[button];
        if (path) {
            navigateToPage(path);
        }
    }, [navigateToPage, buttonPaths]);

    const handleGetStarted = () => {
        if (isAuthenticated) {
            navigate('/assistance');
        } else {
            navigate('/auth/login');
        }
    };

    const handleLogoClock = () => {
        if (isAuthenticated) {
            navigate('/assistance');
        } else {
            navigate('/home');
        }
    };

    const handleLogOut = () => {
        toast.info('Wait, we\'re coming out...');
        setIsSpinning(true);

        setTimeout(() => {
            sessionStorage.removeItem('Bearer');
            localStorage.removeItem('Bearer');
            localStorage.removeItem('role');
            navigate('/home');
            setIsSpinning(false);
            toast.success('You have successfully logged out!');
        }, 1500);
    };

    return (
        <div className="top-header">
            <div className='top-header-red'>
                <div className='top-header-left-container'>
                    <button onClick={handleLogOut} className='top-header-btn-logout'>
                        {isAuthenticated && !isOnAuthPage && (
                            <div title='Log out' className={`icon-container ${isSpinning ? 'spin-logout' : ''}`}>
                                <IconLogOut/>
                            </div>
                        )}
                    </button>
                    <div onClick={handleLogoClock} className='header-logo'>
                        <img className='header-logo-img' src='/img_projects/gobuild.svg' alt='Logo'/>
                    </div>
                </div>
                <div className="header-switcher">
                    {activeButton && buttons.includes(activeButton) && (
                        <div
                            className="active-background"
                            style={{
                                left: `${(buttons.indexOf(activeButton) * 100) / buttons.length}%`,
                                width: `${100 / buttons.length}%`
                            }}
                        />
                    )}
                    {buttons.map(button => (
                        <button
                            key={button}
                            className={`header-switcher-btn ${activeButton === button ? 'active-header-btn' : ''} ${isHovered === button ? 'hovered-header-btn' : ''}`}
                            onClick={() => handleButtonClick(button)}
                            onMouseEnter={() => setIsHovered(button)}
                            onMouseLeave={() => setIsHovered('')}
                        >
                            {button}
                        </button>
                    ))}
                </div>

                <div className='top-header-right-container'>
                    <button onClick={handleGetStarted} className='top-header-btn-calc'>
                        Get Started <span className="arrow-header">-&gt;</span>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default TopHeader;
