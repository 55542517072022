import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {RootState} from "../../store";
import {connect, ConnectedProps, useSelector} from "react-redux";
import './style.css';
import {Loader} from "../../components/Loader/Loader";
import MobileNavigation from "../../components/MobileNavigation/MobileNavigation";
import {Project} from "../../types/ProjectTypes";
import {addTemplate} from "../../Slice/LocalTemplate/LocalTemplateSlice";
import CreateProjectModal from "../../components/Modals/ModalCreateProject/ModalCreateProject";
import ModalAddTemplate from "../../components/ProjectAssistanceTemplatesAdd/ModalAddTemplate";
import {IconTemplateEyes, IconTemplatesAssistance} from "../../IconComponents/IconComponents";
import {get, getTemplateGroupById} from "../../api/APIWraper";
import {CategoryTemplates, Template} from "../../types/TemplateType";
import TemplateGroupSelect from "../../components/Modals/TemplateGroupSelect/TemplateGroupSelect";
import {selectUser} from "../../Slice/tokenSlice/userSlice";

const TEMPLATE_URL = process.env.REACT_APP_BASE_URL_CUSTOMER;

const TemplateGroupPage: React.FC<PropsFromRedux> = ({isAuthenticated}) => {
    const {templateGroupId} = useParams<{ templateGroupId: string }>();
    const navigate = useNavigate();

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isModalOpenProject, setIsModalOpenProject] = useState<boolean>(false);
    const [selectedTempId, setSelectedTempId] = useState<number>(0);
    const [selectedTempTitle, setSelectedTempTitle] = useState<string>('');
    const [htmlContent, setHtmlContent] = useState<string | null>(null);
    const [templateGroup, setTemplateGroup] = useState<CategoryTemplates | null>(null);
    const [isLoadingPage, setIsLoadingPage] = useState<boolean>(true);
    const [templates, setTemplates] = useState<Template[]>([]);
    const [totalTemplates, setTotalTemplates] = useState<number>(0);
    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerWidth <= 920);
    const [showFullList, setShowFullList] = useState<boolean>(false);
    const [isOpenTemplateGroupModal, setIsOpenTemplateGroupModal] = useState<boolean>(false);

    const [templatePaidTemplate, setTemplatePaidTemplate] = useState({
        paid:false,
        bought:false
    });
    const templateMessage = useSelector(selectUser);

    const handeTemplateGroupModalClose =()=>{
        setIsOpenTemplateGroupModal(false)
    }

    const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 920);
    };

    const toggleTemplateList = async () => {
        setShowFullList((prevShowFullList) => !prevShowFullList);

        if (!showFullList) {
            try {
                const response = await getTemplateGroupById(templateGroupId, 1, totalTemplates);
                if (response.status === 200) {
                    setTemplates(response.data.templates.data);
                }
            } catch (error) {
                console.error("Error fetching all templates:", error);
            }
        }
    };

    const displayedTemplates = showFullList ? templates : templates.slice(0, 8);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);



    useEffect(() => {
        const fetchTemplateGroupData = async () => {
            try {
                setIsLoadingPage(true);
                const page=1
                const perPage = 8
                const response = await getTemplateGroupById(templateGroupId, page, perPage);
                if (response.status === 200) {
                    setTemplateGroup(response.data);
                    setTemplates(response.data.templates.data);
                    setTotalTemplates(response.data.templates.pagination.total);

                    if (response.data.description && !htmlContent) {
                        const descResponse = await get(`${TEMPLATE_URL}/templates/description?path=${response.data.description}`);
                        setHtmlContent(descResponse.data.description);
                    }
                } else {
                    console.error('No content found');
                }
            } catch (error) {
                console.error("Error fetching template group or description:", error);
            } finally {
                setIsLoadingPage(false);
            }
        };

        if (isAuthenticated) {
            fetchTemplateGroupData();
        } else {
            navigate('/auth/login');
        }
    }, [templateGroupId, isAuthenticated,templateMessage]);



    const handleCloseModal = () => {
        setIsModalOpen(false);
        setIsModalOpenProject(false);
    };

    const infoTemplateToModal = () => {
        setIsModalOpen(true);
        setTemplatePaidTemplate({
            paid:templateGroup?.template.paid ?? false,
            bought:templateGroup?.template.bought ?? false
        })
        if (templateGroup?.template.id != null) {
            setSelectedTempId(templateGroup?.template.id);
        }
        if (templateGroup) {
            setSelectedTempTitle(templateGroup.title);
        }
    };

    const handleProjectCreation = (project: Project) => {
        navigate('/assistance/create', {state: {projectId: project.id}});
        if (templateGroup) addTemplate(templateGroup.template);
    };

    if (isLoadingPage) {
        return (<Loader/>);
    }

    const renderCategories = () => {
        if (templateGroup) {
            if (templateGroup.categories.length === 0) return null;
            return (
                <div className='blog-categories'>
                    <p>Category: {templateGroup.categories.map(category => category.category).join(', ')}</p>
                    <IconTemplatesAssistance/>
                </div>
            );
        }
        return null;
    };

    const renderMediaSections = () => {
        if (!htmlContent) {
            return <p>No content available</p>;
        }

        const parser = new DOMParser();
        const parsedHtml = parser.parseFromString(htmlContent, "text/html");
        const blocks = parsedHtml.querySelectorAll(".image-block");

        if (blocks.length === 0) {
            return (
                <div className="blog-section">
                    <div className="blog-media-section full-width-text blog-text-block-only-group">
                        <div className="blog-text-block-only-group">
                            <p
                                className="blog-text-block-p-only"
                                style={{fontFamily: 'Roboto, sans-serif', fontSize: '20px'}}
                                dangerouslySetInnerHTML={{__html: htmlContent}}
                            ></p>
                        </div>
                    </div>
                </div>
            );
        }

        return Array.from(blocks).map((block, index) => {
            const isOdd = index % 2 !== 0;
            const image = block.querySelector("img");
            const text = block.querySelector(".image-text");

            const hasImage = !!image;
            const hasText = !!(text?.innerHTML && text.innerHTML.trim());

            let mediaClass = '';
            if (hasImage && !hasText) {
                mediaClass = 'full-width-image';
            } else if (!hasImage && hasText) {
                mediaClass = 'full-width-text blog-text-block-only-group';
            } else if (hasImage && hasText) {
                mediaClass = isOdd ? 'odd' : 'even';
            }

            const textBlockClass = hasImage
                ? (isOdd ? 'block-even' : 'block-odd')
                : 'blog-text-block-only-group';

            const pClassName = hasImage
                ? 'blog-text-block-p'
                : 'blog-text-block-p blog-text-block-p-only';

            return (
                <div className='blog-section' key={index}>
                    <div className={`blog-media-section ${mediaClass}`}>
                        {hasImage && (
                            <img
                                className='blog-media-img'
                                src={image.src}
                                alt="Block Image"
                            />
                        )}
                        {hasText && (
                            <div className={`blog-text-block ${textBlockClass}`}>
                                <p
                                    className={pClassName}
                                    dangerouslySetInnerHTML={{__html: text.innerHTML || 'No text'}}
                                    style={{fontFamily: 'Roboto, sans-serif', fontSize: '20px'}}
                                ></p>
                            </div>
                        )}
                    </div>
                </div>
            );
        });
    };

    const addTemplateButton = (containerClassName: string) => (
        <div className='blog-media-section-button-block template-group'>
            <div className={`${containerClassName}`}>
                <button onClick={infoTemplateToModal} className='blog-media-section-btn-exis'>Add to existing project
                    group
                </button>
                <button onClick={()=>setIsOpenTemplateGroupModal(true)} className='blog-media-section-btn-new'>Add to new project space
                </button>
            </div>
        </div>
    );

    return (
        <div className='wrapper'>
            {isSmallScreen ? (
                <MobileNavigation
                    links={[
                        {to: '/', label: 'Cashbuild'},
                        {to: '', label: '>'},
                        {to: '/', label: 'Home'},
                        {to: '', label: '>'},
                        {to: '/assistance', label: 'Project assistance'},
                        {to: '', label: '>'},
                        {to: '/', label: 'Project page'}
                    ]}
                />
            ) : (
                <div className='template-blog-placeholder'>
                    <p>Home/</p>
                    <p onClick={() => navigate('/assistance')} className='template-link-assistance'>Project group
                        assistance/</p>
                    <p className='name-link'>Project page</p>
                </div>
            )}

            <div className='blog-name'>
                <p className='blog-name-title'>{templateGroup?.title}</p>
                {renderCategories()}
            </div>

            {renderMediaSections()}
            {addTemplateButton("blog-media-section-button")}

            <section className='group-templates-block'>
                <div className='group-templates-block-header'>
                    <h3 className='group-templates-block-header-title'>{templateGroup?.title}</h3>
                    <p className='group-templates-block-header-content'>We have a variety of boundary wall options so
                        you can choose the one that suits you!</p>
                </div>
                <div className='group-template-block-templates'>
                    {displayedTemplates.length > 0 && displayedTemplates.map((template, index) => {
                        const templateImage = template.galleries && template.galleries.length > 0 && template.galleries[0].medias.length > 0
                            ? template.galleries[0].medias[template.galleries[0].medias.length - 1].url
                            : '/img/house.jpg';
                        return (
                            <div className='template-project-list' key={template.id}>
                                <div onClick={() => navigate(`/template/${template.id}`)}
                                     className='template-project-get'>
                                    <div className='template-img-shadow' style={{
                                        backgroundImage: `url(${templateImage})`
                                    }}>
                                        <IconTemplateEyes/>
                                    </div>
                                    <div className='template-title-block'>
                                        <p className='template-title'>{template.title}</p>
                                    </div>
                                    <div className='view-details'>View details <span
                                        className='view-details-arrow'></span></div>
                                </div>

                            </div>
                        )
                    })}
                </div>
                {displayedTemplates.length >= 8 && (
                    <div className='group-template-item-btn-block'>
                        <button onClick={toggleTemplateList} className='group-template-item-btn'>
                            {showFullList ? 'Show less' : 'View full list'}
                        </button>
                    </div>
                )}
            </section>
            <TemplateGroupSelect
                isOpen={isOpenTemplateGroupModal}
                onRequestClose={handeTemplateGroupModalClose}
                templateGroupId={templateGroupId ? templateGroupId : ''}
            />

            <ModalAddTemplate
                templatePaidTemplate={templatePaidTemplate}
                isOpen={isModalOpen}
                tempId={selectedTempId}
                tempTitle={selectedTempTitle}
                onClose={handleCloseModal}
            />
            <CreateProjectModal
                isOpen={isModalOpenProject}
                onRequestClose={handleCloseModal}
                template={templateGroup?.template}
                onProjectCreated={handleProjectCreation}
            />
        </div>
    );
};


const mapStateToProps = (state: RootState) => ({
    isAuthenticated: state.auth.isAuthenticated
});

const mapDispatchToProps = {
    addTemplate
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(TemplateGroupPage);