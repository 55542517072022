import React, {useEffect, useState} from "react";
import Modal from 'react-modal';
import {connect, ConnectedProps, useSelector} from "react-redux";
import './style.css';
import {PermissionEdit} from "../../RoleComponent/EnumRole";
import {updateProjectPermissions} from "../../../Slice/updateProject/updateProjectSlice";
import {RootState} from "../../../store";
import {fetchAllProjects} from "../../../Slice/getProjectAssistance/ProjectAssistanceSlice";
import {IconInfoIcon} from "../../../IconComponents/IconComponents";
import {getAllProjectsAssistance} from "../../../api/APIWraper";
import {toast} from "react-toastify";
import {Project} from "../../../types/ProjectTypes";
import {Loader} from "../../Loader/Loader";
import {selectUser} from "../../../Slice/tokenSlice/userSlice";

interface AddMemberModalProps {
    isOpen: boolean;
    onRequestClose: () => void;
    projectId?: string;
    showProjects?: boolean;
    onPermissionsUpdated: () => void;
    pageTeamManagement: boolean;
}

export type RoleEditor = 'Editor' | 'Viewer';

const AddMemberModal: React.FC<AddMemberModalProps & PropsFromRedux> = ({
                                                                            isOpen,
                                                                            onRequestClose,
                                                                            projectId,
                                                                            showProjects,
                                                                            updateProjectPermissions,
                                                                            pageTeamManagement,
                                                                            users,
                                                                            onPermissionsUpdated,
                                                                            user
                                                                        }) => {
    const [role, setRole] = useState<string>('User');
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
    const [emailError, setEmailError] = useState<string>('');
    const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
    const [suggestions, setSuggestions] = useState<string[]>([]);
    const [selectedProjectId, setSelectedProjectId] = useState<string | null>(projectId || null);

    const [allProjects, setAllProjects] = useState<Project[]>([]);
    const [loading, setLoading] = useState<boolean>(false);



    const [isEditSection, setIsEditSection] = useState(true);
    const [isMeasurement, setIsMeasurement] = useState(true);
    const [isPurchasePermissions, setIsPurchasePermissions] = useState(true);
    const [isChangeAddress, setIsChangeAddress] = useState(true);
    const [isMakePayments, setIsMakePayments] = useState(true);
    const roleGlobal = user?.role?.role

    const [roleEditor, setRoleEditor] = useState<RoleEditor>('Editor');
    const [isOpenEditor, setIsOpenEditor] = useState<boolean>(false);

    const [isOpenProjectGroup, setIsOpenProjectGroup] = useState<boolean>(false);
    const [isOpenRole, setIsOpenRole] = useState<boolean>(false);

    const checkRole = roleGlobal === 'Customer'

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
            document.body.classList.add('modal-open');
        } else {
            document.body.style.overflow = '';
            document.body.classList.remove('modal-open');
        }

        return () => {
            document.body.style.overflow = '';
            document.body.classList.remove('modal-open');
        };
    }, [isOpen]);


    useEffect(() => {
        if (pageTeamManagement && isOpen) {
            if (allProjects.length === 0) {
                fetchProjects()
            }
        }
    }, [isOpen]);

    useEffect(() => {
        if (email && Array.isArray(users)) {
            const filteredSuggestions = users
                .map(user => user.email)
                .filter(userEmail => userEmail.includes(email) && userEmail !== email)
                .sort((a, b) => {
                    const startsWithA = a.startsWith(email);
                    const startsWithB = b.startsWith(email);
                    if (startsWithA && !startsWithB) return -1;
                    if (!startsWithA && startsWithB) return 1;
                    return 0;
                });
            setSuggestions(filteredSuggestions);
            setShowSuggestions(true);
        } else {
            setSuggestions([]);
            setShowSuggestions(false);
        }
    }, [email, users]);

    const fetchProjects = async () => {
        setLoading(true)
        try {
            const {
                allProjects,
                status
            } = await getAllProjectsAssistance(1, 1000, undefined, undefined, '&with[]=title');
            if (status === 200) {
                setAllProjects(allProjects)
                setLoading(false)
            } else {
                toast.error('Failed to get projects')
                setAllProjects([])
                setLoading(false)
            }
        } catch (error) {
            console.error(error);
            setLoading(false)
            setAllProjects([])
        }
    }

    const handleInvite = () => {
        if (email && selectedProjectId) {
            const permissions = role === 'User' ? [PermissionEdit.READ] : [PermissionEdit.READ, PermissionEdit.UPDATE, PermissionEdit.DELETE, PermissionEdit.OWNER];
            updateProjectPermissions({
                projectId: selectedProjectId,
                email: email,
                permissions: permissions.map(permission => PermissionEdit[permission])
            }).then(() => {
                onPermissionsUpdated();
                onRequestClose();
            });
            setName('');
            setEmail('');
            setIsEmailValid(false);
            setEmailError('');
        }
    };

    const validateEmail = (email: string): boolean => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const emailValue = e.target.value;
        setEmail(emailValue);
        setIsEmailValid(false);
        setEmailError('');

        if (validateEmail(emailValue)) {
            setIsEmailValid(true);
        } else {
            setIsEmailValid(false);
        }
    };

    const handleSuggestionClick = (suggestion: string) => {
        setEmail(suggestion);
        setIsEmailValid(true);
        setEmailError('');
        setShowSuggestions(false);
    };

    const handleBlur = () => {
        setTimeout(() => {
            setShowSuggestions(false);
            if (email && !isEmailValid) {
                setEmailError('Invalid email address');
            }
        }, 100);
    };

    const handleFocus = () => {
        if (email) {
            setShowSuggestions(true);
        }
    };

    const isInviteDisabled = showProjects ? !selectedProjectId || !role || !email || !isEmailValid : !email || !isEmailValid;

    const handleToggle = (essence: string) => {
        switch (essence) {
            case 'ItemSection': {
                setIsEditSection(!isEditSection);
                break;
            }
            case 'Measurement': {
                setIsMeasurement(!isMeasurement);
                break;
            }
            case 'PurchasePermissions': {
                setIsPurchasePermissions(!isPurchasePermissions);
                break;
            }
            case 'ChangeAddress': {
                setIsChangeAddress(!isChangeAddress);
                break;
            }
            case 'MakePayments': {
                setIsMakePayments(!isMakePayments);
                break;
            }
        }
    };

    const toggleDropdownEditor = () => {
        setIsOpenEditor(!isOpenEditor);
    };

    const handleSelectEditor = (selectedRole: RoleEditor) => {
        setRoleEditor(selectedRole);
        setIsOpenEditor(false);
    };

    const toggleDropdownProjectGroup = () => {
        setIsOpenProjectGroup(!isOpenProjectGroup);
    };

    const handleSelectProjectGroup = (projectId: string) => {
        setSelectedProjectId(projectId);
        setIsOpenProjectGroup(false);
    };

    const toggleDropdownRole = () => {
        setIsOpenRole(!isOpenRole);
    };

    const handleSelectRole = (selectedRole: string) => {
        setRole(selectedRole);
        setIsOpenRole(false);
    };

    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className="modal-new-member" overlayClassName="overlay">
            <div className="modal-header">
                <h2>Add new member</h2>
                <button onClick={onRequestClose} className="close-button">&times;</button>
            </div>
            <div className="modal-content">

                {showProjects && allProjects && (
                    <div>
                        <p className="user-input-label">Select project</p>
                        <div className="new-member-check-permission-modal-project-permission">
                            <div className="new-member-user-permissions-dropdown-editor">
                                <button className="new-member-user-permissions-dropdown-button"
                                        onClick={toggleDropdownProjectGroup}
                                        disabled={loading}
                                >
                                    {loading ? <span>Loading...</span> : (selectedProjectId ? allProjects.find(p => p.id === Number(selectedProjectId))?.title : 'Select project')}
                                    <span className={`new-member-user-permissions-arrow ${isOpenProjectGroup ? 'new-member-user-permissions-open' : ''}`} />
                                </button>

                                {isOpenProjectGroup && !loading && (
                                    <div className="new-member-user-permissions-dropdown-content">
                                        {allProjects.map(project => (
                                            <div
                                                key={project.id}
                                                onClick={() => handleSelectProjectGroup(String(project.id))}
                                                className="new-member-user-permissions-dropdown-option"
                                            >
                                                {project.title}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}

                <div>
                    <p className="user-input-label">Role:</p>
                    <div className="new-member-check-permission-modal-project-permission">

                        <div className="new-member-user-permissions-dropdown-editor">

                            <button className="new-member-user-permissions-dropdown-button"
                                    onClick={toggleDropdownRole}>
                                {role}
                                <span
                                    className={`new-member-user-permissions-arrow ${isOpenRole ? 'new-member-user-permissions-open' : ''}`}/>
                            </button>
                            {isOpenRole && (
                                <div className="new-member-user-permissions-dropdown-content">
                                    {['User', 'Admin'].map(option => (
                                        <div
                                            key={option}
                                            onClick={() => handleSelectRole(option)}
                                            className="new-member-user-permissions-dropdown-option"
                                        >
                                            {option}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="user-input-field">
                    <p className="user-input-label">Name:</p>
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="user-input-text"
                    />
                </div>
                <div className="user-input-field">
                    <p className="user-input-label">E-mail:</p>
                    <div className="email-input-wrapper">
                        <input
                            type="text"
                            value={email}
                            onChange={handleEmailChange}
                            onBlur={handleBlur}
                            onFocus={handleFocus}
                            className="user-input-email"
                        />
                        {emailError && !isEmailValid && <span className="error">{emailError}</span>}
                        {showSuggestions && suggestions.length > 0 && (
                            <ul className="suggestions">
                                {suggestions.map((suggestion, index) => (
                                    <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
                                        {suggestion}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>
            </div>
            {!checkRole && (
                <div className="check-permission-permissions">
                    <div className='new-member-check-permission-container'>
                        <p className="user-input-label">Permissions</p>
                        <div className='new-member-check-permission-modal-project-permission'>
                            <div className="new-member-user-permissions-dropdown-editor">
                                <button className="new-member-user-permissions-dropdown-button"
                                        onClick={toggleDropdownEditor}>
                                    {roleEditor}
                                    <span
                                        className={`new-member-user-permissions-arrow ${isOpenEditor ? 'new-member-user-permissions-open' : ''}`}/>
                                </button>
                                {isOpenEditor && (
                                    <div className="new-member-user-permissions-dropdown-content">
                                        {['Editor', 'Viewer'].map((option) => (
                                            <div
                                                key={option}
                                                onClick={() => handleSelectEditor(option as RoleEditor)}
                                                className="new-member-user-permissions-dropdown-option"
                                            >
                                                {option}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='check-permission-modal-toggle-block'>
                        <div className='check-user-permissions-content-block'>
                            <p>Edit Item Section</p>
                            <div
                                className={`check-user-permissions-switcher-toggle ${isEditSection ? '' : 'active'}`}
                                onClick={() => handleToggle('ItemSection')}>

                                <div
                                    className={`check-user-permissions-switcher-toggle-knob ${isEditSection ? '' : 'active'}`}>
                                </div>
                            </div>
                        </div>

                        <div className='check-user-permissions-content-block'>
                            <p>Measurement Adjustments</p>
                            <div
                                className={`check-user-permissions-switcher-toggle ${isMeasurement ? '' : 'active'}`}
                                onClick={() => handleToggle('Measurement')}>
                                <div
                                    className={`check-user-permissions-switcher-toggle-knob ${isMeasurement ? '' : 'active'}`}>
                                </div>
                            </div>
                        </div>

                        <div className='check-user-permissions-content-block'>
                            <p>Purchase Permissions</p>
                            <div
                                className={`check-user-permissions-switcher-toggle ${isPurchasePermissions ? '' : 'active'}`}
                                onClick={() => handleToggle('PurchasePermissions')}>
                                <div
                                    className={`check-user-permissions-switcher-toggle-knob ${isPurchasePermissions ? '' : 'active'}`}>
                                </div>
                            </div>
                        </div>

                        <div className='check-user-permissions-content-block-info'>
                            <div className='check-user-permissions-content-block-info-content'>
                                <p>Add or Change Delivery Address</p>
                                <div className="user-permissions-tooltip-container">
                                    <IconInfoIcon/>
                                    <span className="user-permissions-tooltip-text">Gives this user access to make payments on this project.</span>
                                </div>
                            </div>
                            <div
                                className={`check-user-permissions-switcher-toggle ${isChangeAddress ? '' : 'active'}`}
                                onClick={() => handleToggle('ChangeAddress')}>
                                <div
                                    className={`check-user-permissions-switcher-toggle-knob ${isChangeAddress ? '' : 'active'}`}>
                                </div>
                            </div>
                        </div>

                        <div className='check-user-permissions-content-block-info'>
                            <div className='check-user-permissions-content-block-info-content'>
                                <p>Make Payments</p>
                                <div className="user-permissions-tooltip-container">
                                    <IconInfoIcon/>
                                    <span className="user-permissions-tooltip-text">Gives this user access to make payments on this project.</span>
                                </div>
                            </div>
                            <div
                                className={`check-user-permissions-switcher-toggle ${isMakePayments ? '' : 'active'}`}
                                onClick={() => handleToggle('MakePayments')}>
                                <div
                                    className={`check-user-permissions-switcher-toggle-knob ${isMakePayments ? '' : 'active'}`}>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div className="modal-footer-template">
                <button
                    onClick={handleInvite}
                    className={`invite-button ${isInviteDisabled ? 'disabled' : ''}`}
                    disabled={isInviteDisabled}
                >
                    Share project
                </button>
            </div>
        </Modal>
    );
};

const mapStateToProps = (state: RootState) => ({
    users: state.usersFromOwnProjects.users,
    user:state.user.user
});

const mapDispatchToProps = {
    updateProjectPermissions,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AddMemberModal);
