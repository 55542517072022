// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.change-password-block{
    height: 575px;
    border: 1px solid var(--background-header-btn-color);
    border-radius: 15px;
}

.icon-in-input-show{
    position: absolute;
    top: 55px;
    right: 15px;
}

.change-password-details-input{
    width: 100%;
    height: 65px;
    border: 1px solid var(--background-header-btn-color);
    border-radius: 15px;
    font-size: 16px;
    padding: 23px 50px 23px 23px;
    box-sizing: border-box;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: clip;
    color: var(--basic-text-description-color);
}
.change-password-details-input:focus{
    outline: none;
    box-shadow: 0px 0px 8px 0px rgba(80, 138, 129, 0.75);
}

.change-password-forgot-block{
    text-align: right;
}

.change-password-button-forgot{
    display: inline-block;
    border: none;
    border-bottom: 1px solid var(--basic-btn-dark-color);
    color: var(--basic-btn-dark-color);
    background-color: inherit;
    padding-top: 10px;
    margin: 0;
    font-size: 14px;
    transition: 0.1s;

}

.change-password-button-forgot:hover{
    border-bottom: 1px solid var(--basic-text-description-color);
    cursor: pointer;
    transition: 0.1s;
    transform: scale(1.03);
}
`, "",{"version":3,"sources":["webpack://./src/Page/PreferencesPage/styles/changePassword.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,oDAAoD;IACpD,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;AACf;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,oDAAoD;IACpD,mBAAmB;IACnB,eAAe;IACf,4BAA4B;IAC5B,sBAAsB;IACtB,gBAAgB;IAChB,mBAAmB;IACnB,mBAAmB;IACnB,0CAA0C;AAC9C;AACA;IACI,aAAa;IACb,oDAAoD;AACxD;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,qBAAqB;IACrB,YAAY;IACZ,oDAAoD;IACpD,kCAAkC;IAClC,yBAAyB;IACzB,iBAAiB;IACjB,SAAS;IACT,eAAe;IACf,gBAAgB;;AAEpB;;AAEA;IACI,4DAA4D;IAC5D,eAAe;IACf,gBAAgB;IAChB,sBAAsB;AAC1B","sourcesContent":[".change-password-block{\n    height: 575px;\n    border: 1px solid var(--background-header-btn-color);\n    border-radius: 15px;\n}\n\n.icon-in-input-show{\n    position: absolute;\n    top: 55px;\n    right: 15px;\n}\n\n.change-password-details-input{\n    width: 100%;\n    height: 65px;\n    border: 1px solid var(--background-header-btn-color);\n    border-radius: 15px;\n    font-size: 16px;\n    padding: 23px 50px 23px 23px;\n    box-sizing: border-box;\n    overflow: hidden;\n    white-space: nowrap;\n    text-overflow: clip;\n    color: var(--basic-text-description-color);\n}\n.change-password-details-input:focus{\n    outline: none;\n    box-shadow: 0px 0px 8px 0px rgba(80, 138, 129, 0.75);\n}\n\n.change-password-forgot-block{\n    text-align: right;\n}\n\n.change-password-button-forgot{\n    display: inline-block;\n    border: none;\n    border-bottom: 1px solid var(--basic-btn-dark-color);\n    color: var(--basic-btn-dark-color);\n    background-color: inherit;\n    padding-top: 10px;\n    margin: 0;\n    font-size: 14px;\n    transition: 0.1s;\n\n}\n\n.change-password-button-forgot:hover{\n    border-bottom: 1px solid var(--basic-text-description-color);\n    cursor: pointer;\n    transition: 0.1s;\n    transform: scale(1.03);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
