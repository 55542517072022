// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.preferences-header-container {
    position: fixed;
    top: 70px;
    left: 0;
    right: 0;
    background: white;
    z-index: 2;
}

.preferences-header {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 85px;
    padding: 20px;
}

.preferences-header-list {
    font-family: var(--font-roboto), sans-serif;
    font-size: 20px;
    color: var(--header-list-text-color);
    font-weight: 500;
    cursor: pointer;
    position: relative;
    padding-bottom: 5px;
    transition: 0.3s;
}

.preferences-header-list:hover {
    color: var(--basic-btn-color);
    transition: 0.3s;
}

.preferences-header-list.active {
    color: var(--basic-btn-color);
}

.preferences-header-list.active::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--basic-btn-color);
}
`, "",{"version":3,"sources":["webpack://./src/Page/PreferencesPage/styles/header.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,SAAS;IACT,OAAO;IACP,QAAQ;IACR,iBAAiB;IACjB,UAAU;AACd;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;IACT,aAAa;AACjB;;AAEA;IACI,2CAA2C;IAC3C,eAAe;IACf,oCAAoC;IACpC,gBAAgB;IAChB,eAAe;IACf,kBAAkB;IAClB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,6BAA6B;IAC7B,gBAAgB;AACpB;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,aAAa;IACb,OAAO;IACP,WAAW;IACX,WAAW;IACX,wCAAwC;AAC5C","sourcesContent":[".preferences-header-container {\n    position: fixed;\n    top: 70px;\n    left: 0;\n    right: 0;\n    background: white;\n    z-index: 2;\n}\n\n.preferences-header {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    gap: 85px;\n    padding: 20px;\n}\n\n.preferences-header-list {\n    font-family: var(--font-roboto), sans-serif;\n    font-size: 20px;\n    color: var(--header-list-text-color);\n    font-weight: 500;\n    cursor: pointer;\n    position: relative;\n    padding-bottom: 5px;\n    transition: 0.3s;\n}\n\n.preferences-header-list:hover {\n    color: var(--basic-btn-color);\n    transition: 0.3s;\n}\n\n.preferences-header-list.active {\n    color: var(--basic-btn-color);\n}\n\n.preferences-header-list.active::after {\n    content: '';\n    position: absolute;\n    bottom: -10px;\n    left: 0;\n    width: 100%;\n    height: 2px;\n    background-color: var(--basic-btn-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
