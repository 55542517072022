import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './GuideStyles/GuideFooter.css';

const steps = [
    "/guide/step1",
    "/guide/step2",
    "/guide/step3",
    "/guide/step4",
    "/guide/step5",
    "/guide/step6",
    "/guide/step7",
    "/guide/step8",
];

const GuideFooter: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const currentStepIndex = steps.indexOf(location.pathname);

    const handleNext = () => {
        if (currentStepIndex < steps.length - 1) {
            navigate(steps[currentStepIndex + 1]);
        }
    };

    const progressPercentage = ((currentStepIndex + 1) / steps.length) * 100;

    return (
        <div className="guide-footer">
            <div className="progress-container-guide">
                <span>{currentStepIndex + 1} of {steps.length}</span>
                <div className="progress-bar-background-guide">
                    <div className="progress-bar-guide" style={{ width: `${progressPercentage}%` }} />
                </div>
            </div>
            <div >
                <button className="guide-footer-btn-next" onClick={handleNext} disabled={currentStepIndex === steps.length - 1}>
                    Next
                </button>
            </div>
        </div>
    );
};

export default GuideFooter;
