// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scroll-to-top {
    position: fixed;
    bottom: 40px;
    right: 40px;
    width: 50px;
    height: 50px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, transform 0.3s ease-in-out;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.scroll-to-top:hover {
    opacity: 1;
    transform: translateY(-5px);
}

.scroll-to-top:focus {
    outline: none;
}

.scroll-to-top.show {
    opacity: 0.7;
    visibility: visible;
}
`, "",{"version":3,"sources":["webpack://./src/components/ScrollArrowUp/ScrollToTopButton.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,WAAW;IACX,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,wCAAwC;IACxC,UAAU;IACV,kBAAkB;IAClB,6FAA6F;IAC7F,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,UAAU;IACV,2BAA2B;AAC/B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,mBAAmB;AACvB","sourcesContent":[".scroll-to-top {\n    position: fixed;\n    bottom: 40px;\n    right: 40px;\n    width: 50px;\n    height: 50px;\n    background-color: #4CAF50;\n    color: white;\n    border: none;\n    border-radius: 50%;\n    cursor: pointer;\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);\n    opacity: 0;\n    visibility: hidden;\n    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, transform 0.3s ease-in-out;\n    z-index: 1000;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.scroll-to-top:hover {\n    opacity: 1;\n    transform: translateY(-5px);\n}\n\n.scroll-to-top:focus {\n    outline: none;\n}\n\n.scroll-to-top.show {\n    opacity: 0.7;\n    visibility: visible;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
