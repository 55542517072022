import React, {useEffect, useState} from 'react';
import {connect, ConnectedProps} from "react-redux";
import {ItemType, TItem} from "../../../types/TemplateType";
import {RootState} from "../../../store";
import "../Step2Styles/projectUpdateItems.css";
import "../Step2Styles/ProductLists.css";

import {
    setSelectedItemsTotal,
    setSelectedLaborTotal,
    setSelectedToolsTotal
} from "../../../Slice/SelectedItems/SelectedItemsSlice";
import {Vendor, vendorData} from "../../../Enums/VendorEnum";
import {IconArrowLightStpe2} from "../../../IconComponents/IconComponents";
import {
    notifySuccessOrderTemplate, notifyWarningOrderTemplate
} from "../../../components/Toast/AssisCreateToastNotification";

interface ItemTypeWithIndex extends ItemType {
    originalIndex: number;
    clone_id?: number;
}

interface ItemTypeListProps {
    fragment: {
        id: number;
        data: {
            item_types: ItemType[];
        };
    };
    fragmentIndex: number;
    templateIndex: number;
    templateId: number;
    viewMode: 'Work Stage' | 'Materials|Labor|Tools';
}

const ItemTypeList: React.FC<ItemTypeListProps & PropsFromRedux> = ({
                                                                        fragment,
                                                                        fragmentIndex,
                                                                        templateIndex,
                                                                        templateId,
                                                                        viewMode,
                                                                        setSelectedItemsTotal,
                                                                        setSelectedToolsTotal,
                                                                        setSelectedLaborTotal,
                                                                    }) => {
    const fragmentKey = `${fragment.id}-${fragmentIndex}-${templateIndex}`;
    const generateItemTypeKey = (itemType: ItemTypeWithIndex) => {
        return `${itemType.id}-${itemType.clone_id || itemType.originalIndex}-${fragmentIndex}`;
    };
    const generateItemTypeKeyVisible = (itemType: ItemTypeWithIndex, idx: number) => {
        if (itemType.items && itemType.items.length > 0) {
            return `${itemType.id}-${idx}-${itemType.items.map((item, itemIdx) => `${item.id}-${item.title}-${itemIdx}`).join('-')}`;
        }
        return `${itemType.id}-${itemType.originalIndex}-${fragmentIndex}-${idx}`;
    };

    const generateItemKeyVisible = (item: TItem, idx: number) => {
        return `${item.id}-${item.clone_id}-${fragmentIndex}-${idx}`;
    };


    const generateUniqueKey = (itemType: ItemTypeWithIndex, itemIndex: number, item: any) => {
        return `${itemType.id}-${itemType.clone_id || itemType.originalIndex}-${itemIndex}`;
    };

    const [selectedItems, setSelectedItemsState] = useState<{ [key: string]: boolean }>({});
    const [selectedLaborItems, setSelectedLaborItemsState] = useState<{ [key: string]: boolean }>({});
    const [selectedToolItems, setSelectedToolItemsState] = useState<{ [key: string]: boolean }>({});
    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [isButtonDisabledOrderFragment, setIsButtonDisabledOrderFragment] = useState(false);

    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerWidth <= 920);
    const [visibilityMap, setVisibilityMap] = useState<{ [key: string]: boolean }>({});


    const toggleVisibility = (itemTypeKey: string) => {
        setVisibilityMap((prevMap) => ({
            ...prevMap,
            [itemTypeKey]: !prevMap[itemTypeKey],
        }));
    };

    const isVisible = (itemTypeKey: string) => {
        return visibilityMap[itemTypeKey] || false;
    };
    const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 920);
    };

    const handleOrderFragment = (fragmentId: number, fragmentIndex: number) => {
        const storedSelectedFragments = JSON.parse(localStorage.getItem('selectedFragments') || '{}');
        const fragmentKey = `${fragment.id}-${fragmentIndex}-${templateIndex}`;

        const isAllSelected = fragment.data.item_types.every((itemType, itemTypeIndex) => {
            const itemKey = `${itemType.id}-${itemTypeIndex}-${fragmentIndex}`;
            return (
                storedSelectedFragments[fragmentKey]?.items?.[itemKey] ||
                storedSelectedFragments[fragmentKey]?.LaborItemTypes?.[itemKey] ||
                storedSelectedFragments[fragmentKey]?.ToolsItemTypes?.[itemKey]
            );
        });

        if (isAllSelected) {
            fragment.data.item_types.forEach((itemType, itemTypeIndex) => {
                const itemKey = `${itemType.id}-${itemTypeIndex}-${fragmentIndex}`;

                delete storedSelectedFragments[fragmentKey]?.items?.[itemKey];
                delete storedSelectedFragments[fragmentKey]?.LaborItemTypes?.[itemKey];
                delete storedSelectedFragments[fragmentKey]?.ToolsItemTypes?.[itemKey];
            });

            setSelectedItemsTotal(Date.now());
            setSelectedLaborTotal(Date.now());
            setSelectedToolsTotal(Date.now());

            notifyWarningOrderTemplate('Products removed from cart');
        } else {
            fragment.data.item_types.forEach((itemType, itemTypeIndex) => {
                const itemKey = `${itemType.id}-${itemTypeIndex}-${fragmentIndex}`;

                if (!storedSelectedFragments[fragmentKey]) {
                    storedSelectedFragments[fragmentKey] = { items: {}, LaborItemTypes: {}, ToolsItemTypes: {} };
                }

                const addItemType = (category: string) => {
                    if (!storedSelectedFragments[fragmentKey][category]) {
                        storedSelectedFragments[fragmentKey][category] = {};
                    }

                    const filteredItems = itemType.items?.filter(i => i.qty !== 0);

                    storedSelectedFragments[fragmentKey][category][itemKey] = {
                        id: itemType.id,
                        qty: itemType.qty,
                        tool: category === 'ToolsItemTypes',
                        labor: category === 'LaborItemTypes',
                        item_type_title: itemType.title,
                        payload: {
                            template_index: templateIndex,
                            fragment_index: fragmentIndex,
                            item_type_index: itemTypeIndex,
                            template_id: templateId,
                            fragment_id: fragment.id,
                            item_type_id: itemType.id,
                            item_ids: filteredItems ? filteredItems.map(i => i.id) : [],
                        },
                        items: filteredItems
                            ? filteredItems.map(i => ({
                                id: i.id,
                                qty: i.qty,
                                unit: i.unit,
                                price: i.price,
                                title: i.title,
                                ordered_qty: i.ordered_qty,
                                vendor: i.vendor,
                                payload: {
                                    template_index: templateIndex,
                                    fragment_index: fragmentIndex,
                                    item_type_index: itemTypeIndex,
                                    template_id: templateId,
                                    fragment_id: fragment.id,
                                    item_type_id: itemType.id,
                                    item_ids: i.id ? [i.id] : [],
                                },
                            }))
                            : [],
                    };
                };

                if (itemType.labor) {
                    addItemType('LaborItemTypes');
                } else if (itemType.tool) {
                    addItemType('ToolsItemTypes');
                } else {
                    addItemType('items');
                }
            });

            setSelectedItemsTotal(Date.now());
            setSelectedLaborTotal(Date.now());
            setSelectedToolsTotal(Date.now());

            notifySuccessOrderTemplate('Products added to cart');
        }

        localStorage.setItem('selectedFragments', JSON.stringify(storedSelectedFragments));

        syncSelectionsWithLocalStorage();
    };



    useEffect(() => {
        const checkIfButtonShouldBeDisabled = () => {
            const hasItemsInFragments = fragment.data.item_types.some((itemType) => itemType.items && itemType.items.length > 0)
            setIsButtonDisabledOrderFragment(!hasItemsInFragments);
        };
        checkIfButtonShouldBeDisabled();
    }, [fragment, viewMode]);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        syncSelectionsWithLocalStorage();
    }, [fragment, fragmentIndex, templateIndex, viewMode]);

    useEffect(() => {
        getTotalPrice(selectedItems, selectedLaborItems, selectedToolItems);
    }, [selectedItems, selectedLaborItems, selectedToolItems, viewMode]);


    const syncSelectionsWithLocalStorage = () => {
        const storedSelectedFragments = JSON.parse(localStorage.getItem('selectedFragments') || '{}');
        const initialSelectedItems = storedSelectedFragments[fragmentKey]?.items || {};
        const laborSelectedItems = storedSelectedFragments[fragmentKey]?.LaborItemTypes || {};
        const toolsSelectedItems = storedSelectedFragments[fragmentKey]?.ToolsItemTypes || {};

        const updatedItemsSelections: { [key: string]: boolean } = {};
        const updatedLaborSelections: { [key: string]: boolean } = {};
        const updatedToolSelections: { [key: string]: boolean } = {};

        fragment.data.item_types.forEach((itemType, i) => {
            const itemTypeKey = generateItemTypeKey({...itemType, originalIndex: i});

            if (initialSelectedItems[itemTypeKey]) {
                if (!itemType.items || itemType.items.length === 0) {
                    const key = generateUniqueKey({...itemType, originalIndex: i}, i, itemType);
                    updatedItemsSelections[key] = initialSelectedItems[itemTypeKey].items?.some((it: {
                        id: number;
                    }) => it.id === itemType.id) || false;
                } else {
                    itemType.items?.forEach((item, index) => {
                        const key = generateUniqueKey({...itemType, originalIndex: i}, index, item);
                        updatedItemsSelections[key] = initialSelectedItems[itemTypeKey].items?.some((it: {
                            id: number;
                        }) => it.id === item.id) || false;
                    });
                }
            } else {
                if (!itemType.items || itemType.items.length === 0) {
                    const key = generateUniqueKey({...itemType, originalIndex: i}, i, itemType);
                    updatedItemsSelections[key] = false;
                } else {
                    itemType.items?.forEach((item, index) => {
                        const key = generateUniqueKey({...itemType, originalIndex: i}, index, item);
                        updatedItemsSelections[key] = false;
                    });
                }
            }

            if (laborSelectedItems[itemTypeKey]) {
                if (!itemType.items || itemType.items.length === 0) {
                    const key = generateUniqueKey({...itemType, originalIndex: i}, i, itemType);
                    updatedLaborSelections[key] = laborSelectedItems[itemTypeKey].items?.some((it: {
                        id: number;
                    }) => it.id === itemType.id) || false;
                } else {
                    itemType.items?.forEach((item, index) => {
                        const key = generateUniqueKey({...itemType, originalIndex: i}, index, item);
                        updatedLaborSelections[key] = laborSelectedItems[itemTypeKey].items?.some((it: {
                            id: number;
                        }) => it.id === item.id) || false;
                    });
                }
            } else {
                if (!itemType.items || itemType.items.length === 0) {
                    const key = generateUniqueKey({...itemType, originalIndex: i}, i, itemType);
                    updatedLaborSelections[key] = false;
                } else {
                    itemType.items?.forEach((item, index) => {
                        const key = generateUniqueKey({...itemType, originalIndex: i}, index, item);
                        updatedLaborSelections[key] = false;
                    });
                }
            }

            if (toolsSelectedItems[itemTypeKey]) {
                if (!itemType.items || itemType.items.length === 0) {
                    const key = generateUniqueKey({...itemType, originalIndex: i}, i, itemType);
                    updatedToolSelections[key] = toolsSelectedItems[itemTypeKey].items?.some((it: {
                        id: number;
                    }) => it.id === itemType.id) || false;
                } else {
                    itemType.items?.forEach((item, index) => {
                        const key = generateUniqueKey({...itemType, originalIndex: i}, index, item);
                        updatedToolSelections[key] = toolsSelectedItems[itemTypeKey].items?.some((it: {
                            id: number;
                        }) => it.id === item.id) || false;
                    });
                }
            } else {
                if (!itemType.items || itemType.items.length === 0) {
                    const key = generateUniqueKey({...itemType, originalIndex: i}, i, itemType);
                    updatedToolSelections[key] = false;
                } else {
                    itemType.items?.forEach((item, index) => {
                        const key = generateUniqueKey({...itemType, originalIndex: i}, index, item);
                        updatedToolSelections[key] = false;
                    });
                }
            }
        });

        setSelectedItemsState(updatedItemsSelections);
        setSelectedLaborItemsState(updatedLaborSelections);
        setSelectedToolItemsState(updatedToolSelections);
        getTotalPrice(updatedItemsSelections, updatedLaborSelections, updatedToolSelections);

    };

    const getTotalPrice = (items: { [key: string]: boolean }, laborItems: { [key: string]: boolean }, toolItems: {
        [key: string]: boolean
    }) => {
        let totalPrice = 0;

        fragment.data.item_types.forEach((itemType, index) => {
            if (itemType.labor) {
                if (viewMode === 'Materials|Labor|Tools') return;
            }
            if (itemType.tool) {
                if (viewMode === 'Materials|Labor|Tools') return;
            }
            if (!itemType.items || itemType.items.length === 0) {
                const key = generateUniqueKey({...itemType, originalIndex: index}, index, itemType);
                if (items[key]) {
                    totalPrice += 0;
                }
            } else {
                itemType.items?.forEach((item, i) => {
                    const key = generateUniqueKey({...itemType, originalIndex: index}, i, item);
                    if (items[key] || laborItems[key] || toolItems[key]) {
                        const priceTotalQty = parseFloat(String(item.price)) * item.qty;
                        const difTotalQty = item.ordered_qty ? Math.max(0, priceTotalQty - (item.ordered_qty * parseFloat(String(item.price)))) : priceTotalQty;
                        totalPrice += difTotalQty;
                    }
                });
            }
        });
        setTotalPrice(totalPrice);
    };

    const handleCheckboxChange = (index: number, itemIndex: number, itemType: ItemTypeWithIndex) => {
        const item = itemType.items ? itemType.items[itemIndex] : itemType;
        const key = generateUniqueKey(itemType, itemIndex, item);

        const isSelected = !selectedItems[key];
        const isLaborSelected = !selectedLaborItems[key];
        const isToolSelected = !selectedToolItems[key];

        const updatedSelections = {...selectedItems, [key]: isSelected};
        const updatedLaborSelections = {...selectedLaborItems, [key]: isLaborSelected};
        const updatedToolSelections = {...selectedToolItems, [key]: isToolSelected};

        if (itemType.labor) {
            setSelectedLaborItemsState(updatedLaborSelections);
            setSelectedLaborTotal(Date.now());
        } else if (itemType.tool) {
            setSelectedToolItemsState(updatedToolSelections);
            setSelectedToolsTotal(Date.now());
        } else {
            setSelectedItemsState(updatedSelections);
            setSelectedItemsTotal(Date.now());
        }

        updateLocalStorage(itemType, item, isSelected, isLaborSelected, isToolSelected);
        getTotalPrice(updatedSelections, updatedLaborSelections, updatedToolSelections);
    };

    const updateLocalStorage = (itemType: ItemTypeWithIndex, item: any, isSelected: boolean, isLaborSelected: boolean, isToolSelected: boolean) => {
        const storedFragments = JSON.parse(localStorage.getItem('selectedFragments') || '{}');

        if (!storedFragments[fragmentKey]) {
            storedFragments[fragmentKey] = {items: {}, LaborItemTypes: {}, ToolsItemTypes: {}};
        }

        const itemTypeKey = generateItemTypeKey(itemType);

        const fragmentItems = storedFragments[fragmentKey].items || {};
        const laborItemTypes = storedFragments[fragmentKey].LaborItemTypes || {};
        const toolsItemTypes = storedFragments[fragmentKey].ToolsItemTypes || {};

        const addItemToLocalStorage = (storage: any, item: any) => {
            if (!storage[itemTypeKey]) {
                storage[itemTypeKey] = {
                    ...itemType,
                    items: [{
                        ...item,
                        payload: generatePayload(itemType, [item.id])
                    }],
                    payload: generatePayload(itemType, itemType.items ? [item.id] : [])
                };
            } else if (itemType.items) {
                if (!storage[itemTypeKey].items) {
                    storage[itemTypeKey].items = [];
                }
                storage[itemTypeKey].items.push({
                    ...item,
                    payload: generatePayload(itemType, [item.id])
                });
                if (!storage[itemTypeKey].payload) {
                    storage[itemTypeKey].payload = {item_ids: []};
                }
                if (!storage[itemTypeKey].payload.item_ids) {
                    storage[itemTypeKey].payload.item_ids = [];
                }
                storage[itemTypeKey].payload.item_ids.push(item.id);
            }
        };

        const removeItemFromLocalStorage = (storage: any, item: any) => {
            if (storage[itemTypeKey] && itemType.items) {
                if (storage[itemTypeKey].items) {
                    storage[itemTypeKey].items = storage[itemTypeKey].items.filter((i: any) => i.id !== item.id) || [];
                }
                if (storage[itemTypeKey].payload && storage[itemTypeKey].payload.item_ids) {
                    storage[itemTypeKey].payload.item_ids = storage[itemTypeKey].payload.item_ids.filter((id: number) => id !== item.id);
                }

                if (storage[itemTypeKey].items.length === 0) {
                    delete storage[itemTypeKey];
                }
            }
        };

        switch (true) {
            case itemType.labor:
                if (isLaborSelected) {
                    addItemToLocalStorage(laborItemTypes, item);
                } else {
                    removeItemFromLocalStorage(laborItemTypes, item);
                }
                break;
            case itemType.tool:
                if (isToolSelected) {
                    addItemToLocalStorage(toolsItemTypes, item);
                } else {
                    removeItemFromLocalStorage(toolsItemTypes, item);
                }
                break;
            default:
                if (isSelected) {
                    addItemToLocalStorage(fragmentItems, item);
                } else {
                    removeItemFromLocalStorage(fragmentItems, item);
                }
                break;
        }

        storedFragments[fragmentKey] = {
            items: fragmentItems,
            LaborItemTypes: laborItemTypes,
            ToolsItemTypes: toolsItemTypes
        };

        localStorage.setItem('selectedFragments', JSON.stringify(storedFragments));
        syncSelectionsWithLocalStorage();
    };

    const generatePayload = (itemType: ItemTypeWithIndex, itemIds: number[]): any => ({
        template_index: templateIndex,
        fragment_index: fragmentIndex,
        item_type_index: itemType.originalIndex,
        template_id: templateId,
        fragment_id: fragment.id,
        item_type_id: itemType.id,
        item_ids: itemIds,
    });

    const filteredItemTypes = fragment.data.item_types
        .map((itemType, index) => ({
            ...itemType,
            originalIndex: index,
            clone_id: itemType.clone_id || index
        } as ItemTypeWithIndex))
        .filter(itemType => !itemType.labor && !itemType.tool);

    const laborItems = fragment.data.item_types
        .map((itemType, index) => ({
            ...itemType,
            originalIndex: index,
            clone_id: itemType.clone_id || index
        } as ItemTypeWithIndex))
        .filter(itemType => itemType.labor);

    const toolItems = fragment.data.item_types
        .map((itemType, index) => ({
            ...itemType,
            originalIndex: index,
            clone_id: itemType.clone_id || index
        } as ItemTypeWithIndex))
        .filter(itemType => itemType.tool);

    if (filteredItemTypes.length === 0 && laborItems.length === 0 && toolItems.length === 0) {
        return <div className="step2-div-no-materials">No product</div>;
    }

    return (
        <div>
            {filteredItemTypes.length > 0 && <h3>Products name</h3>}
            {filteredItemTypes.map((itemType: ItemTypeWithIndex, index: number) => {
                const itemTypeKey = generateItemTypeKeyVisible(itemType, index);
                if(itemType.qty === 0){
                    return null
                }

                return (
                    <React.Fragment key={`${itemType.originalIndex}`}>
                        {itemType.items && itemType.items.length > 0 && (
                            (!isSmallScreen ? (
                                <React.Fragment>
                                    <p style={{
                                        color: "#828282",
                                        marginBottom: "5px",
                                        marginLeft: "15px"
                                    }}>{itemType.title}</p>
                                </React.Fragment>
                            ) : (''))
                        )}

                        {(!itemType.items || itemType.items.length === 0) && (
                            !isSmallScreen ? (
                                <div key={itemType.originalIndex} className='step2-item-type-list'>
                                    <div className='step2-material-width-list'>
                                        <label style={{color: "#828282"}}>{itemType.title}</label>
                                    </div>
                                    <p className="step2-other-width-list">0</p>
                                    <p className="step2-other-width-list">{itemType.qty}</p>
                                    <p className="step2-other-width-list">R 0.00</p>
                                    <p className="step2-other-width-list">R 0.00</p>
                                    <p className="step2-other-width-list-total">R 0.00</p>
                                    <div className='step2-other-width-vendor-list'></div>
                                </div>
                            ) : (
                                <div key={itemType.originalIndex} className={`step2-item-type-list`}>
                                    <div
                                        className={`step2-item-checkbox-block ${isVisible(itemTypeKey) ? 'visible' : 'hidden'}`}>
                                        <label style={{color: "#828282"}}>{itemType.title}</label>
                                        <div onClick={() => toggleVisibility(itemTypeKey)}
                                             className={isVisible(itemTypeKey) ? 'icon-arrow-light-step2-up' : 'icon-arrow-light-step2-down'}>
                                            <IconArrowLightStpe2/>
                                        </div>
                                    </div>

                                    <div
                                        className={`step2-content-list ${isVisible(itemTypeKey) ? 'visible' : 'hidden'}`}>
                                        <p className="step2-other-width-list">
                                            <span>Calculated QTY:</span>
                                            <span className="dashed-line"></span>0
                                        </p>
                                        <p className="step2-other-width-list">
                                            <span>QTY ordered:</span>
                                            <span className="dashed-line"></span>{itemType.qty}
                                        </p>
                                        <p className="step2-other-width-list">
                                            <span>Unit price:</span>
                                            <span className="dashed-line"></span>R 0.00
                                        </p>
                                        <p className="step2-other-width-list">
                                            <span>Total:</span>
                                            <span className="dashed-line"></span>R 0.00
                                        </p>
                                        <p className="step2-other-width-list-total total-ordred-bold">
                                            <span>Total ordered:</span>
                                            <span className="dashed-line"></span>R 0.00
                                        </p>
                                    </div>
                                </div>
                            )
                        )}

                        {itemType.items && itemType.items.map((item, itemIndex) => {
                            const key = generateUniqueKey(itemType, itemIndex, item);
                            const price = parseFloat(String(item.price)) || 0;
                            const orderedQty = item.ordered_qty || 0;
                            const difPrice = orderedQty * price;
                            const totalDifPrice = Math.max(0, (item.qty * price) - (orderedQty * price));
                            const itemKeyVisible = generateItemKeyVisible(item, itemIndex);
                            return (
                                !isSmallScreen ? (
                                    <div key={`${itemType.originalIndex}-${itemIndex}`}
                                         className='step2-item-type-list'>
                                        <div className='step2-material-width-list'>
                                            <div className="step2-item-checkbox-block">
                                                <input
                                                    type="checkbox"
                                                    id={`checkbox-${fragment.id}-${fragmentIndex}-${itemType.originalIndex}-${itemIndex}`}
                                                    checked={!!selectedItems[key] || !!selectedLaborItems[key] || !!selectedToolItems[key]}
                                                    onChange={() => handleCheckboxChange(index, itemIndex, itemType)}
                                                />
                                                <label
                                                    htmlFor={`checkbox-${fragment.id}-${fragmentIndex}-${itemType.originalIndex}-${itemIndex}`}>
                                                    {item.title}
                                                </label>
                                            </div>
                                        </div>
                                        <p className="step2-other-width-list">{orderedQty} ({item.unit})</p>
                                        <p className="step2-other-width-list">{item.qty} ({item.unit})</p>
                                        <p className="step2-other-width-list">R {price.toFixed(2)}</p>
                                        <p className="step2-other-width-list">R {isNaN(difPrice) ? 0 : difPrice.toFixed(2)}</p>
                                        <p className="step2-other-width-list-total">R {isNaN(totalDifPrice) ? 0 : totalDifPrice.toFixed(2)}</p>
                                        <div className='step2-other-width-vendor-list'>
                                            {item.vendor !== null && vendorData[item.vendor as Vendor] ? (
                                                <p className='item-selection-modal-vendor'>{vendorData[item.vendor as Vendor].displayName}</p>
                                            ) : (
                                                <p className='item-selection-modal-no-vendor'>No Vendor</p>
                                            )}

                                        </div>
                                    </div>

                                ) : (
                                    <div key={`${itemType.originalIndex}-${itemIndex}`}
                                         className='step2-item-type-list'>
                                        <div className='step2-material-width-list'>
                                            <div
                                                className={`step2-item-checkbox-block ${isVisible(itemKeyVisible) ? 'visible' : 'hidden'}`}>
                                                <input
                                                    type="checkbox"
                                                    id={`checkbox-${fragment.id}-${fragmentIndex}-${itemType.originalIndex}-${itemIndex}`}
                                                    checked={!!selectedItems[key] || !!selectedLaborItems[key] || !!selectedToolItems[key]}
                                                    onChange={() => handleCheckboxChange(index, itemIndex, itemType)}
                                                />
                                                <label
                                                    htmlFor={`checkbox-${fragment.id}-${fragmentIndex}-${itemType.originalIndex}-${itemIndex}`}>
                                                    {item.title}
                                                </label>
                                                <div onClick={() => toggleVisibility(itemKeyVisible)}
                                                     className={isVisible(itemKeyVisible) ? 'icon-arrow-light-step2-up' : 'icon-arrow-light-step2-down'}>
                                                    <IconArrowLightStpe2/>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className={`step2-content-list ${isVisible(itemKeyVisible) ? 'visible' : 'hidden'}`}>
                                            <p className="step2-other-width-list">
                                                <span>Calculated QTY:</span><span
                                                className="dashed-line"></span>{orderedQty} ({item.unit})
                                            </p>
                                            <p className="step2-other-width-list">
                                                <span>QTY ordered:</span><span
                                                className="dashed-line"></span>{item.qty} ({item.unit})
                                            </p>
                                            <p className="step2-other-width-list">
                                                <span>Unit price:</span><span
                                                className="dashed-line"></span>R {price.toFixed(2)}
                                            </p>
                                            <p className="step2-other-width-list">
                                                <span>Total:</span><span
                                                className="dashed-line"></span>R {isNaN(difPrice) ? 0 : difPrice.toFixed(2)}
                                            </p>
                                            <p className="step2-other-width-list-total">
                                                <span>Total ordered:</span><span
                                                className="dashed-line"></span>R {isNaN(totalDifPrice) ? 0 : totalDifPrice.toFixed(2)}
                                            </p>
                                            <div className='step2-other-width-vendor-list'>
                                                {item.vendor !== null && vendorData[item.vendor as Vendor] ? (
                                                    <p className='item-selection-modal-vendor'>{vendorData[item.vendor as Vendor].displayName}</p>
                                                ) : (
                                                    <p className='item-selection-modal-no-vendor'>No Vendor</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )
                            );
                        })}
                    </React.Fragment>
                )
            })}

            {viewMode === 'Work Stage' && (
                <>
                    {laborItems.length > 0 && (
                        <>
                            <h3>Labor Items</h3>
                            {laborItems.map((itemType: ItemTypeWithIndex, index: number) => {
                                const itemTypeKey = generateItemTypeKeyVisible(itemType, index);
                                if(itemType.qty === 0){
                                    return null
                                }

                                return (
                                    <React.Fragment key={`${itemType.originalIndex}`}>
                                        {itemType.items && itemType.items.length > 0 && (
                                            (!isSmallScreen ? (
                                                <React.Fragment>
                                                    <p className='item-type-name-before-select'>{itemType.title}</p>
                                                </React.Fragment>
                                            ) : (''))
                                        )}

                                        {(!itemType.items || itemType.items.length === 0) && (
                                            !isSmallScreen ? (
                                                <div key={itemType.originalIndex} className='step2-item-type-list'>
                                                    <div className='step2-material-width-list'>
                                                        <label style={{color: "#828282"}}>{itemType.title}</label>
                                                    </div>
                                                    <p className="step2-other-width-list">0</p>
                                                    <p className="step2-other-width-list">{itemType.qty}<span
                                                        className='labor-hours'>(each)</span></p>
                                                    <p className="step2-other-width-list">R 0.00</p>
                                                    <p className="step2-other-width-list">R 0.00</p>
                                                    <p className="step2-other-width-list-total">R 0.00</p>
                                                    <div className='step2-other-width-vendor-list'></div>
                                                </div>
                                            ) : (
                                                <div key={itemType.originalIndex} className={`step2-item-type-list`}>
                                                    <div
                                                        className={`step2-item-checkbox-block ${isVisible(itemTypeKey) ? 'visible' : 'hidden'}`}>
                                                        <label style={{color: "#828282"}}>{itemType.title}</label>
                                                        <div onClick={() => toggleVisibility(itemTypeKey)}
                                                             className={isVisible(itemTypeKey) ? 'icon-arrow-light-step2-up' : 'icon-arrow-light-step2-down'}>
                                                            <IconArrowLightStpe2/>
                                                        </div>
                                                    </div>

                                                    <div
                                                        className={`step2-content-list ${isVisible(itemTypeKey) ? 'visible' : 'hidden'}`}>
                                                        <p className="step2-other-width-list">
                                                            <span>Calculated QTY:</span>
                                                            <span className="dashed-line"></span>0<span
                                                            className='labor-hours'>(each)</span>
                                                        </p>
                                                        <p className="step2-other-width-list">
                                                            <span>QTY ordered:</span>
                                                            <span className="dashed-line"></span>{itemType.qty}
                                                        </p>
                                                        <p className="step2-other-width-list">
                                                            <span>Unit price:</span>
                                                            <span className="dashed-line"></span>R 0.00
                                                        </p>
                                                        <p className="step2-other-width-list">
                                                            <span>Total:</span>
                                                            <span className="dashed-line"></span>R 0.00
                                                        </p>
                                                        <p className="step2-other-width-list-total total-ordred-bold">
                                                            <span>Total ordered:</span>
                                                            <span className="dashed-line"></span>R 0.00
                                                        </p>
                                                    </div>
                                                </div>
                                            )
                                        )}

                                        {itemType.items && itemType.items.map((item, itemIndex) => {
                                            const key = generateUniqueKey(itemType, itemIndex, item);
                                            const price = parseFloat(String(item.price)) || 0;
                                            const orderedQty = item.ordered_qty || 0;
                                            const difPrice = orderedQty * price;
                                            const totalDifPrice = Math.max(0, (item.qty * price) - (orderedQty * price));
                                            const itemKeyVisible = generateItemKeyVisible(item, itemIndex);

                                            return (
                                                !isSmallScreen ? (
                                                    <div key={`${itemType.originalIndex}-${itemIndex}`}
                                                         className='step2-item-type-list'>
                                                        <div className='step2-material-width-list'>
                                                            <div className="step2-item-checkbox-block">
                                                                <input
                                                                    type="checkbox"
                                                                    id={`checkbox-${fragment.id}-${fragmentIndex}-${itemType.originalIndex}-${itemIndex}`}
                                                                    checked={selectedLaborItems[key]}
                                                                    onChange={() => handleCheckboxChange(index, itemIndex, itemType)}
                                                                />
                                                                <label
                                                                    htmlFor={`checkbox-${fragment.id}-${fragmentIndex}-${itemType.originalIndex}-${itemIndex}`}>
                                                                    {item.title}
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <p className="step2-other-width-list">{orderedQty} ({item.unit})</p>
                                                        <p className="step2-other-width-list">{item.qty} <span
                                                            className='labor-hours'>({item.unit ? item.unit : 'each'})</span></p>
                                                        <p className="step2-other-width-list">R {price.toFixed(2)}</p>
                                                        <p className="step2-other-width-list">R {isNaN(difPrice) ? 0 : difPrice.toFixed(2)}</p>
                                                        <p className="step2-other-width-list-total">R {isNaN(totalDifPrice) ? 0 : totalDifPrice.toFixed(2)}</p>
                                                        <div className='step2-other-width-vendor-list'>
                                                            {item.vendor !== null && vendorData[item.vendor as Vendor] ? (
                                                                <p className='item-selection-modal-vendor'>{vendorData[item.vendor as Vendor].displayName}</p>
                                                            ) : (
                                                                <p className='item-selection-modal-no-vendor'>No Vendor</p>
                                                            )}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div key={`${itemType.originalIndex}-${itemIndex}`}
                                                         className='step2-item-type-list'>
                                                        <div className='step2-material-width-list'>
                                                            <div
                                                                className={`step2-item-checkbox-block ${isVisible(itemKeyVisible) ? 'visible' : 'hidden'}`}>
                                                                <input
                                                                    type="checkbox"
                                                                    id={`checkbox-${fragment.id}-${fragmentIndex}-${itemType.originalIndex}-${itemIndex}`}
                                                                    checked={selectedLaborItems[key]}
                                                                    onChange={() => handleCheckboxChange(index, itemIndex, itemType)}
                                                                />
                                                                <label
                                                                    htmlFor={`checkbox-${fragment.id}-${fragmentIndex}-${itemType.originalIndex}-${itemIndex}`}>
                                                                    {item.title}
                                                                </label>
                                                                <div onClick={() => toggleVisibility(itemKeyVisible)}
                                                                     className={isVisible(itemTypeKey) ? 'icon-arrow-light-step2-up' : 'icon-arrow-light-step2-down'}>
                                                                    <IconArrowLightStpe2/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={`step2-content-list ${isVisible(itemKeyVisible) ? 'visible' : 'hidden'}`}>

                                                            <p className="step2-other-width-list">
                                                                <span>Calculated QTY:</span><span
                                                                className="dashed-line"></span>{orderedQty} ({item.unit})
                                                            </p>
                                                            <p className="step2-other-width-list">
                                                                <span>QTY ordered:</span><span
                                                                className="dashed-line"></span>{item.qty} <span
                                                                className='labor-hours'>(each)</span>
                                                            </p>
                                                            <p className="step2-other-width-list">
                                                                <span>Unit price:</span><span
                                                                className="dashed-line"></span>R {price.toFixed(2)}
                                                            </p>
                                                            <p className="step2-other-width-list">
                                                                <span>Total:</span><span
                                                                className="dashed-line"></span>R {isNaN(difPrice) ? 0 : difPrice.toFixed(2)}
                                                            </p>
                                                            <p className="step2-other-width-list-total">
                                                                <span>Total ordered:</span><span
                                                                className="dashed-line"></span>R {isNaN(totalDifPrice) ? 0 : totalDifPrice.toFixed(2)}
                                                            </p>
                                                            <div className='step2-other-width-vendor-list'>
                                                                {item.vendor !== null && vendorData[item.vendor as Vendor] ? (
                                                                    <p className='item-selection-modal-vendor'>{vendorData[item.vendor as Vendor].displayName}</p>
                                                                ) : (
                                                                    <p className='item-selection-modal-no-vendor'>No Vendor</p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            );
                                        })}
                                    </React.Fragment>
                                )
                            })}
                        </>
                    )}

                    {toolItems.length > 0 && (
                        <>
                            <h3>Tool Items</h3>
                            {toolItems.map((itemType: ItemTypeWithIndex, index: number) => {
                                const itemTypeKey = generateItemTypeKeyVisible(itemType, index);
                                if(itemType.qty === 0){
                                    return null
                                }
                                return (
                                    <React.Fragment key={`${itemType.originalIndex}`}>
                                        {itemType.items && itemType.items.length > 0 && (
                                            (!isSmallScreen ? (
                                                <React.Fragment>
                                                    <p className='item-type-name-before-select'>{itemType.title}</p>
                                                </React.Fragment>
                                            ) : (''))
                                        )}

                                        {(!itemType.items || itemType.items.length === 0) && (
                                            !isSmallScreen ? (
                                                <div key={itemType.originalIndex} className='step2-item-type-list'>
                                                    <div className='step2-material-width-list'>
                                                        <label style={{color: "#828282"}}>{itemType.title}</label>
                                                    </div>
                                                    <p className="step2-other-width-list">0</p>
                                                    <p className="step2-other-width-list">{itemType.qty}</p>
                                                    <p className="step2-other-width-list">R 0.00</p>
                                                    <p className="step2-other-width-list">R 0.00</p>
                                                    <p className="step2-other-width-list-total">R 0.00</p>
                                                    <div className='step2-other-width-vendor-list'></div>
                                                </div>
                                            ) : (
                                                <div key={itemType.originalIndex} className={`step2-item-type-list`}>
                                                    <div
                                                        className={`step2-item-checkbox-block ${isVisible(itemTypeKey) ? 'visible' : 'hidden'}`}>
                                                        <label style={{color: "#828282"}}>{itemType.title}</label>
                                                        <div onClick={() => toggleVisibility(itemTypeKey)}
                                                             className={isVisible(itemTypeKey) ? 'icon-arrow-light-step2-up' : 'icon-arrow-light-step2-down'}>
                                                            <IconArrowLightStpe2/>
                                                        </div>
                                                    </div>

                                                    <div
                                                        className={`step2-content-list ${isVisible(itemTypeKey) ? 'visible' : 'hidden'}`}>
                                                        <p className="step2-other-width-list">
                                                            <span>Calculated QTY:</span>
                                                            <span className="dashed-line"></span>0
                                                        </p>
                                                        <p className="step2-other-width-list">
                                                            <span>QTY ordered:</span>
                                                            <span className="dashed-line"></span>{itemType.qty}
                                                        </p>
                                                        <p className="step2-other-width-list">
                                                            <span>Unit price:</span>
                                                            <span className="dashed-line"></span>R 0.00
                                                        </p>
                                                        <p className="step2-other-width-list">
                                                            <span>Total:</span>
                                                            <span className="dashed-line"></span>R 0.00
                                                        </p>
                                                        <p className="step2-other-width-list-total total-ordred-bold">
                                                            <span>Total ordered:</span>
                                                            <span className="dashed-line"></span>R 0.00
                                                        </p>
                                                    </div>
                                                </div>
                                            )
                                        )}

                                        {itemType.items && itemType.items.map((item, itemIndex) => {
                                            const key = generateUniqueKey(itemType, itemIndex, item);
                                            const price = parseFloat(String(item.price)) || 0;
                                            const orderedQty = item.ordered_qty || 0;
                                            const difPrice = orderedQty * price;
                                            const totalDifPrice = Math.max(0, (item.qty * price) - (orderedQty * price));
                                            const itemKeyVisible = generateItemKeyVisible(item, itemIndex);

                                            return (
                                                !isSmallScreen ? (
                                                    <div key={`${itemType.originalIndex}-${itemIndex}`}
                                                         className='step2-item-type-list'>
                                                        <div className='step2-material-width-list'>
                                                            <div className="step2-item-checkbox-block">
                                                                <input
                                                                    type="checkbox"
                                                                    id={`checkbox-${fragment.id}-${fragmentIndex}-${itemType.originalIndex}-${itemIndex}`}
                                                                    checked={!!selectedToolItems[key]}
                                                                    onChange={() => handleCheckboxChange(index, itemIndex, itemType)}
                                                                />
                                                                <label
                                                                    htmlFor={`checkbox-${fragment.id}-${fragmentIndex}-${itemType.originalIndex}-${itemIndex}`}>
                                                                    {item.title}
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <p className="step2-other-width-list">{orderedQty} ({item.unit})</p>
                                                        <p className="step2-other-width-list">{item.qty} ({item.unit})</p>
                                                        <p className="step2-other-width-list">R {price.toFixed(2)}</p>
                                                        <p className="step2-other-width-list">R {isNaN(difPrice) ? 0 : difPrice.toFixed(2)}</p>
                                                        <p className="step2-other-width-list-total">R {isNaN(totalDifPrice) ? 0 : totalDifPrice.toFixed(2)}</p>
                                                        <div className='step2-other-width-vendor-list'>
                                                            {item.vendor !== null && vendorData[item.vendor as Vendor] ? (
                                                                <p className='item-selection-modal-vendor'>{vendorData[item.vendor as Vendor].displayName}</p>
                                                            ) : (
                                                                <p className='item-selection-modal-no-vendor'>No Vendor</p>
                                                            )}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div key={`${itemType.originalIndex}-${itemIndex}`}
                                                         className='step2-item-type-list'>
                                                        <div className='step2-material-width-list'>
                                                            <div
                                                                className={`step2-item-checkbox-block ${isVisible(itemKeyVisible) ? 'visible' : 'hidden'}`}>
                                                                <input
                                                                    type="checkbox"
                                                                    id={`checkbox-${fragment.id}-${fragmentIndex}-${itemType.originalIndex}-${itemIndex}`}
                                                                    checked={!!selectedToolItems[key]}
                                                                    onChange={() => handleCheckboxChange(index, itemIndex, itemType)}
                                                                />
                                                                <label
                                                                    htmlFor={`checkbox-${fragment.id}-${fragmentIndex}-${itemType.originalIndex}-${itemIndex}`}>
                                                                    {item.title}
                                                                </label>
                                                                <div onClick={() => toggleVisibility(itemKeyVisible)}
                                                                     className={isVisible(itemKeyVisible) ? 'icon-arrow-light-step2-up' : 'icon-arrow-light-step2-down'}>
                                                                    <IconArrowLightStpe2/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={`step2-content-list ${isVisible(itemKeyVisible) ? 'visible' : 'hidden'}`}>

                                                            <p className="step2-other-width-list">
                                                                <span>Calculated QTY:</span><span
                                                                className="dashed-line"></span>{orderedQty} ({item.unit})
                                                            </p>
                                                            <p className="step2-other-width-list">
                                                                <span>QTY ordered:</span><span
                                                                className="dashed-line"></span>{item.qty} ({item.unit})
                                                            </p>
                                                            <p className="step2-other-width-list">
                                                                <span>Unit price:</span><span
                                                                className="dashed-line"></span>R {price.toFixed(2)}
                                                            </p>
                                                            <p className="step2-other-width-list">
                                                                <span>Total:</span><span
                                                                className="dashed-line"></span>R {isNaN(difPrice) ? 0 : difPrice.toFixed(2)}
                                                            </p>
                                                            <p className="step2-other-width-list-total">
                                                                <span>Total ordered:</span><span
                                                                className="dashed-line"></span>R {isNaN(totalDifPrice) ? 0 : totalDifPrice.toFixed(2)}
                                                            </p>
                                                            <div className='step2-other-width-vendor-list'>
                                                                {item.vendor !== null && vendorData[item.vendor as Vendor] ? (
                                                                    <p className='item-selection-modal-vendor'>{vendorData[item.vendor as Vendor].displayName}</p>
                                                                ) : (
                                                                    <p className='item-selection-modal-no-vendor'>No Vendor</p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            );
                                        })}
                                    </React.Fragment>
                                )
                            })}
                        </>
                    )}
                </>
            )}
            <div className='step2-bottom-block'>
                {!isSmallScreen ? (
                    <>
                        <p>Total:</p>
                        {totalPrice ? (
                            <span> R {totalPrice.toFixed(2)}</span>
                        ) : (
                            <span> R 0</span>
                        )}
                        <button
                            onClick={() => handleOrderFragment(fragment.id, fragmentIndex)}
                            className='step2-bottom-block-btn-cart-list'
                            style={{
                                backgroundColor: isButtonDisabledOrderFragment ? '#cccccc' : '',
                                cursor: isButtonDisabledOrderFragment ? 'not-allowed' : 'pointer'
                            }}>Add products to cart
                        </button>
                    </>
                ) : (
                    <>
                        <div className='step2-bottom-block-mobile'>
                            <p>Total: </p>
                            <span className="dashed-line"></span>
                            {totalPrice ? (
                                <span className='mobile-total-price-red'> R {totalPrice.toFixed(2)}</span>
                            ) : (
                                <span className='mobile-total-price-red'> R 0</span>
                            )}
                        </div>
                        <button
                            onClick={() => handleOrderFragment(fragment.id, fragmentIndex)}
                            className='step2-bottom-block-btn-cart-list'
                            style={{
                                backgroundColor: isButtonDisabledOrderFragment ? '#cccccc' : '',
                                cursor: isButtonDisabledOrderFragment ? 'not-allowed' : 'pointer'
                            }}>Add products to cart
                        </button>
                    </>
                )}
            </div>

        </div>
    );

};

const mapStateToProps = (state: RootState) => ({
    selectedLabor: state.selectedItems.selectedLabor,
    selectedTools: state.selectedItems.selectedTools,
});

const mapDispatchToProps = {
    setSelectedItemsTotal,
    setSelectedToolsTotal,
    setSelectedLaborTotal,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ItemTypeList);
