// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.edit-user-modal {
    background: white;
    border-radius: 10px;
    width: 400px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.edit-user-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
}

.edit-user-modal-header h2 {
    margin: 0;
}

.close-button {
    background: none;
    border: none;
    color: #979797;
    font-size: 2rem;
    cursor: pointer;
}

.edit-user-modal-content label {
    display: block;
    margin-bottom: 10px;
}

.edit-user-modal-content input,
.edit-user-modal-content select {
    width: calc(100% - 10px);
    padding: 5px;
    margin-top: 5px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.edit-user-modal-content .readonly-input {
    background-color: #f0f0f0;
    cursor: not-allowed;
}

.edit-user-modal-footer {
    display: flex;
    justify-content: center;
}

`, "",{"version":3,"sources":["webpack://./src/components/Modals/ModalUserInformation/userInformationModalRole.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,yCAAyC;AAC7C;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,cAAc;IACd,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,mBAAmB;AACvB;;AAEA;;IAEI,wBAAwB;IACxB,YAAY;IACZ,eAAe;IACf,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,uBAAuB;AAC3B","sourcesContent":[".overlay {\n    background-color: rgba(0, 0, 0, 0.5);\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.edit-user-modal {\n    background: white;\n    border-radius: 10px;\n    width: 400px;\n    padding: 20px;\n    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n}\n\n.edit-user-modal-header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 20px;\n    position: relative;\n}\n\n.edit-user-modal-header h2 {\n    margin: 0;\n}\n\n.close-button {\n    background: none;\n    border: none;\n    color: #979797;\n    font-size: 2rem;\n    cursor: pointer;\n}\n\n.edit-user-modal-content label {\n    display: block;\n    margin-bottom: 10px;\n}\n\n.edit-user-modal-content input,\n.edit-user-modal-content select {\n    width: calc(100% - 10px);\n    padding: 5px;\n    margin-top: 5px;\n    margin-bottom: 20px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n}\n\n.edit-user-modal-content .readonly-input {\n    background-color: #f0f0f0;\n    cursor: not-allowed;\n}\n\n.edit-user-modal-footer {\n    display: flex;\n    justify-content: center;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
