import React, {ChangeEvent, useEffect, useState} from 'react';
import Modal from 'react-modal';
import {TItem} from '../../../types/TemplateType';
import 'react-toastify/dist/ReactToastify.css';
import {toastItemCheckboxFalse, toastItemTrue} from '../../../components/Toast/AssisCreateToastNotification';
import {
    IconArrowBottom,
    IconArrowTop,
    IconCheckItemAvailable,
    IconResetInput,
    IconSearch
} from '../../../IconComponents/IconComponents';
import {Loader} from '../../../components/Loader/Loader';
import PaginationInModal from '../../../components/PaginationAssistance/paginationInModal';
import {toast} from 'react-toastify';
import ProgressBar from "../../Step2/ItemProgressBar";
import {Vendor, vendorData} from "../../../Enums/VendorEnum";

interface ItemsBomModalProps {
    isLoading: boolean;
    isOpen: boolean;
    onClose: () => void;
    items: TItem[];
    currentPage: number;
    totalItems: number;
    itemsPerPage: number;
    onPageChange: (page: number) => void;
    onSortChange: (direction: 'asc' | 'desc') => void;
    onSearch?: (query: React.ChangeEvent<HTMLInputElement>) => void;
    totalSearchResults?: number;
    onSelectItems: (selectedItems: TItem[]) => void;
    initialSelectedItems?: TItem[];
    itemTypeQtyByFragment: number;
    itemTypeUnit?:string
}

const FragmentComponentModal: React.FC<ItemsBomModalProps> = ({
                                                                  isLoading,
                                                                  isOpen,
                                                                  onClose,
                                                                  items = [],
                                                                  currentPage,
                                                                  totalItems,
                                                                  itemsPerPage,
                                                                  onPageChange,
                                                                  onSearch,
                                                                  onSortChange,
                                                                  onSelectItems,
                                                                  initialSelectedItems = [],
                                                                  totalSearchResults,
                                                                  itemTypeQtyByFragment,
                                                                  itemTypeUnit
                                                              }) => {
    const [userItemQuantities, setUserItemQuantities] = useState<{ [id: number]: number }>({});
    const [searchTerm, setSearchTerm] = useState('');
    const [searchTimer, setSearchTimer] = useState<NodeJS.Timeout | null>(null);
    const [totalProgress, setTotalProgress] = useState(0);
    const [allItemsData, setAllItemsData] = useState<{ [id: number]: TItem }>({});

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
            initializeState();
        } else {
            document.body.style.overflow = '';
            resetState();
        }
        return () => {
            document.body.style.overflow = '';
        };
    }, [isOpen, initialSelectedItems]);

    useEffect(() => {
        setAllItemsData(prev => {
            const newAllItems = { ...prev };
            items.forEach(item => {
                newAllItems[item.id] = item;
            });
            return newAllItems;
        });
    }, [items]);

    const resetState = () => {
        setUserItemQuantities({});
        setSearchTerm('');
        onPageChange(1)
    };

    const initializeState = () => {
        const initialQuantities = initialSelectedItems.reduce((acc: { [id: number]: number }, item: TItem) => {
            acc[item.id] = item.qty || 0;
            return acc;
        }, {});
        setUserItemQuantities(prev => ({ ...prev, ...initialQuantities }));
    };

    const handleConfirmSelection = () => {
        const addedItems = Object.entries(userItemQuantities)
            .filter(([id, qty]) => qty > 0)
            .map(([id, qty]) => {
                const item = allItemsData[Number(id)];
                return {
                    ...item,
                    qty: qty,
                };
            });

        if (addedItems.length === 0) {
            toast.error(`You must select at least one product.`);
            return;
        }

        onSelectItems(addedItems);
        onClose();
    };

    const calculateTotalProgress = (quantities: { [id: number]: number } = userItemQuantities) => {
        return Object.entries(quantities).reduce((sum, [itemId, qty]) => {
            const currentItem = allItemsData[Number(itemId)];
            let packagingValue = 1;
            if (currentItem && currentItem.packaging && typeof currentItem.packaging === 'object') {
                const firstPackagingValue = Object.values(currentItem.packaging)[0];
                packagingValue =
                    typeof firstPackagingValue === 'number' && !isNaN(firstPackagingValue)
                        ? firstPackagingValue
                        : 1;
            }
            return sum + Math.ceil(qty * packagingValue);
        }, 0);
    };

    const handleQtyChange = (itemId: number, newQty: number) => {
        setUserItemQuantities((prev) => {
            const updatedQuantities = { ...prev, [itemId]: Math.max(0, newQty) };
            setTotalProgress(calculateTotalProgress(updatedQuantities));
            return updatedQuantities;
        });
    };

    const handleFillOutItemQty = (item: TItem) => {
        const totalQty = Object.entries(userItemQuantities).reduce((sum, [itemId, qty]) => {
            const currentItem = allItemsData[Number(itemId)];
            if (!currentItem) return sum;

            let packagingValue = 1;
            if (currentItem.packaging && typeof currentItem.packaging === 'object') {
                const firstPackagingValue = Object.values(currentItem.packaging)[0];
                packagingValue =
                    typeof firstPackagingValue === 'number' && !isNaN(firstPackagingValue)
                        ? firstPackagingValue
                        : 1;
            }

            return sum + Math.ceil(qty * packagingValue);
        }, 0);

        const remainingQty = Math.max(itemTypeQtyByFragment - totalQty, 0);
        const userInputValue = userItemQuantities[item.id] || 0;

        let packagingValue = 1;
        if (item.packaging && typeof item.packaging === 'object') {
            const firstPackagingValue = Object.values(item.packaging)[0];
            packagingValue =
                typeof firstPackagingValue === 'number' && !isNaN(firstPackagingValue)
                    ? firstPackagingValue
                    : 1;
        }

        if (userInputValue > 0) {
            handleClearItemQty(item.id);
            return;
        }

        if (remainingQty > 0) {
            const adjustedQty = Math.ceil(remainingQty / packagingValue);
            setUserItemQuantities(prevState => ({
                ...prevState,
                [item.id]: adjustedQty
            }));
            toastItemTrue(
                `Field "${item.title}" filled with ${adjustedQty} units (packaging: ${packagingValue}).`
            );
        } else {
            toast.error(`No remaining quantity to distribute.`);
        }
    };

    const handleClearItemQty = (itemId: number) => {
        setUserItemQuantities((prev) => ({
            ...prev,
            [itemId]: 0,
        }));
        const item = allItemsData[itemId];
        if (item) {
            toastItemCheckboxFalse(`Field "${item.title}" cleared`);
        }
    };

    const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const query = e.target.value;
        setSearchTerm(query);
        if (searchTimer) {
            clearTimeout(searchTimer);
        }
        const timer = setTimeout(() => {
            if (onSearch) {
                onSearch(e);
            }
        }, 500);
        setSearchTimer(timer);
    };

    const calculateTotalQty = () => {
        return Object.values(userItemQuantities).reduce((total, qty) => total + qty, 0);
    };

    const handleResetQuantities = () => {
        setUserItemQuantities(
            items.reduce<{ [id: number]: number }>((acc, item) => {
                acc[item.id] = 0;
                return acc;
            }, {})
        );
        toastItemTrue('All input fields have been reset.');
    };

    const highlightMatch = (text: string, match: string) => {
        if (!match) return text;
        const regex = new RegExp(`(${match})`, 'gi');
        return text.replace(regex, (matched) => `<span class="item-selection-modal-highlight">${matched}</span>`);
    };

    const totalQty = calculateTotalQty();

    useEffect(() => {
        setTotalProgress(calculateTotalProgress());
    }, [userItemQuantities, items]);

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            className="item-selection-modal-content"
            overlayClassName="item-selection-modal-overlay"
            ariaHideApp={false}
        >
            <section className="item-selection-modal-section">
                <div className="item-selection-modal-header">
                    <div className="item-selection-modal-header-block-text">
                        <h2 className="item-selection-modal-title">Please, select your product</h2>
                    </div>
                    <button className="item-selection-modal-close-button" onClick={onClose}>
                        &#x2715;
                    </button>
                </div>

                <div className="item-selection-modal-content-block">
                    <div className="item-selection-modal-search">
                        <div className="item-selection-modal-search-icon">
                            <IconSearch />
                        </div>
                        <input
                            className="item-selection-modal-search-input"
                            placeholder="Search"
                            type="search"
                            value={searchTerm}
                            onChange={handleSearchInputChange}
                        />
                    </div>
                    <ProgressBar unit={itemTypeUnit ? itemTypeUnit : 'each'} total={itemTypeQtyByFragment} current={totalProgress}/>
                </div>
                <div className="item-selection-modal-sort-icons">
                    <div className="item-selection-modal-sort-buttons">
                        <div className='item-selection-modal-sort-button' onClick={handleResetQuantities}>
                            <IconResetInput/>
                        </div>
                        <div className="item-selection-modal-sort-button" onClick={() => onSortChange('desc')}>
                            <IconArrowTop/>
                        </div>
                        <div className="item-selection-modal-sort-button" onClick={() => onSortChange('asc')}>
                            <IconArrowBottom/>
                        </div>
                    </div>
                </div>
                {isLoading ? (
                    <Loader/>
                ) : (
                    <ul className="item-selection-modal-list">
                        {items.length === 0 && <p className="fragment-component-no-items">No items</p>}
                        {items.map((item: TItem) => {
                            const initialQty = userItemQuantities[item.id] || 0;

                            let packagingValue = 1;
                            if (item.packaging && typeof item.packaging === 'object') {
                                const firstPackagingValue = Object.values(item.packaging)[0];
                                packagingValue =
                                    typeof firstPackagingValue === 'number' && !isNaN(firstPackagingValue)
                                        ? firstPackagingValue
                                        : 1;
                            }

                            const totalProgress = calculateTotalProgress();
                            const isDisabled = initialQty === 0 && totalProgress >= itemTypeQtyByFragment;

                            return (
                                <li key={item.id} className="item-selection-modal-list-item">
                                    <section className="item-type-bom-modal-list-item-content">
                                        <div className="item-selection-modal-list-item-image">
                                            <img
                                                className="item-selection-modal-image"
                                                src={
                                                    item.merchant_info?.image ||
                                                    (item.galleries?.[0]?.medias?.[0]?.url) ||
                                                    '/img_vendor/item.png'
                                                }
                                                alt="Item"
                                            />
                                            <section className="item-selection-modal-list-item-info">
                                                <p
                                                    className="item-selection-modal-item-title"
                                                    dangerouslySetInnerHTML={{
                                                        __html: highlightMatch(item.title, searchTerm),
                                                    }}
                                                />
                                                <p className="item-selection-modal-item-price">
                                                    R {item.price ? Number(item.price).toFixed(2) : '0.00'}
                                                </p>
                                            </section>
                                            <div className='item-packaging'>
                                                {item.packaging && Object.keys(item.packaging).length > 0 ? (
                                                    <>
                                <span className='item-packaging-value'>
                                    {item.packaging[Object.keys(item.packaging)[0]]}
                                </span>
                                                        <p className='item-packaging-key'>
                                                            {Object.keys(item.packaging)[0]}
                                                        </p>
                                                    </>
                                                ) : (
                                                    <p className='item-packaging-none'>{item.unit}</p>
                                                )}
                                            </div>
                                        </div>

                                        <div className='item-selection-modal-action-block-img'>
                                            {item.vendor !== null && vendorData[item.vendor as Vendor] ? (
                                                <p className='item-selection-modal-vendor'>{vendorData[item.vendor as Vendor].displayName}</p>
                                            ) : (
                                                <p className='item-selection-modal-no-vendor'>No vendor</p>
                                            )}
                                        </div>

                                        <div className="item-selection-modal-quantity-input">
                                            <button
                                                className="item-selection-modal-qty-button minus"
                                                onClick={() => handleQtyChange(item.id, Math.max(0, initialQty - 1))}
                                            >
                                                -
                                            </button>
                                            <input
                                                type="number"
                                                value={initialQty > 0 ? initialQty : ''}
                                                onChange={(e) => {
                                                    const newValue = e.target.value;
                                                    handleQtyChange(item.id, Math.max(0, Number(newValue)));
                                                }}
                                                className="item-selection-modal-qty-input-field"
                                                min={0}
                                            />
                                            <button
                                                className="item-selection-modal-qty-button plus"
                                                onClick={() => handleQtyChange(item.id, initialQty + 1)}
                                            >
                                                +
                                            </button>
                                        </div>

                                        <div className="item-type-bom-modal-action-block">
                                            <button
                                                onClick={() => {
                                                    if (userItemQuantities[item.id] > 0) {
                                                        handleClearItemQty(item.id);
                                                    } else {
                                                        handleFillOutItemQty(item);
                                                    }
                                                }}
                                                className={`item-selection-modal-button ${
                                                    userItemQuantities[item.id] > 0 ? 'add-item' : (isDisabled ? 'disabled-item' : 'add-item')
                                                }`}
                                                disabled={isDisabled}
                                            >
                                                {userItemQuantities[item.id] > 0 ? 'Clear' : 'Fill'}
                                            </button>

                                            {typeof (item.qty_from_store) === 'string' ? (
                                                <button className="item-selection-modal-button out-of-stock">
                                                    No limit
                                                </button>
                                            ) : (
                                                <div className='item-available-block'>
                                                    <div><IconCheckItemAvailable/></div>
                                                    <p>{item.qty_from_store}</p>
                                                    <p>available</p>
                                                </div>
                                            )}
                                        </div>
                                    </section>
                                </li>
                            );
                        })}

                    </ul>
                )}
                <div className="item-selection-modal-save-button-block">
                    <div>
                        <PaginationInModal
                            perPage={itemsPerPage}
                            total={searchTerm ? totalSearchResults || 0 : totalItems}
                            paginate={onPageChange}
                            currentPage={currentPage}
                        />
                    </div>
                    <button onClick={handleConfirmSelection} className="item-selection-modal-save-button">
                        Confirm selection
                    </button>
                </div>
            </section>
        </Modal>
    );
};

export default FragmentComponentModal;
