import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './GuideStyles/GuideHeader.css';
import {
    GuideStep1,
    GuideStep2,
    GuideStep3,
    GuideStep4,
    GuideStep5,
    GuideStep6,
    GuideStep7,
    GuideStep8,
    GuideStep2Check,
    GuideStep3Check,
    GuideStep4Check,
    GuideStep5Check,
    GuideStep6Check,
    GuideStep7Check,
    GuideStep8Check
} from "../../IconComponents/IconGuide";

const GuideHeader: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const steps = [
        { component: GuideStep1, componentChecked: GuideStep1, route: "/guide/step1" },
        { component: GuideStep2, componentChecked: GuideStep2Check, route: "/guide/step2" },
        { component: GuideStep3, componentChecked: GuideStep3Check, route: "/guide/step3" },
        { component: GuideStep4, componentChecked: GuideStep4Check, route: "/guide/step4" },
        { component: GuideStep5, componentChecked: GuideStep5Check, route: "/guide/step5" },
        { component: GuideStep6, componentChecked: GuideStep6Check, route: "/guide/step6" },
        { component: GuideStep7, componentChecked: GuideStep7Check, route: "/guide/step7" },
        { component: GuideStep8, componentChecked: GuideStep8Check, route: "/guide/step8" },
    ];

    const currentStepIndex = steps.findIndex(step => step.route === location.pathname);

    return (
        <div className="guide-header">
            <img src='/img_projects/gobuild.svg' alt='Gobuild'/>
            <h1>BoM User Guide</h1>
            <p className='guide-header-text'>Learn how to use all the features and benefits</p>
            <button title='Get Started!' onClick={() => navigate('/assistance')} className='guide-header-scip'>Skip</button>
            <div className="guide-header-block">
                {steps.map((step, index) => {
                    const StepIcon = index <= currentStepIndex ? step.componentChecked : step.component;
                    return (
                        <div key={index} className="guide-icon-container">

                            <div
                                className={`guide-icon ${index <= currentStepIndex ? 'active' : ''}`}
                                onClick={() => navigate(step.route)}
                            >
                                <StepIcon/>
                                {index < steps.length - 1 && (
                                    <div
                                        className={`guide-header-line ${index < currentStepIndex ? 'completed' : ''}`}
                                    />
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default GuideHeader;
