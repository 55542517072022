export const setVarGlobalFont = () => {
    const root = document.documentElement;
    root.style.setProperty('--font-sintony', 'Sintony');
    root.style.setProperty('--font-roboto', 'Roboto');
    root.style.setProperty('--font-inter', 'Inter Semi Bold');

    root.style.setProperty('--font-weight-regular', '400');
    root.style.setProperty('--font-weight-bold', '700');
    root.style.setProperty('--font-weight-medium', '500');
    root.style.setProperty('--font-size-large', '48px');
    root.style.setProperty('--font-size-large-middle', '40px');
    root.style.setProperty('--font-size-large-min', '32px');
    root.style.setProperty('--font-size-medium-large', '24px');
    root.style.setProperty('--font-size-medium', '20px');

}
