import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { get } from "../../api/APIWraper";
import { Loader } from "../../components/Loader/Loader";
import './RoleCheckPage.css'
const RoleCheckPage: React.FC = () => {
    const navigate = useNavigate();
    const roleCheckIntervalRef = useRef<number | null>(null);

    const startRoleCheckInterval = () => {
        if (roleCheckIntervalRef.current !== null) {
            clearInterval(roleCheckIntervalRef.current);
        }

        roleCheckIntervalRef.current = window.setInterval(async () => {
            try {
                const responseMe = await get(`${process.env.REACT_APP_BASE_URL_CUSTOMER}/my_profile_info`);
                const serverRole = responseMe.data.role.role;
                const localRole = localStorage.getItem('role');

                if (localRole !== serverRole) {
                    localStorage.setItem('role', serverRole);

                    if (roleCheckIntervalRef.current !== null) {
                        clearInterval(roleCheckIntervalRef.current);
                        roleCheckIntervalRef.current = null;
                    }

                    toast.success(`You successfully purchased a plan "${serverRole}"`);
                    navigate('/home');
                }
            } catch (error) {
                console.error("Error when receiving user role:", error);
                toast.error('An error occurred while validating the role. Please try again.');
                if (roleCheckIntervalRef.current !== null) {
                    clearInterval(roleCheckIntervalRef.current);
                    roleCheckIntervalRef.current = null;
                }
                navigate('/home');
            }
        }, 5000);
    };

    useEffect(() => {
        startRoleCheckInterval();

        return () => {
            if (roleCheckIntervalRef.current !== null) {
                clearInterval(roleCheckIntervalRef.current);
            }
        };
    }, []);

    return (
        <div className='check-role-page'>
            <Loader />
            <p className='animated-text'>Payment verification, please wait...</p>
        </div>
    );
};

export default RoleCheckPage;
