import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import PreferencesHeader from "./PreferencesHeader";
import AccountDetails from "./AccountDetails";
import { Loader } from "../../components/Loader/Loader";
import './styles/preferences.css';
import SubscriptionPlan from "./SubscriptionPlan";
import { useSelector } from "react-redux";
import { selectUser } from "../../Slice/tokenSlice/userSlice";
import ChangePassword from "./ChangePassword";
import NotificationSettings from "./NotificationSettings";
import PrivacySettings from "./PrivacySettings";

const PreferencesPage = () => {
    const user = useSelector(selectUser);
    const [activeSection, setActiveSection] = useState('account-details');

    const sectionRefs = {
        'account-details': useRef<HTMLElement>(null),
        'subscription-plan': useRef<HTMLElement>(null),
        'notification-settings': useRef<HTMLElement>(null),
        'change-password': useRef<HTMLElement>(null),
        'privacy-settings': useRef<HTMLElement>(null),
    };

    const handleScroll = useCallback(() => {
        const scrollPosition = window.scrollY + 150;
        for (const [section, ref] of Object.entries(sectionRefs)) {
            const element = ref.current;
            if (element && element.offsetTop <= scrollPosition && element.offsetTop + element.offsetHeight > scrollPosition) {
                setActiveSection(section);
                break;
            }
        }
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);

    const scrollToSection = (sectionId: string) => {
        const headerOffset = 180;
        const element = sectionRefs[sectionId as keyof typeof sectionRefs]?.current;
        if (element) {
            const elementPosition = element.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }
    };

    const location = useLocation();
    useEffect(() => {
        if (location.state && (location.state as any).scrollTo) {
            scrollToSection((location.state as any).scrollTo);
        }
    }, [location.state]);

    if (!user) return <Loader />;

    const userPhoto =
        user.galleries &&
        user.galleries.length > 0 && user.galleries[0]?.medias?.length > 0
            ? user.galleries[0].medias[user.galleries[0].medias.length - 1]?.url
            : '/img/user.png';

    return (
        <section className='preferencesPage'>
            <section className='preferences-header-container'>
                <PreferencesHeader activeSection={activeSection} scrollToSection={scrollToSection} />
            </section>

            <section ref={sectionRefs['account-details']} className='preferences-section'>
                <AccountDetails user={user} userPhoto={userPhoto} />
            </section>

            <section ref={sectionRefs['subscription-plan']} id='subscription-plan' className='preferences-section'>
                <SubscriptionPlan user={user}/>
            </section>

            <section ref={sectionRefs['notification-settings']} id='notification-settings' className='preferences-section'>
                {/*<NotificationSettings/>*/}
            </section>

            <section ref={sectionRefs['change-password']} id='change-password' className='preferences-section'>
                <ChangePassword/>
            </section>

            <section ref={sectionRefs['privacy-settings']} id='privacy-settings' className='preferences-section'>
                {/*<PrivacySettings/>*/}
            </section>
        </section>
    );
};

export default PreferencesPage;
