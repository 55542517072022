import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TemplateState {
    templateMessage: string;
}

const initialState: TemplateState = {
    templateMessage: '',
};

const templateSlice = createSlice({
    name: 'template',
    initialState,
    reducers: {
        setTemplateMessage: (state, action: PayloadAction<string>) => {
            state.templateMessage = action.payload;
        },
    },
});

export const { setTemplateMessage } = templateSlice.actions;
export default templateSlice.reducer;
