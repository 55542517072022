import React, {useEffect, useState} from 'react';
import './styles/subscriptionPlan.css'
import {User} from "../../types/ProjectTypes";
import {
    SidebarArrowShow,
    SidebarUpgradeContractorPreferences
} from "../../components/TopHeaderAfterAuth/Sidebar/IconsSidebar";
import {
    IconPricingAddLanding,
    IconPricingBomCostsLanding,
    IconPricingPDFLanding,
    IconPricingProjectLanding,
    IconPricingShareLanding,
    IconPricingTemplateLanding
} from "../../IconComponents/IconComponents";
import {toast} from "react-toastify";
import {canselSubscription, getBillingHistory, post} from "../../api/APIWraper";
import PaginationInModal from "../../components/PaginationAssistance/paginationInModal";

interface ISubscriptionPlanProps {
    user: User
}

const BASE_CUSTOMER_URL = process.env.REACT_APP_BASE_URL_CUSTOMER

const SubscriptionPlan: React.FC<ISubscriptionPlanProps> = ({user}) => {
    const userRole = user.role.role
    const [show, setShow] = useState<boolean>(false);
    const [isVisible, setIsVisible] = useState(show);
    const [animate, setAnimate] = useState(false);
    const [history, setHistory] = useState<any>([]);
    const [total, setTotal] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const perPage = 10;
    const subscriptionCheck =
        Object.keys(user.current_subscription || {}).length === 0 ||
        user.current_subscription?.canceled_at === null;

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;

        if (show) {
            setIsVisible(true);
            setTimeout(() => setAnimate(true), 10);
        } else {
            setAnimate(false);
            timeoutId = setTimeout(() => setIsVisible(false), 300);
        }

        return () => clearTimeout(timeoutId);
    }, [show]);

    useEffect(() => {
        fetchBillingHistory(currentPage);
    }, [currentPage]);

    const fetchBillingHistory = async (page: number) => {
        try {
            const response = await getBillingHistory( page, perPage );
            if (response.status === 200) {
                setHistory(response.data.data);
                setTotal(response.data.pagination.lastPage);
            } else {
                setHistory([]);
            }
        } catch (error) {
            console.error(error);
        }
    };
    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };


    const handleUpdateRole = async () => {
        const currentPath = window.location.pathname;
        if (userRole === 'Contractor') {
            toast.warning('Your "Contractor" plan has already been paid for');
            return;
        }

        try {
            const response = await post(`${BASE_CUSTOMER_URL}/subscription/contractor/monthly`);
            if (response.status === 200) {
                const sessionToken = sessionStorage.getItem('Bearer');
                if (sessionToken) {
                    sessionStorage.setItem('Bearer', sessionToken);
                }
                localStorage.setItem('returnPath', currentPath);
                localStorage.setItem('redirectOnReturn', 'true');
                window.open(response.data.links[0].href, "_self");
            } else {
                console.error(response);
                toast.error('An error occurred, try again later.');
            }
        } catch (error) {
            console.error(error);
            toast.error('An error occurred, try again later.');
        }

    };

    const formatDate = (dateStr: string) => {
        const date = new Date(dateStr);
        const day = date.getDate();
        const month = date.toLocaleString('en', { month: 'short' });
        return `${day} ${month}`;
    };

    const formatDateSubscriptions = (subscriptions?: { canceled_at: string }): string | null => {
        if (!subscriptions?.canceled_at) return null;

        const date = new Date(subscriptions.canceled_at);
        if (isNaN(date.getTime())) return null;

        return date.toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "short",
            year: "numeric",
        });
    };

    const handleCansel = async () =>{
        if (subscriptionCheck) return
        try {
            const response = await canselSubscription()
            if (response.status === 200){
                toast.dismiss()
                toast.success('Subscription cancelled successfully')
            }
        } catch (error){
            console.error(error)
        }
    }


    const renderHistory = () => {
        return (
            <section className='billing-history-section'>
                <p className='subscription-plan-payment-block-text-method'>Billing history</p>
                <div className='billing-history-content'>
                    <div className='billing-history-header-block'>
                        <p>Date</p>
                        <p>Type</p>
                        <p>Status</p>
                        <p>Price</p>
                        <p>Invoice</p>
                    </div>
                    {history.length > 0 && history.map((item: any, index: number) => (
                        <div className='billing-history-block' key={index}>
                            <p>{formatDate(item.created_at)}</p>
                            <p>{item.type ? item.type : 'Structural'}</p>
                            <p className='billing-history-status'>{item.status}</p>
                            <p>$ {item.amount}</p>
                            <p className='billing-history-download'>Download</p>
                        </div>
                    ))}
                </div>
                <div>
                    {total > 1 && (
                        <PaginationInModal
                            perPage={perPage}
                            total={total}
                            paginate={handlePageChange}
                            currentPage={currentPage}
                        />
                    )}
                </div>
            </section>
        )
    }


    const renderTotalBlocks = () => {
        switch (userRole) {
            case 'Customer':
                return (
                    <div className='subscription-plan-block'>
                        <p className='subscription-plan-active-name'>Foundation</p>
                        <p className='subscription-plan-active-text'>Active</p>
                    </div>
                )
            case 'Contractor':
                return (
                    <div className='subscription-plan-block-structural'>
                        <div className='subscription-plan-structural-block'>
                            <p className='subscription-plan-active-name'>Structural</p>
                            <div className='subscription-plan-structural'>
                                <p className='subscription-plan-monthly-text'>Monthly</p>
                                <p className='subscription-plan-active-text'>Active</p>
                            </div>
                        </div>
                        <p className='subscription-plan-block-text'>
                            Your next payment is <span className='subscription-plan-time'>$12.50 / month</span>
                            {user.current_subscription?.canceled_at && (
                                <>, to be charged on <span className='subscription-plan-time'>{formatDateSubscriptions(user.current_subscription)}</span></>
                            )}
                        </p>

                    </div>
                )
            case 'SAU':
                return (
                    <div className='subscription-plan-block-structural'>
                        <div className='subscription-plan-structural-block'>
                            <p className='subscription-plan-active-name'>Architect</p>
                            <div className='subscription-plan-structural'>
                                <p className='subscription-plan-monthly-text'>Annually</p>
                                <p className='subscription-plan-active-text'>Active</p>
                            </div>
                        </div>
                        <p className='subscription-plan-block-text'>Your tariff expires on the <span
                            className='subscription-plan-time'>Oct 25 2024</span></p>
                    </div>
                )
        }
    }

    const renderBlockStructural = () => {
        return (
            <div className='subscription-plan-block-structural-full'>
                <div className='subscription-plan-structural-block-show'>
                    <div className='subscription-plan-structural-block-text'>
                        <div className='subscription-plan-structural-block'>
                            <p className='subscription-plan-active-name'>Structural</p>
                        </div>
                        <div className='subscription-plan-structural-text-content'>
                            <p className='subscription-plan-block-text'>Manual Measurement Insertion</p>
                            <p className='subscription-plan-block-text'>
                                You can select projects, input measurements of required
                                building project, customize materials, and receive a comprehensive cost breakdown.
                            </p>
                        </div>
                        <p className='subscription-plan-block-price'>
                            $12.50 <span className='subscription-plan-block-price-time'>/month</span>
                        </p>
                    </div>
                    <div className='subscription-plan-block-actions'>
                        <div
                            onClick={() => handleUpdateRole()}
                            className='subscription-plan-confirm-button-contractor'>
                            <div className='upgrade-btn-icon'><SidebarUpgradeContractorPreferences/></div>
                            <span>Upgrade now</span>
                        </div>
                        <div
                            onClick={() => setShow(prev => !prev)}
                            className={`subscription-plan-icon ${show ? 'show' : ''}`}
                        >
                            <SidebarArrowShow/>
                        </div>
                    </div>
                </div>

                {isVisible && (
                    <div
                        className={`subscription-plan-block-workspace ${animate ? 'active' : ''}`}
                    >
                        <h4 className='subscription-plan-workspace-h'>Contractors Workspace</h4>
                        <div className='subscription-plan-workspace-block'>
                            <div><IconPricingProjectLanding/></div>
                            <p>Custom Bill of Materials Creation</p>
                        </div>
                        <div className='subscription-plan-workspace-block'>
                            <div><IconPricingTemplateLanding/></div>
                            <p>Add own list of products required</p>
                        </div>
                        <div className='subscription-plan-workspace-block'>
                            <div><IconPricingAddLanding/></div>
                            <p>Add Preliminary and Generals</p>
                        </div>
                        <div className='subscription-plan-workspace-block'>
                            <div><IconPricingAddLanding/></div>
                            <p>Add Profit and Attendance</p>
                        </div>
                        <div className='subscription-plan-workspace-block'>
                            <div><IconPricingBomCostsLanding/></div>
                            <p>All inclusive BoM Cost Estimate</p>
                        </div>
                        <div className='subscription-plan-workspace-block'>
                            <div><IconPricingPDFLanding/></div>
                            <p>Share BoM as PDF</p>
                        </div>
                        <div className='subscription-plan-workspace-block'>
                            <div><IconPricingShareLanding/></div>
                            <p>Share link for payment with Client</p>
                        </div>
                    </div>
                )}
            </div>
        )
    }

    const renderPaymentMethods = () => {
        return (
            <div className='subscription-plan-payment'>
                <div className='subscription-plan-payment-block-text'>
                    <p className='subscription-plan-payment-block-text-method'>Payment methods</p>
                    <p className='subscription-plan-payment-btn-text-info'>Modify your payment method for future
                        payments.</p>
                </div>
                <button className='subscription-plan-payment-btn'>Change payment methods</button>
            </div>
        )
    }

    const renderCanselSubscription = () => {
        return (
            <div className='subscription-plan-payment-cansel'>
                <div className='subscription-plan-payment-block-text'>
                    <p className='subscription-plan-payment-block-text-method'>Pause or cancel subscription</p>
                    <p className='subscription-plan-payment-btn-text-info'>By canceling your account you will lose all
                        your data.</p>
                </div>
                <span
                    onClick={()=>handleCansel()}
                    className={`subscription-plan-cansel-btn ${subscriptionCheck ? 'cansel-subscription' : ''}`}>Cancel subscription</span>
            </div>
        )
    }

    return (
        <div className='subscription-plan'>
            <div className='subscription-plan-text'>
                <h3>Subscription plan</h3>
            </div>
            {renderTotalBlocks()}
            {userRole !== 'Contractor' && renderBlockStructural()}
            {renderPaymentMethods()}
            {renderHistory()}
            {renderCanselSubscription()}
        </div>
    );
}

export default SubscriptionPlan;