import React, { useState } from 'react';
import './ReviewSlider.css';

interface Review {
    id: number;
    name: string;
    avatar: string;
    rating: number;
    comment: string;
    date: string;
    dateTime: string;
}

interface ReviewSliderProps {
    variant?: 'compact' | 'detailed';
}

const reviews: Review[] = [
    {
        id: 1,
        name: 'Joseph E.',
        avatar: '/img_landing/img1.svg',
        rating: 5,
        comment: 'This wonderful extension has solved for me many problems that arise during construction. Now I can easily and quickly calculate all the necessary materials for my projects and also immediately order everything from trusted and nearby suppliers!',
        date: 'Nov 11, 2024',
        dateTime: '10:30 AM',
    },
    {
        id: 2,
        name: 'Ethan S.',
        avatar: '/img_landing/img2.svg',
        rating: 5,
        comment: 'This extension is easy to use and provides all the necessary calculations of materials for your project. It is very convenient. Thank you!',
        date: 'Nov 9, 2024',
        dateTime: '2:43 AM',
    },
    {
        id: 3,
        name: 'Anna J.',
        avatar: '/img_landing/img3.svg',
        rating: 5,
        comment: 'Thanks to the GoBuild team for making it so easy to build a garage or paint your home by taking 5 minutes to enter measurements and get a list of products needed. Amazing work!\n' +
            'Keep it up.',
        date: 'Nov 14, 2024',
        dateTime: '10:12 AM',
    },
    {
        id: 4,
        name: 'Matthew N.',
        avatar: '/img_landing/img4.svg',
        rating: 5,
        comment: 'There are no words to describe my delight after using this extension! Now such problems as calculations during construction can be solved quickly and easily. I will look forward to new features in the future to continue working with GoBuild Bill of Materials.',
        date: 'Nov 15, 2024',
        dateTime: '15:15 PM',
    },
    {
        id: 5,
        name: 'Amanda R.',
        avatar: '/img_landing/img5.svg',
        rating: 4,
        comment: 'Interesting and promising app, I will be watching for future updates to enjoy all the features! But even for now it is quite convenient and simple. Bravo!',
        date: 'Nov 18, 2024',
        dateTime: '13:02 PM',
    },

];

const ReviewSlider: React.FC<ReviewSliderProps> = ({ variant = 'detailed' }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [hoveredCardId, setHoveredCardId] = useState<number | null>(null);
    const reviewsPerPage = variant === 'compact' ? 2 : 3;
    const totalPages = Math.ceil(reviews.length / reviewsPerPage);

    const handleDotClick = (index: number) => {
        setCurrentPage(index);
        setHoveredCardId(null);
    };

    const startIndex = currentPage * reviewsPerPage;
    const currentReviews = reviews.slice(startIndex, startIndex + reviewsPerPage);

    return (
        variant === 'detailed' ? (
            <div className="review-slider">
                <div className="reviews-container">
                    {currentReviews.map((review) => (
                        <div
                            key={review.id}
                            className={`review-card 
                                ${hoveredCardId === review.id ? 'hovered' : hoveredCardId !== null ? 'dimmed' : ''}
                            `}
                            onMouseEnter={() => setHoveredCardId(review.id)}
                            onMouseLeave={() => setHoveredCardId(null)}
                        >
                            <div className="review-card-content">
                                <div className="user-info">
                                    <img src={review.avatar} alt={review.name} className="avatar" />

                                    <div className="user-details">
                                        <h4>{review.name}</h4>

                                        <div className="rating">
                                            {'★'.repeat(review.rating)}
                                            {'☆'.repeat(5 - review.rating)}
                                        </div>
                                    </div>
                                </div>

                                <div className={`comment ${hoveredCardId === review.id ? 'expanded' : ''}`}>
                                    {review.comment}
                                </div>

                                <div className="review-date">
                                    <p>{review.dateTime}</p>
                                    <p>{review.date}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className={`pagination-reviews ${hoveredCardId !== null ? 'moved-down' : ''}`}>
                    {Array.from({ length: totalPages }, (_, index) => (
                        <span
                            key={index}
                            className={`dot ${index === currentPage ? 'active-compact' : ''}`}
                            onClick={() => handleDotClick(index)}
                        />
                    ))}
                </div>
            </div>
        ) : (

            <div className="review-slider-compact">
                <p className='compact-opport'>Explore our opportunities</p>
                <p className='compact-reviews'>Client's reviews</p>
                <div className="reviews-container">
                    {currentReviews.map((review) => (
                        <div
                            key={review.id}
                            className={`review-card-compact 
                                ${hoveredCardId === review.id ? 'hovered' : hoveredCardId !== null ? 'dimmed' : ''}
                            `}
                            onMouseEnter={() => setHoveredCardId(review.id)}
                            onMouseLeave={() => setHoveredCardId(null)}
                        >
                            <div className="review-card-content">
                                <div className="user-info">
                                    <img src={review.avatar} alt={review.name} className="avatar"/>

                                    <div className="user-details">
                                        <h4>{review.name}</h4>

                                        <div className="rating">
                                            {'★'.repeat(review.rating)}
                                            {'☆'.repeat(5 - review.rating)}
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className={`comment ${hoveredCardId === review.id ? 'expanded' : ''}`}>
                                    {review.comment}
                                </div>

                                <div className="review-date">
                                    <p>{review.dateTime}</p>
                                    <p>{review.date}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className= "pagination-reviews-compact">
                    {Array.from({length: totalPages}, (_, index) => (
                        <span
                            key={index}
                            className={`dot ${index === currentPage ? 'active' : ''}`}
                            onClick={() => handleDotClick(index)}
                        />
                    ))}
                </div>
            </div>

        )
    );
};

export default ReviewSlider;
