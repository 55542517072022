import React from 'react';
import {useNavigate} from 'react-router-dom';

import './GuideStyles/GuideBom.css'
import {
    GuideMouse,
    GuideNextArrow,
} from "../../IconComponents/IconGuide";

const Step1Guide: React.FC = () => {
    const navigate = useNavigate();


    return (
        <>
            <div className='new-user-info-block'>
                <p className='new-user-info-block-number'>1</p>
                <h3 className='new-user-info-block-text'>Creating a project</h3>
            </div>
            <div className='new-user-info-content'>
                <div className='new-user-info-content-img'>
                    <img src='/img_guide/guide1.png'/>
                    <div><GuideNextArrow/></div>
                    <img src='/img_guide/guide2.png'/>
                    <div><GuideNextArrow/></div>
                    <img src='/img_guide/guide3.png'/>
                    <div className='new-user-mouse'><GuideMouse/></div>
                </div>
                <div className='new-user-info-content-text-block'>
                    <p className='new-user-info-content-text-1 new-user-info-content-text'>Click to “Create new
                        project”</p>
                    <p className='new-user-info-content-text-2 new-user-info-content-text'>Choose template</p>
                    <p className='new-user-info-content-text-3 new-user-info-content-text'>Check your template and start
                        measuring”</p>
                </div>
                <div>
                </div>
            </div>
        </>
    )
        ;
};

export default Step1Guide;
