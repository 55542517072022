import React, {useState} from 'react';
import Modal from 'react-modal';
import './UpgradeRole.css';
import {
    IconPricing3DLanding,
    IconPricingAddLanding, IconPricingBomCostsLanding, IconPricingContractorsLanding,
    IconPricingProjectLanding,
    IconPricingTemplateLanding
} from "../../../IconComponents/IconComponents";
import {
    SidebarUpgradeCollapsed,
    SidebarUpgradeContractor
} from "../../TopHeaderAfterAuth/Sidebar/IconsSidebar";
import {toast} from "react-toastify";
import {post} from "../../../api/APIWraper";
import {useSelector} from "react-redux";
import {selectUser} from "../../../Slice/tokenSlice/userSlice";

interface ModalUpgradeRoleProps {
    isOpen: boolean;
    onCancel: () => void;
}

const options = ['Structural', 'Architect'] as const;
type OptionType = (typeof options)[number];
const BASE_CUSTOMER_URL = process.env.REACT_APP_BASE_URL_CUSTOMER

const UpgradeRole: React.FC<ModalUpgradeRoleProps> = ({isOpen, onCancel}) => {
    const [selectedOption, setSelectedOption] = useState<OptionType>('Structural');
    const handleClick = (option: OptionType) => {
        setSelectedOption(option);
    };
    const user = useSelector(selectUser);

    const handleUpdateRole = async (selectedOption: string) => {
        const currentPath = window.location.pathname;
        const actualRole = user?.role.role;

        if (selectedOption === 'Structural') {
            if (actualRole === 'Contractor' || actualRole === 'SAU' || actualRole === 'MAU') {
                toast.warning('Your "Contractor" plan has already been paid for');
                return;
            }

            try {
                const response = await post(`${BASE_CUSTOMER_URL}/subscription/contractor/monthly`);
                if (response.status === 200) {
                    const sessionToken = sessionStorage.getItem('Bearer');
                    if (sessionToken) {
                        sessionStorage.setItem('Bearer', sessionToken);
                    }
                    localStorage.setItem('returnPath', currentPath);
                    localStorage.setItem('redirectOnReturn', 'true');
                    window.open(response.data.links[0].href, "_self");
                } else {
                    console.error(response);
                    toast.error('An error occurred, try again later.');
                }
            } catch (error) {
                console.error(error);
                toast.error('An error occurred, try again later.');
            }

        } else if (selectedOption === 'Architect') {
            if (actualRole === 'Architect' || actualRole === 'SAU' || actualRole === 'MAU') {
                toast.warning('Your "Architect" plan has already been paid for');
                return;
            }

            try {
                const response = await post(`${BASE_CUSTOMER_URL}/subscription/architect/annual`);
                if (response.status === 200) {
                    const sessionToken = sessionStorage.getItem('Bearer');
                    if (sessionToken) {
                        sessionStorage.setItem('Bearer', sessionToken);
                    }
                    localStorage.setItem('returnPath', currentPath);
                    localStorage.setItem('redirectOnReturn', 'true');
                    window.open(response.data.links[0].href, "_self");
                } else {
                    console.error(response);
                    toast.error('An error occurred, try again later.');
                }
            } catch (error) {
                console.error(error);
                toast.error('An error occurred, try again later.');
            }
        }
    };

        const renderContractorBlock = () => {
            return (
                <section className='upgrade-role-container'>
                    <p className='upgrade-role-container-text'>You will have access to the following features:</p>
                    <div className='upgrade-role-content-block'>
                        <div className='upgrade-role-content-info-text'>
                            <div><IconPricingProjectLanding/></div>
                            <p>Custom Bill of Materials Creation</p>
                        </div>
                        <div className='upgrade-role-content-info-text'>
                            <div><IconPricingTemplateLanding/></div>
                            <p>Add own list of products required</p>
                        </div>

                        <div className='upgrade-role-content-info-text'>
                            <div><IconPricingAddLanding/></div>
                            <p>Add Preliminary and Generals</p>
                        </div>
                        <div className='upgrade-role-content-info-text'>
                            <div><IconPricingAddLanding/></div>
                            <p>Add Profit and Attendance</p>
                        </div>

                        <div className='upgrade-role-content-info-text'>
                            <div><IconPricingBomCostsLanding/></div>
                            <p>All inclusive BoM Cost Estimate</p>
                        </div>
                        <div className='upgrade-role-content-info-text'>
                            <div><IconPricingProjectLanding/></div>
                            <p>Share link for payment with Client</p>
                        </div>
                    </div>
                </section>

            )
        }

        const renderArchitectBlock = () => {
            return (
                <section className='upgrade-role-container'>
                    <p className='upgrade-role-container-text'>You will have access to the following features:</p>
                    <div className='upgrade-role-content-block'>
                        <div className='upgrade-role-content-info-text'>
                            <div><IconPricing3DLanding/></div>
                            <p>Revit 3D Digital Plans Upload</p>
                        </div>
                        <div className='upgrade-role-content-info-text'>
                            <div><IconPricingBomCostsLanding/></div>
                            <p>Cost to complete management</p>
                        </div>

                        <div className='upgrade-role-content-info-text'>
                            <div><IconPricing3DLanding/></div>
                            <p>AutoCAD 3D Digital Plans Upload</p>
                        </div>
                        <div className='upgrade-role-content-info-text'>
                            <div><IconPricingContractorsLanding/></div>
                            <p>Extracting of all plan details</p>
                        </div>

                        <div className='upgrade-role-content-info-text'>
                            <div><IconPricingContractorsLanding/></div>
                            <p>Extracting of all measurements</p>
                        </div>
                        <div className='upgrade-role-content-info-text'>
                            <div><IconPricingAddLanding/></div>
                            <p>Creation of BoM from plans uploaded</p>
                        </div>
                    </div>
                </section>


            )
        }
        const price = selectedOption === 'Structural' ? 12.50 : 1000
        const infoBlock = renderContractorBlock();

        return (
            <Modal
                isOpen={isOpen}
                className="upgrade-role-modal"
                overlayClassName="overlay-upgrade-role"
            >
                <div>
                    <button className="close-button" onClick={onCancel}>&times;</button>
                </div>
                <div className="upgrade-role-content">
                    <h2 className='upgrade-role-content-h'>Upgrade your tariff plan to Structural now!</h2>
                    <p>Get even more opportunities in creating construction projects with Structural</p>
                    <span className='upgrade-role-content-price'>For only ${price} per month!</span>
                    <div className='switcher-container-upgrade-role'>
                        <div className="switcher-upgrade-role switcher-2-upgrade-role">
                                    Structural
                        </div>
                    </div>

                    {infoBlock}

                    <div className="confirmation-buttons">
                        <button className="cancel-button">Cancel</button>
                                <div
                                    onClick={() => handleUpdateRole('Structural')}
                                    className='upgrade-confirm-button-contractor'>
                                    <div className='upgrade-btn-icon'><SidebarUpgradeContractor/></div>
                                    <span>Upgrade now</span>
                                </div>
                    </div>
                </div>
            </Modal>
        );
    };

    export default UpgradeRole;
