import React, {useRef, useState} from 'react';
import {User} from "../../types/ProjectTypes";
import './styles/accountDeatails.css';
import {IconUploadPhoto} from "../../components/TopHeaderAfterAuth/Sidebar/IconsSidebar";
import {IconPenNew} from "../../IconComponents/IconComponents";
import {toast} from "react-toastify";
import {updateUserName} from "../../api/APIWraper";
import {useAppDispatch} from "../../store";
import {setUser} from "../../Slice/tokenSlice/userSlice";
import {PostFormData} from "../../api/ApiWrapperImage";

interface AccountDetailsProps {
    user: User;
    userPhoto: string;
}

const BASE_URL_CUSTOMER = process.env.REACT_APP_BASE_URL_CUSTOMER;

const AccountDetails: React.FC<AccountDetailsProps> = ({user, userPhoto}) => {
    const [userName, setUserName] = useState<string>(user.username);
    const [newPhoto, setNewPhoto] = useState<File | null>(null);
    const [previewPhoto, setPreviewPhoto] = useState<string>(userPhoto);

    const dispatch = useAppDispatch();
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleUserNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserName(e.target.value);
    };

    const handleSaveEdits = async () => {
        const hasNameChanged = userName !== user.username;
        const hasPhotoChanged = newPhoto !== null;

        if (!hasNameChanged && !hasPhotoChanged) {
            toast.info("No change");
            return;
        }

        try {
            let updatedUser = user;

            if (hasNameChanged) {
                const response = await updateUserName({name: userName});
                if (response.status === 200) {
                    updatedUser = response.data;
                } else {
                    toast.error('The username could not be updated. Try again later');
                    return;
                }
            }

            if (hasPhotoChanged && newPhoto) {
                const formData = new FormData();
                formData.append("file", newPhoto);
                const responsePhoto = await PostFormData(
                    BASE_URL_CUSTOMER || "",
                    `/change_photo`,
                    formData
                );
                if (responsePhoto.status === 200) {
                    updatedUser = responsePhoto.data;
                } else {
                    toast.error("Failed to update image");
                    return;
                }
            }

            dispatch(setUser(updatedUser));
            toast.success("Profile updated successfully");
            setNewPhoto(null);
        } catch (error) {
            console.error('Error updating profile:', error);
            toast.error("Failed to update profile");
        }
    };

    const handleIconClick = () => {
        fileInputRef.current?.click();
    };

    return (
        <div className='account-details'>
            <div className='account-details-header'>
                <h3>Account Details</h3>
            </div>
            <div className='account-details-content'>
                <div className='account-details-img-container'>
                    <img
                        className='account-details-img-container-image'
                        src={previewPhoto}
                        alt="User Profile"
                    />
                    <p className='preferencesPage-text'>Upload profile photo</p>
                    <div className='account-details-icon' onClick={handleIconClick}>
                        <IconUploadPhoto/>
                        <input
                            ref={fileInputRef}
                            type="file"
                            accept="image/*"
                            style={{display: 'none'}}
                            onChange={(e) => {
                                if (e.target.files && e.target.files.length > 0) {
                                    const file = e.target.files[0];
                                    setNewPhoto(file);
                                    const previewURL = URL.createObjectURL(file);
                                    setPreviewPhoto(previewURL);
                                }
                            }}
                        />
                    </div>
                </div>
                <div className='account-details-name-container'>
                    <div className='input-with-icon'>
                        <p className='account-details-name-container-username'>Username:</p>
                        <input
                            type='text'
                            className='account-details-input'
                            value={userName}
                            onChange={handleUserNameChange}
                        />
                        <div className='icon-in-input'>
                            <IconPenNew/>
                        </div>
                    </div>
                    <div className='input-with-icon'>
                        <p className='account-details-name-container-username'>E-mail:</p>
                        <input
                            type='text'
                            className='account-details-input-email'
                            value={user.email}
                            disabled={true}
                        />
                    </div>
                    <p className='account-details-name-email'></p>
                </div>
                <button className='account-details-btn' onClick={handleSaveEdits}>
                    Save edits
                </button>
            </div>
        </div>
    );
};

export default AccountDetails;
