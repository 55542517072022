export const clearLogOutStorage = () => {
    const visitedVendors = localStorage.getItem('visitedVendors');
    const visitedItems = localStorage.getItem('visitedItems');
    const rememberMe = localStorage.getItem('rememberMe');
    const hasSeenGuide = localStorage.getItem('hasSeenGuide');
    const hasSeenContractorGuide = localStorage.getItem('hasSeenContractorGuide');
    const SocketItemModal = localStorage.getItem('SocketItemModal');
    const ItemQuantity = localStorage.getItem('ItemQuantity');

    localStorage.clear();
    sessionStorage.clear();
    if (hasSeenGuide) {
        localStorage.setItem('hasSeenGuide', hasSeenGuide);
    }
    if (hasSeenContractorGuide) {
        localStorage.setItem('hasSeenContractorGuide', hasSeenContractorGuide);
    }
    if (rememberMe) {
        localStorage.setItem('rememberMe', rememberMe);
    }
    if (visitedItems) {
        localStorage.setItem('visitedItems', visitedItems);
    }
    if (visitedVendors) {
        localStorage.setItem('visitedVendors', visitedVendors);
    }
    if(SocketItemModal){
        localStorage.setItem('SocketItemModal', SocketItemModal);
    }
    if(ItemQuantity){
        localStorage.setItem('ItemQuantity', ItemQuantity);
    }
};
