export const clearLocalStorageExceptToken = () => {
    const sessionAuthToken = sessionStorage.getItem('Bearer');
    const localAuthToken = localStorage.getItem('Bearer');
    const role = localStorage.getItem('role');
    const visitedItems = localStorage.getItem('visitedItems');
    const rememberMe = localStorage.getItem('rememberMe');

    localStorage.clear();

    if (sessionAuthToken) {
        sessionStorage.setItem('Bearer', sessionAuthToken);
    } else if (localAuthToken) {
        localStorage.setItem('Bearer', localAuthToken);
    }

    if (role) {
        localStorage.setItem('role', role);
    }
    if (rememberMe) {
        localStorage.setItem('rememberMe', rememberMe);
    }
    if (visitedItems) {
        localStorage.setItem('visitedItems', visitedItems);
    }
};
