import React, {useState, useEffect, useRef} from 'react';
import ProgressBar from "../ProgressBar/ProgressBar";
import './BOMProjectDetails.css';
import {Project} from "../../types/ProjectTypes";
import {IconPen, IconPenUsers} from "../../IconComponents/IconComponents";
import {BOMProject} from "../../types/ProjectBOMInterface";

interface ProjectDetailsProps {
    project: BOMProject;
    isEditingTitle: boolean;
    isEditingDate: boolean;
    newTitle: string;
    targetDate: string;
    handleEditTitleClick: () => void;
    handleEditDateClick: () => void;
    handleTitleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleTargetDateChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleBlur: () => void;
    isSmallScreen: boolean;
}

const BOMProjectDetailsHeader: React.FC<ProjectDetailsProps> = ({
                                                                    project,
                                                                    isEditingTitle,
                                                                    isEditingDate,
                                                                    newTitle,
                                                                    targetDate,
                                                                    handleEditTitleClick,
                                                                    handleEditDateClick,
                                                                    handleTitleChange,
                                                                    handleTargetDateChange,
                                                                    handleBlur,
                                                                    isSmallScreen
                                                                }) => {
    const [initialTitle, setInitialTitle] = useState<string>(project.title);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setInitialTitle(project.title);
    }, [project.title]);

    const formatDate = (dateString: string): string => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
    };

    const targetDateProject = () => {
        if (!project.target_date) {
            return formatDate(project.updated_at);
        } else if (!targetDate) {
            return formatDate(project.target_date);
        }
        return formatDate(targetDate);
    };

    const getTomorrowDate = (): string => {
        const today = new Date();
        today.setDate(today.getDate() + 1);
        return today.toISOString().split('T')[0];
    };

    const tomorrowDate = getTomorrowDate();

    const handleBlurTitle = () => {
        if (!newTitle.trim()) {
            handleTitleChange({
                target: {value: initialTitle}
            } as React.ChangeEvent<HTMLInputElement>);
        }
        handleBlur();
    };

    const handleEditTitleClickWithFocus = () => {
        handleEditTitleClick();
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };
    const hasLink = project.link !== null && project.link !== '';
    return (
        <>
            {!isSmallScreen ? (
                <div className='mh-bom' key={project.id}>
                    <div className='mh-name-bom'>
                        <div title='Rename project title' onClick={handleEditTitleClickWithFocus}
                             className={`mh-name-bom-block ${isEditingTitle ? 'editing' : ''}`}>
                            <input
                                type='text'
                                value={newTitle}
                                onChange={handleTitleChange}
                                onBlur={handleBlurTitle}
                                className='mh-name-bom-input'
                                ref={inputRef}
                                disabled={project.link !== null}
                            />
                            <div className='mh-name-bom-block-img' onClick={handleEditTitleClickWithFocus}>
                                <IconPenUsers/>
                            </div>
                        </div>
                    </div>
                    <div className='mh-date-bom'>
                        <p className="project-header-titles-bom">Creation date</p>
                        <div className='mh-date-block'>
                            <p className='mh-date-p-bom '>{formatDate(project.created_at)}</p>
                        </div>
                    </div>
                    <div className='mh-date-bom'>
                        <p className="project-header-titles-bom">Target date</p>
                        <div title='Settings date' className='mh-date-block'>
                            {!hasLink ? (
                                isEditingDate ? (
                                    <input
                                        type='date'
                                        value={targetDate}
                                        onChange={handleTargetDateChange}
                                        onBlur={handleBlur}
                                        className='mh-date-input'
                                        min={tomorrowDate}
                                    />
                                ) : (
                                    <p onClick={handleEditDateClick} className='mh-date-p project-target-date'>
                                        {targetDateProject()}
                                    </p>
                                )
                            ) : (
                                <p className='mh-date-p'>{formatDate(targetDate)}</p>
                            )}
                        </div>
                    </div>
                </div>
            ) : (


                <div className='mh' key={project.id}>
                    <div className='mh-name-bom'>
                        <p className="project-header-titles-project-mobile">Project space name</p>
                        <div onClick={handleEditTitleClickWithFocus}
                             className={`mh-name-bom-block ${isEditingTitle ? 'editing' : ''}`}>
                            <input
                                type='text'
                                value={newTitle}
                                onChange={handleTitleChange}
                                onBlur={handleBlurTitle}
                                className='mh-name-bom-input'
                                ref={inputRef}
                                disabled={hasLink}
                            />
                            <div className='mh-name-bom-block-img' onClick={handleEditTitleClickWithFocus}>
                                <IconPen fill={"#9D9D9D"} width={"18px"} height={"18px"}/>
                            </div>
                        </div>
                    </div>
                    <div className='mh-date-mobile'>
                        <div className='mh-date'>
                            <p className="project-header-titles">Creation date</p>
                            <div className='mh-date-block'>
                                <p className='mh-date-p '>{formatDate(project.created_at)}</p>
                            </div>
                        </div>
                        <div className='mh-date'>
                            <p className="project-header-titles">Target date</p>
                            <div className='mh-date-block'>
                                {!hasLink ? (
                                    isEditingDate ? (
                                        <input
                                            type='date'
                                            value={targetDate}
                                            onChange={handleTargetDateChange}
                                            onBlur={handleBlur}
                                            className='mh-date-input'
                                            min={tomorrowDate}
                                        />
                                    ) : (
                                        <p onClick={handleEditDateClick} className='mh-date-p project-target-date'>
                                            {targetDateProject()}
                                        </p>
                                    )
                                ) : (
                                    <p className='mh-date-p'>{formatDate(targetDate)}</p>
                                )}
                            </div>

                        </div>
                    </div>

                </div>
            )}
        </>
    );
};

export default BOMProjectDetailsHeader;
