import React, { useState, useEffect, ChangeEvent } from 'react';
import Modal from 'react-modal';
import { ItemType } from "../../../types/TemplateType";
import './ItemTypeBomModal.css';
import 'react-toastify/dist/ReactToastify.css';
import {
    toastItemCheckboxFalse,
    toastItemTrue
} from "../../../components/Toast/AssisCreateToastNotification";
import { IconArrowBottom, IconArrowTop, IconReload, IconSearch } from "../../../IconComponents/IconComponents";
import { Loader } from "../../../components/Loader/Loader";
import PaginationInModal from "../../../components/PaginationAssistance/paginationInModal";

interface ItemTypeBomModalProps {
    isOpen: boolean,
    onClose: () => void,
    itemTypes: ItemType[],
    loading: boolean,
    onReload?: () => void,
    currentPage: number,
    totalItems: number,
    itemsPerPage: number,
    onPageChange: (page: number) => void,
    onSearch?: (query: string) => void,
    onSortChange?: (direction: 'asc' | 'desc') => void,
    sortDirection?: 'asc' | 'desc',
    totalSearchResults?: number,
    onSelectItems: (selectedItems: ItemType[]) => void;
    initialSelectedItems?: ItemType[];
    onItemTypeCountChange: (itemId: number, count: number) => void;
    itemTypeCount: { [key: number]: number };
}


const ItemTypeBomModal: React.FC<ItemTypeBomModalProps> = ({
                                                               isOpen,
                                                               onClose,
                                                               itemTypes = [],
                                                               loading,
                                                               onReload,
                                                               currentPage,
                                                               totalItems,
                                                               itemsPerPage,
                                                               onPageChange,
                                                               onSearch,
                                                               onSortChange,
                                                               totalSearchResults,
                                                               onSelectItems,
                                                               initialSelectedItems,
                                                               onItemTypeCountChange,
                                                               itemTypeCount
                                                           }) => {
    const [selectedItemTypesState, setSelectedItemTypesState] = useState<{ [id: number]: ItemType[] }>({});
    const [searchTerm, setSearchTerm] = useState('');
    const [searchTimer, setSearchTimer] = useState<NodeJS.Timeout | null>(null);

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
            resetState();
        }

        return () => {
            document.body.style.overflow = '';
        };
    }, [isOpen]);

    useEffect(() => {
        if (isOpen && initialSelectedItems) {
            const newSelectedItemTypes: { [id: number]: ItemType[] } = {};

            initialSelectedItems.forEach(itemType => {
                newSelectedItemTypes[itemType.id] = newSelectedItemTypes[itemType.id] || [];
                newSelectedItemTypes[itemType.id].push(itemType);
            });

            setSelectedItemTypesState(newSelectedItemTypes);
        }
    }, [isOpen, initialSelectedItems]);




    const resetState = () => {
        setSelectedItemTypesState({});
        setSearchTerm('');
    };

    const handleConfirmSelection = () => {
        const selectedItems: ItemType[] = Object.values(selectedItemTypesState).flat();
        onSelectItems(selectedItems);
        onClose();
    };


    const handleAddItemClick = (itemType: ItemType) => {
        setSelectedItemTypesState(prevState => ({
            ...prevState,
            [itemType.id]: [...(prevState[itemType.id] || []), { ...itemType }]
        }));
        toastItemTrue(`${itemType.title} added`);
        onItemTypeCountChange(itemType.id, 1);
    };

    const handleRemoveItemClick = (itemType: ItemType) => {
        setSelectedItemTypesState(prevState => {
            const currentArray = prevState[itemType.id] || [];
            if (currentArray.length === 0) return prevState;

            return {
                ...prevState,
                [itemType.id]: currentArray.slice(0, -1)
            };
        });
        toastItemCheckboxFalse(`Removed ${itemType.title}`);
        onItemTypeCountChange(itemType.id, -1);
    };



    const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const query = e.target.value;
        setSearchTerm(query);
        if (searchTimer) {
            clearTimeout(searchTimer);
        }
        const timer = setTimeout(() => {
            if (onSearch) {
                onSearch(query);
            }
        }, 500);
        setSearchTimer(timer);
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            className="item-selection-modal-content"
            overlayClassName="item-selection-modal-overlay"
            ariaHideApp={false}
        >
            <section className="item-selection-modal-section">
                <div className='item-selection-modal-header'>
                    <div className='item-selection-modal-header-block-text'>
                        <h2 className="item-selection-modal-title">Please, select your product types</h2>
                    </div>
                    <button className="item-selection-modal-close-button" onClick={onClose}>
                        &#x2715;
                    </button>
                </div>
                <div className='item-selection-modal-search'>
                    <div className='item-selection-modal-search-icon'>
                        <IconSearch />
                    </div>
                    <input
                        className='item-selection-modal-search-input'
                        placeholder='Search'
                        type='search'
                        value={searchTerm}
                        onChange={handleSearchInputChange}
                    />
                </div>
                <div className='item-selection-modal-sort-icons'>
                    <div className='item-selection-modal-sort-buttons'>
                        <div className='item-selection-modal-sort-button' onClick={onReload}>
                            <IconReload />
                        </div>
                        <div className='item-selection-modal-sort-button' onClick={() => onSortChange && onSortChange('desc')}>
                            <IconArrowTop />
                        </div>
                        <div className='item-selection-modal-sort-button' onClick={() => onSortChange && onSortChange('asc')}>
                            <IconArrowBottom />
                        </div>
                    </div>
                </div>
                {loading ? (
                    <Loader />
                ) : (
                    <ul className="item-selection-modal-list">
                        {itemTypes.map((itemType: ItemType) => {
                            const currentCount = itemTypeCount[itemType.id] || 0;

                            return (
                                <li key={itemType.id} className="item-selection-modal-list-item">
                                    <section className="item-type-bom-modal-list-item-content">
                                        <div className="item-selection-modal-list-item-image">
                                            {itemType.galleries.length > 0 && itemType.galleries[0].medias[itemType.galleries[0].medias.length - 1]?.url ? (
                                                <img
                                                    className="item-selection-modal-image"
                                                    src={itemType.galleries[0].medias[itemType.galleries[0].medias.length - 1].url}
                                                    alt="Item"
                                                />
                                            ) : (
                                                <img
                                                    className="item-selection-modal-image"
                                                    src="/img_vendor/item.png"
                                                    alt="Item"
                                                />
                                            )}
                                            <section className="item-selection-modal-list-item-info">
                                                <p className="item-selection-modal-item-title">
                                                    {itemType.title}
                                                </p>
                                                <p className="item-type-bom-modal-items">{itemType.items.length} products</p>
                                            </section>
                                        </div>

                                        <div className='item-type-bom-modal-action-count-block'>
                                            {currentCount > 0 && (
                                                <span className="item-type-bom-modal-action-count">{currentCount}</span>
                                            )}
                                        </div>

                                        <div className='item-type-bom-modal-action-block-step1'>
                                            <button
                                                onClick={() => handleAddItemClick(itemType)}
                                                className="item-selection-modal-button add-item"
                                                style={{
                                                    cursor: 'pointer',
                                                    marginRight: '8px'
                                                }}
                                            >
                                                Add
                                            </button>
                                            <button
                                                onClick={() => handleRemoveItemClick(itemType)}
                                                className="item-selection-modal-button remove-item"
                                                disabled={currentCount === 0}
                                                style={{
                                                    cursor: currentCount === 0 ? 'not-allowed' : 'pointer'
                                                }}
                                            >
                                                Remove
                                            </button>
                                        </div>
                                    </section>
                                </li>
                            );
                        })}
                    </ul>

                )}
                <div className='item-selection-modal-save-button-block'>
                    <div>
                        <PaginationInModal
                            perPage={itemsPerPage}
                            total={searchTerm ? totalSearchResults || 0 : totalItems}
                            paginate={onPageChange}
                            currentPage={currentPage}
                        />
                    </div>
                    <button onClick={handleConfirmSelection} className="item-selection-modal-save-button">Confirm
                        selection
                    </button>
                </div>
            </section>
        </Modal>
    );

}

export default ItemTypeBomModal;
