export enum Vendor {
    Cashbuild = 'cashbuild',
    Afrisam = 'afrisam',
    LeroyMerlin = 'leroymerlincoza',
    Builders = 'builderscoza',
    HomeDepotCA = 'homedepotca',
    HomeDepotUSA = 'homedepotusa',
    HouzzUSA = 'houzzusa',
    LowesUSA = 'lowesusa'
}


const cashBuildLink = localStorage.getItem('cashBuildLink');
const tmR = localStorage.getItem('Bearer') || sessionStorage.getItem('Bearer');
const role = localStorage.getItem('role');

const displayedTMR = () =>{
    if(tmR && role){
        return '®'
    } else
        return '™'
}

export const vendorData: Record<Vendor, { displayName: string; url: string }> = {
    [Vendor.Cashbuild]: { displayName: `Cashbuild${displayedTMR()}`, url: cashBuildLink ? cashBuildLink : '' },
    [Vendor.Builders]: { displayName: `Builders${displayedTMR()}`, url: "https://www.builders.co.za/" },
    [Vendor.LeroyMerlin]: { displayName: `Leroy Merlin${displayedTMR()}`, url: "https://leroymerlin.co.za/" },
    [Vendor.HomeDepotCA]: { displayName: `Home Depot CA${displayedTMR()}`, url: "https://www.homedepot.ca" },
    [Vendor.HomeDepotUSA]: { displayName: `Home Depot USA${displayedTMR()}`, url: "https://www.homedepot.com" },
    [Vendor.HouzzUSA]: { displayName: `Houzz USA${displayedTMR()}`, url: "https://www.houzz.com" },
    [Vendor.LowesUSA]: { displayName: `Lowes USA${displayedTMR()}`, url: "https://www.lowes.com/" },
    [Vendor.Afrisam]: { displayName: `Afrisam${displayedTMR()}`, url: "https://www.afrisam.com/" }
};

// export const vendorLogos: Record<Vendor, string> = {
//     [Vendor.Cashbuild]: '/img_vendor/cashbuild.svg',
//     [Vendor.Afrisam]: '/img_vendor/afrisam.svg',
//     [Vendor.LeroyMerlin]: '/img_vendor/leroyMerlin.svg',
//     [Vendor.Builders]: '/img_vendor/builders.svg',
//     [Vendor.HomeDepotCA]: '/img_vendor/homeDepotCA.svg',
//     [Vendor.HomeDepotUSA]: '/img_vendor/homeDepotUSA.svg',
//     [Vendor.HouzzUSA]: '/img_vendor/houzzUSA.svg',
//     [Vendor.LowesUSA]: '/img_vendor/lowesUSA.svg'
// };
//
// export const getVendorLogo = (vendor: Vendor): string => {
//     return vendorLogos[vendor];
// };
