import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    getProjectBomById,
    updateProjectBOMById,
    updatePermissionsBOM,
    deleteUserProjectsPermissionsBOM
} from "../../api/APIWraper";
import {BOMProject} from "../../types/ProjectBOMInterface";

interface ProjectsState {
    currentProjectBOM: BOMProject | null;
    isFetching: boolean;
    error: string | null;
    permissionsUpdated: boolean;
    updateStatus: 'idle' | 'pending' | 'succeeded' | 'failed';
}

const initialState: ProjectsState = {
    currentProjectBOM: null,
    isFetching: false,
    error: null,
    permissionsUpdated: false,
    updateStatus: 'idle',
};

export const updateProjectBOM = createAsyncThunk<BOMProject, { projectId: number, projectData: any }>(
    'projectsBOM/updateProjectBOM',
    async ({ projectId, projectData }) => {
        const response = await updateProjectBOMById(projectId, projectData);
        return response.data;
    }
);

export const updateProjectTitleBOM = createAsyncThunk<BOMProject, { projectId: number, title?: string, target_date?: string }>(
    'projectByIdBOM/updateProjectTitleBOM',
    async ({ projectId, title, target_date }) => {
        return await updateProjectBOMById(projectId, { title, target_date });
    }
);

export const fetchProjectByIdBOM = createAsyncThunk<BOMProject, string>(
    'projectsBOM/fetchProjectByIdBOM',
    async (projectId) => {
        return await getProjectBomById(projectId);
    }
);

export const updateProjectPermissionsBOM = createAsyncThunk<BOMProject, { projectId: string, email: string, permissions: string[] }>(
    'projectByIdBOM/updateProjectPermissionsBOM',
    async ({ projectId, email, permissions }) => {
        const response = await updatePermissionsBOM(projectId, { email, permissions });
        return response.data;
    }
);

export const deleteUserPermissionsBOM = createAsyncThunk(
    'updateProjectBOM/deleteUserPermissionsBOM',
    async ({ userId, projects_id }: { userId: number, projects_id: number[] }) => {
        await deleteUserProjectsPermissionsBOM(userId, { projects_id, permissions: [] });
        return { userId, projects_id };
    }
);

const projectsSliceBOM = createSlice({
    name: 'projectsBOM',
    initialState,
    reducers: {
        setProjectTitleBOM(state, action: PayloadAction<{ projectId: number, title?: string, target_date: string }>) {
            if (state.currentProjectBOM && state.currentProjectBOM.id === action.payload.projectId) {
                if (action.payload.title) state.currentProjectBOM.title = action.payload.title;
                if (action.payload.target_date) state.currentProjectBOM.target_date = action.payload.target_date;
            }
        },
        setProject(state, action: PayloadAction<BOMProject>) {
            state.currentProjectBOM = action.payload;
        },
        clearProject(state) {
            state.currentProjectBOM = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchProjectByIdBOM.pending, (state) => {
                state.isFetching = true;
            })
            .addCase(fetchProjectByIdBOM.fulfilled, (state, action: PayloadAction<BOMProject>) => {
                state.isFetching = false;
                state.currentProjectBOM = action.payload;
                state.permissionsUpdated = false;
            })
            .addCase(fetchProjectByIdBOM.rejected, (state, action) => {
                state.isFetching = false;
                state.error = action.error.message ?? null;
            })
            .addCase(updateProjectBOM.pending, (state) => {
                state.isFetching = true;
                state.updateStatus = 'pending';
            })
            .addCase(updateProjectBOM.fulfilled, (state, action: PayloadAction<BOMProject>) => {
                state.currentProjectBOM = action.payload;
                state.isFetching = false;
                state.updateStatus = 'succeeded';
            })
            .addCase(updateProjectBOM.rejected, (state, action) => {
                state.isFetching = false;
                state.error = action.error.message ?? null;
                state.updateStatus = 'failed';
            })
            .addCase(updateProjectPermissionsBOM.pending, (state) => {
                state.isFetching = true;
                state.updateStatus = 'pending';
            })
            .addCase(updateProjectPermissionsBOM.fulfilled, (state, action: PayloadAction<BOMProject>) => {
                state.isFetching = false;
                state.currentProjectBOM = action.payload;
                state.updateStatus = 'succeeded';
            })
            .addCase(updateProjectPermissionsBOM.rejected, (state, action) => {
                state.isFetching = false;
                state.error = action.error.message ?? null;
            })
            .addCase(deleteUserPermissionsBOM.pending, (state) => {
                state.isFetching = true;
                state.updateStatus = 'pending';
            })
            .addCase(deleteUserPermissionsBOM.fulfilled, (state, action: PayloadAction<{ userId: number, projects_id: number[] }>) => {
                state.isFetching = false;
                state.permissionsUpdated = true;
                state.updateStatus = 'succeeded';
                if (state.currentProjectBOM && action.payload.projects_id.length > 0) {
                    state.currentProjectBOM = {
                        ...state.currentProjectBOM,
                        permissions: state.currentProjectBOM.permissions.filter(
                            permission => !(action.payload.projects_id.includes(permission.project_id) && permission.user_id === action.payload.userId)
                        )
                    };
                }
            })
            .addCase(deleteUserPermissionsBOM.rejected, (state, action) => {
                state.isFetching = false;
                state.error = action.error.message ?? null;
                state.updateStatus = 'failed';
            });

    },
});

export const { setProject,setProjectTitleBOM, clearProject } = projectsSliceBOM.actions;

export default projectsSliceBOM.reducer;
